export default {
  data: () => ({
    editedItem: {},
  }),
  methods: {
    async openModal(type, width, path) {
      switch (type) {
        case "add":
          await this.$store.dispatch("popupModal/CREATE", {
            componentName: `${path}`,
            props: this.editedItem,
            title: `Add`,
            width: width,
          });
          break;
        case "edit":
          await this.$store.dispatch("popupModal/UPDATE", {
            componentName: `${path}`,
            props: this.editedItem,
            title: `Edit`,
            width: width,
          });
          break;
        default:
          return false;
      }
    },
  },
};
