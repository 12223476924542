<template>
  <v-text-field
    v-if="type === 'short_answer'"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
    class="solo-cust"
    placeholder="Please enter your input"
  ></v-text-field>

  <v-textarea
    v-else-if="type === 'long_answer'"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
    class="solo-cust"
    placeholder="Please enter your input"
  ></v-textarea>

  <div v-else-if="['date', 'time', 'date_time'].includes(type)">
    <v-row>
      <v-col :cols="type === 'date_time' ? 6 : 12">
        <v-menu
          v-if="['date', 'date_time'].includes(type)"
          v-model="datepickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              autocomplete="off"
              prepend-inner-icon="mdil-calendar"
              clearable
              clear-icon="mdi-close-circle-outline"
              :value="dateUkFormat(date)"
              solo
              outlined
              v-on="on"
              flat
              hide-details="auto"
              class="solo-cust"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title @input="datepickerMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col :cols="type === 'date_time' ? 6 : 12">
        <v-menu
          v-if="['time', 'date_time'].includes(type)"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              flat
              hide-details="auto"
              solo
              class="solo-cust mt-5"
              label="To"
              append-icon="mdil-clock"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            :allowed-minutes="allowedStep"
            no-title
            ampm-in-title
            format="24hr"
            full-width
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>

  <div v-else-if="type === 'checkbox'">
    <v-checkbox
      v-for="(item, index) in answerItem"
      :key="item.id"
      :label="item.text"
      :value="item.value"
      off-icon="mdi-checkbox-blank-circle-outline"
      on-icon="mdi-check-circle"
      class="mt-0"
      hide-details="auto"
    >
      <template v-slot:label>
        <span class="font-13 line-h-16 text-black-300 font-weight--semibold mt-0">{{
          item.text
        }}</span>
      </template>
    </v-checkbox>
  </div>
  <v-radio-group class="mt-0" v-else-if="type === 'radio'">
    <v-radio
      v-for="(item, index) in answerItem"
      :key="item.id"
      :label="item.text"
      :value="item.value"
      off-icon="mdi-checkbox-blank-circle-outline"
      on-icon="mdi-check-circle"
      class="mt-0"
      hide-details="auto"
    >
      <template v-slot:label>
        <span class="font-13 line-h-16 text-black-300 font-weight--semibold mt-0">{{
          item.text
        }}</span>
      </template>
    </v-radio>
  </v-radio-group>
  <v-select
    v-else-if="type === 'dropdown'"
    :items="answerItem"
    class="solo-cust text-subtitle-1 mr-2"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
  >
  </v-select>
  <v-select
    v-else-if="type === 'dropdown_multi_select'"
    multiple
    small-chips
    deletable-chips
    :items="answerItem"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
    class="solo-cust"
  >
  </v-select>
</template>
<script>
import globalFn from "../../mixins/globalFn";
export default {
  mixins: [globalFn],
  props: ["type", "answers"],
  date: () => ({
    datepickerMenu: false,
    date: new Date().toISOString(),
  }),
  computed: {
    answerItem() {
      return this.answers.map((item) => {
        return {
          text: item.title,
          value: item.id,
        };
      });
    },
  },
  methods: {
    allowedStep: (m) => m % 15 === 0,
  },
};
</script>
<style lang="scss" scoped></style>
