import http from "../../http/common";

const resource = "/client";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

  getList: (payload) => http.get(`${resource}${payload}`),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  convertToLead: (payload) => http.post(`${resource}/convert-client-to-lead`, payload),

  archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

  comment: (payload) => http.post(`${resource}/add-comment`, payload),

  upload: (payload) => http.post(`${resource}/upload-docs`, payload),

  clientCount: (payload) => http.post(`${resource}/client-count`, payload),

  assignedTo: (payload) => http.get(`${resource}/staff-list`, payload),
};
