<template>
  <div class="support-wrap">
    <v-expansion-panels multiple>
      <template v-for="(treat, index) in treatments">
        <v-expansion-panel :key="index">
          <v-expansion-panel-header>
            <span class="total mr-2" style="width: 250px">{{ treat.treatment_name }}</span>
            <span class="total mr-2" style="width: 150px"
              ><span class="sold-by">No of Sessions: </span> {{ treat.appointments_completed }}/{{
                treat.no_of_sessions
              }}</span
            >
            <span class="total mr-2" style="width: 150px"
              ><span class="sold-by">Total: </span>£{{ treat.cost }}</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content class="group-panel-content">
            <v-data-table
              class="history-table form-wrap"
              :headers="headers"
              :items="treat.deal_appointments"
            >
              <template v-slot:item.appointment_date="{ item }">
                <template v-if="item.status === 16">
                  {{ item.appointment_date | moment("DD/MM/YY") }}
                </template>
                <template v-else>
                  <v-menu
                    v-model="appointmentDateMenu[item.id]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        :value="dateUkFormat(item.appointment_date)"
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="solo-cust deal-slot"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.appointment_date"
                      no-title
                      @input="appointmentDateMenu[item.id] = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </template>
              <template v-slot:item.appointment_from_time="{ item }">
                <template v-if="item.status === 16">
                  <span>{{ item.appointment_from_time }}</span>
                </template>
                <template v-else>
                  <v-menu
                    v-model="appointmentFromTimeMenu[item.id]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        hide-details="auto"
                        solo
                        outlined
                        class="solo-cust deal-slot"
                        v-model="item.appointment_from_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-model="item.appointment_from_time"
                      full-width
                    ></v-time-picker>
                  </v-menu>
                </template>
              </template>
              <template v-slot:item.appointment_to_time="{ item }">
                <template v-if="item.status === 16">
                  <span>{{ item.appointment_to_time }}</span>
                </template>
                <template v-else>
                  <v-menu
                    v-model="appointmentToTimeMenu[item.id]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        hide-details="auto"
                        solo
                        outlined
                        class="solo-cust deal-slot"
                        v-model="item.appointment_to_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-model="item.appointment_to_time"
                      full-width
                    ></v-time-picker>
                  </v-menu>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="item.status !== 16">
                  <div class="d-flex justify-center">
                    <v-btn icon color="green" @click="update(item, treat)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="cancel(item, treat)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="secondary"
                      :loading="reminderLoading"
                      @click="SendReminder(item)"
                    >
                      <v-icon>mdi-message-text-clock-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-else>
                  <v-chip
                    small
                    class="este-chip status-chip"
                    :class="item.status === 6 ? 'text-uppercase' : ''"
                    :outlined="item.status === 11 || item.status === 12 ? true : false"
                    :text-color="item.status === 12 ? getColor(item.status) : '#221F20'"
                    :color="getColor(item.status)"
                  >
                    {{ getStatus(item.status) | capitalize }}
                  </v-chip>
                </template>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../store/action-types.js";
import globalFn from "../../mixins/globalFn.js";
import status from "../../mixins/status.js";
export default {
  name: "Appointments",
  mixins: [globalFn, status],
  props: {
    dealId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Date",
        align: "start",
        value: "appointment_date",
        sortable: false,
      },
      { text: "Time From", value: "appointment_from_time", sortable: false },
      { text: "Time To", value: "appointment_to_time", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    rescheduleLoading: false,
    reminderLoading: false,
    updateLoading: false,
    cancelLoading: false,
    appointmentDateMenu: [],
    appointmentFromTimeMenu: [],
    appointmentToTimeMenu: [],
    treatments: [],
  }),
  mounted() {
    this.showSkeleton = true;
    this.loading = true;
    this.appointments();
  },
  methods: {
    async appointments() {
      await this.$store
        .dispatch("deals/SHOW_DEAL", { id: this.dealId, params: `?attributes=dealTreatments` })
        .then((res) => {
          this.treatments = res.data.deal_treatments;
        });
    },
    async update(item, treat) {
      this.updateLoading = true;
      let formData = {};
      formData.id = item.id;
      formData.user_id = item.user_id;
      formData.treatment_id = treat.treatment_id;
      formData.deal_treatment_id = item.deal_treatment_id;
      formData.appointment_date = item.appointment_date;
      formData.appointment_type = item.appointment_type;
      formData.appointment_from_time = item.appointment_from_time;
      formData.appointment_to_time = item.appointment_to_time;
      formData.status =
        item.appointment_date && item.appointment_from_time && item.appointment_to_time
          ? 5
          : item.status;
      formData.branch_id = item.branch_id;
      await this.$store
        .dispatch("appointments/UPDATE_APPOINTMENT", formData)
        .then((response) => {
          if (response.result === "success") {
            this.updateLoading = false;
            this.appointments();
          } else if (response.result === "fail") {
            response.type = "error";
            this.updateLoading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.updateLoading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async cancel(item, treat) {
      this.cancelLoading = true;
      const status = 16;
      await this.$store
        .dispatch("appointments/UPDATE_STATUS", { id: item.id, status: status })
        .then((res) => {
          if (res.result === "success") {
            this.cancelLoading = false;
            this.appointments();
          } else if (res.result === "fail") {
            this.cancelLoading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.cancelLoading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async SendReminder(item) {
      this.reminderLoading = true;
      await this.$store
        .dispatch("sms/SEND_SMS", { id: item.id, model: "appointment" })
        .then((res) => {
          if (res.result === "success") {
            this.reminderLoading = false;
            res.type = "success";
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            this.reminderLoading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.reminderLoading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
<style scoped></style>
