import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import rightSlider from "./modules/RightSlider";
import treatments from "./modules/Treatments";
import branches from "./modules/Branches";
import symptoms from "./modules/Symptoms";
import reasons from "./modules/Reasons";
import status from "./modules/Status";
import leads from "./modules/Leads";
import clients from "./modules/Clients";
import leadDetail from "./modules/LeadDetails";
import snackbar from "./modules/Snackbar";
import complaints from "./modules/Complaints";
import staff from "./modules/Staff";
import appointments from "./modules/Appointments";
import machines from "./modules/Machines";
import categories from "./modules/Categories";
import products from "./modules/Products";
import deals from "./modules/Deals";
import payments from "./modules/Payments";
import source from "./modules/Source";
import audit from "./modules/Audit";
import docs from "./modules/Docs";
import reports from "./modules/Reports";
import insights from "./modules/Insights";
import purchaseInvoices from "./modules/PurchaseInvoices";
import cashPayments from "./modules/CashPayments";
import invoicePayments from "./modules/InvoicePayments";
import statistics from "./modules/Statistics";
import callback from "./modules/Callback";
import scheduler from "./modules/EmailScheduler";
import user from "./modules/User";
import dashboard from "./modules/Dashboard";
import pulse from "./modules/PulseDashboard";
import configurations from "./modules/Configurations";
import points from "./modules/Points";
import sms from "./modules/SendSms";
import sales from "./modules/Sales";
import customers from "./modules/Customers";
import campaign from "./modules/Campaign";
import offers from "./modules/Offers";
import opportunities from "./modules/Opportunities";
import contacts from "./modules/Contacts";
import notifications from "./modules/Notifications";
import warehouse from "./modules/Warehouse";
import stock from "./modules/Stock";
import form from "./modules/Forms";
import formElement from "./modules/Forms/elements";
import formPatientCrm from "./modules/Forms/patientCrm";
import formType from "./modules/Forms/type";
import formAnswer from "./modules/Forms/answer";
import rooms from "./modules/Rooms";
import appointmentNotes from "./modules/AppointmentNotes";
import popupModal from "./modules/PopupModal";
import rota from "./modules/Rota";
import financials from "./modules/Financials";
import photos from "./modules/Photos";
import albums from "./modules/Albums";
import shiftTemplate from "./modules/ShiftTemplate";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    wait: {},
  },
  getters: {
    is(state) {
      return (payload) => {
        return state.wait[payload] || false;
      };
    },
    any(state) {
      for (const key in state.wait) {
        if (state.wait[key]) return true;
      }
    },
  },
  mutations: {
    START_LOADING(state, payload) {
      var obj = {};
      obj[payload] = true;
      state.wait = Object.assign({}, obj);
    },
    STOP_LOADING(state, payload) {
      var obj = {};
      obj[payload] = false;
      state.wait = Object.assign({}, obj);
    },
    NOTIFY_SUCCESS(state, payload) {},
    NOTIFY_ERROR(state, payload) {},
  },
  actions: {
    handleError({ dispatch, commit }, errorObj) {
      const response = errorObj.error.response;
      const options = {
        title: "",
        type: "",
        message: "",
      };
      if (response) {
        const status = response.status;
        if (status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("screen");
          window.location.href = "/";
        } else if (status === 413) {
          options.title = "";
          options.type = "error";
          options.message = "Request Entity Too Large";
        } else {
          options.title = "Unable to validate!";
          options.type = "error";
          options.message = response.data.message;
        }
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("screen");
        window.location.href = "/";
      }
      if (response.status !== 500) {
        dispatch("snackbar/SHOW", options);
      }
    },

    handleSuccess({ dispatch, commit }, obj) {
      const options = {
        title: "",
        type: "",
        message: "",
      };

      if (obj.toaster) {
        const res = obj.res;
        if (res.result) {
          options.title = "Success!";
          options.type = "success";
          options.message = res.message;
        } else if (res.status_code === 401) {
          options.title = "Whoops!";
          options.type = "error";
          options.message = res.message;
        } else if (res.status_code === 422 || res.status_code === 403) {
          options.title = "Unable to validate!";
          options.type = "error";
          options.message = res.display_message;
          options.details = res.error.details;
        } else {
          options.title = "Whoops!";
          options.type = "error";
          options.message = "Something went wrong, Please try again later";
        }
        // dispatch('snackbar/SHOW', options)
      }
    },

    clearUserData({ commit }) {},

    start({ commit }, payload) {
      commit("START_LOADING", payload);
    },
    end({ commit }, payload) {
      commit("STOP_LOADING", payload);
    },
  },
  modules: {
    auth,
    rightSlider,
    treatments,
    branches,
    symptoms,
    reasons,
    status,
    leads,
    snackbar,
    leadDetail,
    clients,
    complaints,
    staff,
    appointments,
    machines,
    categories,
    products,
    deals,
    payments,
    source,
    audit,
    docs,
    reports,
    insights,
    purchaseInvoices,
    cashPayments,
    invoicePayments,
    statistics,
    callback,
    scheduler,
    user,
    dashboard,
    pulse,
    configurations,
    sms,
    points,
    sales,
    customers,
    campaign,
    offers,
    opportunities,
    contacts,
    notifications,
    warehouse,
    stock,
    form,
    formAnswer,
    formElement,
    formType,
    rooms,
    formPatientCrm,
    appointmentNotes,
    popupModal,
    rota,
    financials,
    photos,
    albums,
    shiftTemplate,
  },
});
