import collection from "../../../api/collections/CashPayments";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_CASH_PAYMENT(state, data) {
      state.list = data;
    },
    SET_CASH_PAYMENT_ITEM(state, data) {
      state.item = data;
    },
    RESET_CASH_PAYMENT(state, data) {
      state.item = {};
    },
    SET_CASH_PAYMENT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_CASH_PAYMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload.data, payload.params);
          commit("SET_CASH_PAYMENT", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CASH_PAYMENT",
        true
      );
    },
    async CREATE_CASH_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_CASH_PAYMENT",
        true
      );
      return data;
    },
    async SHOW_CASH_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_CASH_PAYMENT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_CASH_PAYMENT",
        false
      );
      return data;
    },
    async UPDATE_CASH_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_CASH_PAYMENT",
        true
      );

      return data;
    },
    async DELETE_CASH_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_CASH_PAYMENT",
        true
      );
      return data;
    },
  },
};
