<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-row>
          <template v-for="(item, index) in widgets">
            <v-col>
              <div
                class="widget"
                :class="item.active ? 'selected' : ''"
                @click="widgetFilter(item)"
              >
                <span class="active" v-if="item.active">
                  <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                </span>
                <div class="content">
                  <!-- <div class="mb-5">
                    <span class="m-0">
                      <inline-svg width="34" height="34" :src="item.svg" />
                    </span>
                  </div> -->
                  <p class="number">{{ item.count }}</p>
                  <p class="text">{{ item.name }}</p>
                </div>
                <span class="bottom-arrow" v-if="item.active"></span>
              </div>
            </v-col>
          </template>
        </v-row>
        <v-card flat class="pa-0 mt-5">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.user="{ item }">
                <div class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor">
                  <span
                    class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                    v-if="item.user.name"
                    @click="$router.push({ path: `/view-contact/${item.user.id}` })"
                  >
                    {{ item.user.name }}
                  </span>
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border"
                    v-if="!item.user.name && !item.user.social_handle"
                    >Not Supplied</span
                  >
                  <span
                    class="font-14 line-h-17 font-weight--bold text-black-100 ml-2 d-flex align-center justify-center"
                    v-if="item.user.name === null"
                    @click="$router.push({ path: `/view-contact/${item.user.id}` })"
                  >
                    <!-- <img :src="`/images/${item.user.source_name.toLowerCase()}.svg`" class="mr-1" v-if="item.user.source_name !== null"> -->
                    {{ item.user.social_handle }}
                  </span>
                  <span
                    class="badge--chip small ml-2 bg-gold-100 font-weight--bold text-black-300 font-11 line-h-13 cursor"
                    v-if="item.opportunity_count > 1"
                    @click="filterOpportuntiyCount(item)"
                  >
                    {{ item.opportunity_count }}
                  </span>
                  <span
                    class="position-absolute small mt-n5 ml-n1 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                    :class="item.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                  >
                    {{ item.user.user_type_name === "Lead" ? "L" : "C" }}
                  </span>
                </div>
                <div class="d-flex align-center mt-2 ml-2">
                  <span
                    class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2"
                    v-if="item.user.contact_no"
                  >
                    {{ item.user.contact_no }}
                    <v-tooltip
                      content-class="copy-tooltip"
                      :open-on-hover="false"
                      :open-on-click="true"
                      color="#000"
                      text-color="#fff"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyContent(item.user.contact_no)" v-on="on" size="15"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copied to clipboard</span>
                    </v-tooltip>
                  </span>
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                    v-if="item.branch_name"
                    >{{ item.branch_name }}</span
                  >
                </div>
              </template>
              <template v-slot:item.phone="{ item }">
                <div
                  class="font-13 line-h-16 font-weight--semibold text-black-300"
                  v-if="item.email !== null"
                >
                  {{ item.user.email }}
                </div>
              </template>
              <template v-slot:item.appointment_date="{ item }">
                <div class="d-flex flex-column align-start">
                  <span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
                    {{ item.enquiry_date | moment("DD/MM/YY") }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300"
                    v-if="item.appointment_date"
                  >
                    {{ item.appointment_date | moment("DD/MM/YY") }}
                    {{ item.appointment_from_time }}-{{ item.appointment_to_time }}
                    <img
                      src="/images/icons/duotune/arrows/arr050.svg"
                      class="text-black-100 ma-0 ml-1"
                      width="18"
                      height="18"
                    />
                  </span>
                  <span
                    v-else
                    @click="bookApp(item)"
                    class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor d-block"
                    icon
                  >
                    Book Now
                  </span>
                </div>
              </template>
              <template v-slot:item.treatments="{ item }">
                <div class="mb-2" v-if="item.appointment_type !== null">
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                  >
                    {{ item.appointment_type | capitalize }}</span
                  >
                </div>
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0, 2)">
                    <span
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                    >
                      {{ tag.name | capitalize }}</span
                    >
                  </template>
                </template>
                <span
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                  v-if="item.treatments.length > 2"
                  >+{{ item.treatments.length - 2 }}</span
                >
              </template>
              <template v-slot:item.responsible="{ item }">
                <v-tooltip
                  content-class="este-tooltip"
                  color="#000"
                  text-color="#fff"
                  top
                  v-if="item.consultant !== null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.consultant.name) }}
                    </span>
                  </template>
                  <span v-if="item.responsible !== null">{{ item.consultant.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>

                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Appointment</a></li>
                      <li><a @click="SmsReminder(item)">Send Reminder</a></li>
                      <li><a @click="deal(item)">Create Deal</a></li>
                      <li>
                        <a class="text-mute" @click="archiveItem(item)" v-if="item.status !== 7"
                          >Archive Appointment</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import eventBus from "../../../eventBus.js";
import AppointmentWidgets from "../../../mixins/AppointmentWidgets";
import modalFn from "../../../mixins/modalFn.js";
import moment from "moment";
export default {
  name: "Complaints",
  mixins: [globalFn, sliderFn, status, AppointmentWidgets, modalFn],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      showCalendar: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "user", align: "left", sortable: false },
        { text: "Contact Details", value: "phone", align: "left", sortable: false },
        { text: "Type / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        {
          text: "Enquiry / Booking Date",
          value: "appointment_date",
          sortable: false,
          align: "left",
        },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      AppointmentParams: {
        attributes: "user,treatments,consultations,consultant,branch",
        search: "",
        type: "",
        filter: {
          enquiry_date_from: "",
          enquiry_date_to: "",
          appointment_date_from: "",
          appointment_date_to: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
      },
      showAppointmentParams: "?attributes=user,treatments,consultations,consultant,branch",
      message: "",
      action: "",
      actionText: "",
      calendarData: [],
      start: "",
      end: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("appointmentsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchAppointments", this.getSearchAppointments);
  },
  created() {
    window.Echo.channel("new-appointment").listen(".appointment-created", (e) => {
      this.options.page = 1;
      let res = [];
      res.type = "success";
      res.treatments = [];
      res.treatments = e.data.treatments;
      let name = e.data.name ? e.data.name : `@ ${e.data.social_handle}`;
      res.message =
        e.data.treatments.length > 0
          ? `New Appointment Added: ${name} for`
          : `New Appointment Added: ${name}`;
      if (this.user.user_type_name === "Admin") {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      } else if (
        this.user.permission_branches.some((el) => el.id === e.data.branch_id) ||
        this.user.branch_id === e.data.branch_id
      ) {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      }
    });
  },
  methods: {
    async getSearchAppointments(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.AppointmentParams.widget_filter = "";
      }
      this.AppointmentParams.search = data.search;
      this.AppointmentParams.filter.enquiry_date_from = data.enquiry_date_from;
      this.AppointmentParams.filter.enquiry_date_to = data.enquiry_date_to;
      this.AppointmentParams.filter.appointment_date_from = data.appointment_date_from;
      this.AppointmentParams.filter.appointment_date_to = data.appointment_date_to;
      this.AppointmentParams.filter.status = data.status;
      this.AppointmentParams.filter.branch = data.branch;
      this.AppointmentParams.filter.source = data.source;
      this.AppointmentParams.filter.assigned = data.assigned;
      this.AppointmentParams.filter.assigned_to = data.assigned_to;
      this.AppointmentParams.filter.source_blank = data.source_blank;
      await this.loadAppointmentCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      this.options.page = 1;
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.AppointmentParams.widget_filter = item.active ? item.slug : "";
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("appointments/GET_APPOINTMENT", {
          data: this.AppointmentParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      // let result = [];
      // await this.$store
      //   .dispatch("appointments/SHOW_APPOINTMENT", {
      //     id: item.id,
      //     param: this.showAppointmentParams,
      //   })
      //   .then((res) => {
      //     if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
      //       this.openDrawer("add", "1100", "appointments/editPane");
      //     } else {
      //       this.openDrawer("add", "750", "appointments/editTab");
      //     }
      //     result = res.data;
      //   });
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: "?attributes=user,treatments,consultations,consultant,branch",
        })
        .then((res) => {
          this.openDrawer("add", "750", "calendar/editAppointment");
        });
    },
    async deal(item) {
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: this.showAppointmentParams,
        })
        .then((res) => {
          this.$root.user_id = item.user_id;
          this.openModal("add", "100%", "consent/addDealGrid");
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async SmsReminder(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to send SMS reminder this to client?";
      this.actionText = "Confirm";
      this.action = "SendReminder";
    },
    async SendReminder() {
      await this.$store
        .dispatch("sms/SEND_SMS", { id: this.selectedItem.id, model: "appointment" })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            res.type = "success";
            this.$store.dispatch("snackbar/SHOW", res);
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this appointment?";
      this.actionText = "Archive";
      this.action = "archiveAppointment";
    },
    async archiveAppointment() {
      this.selectedItem.status = 7;
      await this.$store
        .dispatch("appointments/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    bookApp(item) {
      localStorage.setItem("toBeScheduleAppointment", JSON.stringify(item));
      const routeData = this.$router.resolve({ path: `/calendar/appointments` });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
