<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Payment</div>
        <v-divider class="py-5"></v-divider>
        <v-form ref="paymentDealForm">
          <v-row class="e-row">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Deals </span>
              </label>
              <v-autocomplete
                :rules="rules.deal_id"
                v-model="paymentForm.deal_id"
                :items="deals"
                item-text="deal_info"
                item-value="id"
                chips
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="este-autocomplete-input"
              >
                <template slot="append">
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    class="este-chip tag-chip px-3"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                  >
                    <span class="pr-2">
                      {{ data.item.deal_info }}
                    </span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Total </span>
              </label>
              <v-text-field
                :rules="rules.total"
                v-model="paymentForm.total"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust"
                placeholder="£1999"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Taken By </span>
              </label>
              <v-select
                autocomplete="off"
                v-model="paymentForm.take_by"
                :items="staff"
                item-text="name"
                item-value="id"
                solo
                outlined
                flat
                class="solo-cust"
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Method </span>
              </label>
              <v-select
                autocomplete="off"
                :rules="rules.method"
                v-model="paymentForm.method"
                :items="paymentMethods"
                item-value="id"
                item-text="name"
                solo
                outlined
                flat
                class="solo-cust"
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Date </span>
              </label>
              <v-menu
                v-model="paymentDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentDate"
                    solo
                    outlined
                    v-on="on"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="paymentForm.date"
                  no-title
                  @input="paymentDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="confirmPayment"
        >
          Confirm
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
export default {
  mixins: [globalFn, status],
  data: () => ({
    paymentDateMenu: false,
    loading: false,
    payments: [],
    deals: [],
    paymentForm: {
      deal_id: "",
      method: "",
      total: "",
      date: new Date().toISOString().substr(0, 10),
      take_by: null,
    },
    rules: {
      deal_id: [(v) => !!v || "Please select deal"],
      method: [(v) => !!v || "Please select method"],
      total: [(v) => !!v || "Please enter total value"],
    },
  }),
  computed: {
    paymentDate: {
      get() {
        if (this.paymentForm.date.length) {
          const a = this.formatDate(this.paymentForm.date);
          return a;
        }
      },
      set(value) {
        this.value = new Date().toISOString().substr(0, 10);
      },
    },

    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  created() {
    this.$store.dispatch("staff/GET_STAFF_LIST", {
      data: this.staffParams,
      params: "",
    });
  },
  mounted() {
    this.loadDeals();
  },
  methods: {
    async loadDeals() {
      await this.$store
        .dispatch("deals/GET_ALL_DEAL", {
          data: { client_id: this.$router.currentRoute.params.id },
          params: `?page=1&per_page=100`,
        })
        .then((res) => {
          this.deals = res.data.data;
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$root.$emit("monitorTabEvent", { component: "Financials" });
    },
    async confirmPayment() {
      let formData = {};
      formData.deal_id = this.paymentForm.deal_id;
      formData.method = this.paymentForm.method;
      formData.total = this.paymentForm.total;
      formData.take_by = this.paymentForm.take_by;
      formData.date = this.changeDateFormat(this.paymentDate);
      if (this.$refs.paymentDealForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("payments/CREATE_PAYMENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.close();
              this.$refs.paymentDealForm.reset();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
              this.$refs.paymentDealForm.reset();
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
            this.$refs.paymentDealForm.reset();
          });
      }
    },
  },
};
</script>
