<template>
  <v-card class="overflow-hidden" elevation="0" style="position: fixed; inset: 0">
    <v-toolbar color="white" elevation="0">
      <v-btn icon text @click="close">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      <v-toolbar-title>Add Deal</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text class="pa-3 border-t">
      <v-row class="h-100">
        <v-col cols="1" class="pa-0 border-r">
          <v-list flat>
            <v-list-item-group color="secondary" v-model="activeComponent">
              <v-list-item
                :ripple="false"
                v-for="(tab, index) in tabs"
                :key="index"
                :value="tab.component"
                class="py-8 d-flex justify-center align-center border-b"
              >
                <div class="text-center">
                  <v-icon>{{ tab.icon }}</v-icon>

                  <v-list-item-title class="text-body-2 mt-2">{{ tab.name }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col
          cols="8"
          class="border-r py-0 hide-scrollbar"
          style="overflow-y: scroll; height: 93vh"
        >
          <v-row>
            <v-col
              cols="12"
              class="border-b"
              style="position: sticky; top: 0%; background: white; z-index: 5"
            >
              <v-row align="center">
                <v-col cols="3">
                  <!-- <div class="text-capitalize">
                    {{ activeComponent.title }}
                  </div> -->
                  <div class="text-capitalize text-h6 font-weight-bold">
                    {{ activeComponent.title }}
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-text-field
                    flat
                    solo
                    hide-details
                    class="solo-cust"
                    dense
                    append-inner-icon="mdi-magnify"
                    placeholder="Search"
                    v-model="search"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-8">
              <KeepAlive>
                <component
                  :is="activeComponent.value"
                  :search="search"
                  :formObj="formObj"
                ></component>
              </KeepAlive>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="3" class="border-r hide-scrollbar" style="overflow-y: scroll; height: 93vh">
          <v-form ref="formRef">
            <v-row>
              <v-col cols="12">
                <v-label class="text-subtitle-1 font-weight-bold"> Deal Info </v-label>
                <v-text-field
                  flat
                  solo
                  class="solo-cust mt-2"
                  dense
                  hide-details
                  v-model="formObj.deal_info"
                  :rules="rules.deal_info"
                ></v-text-field>

                <v-label class="text-subtitle-1 font-weight-bold">Deal Notes</v-label>
                <v-textarea
                  flat
                  solo
                  class="solo-cust mt-2"
                  dense
                  hide-details
                  v-model="formObj.notes"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="border-b">
                <v-label class="text-subtitle-1 font-weight-bold d-flex align-center">
                  <v-icon class="mr-2">mdi-storefront-outline</v-icon>
                  Products
                </v-label>

                <div v-if="!formObj.products.length" class="text-center pt-4">
                  No product selected
                </div>

                <v-list dense v-else>
                  <product-summary
                    v-for="product in formObj.products"
                    :product="product"
                    @removeProduct="removeProduct"
                  />
                </v-list>
              </v-col>

              <v-col cols="12" class="border-b">
                <v-label class="text-subtitle-1 font-weight-bold d-flex align-center">
                  <v-icon class="mr-2">mdi-hand-heart-outline</v-icon>
                  Treatments
                </v-label>

                <div v-if="!formObj.treatments.length" class="text-center pt-4">
                  No treatment selected
                </div>

                <v-list dense v-else>
                  <treatment-summary
                    v-for="treatment in formObj.treatments"
                    :treatment="treatment"
                    @removeTreatment="removeTreatment"
                  />
                </v-list>

                <div
                  v-if="formObj.treatments.length || formObj.products.length"
                  class="pa-2 d-flex align-center justify-space-between"
                >
                  <div class="text-subtitle-1 font-weight-bold">Total:</div>
                  <v-chip color="#f8eed5"> {{ addPoundSymbol(getTotal) }} </v-chip>
                </div>
              </v-col>

              <v-col cols="12">
                <div class="d-flex align-center justify-space-between">
                  <v-label class="text-subtitle-1 font-weight-bold d-flex align-center">
                    <v-icon class="mr-2">mdi-account</v-icon>
                    Sold By:
                  </v-label>

                  <v-chip v-if="formObj.soldby_id" color="#f8eed5" small class="text-capitalize">
                    {{ getSoldby }}
                  </v-chip>
                </div>
              </v-col>
              <v-col>
                <v-btn
                  color="secondary"
                  class="b-blue text-capitalize"
                  depressed
                  :disabled="loading || btnDisabled"
                  rounded
                  large
                  block
                  @click="createDeal"
                >
                  Add Deal
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import globalFn from "../../../../mixins/globalFn.js";
import modalFn from "../../../../mixins/modalFn.js";
import status from "../../../../mixins/status.js";
import treatments from "./treatments.vue";
import products from "./products.vue";
import soldBy from "./soldBy.vue";
import productSummary from "./summary/product.vue";
import treatmentSummary from "./summary/treatment.vue";

export default {
  mixins: [globalFn, status, modalFn, modalFn],
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  components: {
    products,
    treatments,
    soldBy,
    productSummary,
    treatmentSummary,
  },
  data: () => ({
    activeComponent: {
      title: "products",
      value: products,
    },
    formObj: {
      deal_info: "",
      notes: "",
      products: [],
      treatments: [],
      soldby_id: null,
    },
    search: "",
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    counter: 0,
    rules: {
      deal_info: [(v) => !!v || "Please enter deal information"],
      cost: [(v) => !!v || "Please enter cost value"],
      no_of_sessions: [(v) => !!v || "Please select no of sessions"],
      treatment_id: [(v) => !!v || "Please select treatment"],
      product_id: [(v) => !!v || "Please select product"],
      soldby_id: [(v) => !!v || "Please select Sold by"],
      sessions: [(v) => !!v || "Please select sessions"],
    },
    tabs: [
      {
        name: "Products",
        icon: "mdi-storefront-outline",
        component: {
          title: "products",
          value: products,
        },
      },
      {
        name: "Treatments",
        icon: "mdi-hand-heart-outline",
        component: {
          title: "treatments",
          value: treatments,
        },
      },
      {
        name: "Sold by",
        icon: "mdi-account",
        component: {
          title: "sold by",
          value: soldBy,
        },
      },
    ],
  }),
  watch: {
    activeComponent: {
      handler(newVal) {
        this.search = "";
      },
      deep: true,
    },
  },
  computed: {
    getTotal() {
      const treatmentSum = this.formObj.treatments.reduce((total, item) => {
        return (total += +item.cost);
      }, 0);

      const productSum = this.formObj.products.reduce((total, item) => {
        let sum = +item.selling_price * +item.quantity;
        return (total += +sum);
      }, 0);

      const total = +productSum + +treatmentSum;
      return parseFloat(total).toFixed(2);
    },
    btnDisabled() {
      return (
        !this.formObj.deal_info ||
        !this.formObj.notes ||
        !this.formObj.products.length ||
        !this.formObj.treatments.length ||
        !this.formObj.soldby_id
      );
    },
    getSoldby() {
      const staff = this.$store.state.staff.all.find((item) => item.id === this.formObj.soldby_id);

      return staff ? staff.name : null;
    },
  },
  created() {
    this.formObj.soldby_id = this.user.id;

    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", {
      data: {
        branch_id: this.user.user_type_name === "Admin" ? "" : this.user.branch_id,
        type: "",
      },
      params: "",
    });
    this.$store.dispatch("products/GET_PRODUCT", "");
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
      localStorage.removeItem("opportunity_id");
      this.$root.$emit("monitorTabEvent", { component: "Financials" });
    },
    removeTreatment(id) {
      const index = this.formObj.treatments.findIndex((item) => item.id === id);

      this.formObj.treatments.splice(index, 1);
    },
    removeProduct(id) {
      const index = this.formObj.products.findIndex((item) => item.id === id);

      this.formObj.products.splice(index, 1);
    },
    async createDeal() {
      if (this.$refs.formRef.validate()) {
        const payload = {
          user_id: this.$root.user_id || this.$route.params.id,
          ...this.formObj,
          products: this.formObj.products.map((item) => ({
            product_id: item.id,
            quantity: item.quantity,
            cost: item.selling_price,
          })),
          treatments: this.formObj.treatments.map((item) => ({
            treatment_id: item.id,
            no_of_sessions: item.no_of_sessions,
            cost: item.cost,
            duration: item.duration,
          })),
        };

        await this.$store
          .dispatch("deals/CREATE_DEAL", payload)
          .then((response) => {
            if (response.result === "success") {
              this.close();
            } else if (response.result === "fail") {
              response.type = "error";
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>

<style>
.border-t {
  border-top: rgba(0, 0, 0, 0.1) solid 1px;
}

.border-b {
  border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
}

.border-r {
  border-right: rgba(0, 0, 0, 0.1) solid 1px;
}

.border-l {
  border-left: rgba(0, 0, 0, 0.1) solid 1px;
}

.active-card {
  background: #f8eed5 !important;
  border: 1px solid #dfb447 !important;
  opacity: 1;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
