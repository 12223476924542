<template>
  <div
    :class="{ 'profile-wrapper': editable }"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <v-img
      v-if="imageUrl"
      :src="imageUrl"
      :height="height"
      :width="width"
      cover
      class="overlay rounded-pill"
    />

    <div
      v-else-if="name"
      class="overlay bg-gray-300 text-white-100 font-20 font-weight--bold d-flex rounded-100 mx-auto align-center justify-center"
      :style="{ width: `${width}px`, height: `${height}px` }"
    >
      {{ getInitials(name) }}
    </div>

    <div v-if="editable" class="profile-action">
      <v-btn variant="text" icon elevation="0" @click="$emit('edit')">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import globalFn from "../../../mixins/globalFn";
export default {
  mixins: [globalFn],
  props: {
    name: String, // If image is not there it will be use for showing inititials
    imageUrl: String,
    editable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-wrapper {
  position: relative;
}

.overlay {
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.profile-action {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.profile-wrapper:hover .overlay {
  opacity: 0.3;
}

.profile-wrapper:hover .profile-action {
  opacity: 1;
}
</style>
