import collection from "../../../api/collections/InvoicePayments";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_INVOICE_PAYMENT(state, data) {
      state.list = data;
    },
    SET_INVOICE_PAYMENT_LIST(state, data) {
      state.all = data;
    },
    SET_INVOICE_PAYMENT_ITEM(state, data) {
      state.item = data;
    },
    RESET_INVOICE_PAYMENT(state, data) {
      state.item = {};
    },
    SET_INVOICE_PAYMENT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_INVOICE_PAYMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload.data, payload.params);
          commit("SET_INVOICE_PAYMENT", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_INVOICE_PAYMENT",
        true
      );
    },
    async GET_INVOICE_PAYMENT_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.list(payload.data, payload.params);
          commit("SET_INVOICE_PAYMENT_LIST", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_INVOICE_PAYMENT_LIST",
        true
      );
    },
    async CREATE_INVOICE_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_INVOICE_PAYMENT",
        true
      );
      return data;
    },
    async SHOW_INVOICE_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_INVOICE_PAYMENT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_INVOICE_PAYMENT",
        false
      );
      return data;
    },
    async UPDATE_INVOICE_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_INVOICE_PAYMENT",
        true
      );

      return data;
    },
    async DELETE_INVOICE_PAYMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_INVOICE_PAYMENT",
        true
      );
      return data;
    },
  },
};
