<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table class="results-table" :headers="headers" :items="configuration">
              <template v-slot:item.brand_logo_url="{ item }">
                <img height="100px" :src="item.brand_logo_url" v-if="item.brand_logo" />
              </template>
              <template v-slot:item.brand_mini_logo_url="{ item }">
                <img height="100px" :src="item.brand_mini_logo_url" v-if="item.brand_mini_logo" />
              </template>
              <template v-slot:item.email_logo_url="{ item }">
                <img height="100px" :src="item.email_logo_url" v-if="item.email_logo" />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import globalFn from "../../../../mixins/globalFn";
import sliderFn from "../../../../mixins/sliderFn";
import status from "../../../../mixins/status";
export default {
  name: "Configurations",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Brand Logo",
          align: "start",
          value: "brand_logo_url",
          align: "left",
          sortable: false,
        },
        {
          text: "Brand Mini Logo",
          align: "start",
          value: "brand_mini_logo_url",
          align: "left",
          sortable: false,
        },
        {
          text: "Email Logo",
          align: "start",
          value: "email_logo_url",
          align: "left",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      selectedItem: "",
      items: [
        {
          brand_logo_url: null,
          brand_mini_logo_url: null,
          email_logo_url: null,
        },
      ],
    };
  },
  computed: {
    configuration() {
      if (this.$store.state.configurations.item) {
        return [this.$store.state.configurations.item];
      }
    },
  },
  created() {
    this.$store.dispatch("configurations/GET_LOGO", "");
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("configurations/GET_LOGO", "").then((res) => {
        this.openDrawer("add", "750", "settings/configurations/edit");
      });
    },
  },
};
</script>
