<template>
  <v-row class="border">
    <v-col cols="12" class="border bg-grey lighten-5">
      <v-row>
        <v-col cols="2" class="text-center text-body-2 font-weight-medium border">
          {{ weekDates[0] | moment("MMM DD YYYY") }} -
          {{ weekDates[weekDates.length - 1] | moment("MMM DD YYYY") }}
        </v-col>
        <v-col
          v-for="date in weekDates"
          :key="date"
          class="text-center text-body-2 font-weight-medium border"
          :class="{ 'current-date': isSameDate(date) }"
        >
          {{ date | moment("MMM DD ddd") }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="max-height: 610px; overflow-y: scroll" class="border">
      <div
        v-if="![...rota.consultants, ...rota.machines].length"
        class="text-subtitle-1 text-center"
      >
        No Data Available
      </div>
      <template v-else>
        <v-row
          v-for="item in [...rota.consultants, ...rota.machines]"
          :key="item.id"
          :style="{ backgroundColor: item.color }"
          style="min-height: 120px"
        >
          <v-col cols="2" class="pa-2 border d-flex align-center" style="position: relative">
            <consultant-profile
              :data="item"
              :type="item.hasOwnProperty('user_type') ? 'staff' : 'machine'"
            />
          </v-col>
          <v-col v-for="(date, index) in weekDates" :key="index" class="pa-1 border">
            <shift-slot
              v-if="item.rotas[date] && item.rotas[date].shift_type === 1"
              :date="date"
              :consultant="item"
              :rota="item.rotas[date]"
            />
            <holiday-slot
              v-else-if="item.rotas[date] && item.rotas[date].shift_type === 2"
              :date="date"
              :consultant="item"
              :rota="item.rotas[date]"
            />
            <empty-slot
              :date="date"
              :consultant="item"
              :height="item.rotas[date] ? '20%' : '100%'"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-col cols="12" class="border">
      <v-row>
        <v-col cols="2" class="text-center"
          >{{ rota.total.total_hour }} | {{ rota.total.shift_count }} Shifts
        </v-col>
        <v-col v-for="(date, index) in weekDates" :key="index" class="text-center">
          <template v-if="rota.daily_time[date]">
            {{ rota.daily_time[date].total_hour }} | {{ rota.daily_time[date].shift_count }} Shifts
          </template>
          <template v-else> No Shifts </template>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import globalFn from "../../mixins/globalFn";
import emptySlot from "./emptySlot/index.vue";
import shiftSlot from "./shiftSlot/index.vue";
import holidaySlot from "./holidaySlot/index.vue";
import consultantProfile from "./consultantProfile.vue";

export default {
  mixins: [globalFn],
  props: ["weekDates"],
  components: {
    emptySlot,
    shiftSlot,
    consultantProfile,
    holidaySlot,
  },
  data: () => ({
    dialog: false,
  }),
  mounted() {
    this.$store.dispatch("shiftTemplate/GET");
  },
  computed: {
    rota() {
      return this.$store.state.rota.list;
    },
    // rotaList() {
    //   // return ;
    //   return [];
    // },
    contStyle() {
      return {
        gridTemplateColumns: `40px 40px 40px repeat(${assetStore.modelDate.length * 2}, 160px)`,
        gridTemplateRows: `auto auto repeat(${totalAssetTypes.value}, 160px)`,
        minWidth: `${assetStore.modelDate.length * 2 * 160}px`,
      };
    },
  },
  methods: {
    isSameDate(dateStr) {
      var today = new Date();
      var todayDate = today.toISOString().slice(0, 10);
      return todayDate === dateStr ? true : false;
    },
    // getAvailableShift(rotas, date) {
    //   if (!rotas.length) return;

    //   const shift = rotas.find((item) => item.rota_date == date);
    //   return shift;
    // },
  },
};
</script>

<style lang="scss">
.current-date {
  background: #fff2cc;
}

// .slot-wrapper {
//   position: relative;

//   .shift-slot {
//     position: static;
//     transition: transform 0.3s;
//   }

//   &:hover .shift-slot {
//     position: absolute;
//     right: 0%;
//     left: 0%;
//     top: -10%;
//   }
// }
</style>
