<template>
  <v-list>
    <v-list-item-group v-model="currentTabComponent" color="secondary">
      <template v-for="item in items">
        <v-list-group
          v-if="item.items"
          :key="item.title"
          v-model="item.active"
          prepend-icon=""
          no-action
          color="secondary"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" style="color: black"></v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="child in item.items" :key="child.title">
            <v-list-item
              :class="currentTabComponent === child.component ? 'active' : ''"
              @click="getMenuComponent(child)"
              class="pl-4"
              :value="child.component"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title" style="color: black"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          @click="getMenuComponent(item)"
          v-if="!item.items"
          class="pl-4"
          :key="item.title"
          :class="currentTabComponent === item.component ? 'active' : ''"
          :value="item.component"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title" style="color: black"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
import status from "../../mixins/status";
import globalFn from "../../mixins/globalFn";
export default {
  name: "Tabmenu",
  mixins: [status, globalFn],
  props: ["currentTabComponent"],
  data() {
    return {
      loading: false,
      items: [
        {
          title: "Appointments",
          active: true,
          component: "Appointments",
        },
        {
          items: [
            { title: "Treament Notes", component: "TreamentNotes" },
            { title: "Forms", component: "Forms" },
            { title: "Photos", component: "Photos" },
            { title: "Documents", component: "Documents" },
          ],
          title: "Charts",
        },
        {
          title: "Financials",
          component: "Financials",
        },
      ],
    };
  },
  methods: {
    getMenuComponent(item) {
      this.$root.$emit("monitorTabEvent", item);
    },
  },
};
</script>
