<template>
  <v-card class="delete-alert">
    <v-card-title>
      <div>
        <v-icon class="mr-2">mdi-file-outline</v-icon>
        Upload Files
      </div>
      <a class="float-right pa-6" @click="close"><img src="/images/close.svg" alt="Close" /></a>
    </v-card-title>

    <v-card-text
      class="drop-area"
      @dragenter.prevent="highlight"
      @dragover.prevent="highlight"
      @dragleave.prevent="unhighlight"
      @drop.prevent="handleDrop"
      :class="{ highlight: isDragging }"
    >
      <div>
        <p class="my-4 text-capitalize">Drag & drop your files here or</p>
        <v-btn :elevation="0" @click="$refs.fileInput.click()" class="text-capitalize"
          >Choose file</v-btn
        >
      </div>
    </v-card-text>

    <div v-if="fileList.length" class="text-right mx-8 text-subtitle-1">
      {{ fileList.length }} Files
    </div>

    <input
      type="file"
      ref="fileInput"
      multiple
      style="visibility: hidden"
      @change="handleFileInput"
    />

    <v-card-actions class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-btn
            block
            depressed
            rounded
            outlined
            @click="close"
            color="dark"
            x-large
            class="text-capitalize"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="success"
            block
            depressed
            @click="submit"
            rounded
            x-large
            :loading="uploading"
            :disabled="!fileList.length"
            class="text-capitalize"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    fileList: [],
    isDraggable: false,
    uploading: false,
  }),
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
    },
    async submit() {
      this.uploading = true;
      const formData = new FormData();

      formData.append("user_id", this.$route.params.id);

      this.fileList.forEach((file) => {
        formData.append("docs[]", file);
      });

      await this.$store
        .dispatch("docs/UPLOAD_USER_DOCS", formData)
        .then((res) => {
          if (res.result === "success") {
            this.$root.$emit("fetchUserDocs");
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            res.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        })
        .finally(() => {
          this.uploading = false;
          this.close();
        });
    },

    // File uploads
    handleFileInput(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    highlight() {
      this.isDragging = true;
    },
    unhighlight() {
      this.isDragging = false;
    },
    handleDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.fileList.push(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-area {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 20px;
  height: 300px !important;
  width: 90% !important;
  margin: 1rem auto;
}

.drop-area.highlight {
  border-color: green;
}
.file-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}
</style>
