export default {
  data: () => ({
    widgets: [
      {
        name: "Need Assigning",
        active: false,
        slug: "need_assigning",
      },
      {
        name: "No Date",
        active: false,
        slug: "no_date",
      },
      {
        name: "Overdue",
        active: false,
        slug: "overdue",
      },
      {
        name: "Recall",
        active: false,
        slug: "recall",
      },
    ],
  }),
  mounted() {
    this.loadCount();
  },
  methods: {
    async loadCount() {
      let counts = {};

      let params = {};
      params.callback_date_from = this.CallbackParams.filter.callback_date_from;
      params.callback_date_to = this.CallbackParams.filter.callback_date_to;
      params.callback_time_from = this.CallbackParams.filter.callback_time_from;
      params.callback_time_to = this.CallbackParams.filter.callback_time_to;
      params.status = this.CallbackParams.filter.status;
      params.branch = this.CallbackParams.filter.branch;
      params.source = this.CallbackParams.filter.source;
      params.source_blank = this.CallbackParams.filter.source_blank;
      params.assigned = this.CallbackParams.filter.assigned;
      params.assigned_to = this.CallbackParams.filter.assigned_to;

      await this.$store.dispatch("callback/WIDGET_COUNT", params).then((res) => {
        counts = res.data;
      });
      this.widgets = this.widgets.map(function (el) {
        let item = Object.assign({}, el);
        item.count = counts.hasOwnProperty(el.slug) ? counts[el.slug] : 0;
        return item;
      });
    },
  },
};
