<template>
  <v-list-item>
    <v-list-item-title>
      <div class="d-flex align-center">
        <div class="text-capitalize w-100 text-body-2 font-weight-bold">
          {{ product.name }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon text color="red" @click="$emit('removeProduct', product.id)"
          ><v-icon>mdi-delete-outline</v-icon></v-btn
        >
      </div>

      <div>
        <div class="d-flex align-center my-2">
          <p class="font-12 lh-14 text-gray-300 font-weight--regular">Quantity:</p>
          <v-spacer></v-spacer>
          <div style="width: 40%">
            <v-text-field
              type="number"
              flat
              solo
              dense
              hide-details="auto"
              class="solo-cust"
              :rules="rules.quantity"
              v-model="product.quantity"
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex align-center">
          <p class="font-12 lh-14 text-gray-300 font-weight--regular">Price:</p>
          <v-spacer></v-spacer>
          <div style="width: 40%">
            <v-text-field
              type="number"
              flat
              solo
              dense
              hide-details="auto"
              class="solo-cust"
              :rules="rules.price"
              v-model="product.selling_price"
            >
            </v-text-field>
          </div>
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: ["product"],
  data: () => ({
    rules: {
      quantity: [
        (v) => !!v || "Please enter quantity",
        (v) => (v !== 0 && v !== undefined && v > 0) || "Invalid quantity",
      ],
      price: [
        (v) => !!v || "Please enter price",
        (v) => (v !== 0 && v !== undefined && v > 0) || "Invalid price",
      ],
    },
  }),
};
</script>
