import collection from "../../../api/collections/Forms/elements";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    elements: [],
    // formDetails: null,
  },
  mutations: {
    SET_FORM_ELEMENT(state, data) {
      state.elements = data;
    },
    // SET_FORM_ELEMENT_DETAILS(state, data) {
    //     state.formDetails = data;
    // },
  },
  actions: {
    async GET_FORM_ELEMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get();
          commit("SET_FORM_ELEMENT", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_FORM_ELEMENT",
        true
      );
    },
    // async SHOW_FORM_ELEMENT({ commit, dispatch }, payload) {
    //     return await asyncMiddleware(
    //         async function () {
    //             const { data } = await collection.show(payload.id, payload);
    //             commit("SET_FORM_ELEMENT_DETAILS", data.data);
    //             return data;
    //         },
    //         commit,
    //         dispatch,
    //         "SHOW_LIST",
    //         true
    //     );
    // },
    async CREATE_FORM_ELEMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);

          return data;
        },
        commit,
        dispatch,
        "CREATE_FORM_ELEMENT",
        true
      );
      return data;
    },
    async UPDATE_FORM_ELEMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);

          return data;
        },
        commit,
        dispatch,
        "UPDATE_FORM_ELEMENT",
        true
      );

      return data;
    },
    async DELETE_FORM_ELEMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload.id);

          return data;
        },
        commit,
        dispatch,
        "DELETE_FORM_ELEMENT",
        true
      );
      return data;
    },
  },
};
