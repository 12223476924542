<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pt-3 pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :options.sync="options"
            >
              <template v-slot:item.name="{ item }">
                <template v-for="(val, index) in item.audit">
                  <template v-for="(value, key) in val.new_values">
                    <div class="my-6 height-60" v-if="key !== 'category'">
                      <div
                        class="font-14 line-h-17 font-weight--bold text-black-100 mb-1"
                        v-if="item.name"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        class="d-flex font-14 line-h-17 font-weight--bold text-black-100 mb-1"
                        v-if="!item.name && item.social_handle"
                      >
                        <img
                          :src="`/images/${item.source_name.toLowerCase()}.svg`"
                          class="mr-1"
                          v-if="item.source_name !== null"
                        />
                        <span> @{{ item.social_handle }} </span>
                      </div>
                      <div class="mb-2">
                        <span
                          class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                        >
                          {{ item.branch_name }}
                        </span>
                      </div>
                      <div>
                        <span
                          class="badge--chip font-10 line-h-12 text-black-300 font-weight--semibold mr-2"
                          :class="item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                        >
                          {{ item.user_type_name }}
                        </span>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.consultant="{ item }">
                <template v-for="(item, index) in item.audit">
                  <template v-for="(value, key) in item.new_values">
                    <div class="my-6 height-60" v-if="key !== 'category'">
                      <div class="font-14 line-h-17 font-weight--bold text-black-100">
                        {{ item.owner_name | capitalize }}
                      </div>
                      <div class="mt-1">
                        <span
                          class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                          v-if="item.branch_name"
                        >
                          {{ item.branch_name }}
                        </span>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.type="{ item }">
                <template v-for="item in item.audit">
                  <template v-for="(value, key) in item.new_values">
                    <p class="my-6 height-60" v-if="key !== 'category'">
                      <span
                        class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                      >
                        <template v-if="key === 'is_urgent'"> Flag </template>
                        <template v-else>
                          {{ key.replace(/_/g, " ") | capitalize }}
                        </template>
                      </span>
                    </p>
                  </template>
                </template>
              </template>
              <template v-slot:item.description="{ item }">
                <template v-for="item in item.audit">
                  <template v-for="(value, key) in item.new_values">
                    <div
                      class="my-6 font-13 line-h-16 font-weight--semibold text-black-300 height-60"
                      v-if="key !== 'category'"
                    >
                      <template v-if="key === 'status'">
                        <span
                          class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                        >
                          {{ value | capitalize }}
                        </span>
                      </template>
                      <template v-else-if="key === 'comment'">
                        <ReadMore :data="value"></ReadMore>
                      </template>
                      <template v-else-if="key === 'is_urgent'">
                        <span class="font-13 line-h-16 font-weight--semibold text-black-300">{{
                          value === 1 ? "True" : "False"
                        }}</span>
                      </template>
                      <template v-else>
                        <span class="font-13 line-h-16 font-weight--semibold text-black-300">{{
                          value
                        }}</span>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.date="{ item }">
                <template v-for="(item, index) in item.audit">
                  <template v-for="(value, key) in item.new_values">
                    <p
                      class="font-13 line-h-16 font-weight--semibold text-black-300 my-6 height-60"
                      v-if="key !== 'category'"
                    >
                      {{ item.created_at }}
                    </p>
                  </template>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-for="(item, index) in item.audit">
                  <template v-for="(value, key) in item.new_values">
                    <div class="my-6 height-60" v-if="key !== 'category'">
                      <v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
                        <inline-svg height="16" src="/images/icons/duotune/general/gen055.svg" />
                      </v-btn>
                    </div>
                  </template>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import ReadMore from "./ReadMore";
export default {
  name: "LeadActivityReport",
  mixins: [globalFn, sliderFn, status],
  components: {
    ReadMore,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Consultant", align: "left", value: "consultant", sortable: false },
        { text: "Name", align: "start", value: "name", sortable: false },
        { text: "Type", align: "left", value: "type", sortable: false },
        { text: "Description", align: "left", value: "description", sortable: false },
        { text: "Date Updated", align: "left", value: "date", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      payload: {
        search: "",
        start_date: new Date(new Date().setDate(new Date().getDate() - 2))
          .toISOString()
          .substr(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1))
          .toISOString()
          .substr(0, 10),
        user_id: [],
        branch_id: [],
        staff: [],
        per_page: 10,
        page: 1,
        type: "",
        keyword: "",
      },
      showLeadParams:
        "?attributes=staff,branch,leadDetails,reasons,symptoms,treatments,source,campaigns",
      showClientsParams:
        "?attributes=staff,branch,clientDetails,reasons,symptoms,treatments,documents,comments,source,campaigns",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("leadActivityTable", this.readTheDataFromApi);
    this.$root.$on("searchLeadActivityTable", this.searchLeads);
  },
  created() {},
  methods: {
    async searchLeads(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.payload.search = data.search;
        this.payload.start_date = data.start_date;
        this.payload.end_date = data.end_date;
        this.payload.user_id = data.user_id;
        this.payload.branch_id = data.branch;
        this.payload.staff = data.staff;
        this.payload.type = data.type;
        this.payload.keyword = data.keyword;
        await this.readTheDataFromApi();
      } else {
        this.payload.search = null;
        this.payload.date = null;
        this.payload.user_id = [];
        this.payload.staff = [];
        this.payload.type = "";
        this.payload.keyword = "";
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("customers/LEAD_ACTIVITY_REPORT", this.payload).then((res) => {
        this.items = res.data.data;
        this.totalCount = res.data.total;
        this.loading = false;
      });
    },

    async editItem(item) {
      const OppId = item.model === "Comment" ? item.comments.commentable_id : item.auditable_id;

      await this.$store.dispatch("opportunities/SHOW", { id: OppId }).then((res) => {
        if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
          this.openDrawer("add", "1100", "opportunities/editPane");
        } else {
          this.openDrawer("add", "750", "opportunities/editTab");
        }
      });
    },
  },
};
</script>
