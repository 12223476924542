<template>
  <v-row>
    <v-col cols="3" v-for="(treatment, index) in filteredTreatments" :key="treatment.id">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :ripple="false"
            :elevation="hover ? 10 : 4"
            class="cursor-pointer overflow-hidden rounded-md transition-swing"
            :class="{ 'active-card': getBgColor(treatment.id) }"
            @click="addtreatments(treatment, index)"
          >
            <div
              style="height: 150px; background: rgb(236, 237, 240)"
              class="d-flex justify-center align-center"
            >
              <img src="../../../../../images/image-placeholder.svg" height="40" width="40" />
            </div>
            <v-card-text class="text-center">
              <p class="text-subtitle-2 font-weight-bold">
                {{ treatment.name }}
              </p>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import globalFn from "../../../../mixins/globalFn";
export default {
  props: ["formObj", "search"],
  mixins: [globalFn],
  data: () => ({
    menu: {},
  }),
  computed: {
    treatments() {
      return this.$store.state.treatments.allList?.map((item) => {
        return {
          ...item,
          no_of_sessions: 1,
          cost: 0,
          duration: 0,
        };
      });
    },
    filteredTreatments() {
      return this.treatments.filter((item) => item.name.toLowerCase().includes(this.search));
    },
  },
  methods: {
    getBgColor(id) {
      return this.formObj.treatments.find((item) => item.id === id) ? true : false;
    },
    addtreatments(treatment, index) {
      const treatmentExist = this.formObj.treatments.find((item) => item.id === treatment.id);

      if (treatmentExist) {
        const index = this.formObj.treatments.findIndex((item) => item.id === treatment.id);

        this.formObj.treatments.splice(index, 1);
      } else {
        this.formObj.treatments.push(treatment);
      }
    },
  },
};
</script>
