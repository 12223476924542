<template>
  <div class="support-wrap">
    <v-expansion-panels multiple>
      <template v-for="(item, index) in products">
        <v-expansion-panel :key="index">
          <v-expansion-panel-header>
            <span class="total mr-2" style="width: 250px">{{ item.product.name }}</span>
            <span class="total mr-2" style="width: 150px"
              ><span class="sold-by">Quantity </span>
              <!-- {{ treat.appointments_completed }}/ -->
              {{ item.quantity }}</span
            >
            <span class="total mr-2" style="width: 150px"
              ><span class="sold-by">Total: </span>£{{ item.product.selling_price }}</span
            >
          </v-expansion-panel-header>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import status from "../../mixins/status.js";
export default {
  name: "Products",
  mixins: [globalFn, status],
  props: {
    dealId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
  data: () => ({
    treatments: [],
    products: [],
  }),
  mounted() {
    this.showSkeleton = true;
    this.loading = true;
    this.appointments();
  },
  methods: {
    async appointments() {
      await this.$store
        .dispatch("deals/SHOW_DEAL", {
          id: this.dealId,
          params: `?attributes=dealTreatments,dealProducts`,
        })
        .then((res) => {
          this.treatments = res.data.deal_treatments;
          this.products = res.data.deal_products;
        });
    },
  },
};
</script>
<style scoped></style>
