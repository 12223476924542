<template>
  <div class="d-flex flex-row">
    <v-btn class="b-btn b-small mr-4" color="secondary" depressed @click="editContact">
      Edit Contact
    </v-btn>
    <v-btn class="b-btn b-small mr-4" color="secondary" depressed @click="addOpp">
      Add Opportunity
    </v-btn>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      max-width="200"
      :nudge-width="200"
      offset-y
      left
      content-class="user-action"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="b-btn b-small mr-4" color="secondary" v-on="on" elevation="0">
          Quick Actions
        </v-btn>
      </template>
      <div class="logout-block">
        <ul>
          <li>
            <a @click.stop="quickHandle('addForm')">Add Consent Form</a>
          </li>
          <li>
            <a @click.stop="quickHandle('addPayment')">Add Payment</a>
          </li>
          <li>
            <a @click.stop="quickHandle('addDealGrid')">Add Deal</a>
          </li>
        </ul>
      </div>
    </v-menu>
  </div>
</template>
<script>
import sliderFn from "../../mixins/sliderFn";
import modalFn from "../../mixins/modalFn";
export default {
  name: "ViewContact",
  mixins: [sliderFn, modalFn],
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    async editContact() {
      const id = this.$router.currentRoute.params.id;
      await this.$store.dispatch("contacts/CONTACT_BY_ID", { user_id: id }).then((res) => {
        this.openDrawer("add", "1100", "customers/edit");
      });
    },

    async addOpp() {
      const id = this.$router.currentRoute.params.id;
      await this.$store.dispatch("contacts/CONTACT_BY_ID", { user_id: id }).then((res) => {
        this.openDrawer("add", "1100", "customers/addOpp");
      });
    },

    quickHandle(type) {
      this.menu = false;

      if (type === "addDealGrid") {
        this.openModal("add", "100%", `consent/${type}`);
      } else this.openDrawer("add", "750", `consent/${type}`);
    },
  },
};
</script>
<style lang="css" scoped></style>
