import http from "../../http/common";

const resource = "/rota";

export default {
  get: (payload) => http.post(`${resource}/get`, payload),

  show: (payload) => http.post(`${resource}/show`, payload),

  create_holiday: (payload) => http.post(`${resource}/create-holiday`, payload),

  update_holiday: (payload) => http.post(`${resource}/update-holiday`, payload),

  delete_holiday: (payload) => http.post(`${resource}/delete-holiday`, payload),

  create_shift: (payload) => http.post(`${resource}/create`, payload),

  delete_shift: (payload) => http.post(`${resource}/delete-shift`, payload),

  create_machine: (payload) => http.post(`${resource}/create-machine`, payload),

  delete_machine: (payload) => http.post(`${resource}/delete-machine-shift`, payload),

  update: (payload) => http.post(`${resource}/update`, payload),

  update_machine: (payload) => http.post(`${resource}/update-machine`, payload),

  user_count: (payload) => http.post(`${resource}/get-user-count`, payload),

  user_shift: (payload) => http.post(`${resource}/get-shift-user`, payload),
};
