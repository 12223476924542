import collection from "../../../api/collections/Reasons";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_REASON(state, data) {
      state.list = data;
    },
    SET_ALL_REASON(state, data) {
      state.allList = data;
    },
    SET_REASON_ITEM(state, data) {
      state.item = data;
    },
    RESET_REASON(state, data) {
      state.item = {};
    },
    SET_REASON_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_REASON({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_REASON", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_REASON",
        true
      );
    },
    async GET_ALL_REASON({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_REASON", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_REASON",
        true
      );
    },
  },
};
