<template>
  <div class="body-wrapper">
    <v-container fluid class="pa-0">
      <v-row class="ma-0 postiton--relative">
        <v-col cols="12" md="8" class="pa-0 bg-surface-100">
          <div class="d-flex flex-column align-center justify-center surface--wrap">
            <v-img
              contain
              lazy-src="/images/login-logo.png"
              src="/images/login-logo.png"
              max-width="170"
            ></v-img>
            <div
              class="login-form-wrap"
              v-if="!tokenExpired && !resetPasswordDialog && !verificationDialog"
            >
              <v-form ref="form" @submit.prevent="validate" v-model="valid" lazy-validation>
                <div class="mb-4">
                  <v-text-field
                    autcomplete="off"
                    v-model="form.password"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    required
                    filled
                    hide-details="auto"
                    class="b-input"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <a class="mr-2 mt-1 mb-0 grey-text" @click.stop="show = !show">Show</a>
                      <v-icon v-if="!show" @click.stop="show = !show">mdil-eye</v-icon>
                      <v-icon v-if="show" @click.stop="show = !show">mdil-eye-off</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <div class="mb-4">
                  <v-text-field
                    autcomplete="off"
                    v-model="form.password_confirmation"
                    :type="show1 ? 'text' : 'password'"
                    :rules="rules.confirm_password.concat(passwordConfirmationRule)"
                    required
                    filled
                    hide-details="auto"
                    class="b-input"
                    placeholder="Confirm Password"
                  >
                    <template slot="append">
                      <a class="mr-2 mt-1 mb-0 grey-text" @click.stop="show1 = !show1">Show</a>
                      <v-icon v-if="!show1" @click.stop="show1 = !show1">mdil-eye</v-icon>
                      <v-icon v-if="show1" @click.stop="show1 = !show1">mdil-eye-off</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <div class="btn-wrapper">
                  <v-btn
                    block
                    class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                    depressed
                    @click.prevent="validate"
                    :loading="loading"
                    >Reset Password</v-btn
                  >
                </div>
              </v-form>
            </div>
            <div
              class="pt-8"
              v-else-if="tokenExpired && !resetPasswordDialog && !verificationDialog"
            >
              <div class="mb-4 message-block error-info">
                <v-icon color="#FF0C3E" size="32">mdil-alert</v-icon>
                <p class="body">
                  Your reset link has expired. Please re-enter your email below for a refreshed
                  link.
                </p>
              </div>
              <div class="mb-4">
                <label class="login-label">Email Address</label>
                <v-text-field
                  autcomplete="off"
                  v-model="form.email"
                  required
                  :rules="emailRules"
                  hide-details="auto"
                  autofocus
                  filled
                  class="mt-1 b-input"
                  placeholder="Your email address"
                ></v-text-field>
              </div>
              <div class="btn-wrapper">
                <v-btn
                  block
                  class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                  @click.prevent="sendPasswordResetLink"
                  :loading="loading"
                  depressed
                  >Reset Password</v-btn
                >
              </div>
            </div>
            <div
              class="pt-8 text-center info-box"
              v-else-if="!tokenExpired && resetPasswordDialog && !verificationDialog"
            >
              <div class="mb-4">
                <img src="/images/check.svg" class="mb-5" alt="" />
                <p class="heading mb-5">Password Reset Successfully</p>
                <p class="body mb-5">
                  Your password is successfully updated. <br />
                  Click the button below to login.
                </p>
                <div class="btn-wrapper">
                  <v-btn
                    block
                    class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                    @click="goto('/login')"
                    depressed
                    >Go to Login</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              class="pt-8 text-center info-box"
              v-else="!tokenExpired && resetPasswordDialog && verificationDialog"
            >
              <div class="mb-4">
                <img src="/images/email-action.svg" class="mb-5" alt="" />
                <p class="heading mb-5">Please check your email</p>
                <p class="body mb-5">
                  If your account exists, we will send you an email with details on how to reset
                  your password
                </p>
                <div class="btn-wrapper">
                  <v-btn
                    block
                    class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                    @click="goto('/login')"
                    depressed
                    >Go to Login</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pa-0 bg-author"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_RESET_LINK,
  PASSWORD_RESET,
} from "../../../../store/action-types";
import TheLogo from "../../../../components/TheLogo.vue";
export default {
  name: "ResetPage",
  components: {
    TheLogo,
  },
  data: () => ({
    valid: true,
    verified: false,
    loading: false,
    show1: false,
    show: false,
    response: "",
    form: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    verificationDialog: false,
    resetPasswordDialog: false,
    tokenExpired: false,
    emailRules: [
      (v) => !!v || "Please enter a valid email address",
      (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
    ],
    rules: {
      password: [
        (v) => !!v || "Please enter your password",
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(v) ||
          "Password must have a minimum character limit of 8, contain upper and lowercase letters and a special character and a number",
      ],
      confirm_password: [(v) => !!v || "Please confirm your password"],
    },
  }),
  mounted() {
    this.checkUser();
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "The password confirmation does not match";
    },
  },
  methods: {
    ...mapActions({
      PASSWORD_RESET_LINK,
      VERIFY_PASSWORD_RESET_LINK,
      PASSWORD_RESET,
    }),
    goto(path) {
      this.$router.push(path);
    },
    validate: function () {
      if (this.$refs.form.validate()) {
        this.resetPassword();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async sendPasswordResetLink() {
      this.loading = true;
      try {
        await this.PASSWORD_RESET_LINK(this.form)
          .then((res) => {
            if (res.result == "success") {
              this.loading = false;
              this.verificationDialog = true;
            } else if (res.result === "fail") {
              this.loading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.loading = false;
          });
      } catch (error) {
        console.log(error.response);
        this.loading = false;
        console.dir(error);
        this.$store.dispatch("snackbar/SHOW", {
          title: "Error!",
          type: "error",
          message: error.message,
        });
      }
    },
    async resetPassword() {
      this.loading = true;
      try {
        await this.PASSWORD_RESET(this.form)
          .then((res) => {
            if (res.result == "success") {
              this.loading = false;
              this.resetPasswordDialog = true;
            } else if (res.result === "fail") {
              if (res.status_code === 400) {
                this.tokenExpired = true;
                this.loading = false;
                res.type = "error";
                this.$store.dispatch("snackbar/SHOW", res);
              } else {
                this.loading = false;
                res.type = "error";
                this.$store.dispatch("snackbar/SHOW", res);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (error) {
        console.dir(error);
        this.$store.dispatch("snackbar/SHOW", {
          title: "Error!",
          type: "error",
          message: error.message,
        });
      }
    },
    async checkUser() {
      this.form.token = this.$route.params.token;
    },
  },
};
</script>
<style></style>
