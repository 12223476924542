<template>
  <div class="d-flex">
    <v-btn
      href="https://video.drift.com/v/abMd0iCDJQ5/"
      target="_blank"
      class="b-btn b-small mr-4"
      color="red"
      depressed
      v-if="!$vuetify.breakpoint.smAndDown && showVideoTraining === 'true'"
    >
      Video Training
    </v-btn>
    <v-text-field
      flat
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      label="Search for a ticket…"
      solo
      hide-details
      class="search-input"
      @input="isTyping = true"
      v-model="search"
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left content-class=" mega-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance bg-white-100" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="reset"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" class="filter-column" sm="6">
                <span class="heading">Callback:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="callbackFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.callback_date_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.callback_date_from"
                      no-title
                      @input="callbackFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="callbackToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.callback_date_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.callback_date_to"
                      no-title
                      @input="callbackToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>

                <span class="heading">Callback Time:</span>
                <div class="d-flex mt-2">
                  <v-menu
                    ref="callbackTimeFromMenu"
                    v-model="callbackTimeFromMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.followup_from_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        hide-details="auto"
                        solo
                        class="advance-input"
                        v-model="filter.followup_from_time"
                        prepend-inner-icon="mdil-clock"
                        placeholder="Time From"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-if="callbackTimeFromMenu"
                      v-model="filter.followup_from_time"
                      full-width
                      @click:minute="$refs.callbackTimeFromMenu.save(filter.followup_from_time)"
                    ></v-time-picker>
                  </v-menu>
                  <v-menu
                    ref="callbackTimeToMenu"
                    v-model="callbackTimeToMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.callback_time_to"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        hide-details="auto"
                        solo
                        class="advance-input"
                        v-model="filter.callback_time_to"
                        prepend-inner-icon="mdil-clock"
                        placeholder="Time To"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-if="callbackTimeToMenu"
                      v-model="filter.callback_time_to"
                      full-width
                      @click:minute="$refs.callbackTimeToMenu.save(filter.callback_time_to)"
                    ></v-time-picker>
                  </v-menu>
                </div>

                <span class="heading">Date Raised:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="dateRaisedFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.date_raised_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.date_raised_from"
                      no-title
                      @input="dateRaisedFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="dateRaisedToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.date_raised_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.date_raised_to"
                      no-title
                      @input="dateRaisedToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>

                <span class="heading">Next Action:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="nextActiondateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.next_action_date_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.next_action_date_from"
                      no-title
                      @input="nextActiondateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="nextActiondateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.next_action_date_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.next_action_date_to"
                      no-title
                      @input="nextActiondateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" class="filter-column" sm="6">
                <span class="heading"
                  >Status:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="StatusToggle"
                    @click="handleFunction('StatusCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="stat in StatusDefault">
                    <v-chip
                      small
                      class="este-chip status-chip mb-2"
                      :outlined="stat.outline"
                      :text-color="stat.text ? stat.color : '#221F20'"
                      :color="stat.color"
                      @click="statusToggle(stat)"
                    >
                      {{ stat.name | capitalize }}
                      <v-icon right color="black" v-if="stat.active" size="15">mdil-check</v-icon>
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" class="filter-column" sm="6">
                <span class="heading"
                  >Branch:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="BranchToggle"
                    @click="handleFunction('BranchCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="branch in BrachDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="branch.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="branchToggle(branch)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" class="filter-column" sm="6">
                <span class="heading"
                  >Source:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="SourceToggle"
                    @click="handleFunction('SourceCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <v-chip
                    small
                    class="este-chip tag-chip mb-2 text-decoration-line-through"
                    :class="SourceBlank === true ? 'selected' : ''"
                    text-color="black"
                    @click.stop="selectSourceBlank"
                  >
                    NONE
                  </v-chip>
                  <template v-for="item in SourceDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="item.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="sourceToggle(item)"
                    >
                      {{ item.name | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" class="filter-column" sm="6">
                <span class="heading">Assigned To:</span>
                <div class="mt-2">
                  <v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mt-n2 ml-n1 ban-icon"
                        :ripple="false"
                        size="45"
                        :color="unassignedCheck ? '#221F20' : 'rgba(0,0,0,0.4)'"
                        @click="filterUnassign"
                        >mdil-cancel</v-icon
                      >
                    </template>
                    <span>Nobody Assigned</span>
                  </v-tooltip>
                  <template v-for="item in staff">
                    <v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="tag-chip mb-2 staff-chip"
                          :class="item.active === true ? 'selected' : ''"
                          text-color="black"
                          @click="staffToggle(item)"
                        >
                          {{ getInitials(item.name) }}
                        </v-chip>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import advancedSearch from "../../mixins/advancedSearch";
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status";
import _ from "lodash";
export default {
  name: "leadSearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    callbackFromMenu: false,
    callbackToMenu: false,
    dateRaisedFromMenu: false,
    callbackTimeToMenu: false,
    callbackTimeFromMenu: false,
    dateRaisedToMenu: false,
    nextActiondateFromMenu: false,
    nextActiondateToMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    params: "?&sort_order=DESC&sort_by=name",
    filter: {
      search: "",
      callback_date_from: "",
      callback_date_to: "",
      callback_time_from: "",
      callback_time_to: "",
      date_raised_from: "",
      date_raised_to: "",
      next_action_date_from: "",
      next_action_date_to: "",
      status: [],
      branch: [],
      source: [],
      assigned: [],
      assigned_to: 0,
      source_blank: 1,
      widget_filter: false,
    },
    search: "",
    isTyping: false,
    showVideoTraining: process.env.MIX_SHOW_VIDEO_TRAINING,
  }),
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.filter.search = this.search;
        this.checkAll();
        this.searchKey();
      }
    },
  },
  mounted() {
    this.loadDefaults();
  },
  methods: {
    async searchKey() {
      this.$root.$emit("searchSupportTickets", this.filter);
    },
    async reset() {
      await this.resetFilter();
      await this.loadDefaults();
    },
    async resetFilter() {
      this.filter.search = "";
      this.filter.widget_filter = true;
      this.filter.callback_date_from = "";
      this.filter.callback_date_to = "";
      this.filter.callback_time_from = "";
      this.filter.callback_time_to = "";
      this.filter.date_raised_from = "";
      this.filter.date_raised_to = "";
      this.filter.next_action_date_from = "";
      this.filter.next_action_date_to = "";
      this.filter.assigned = [];
      this.filter.assigned_to = 0;
      this.filter.source_blank = 1;
      this.unassignedCheck = false;
      this.staff.forEach((value) => {
        value.active = false;
      });
    },
  },
};
</script>
