<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <iframe :src="formFile" width="100%" style="height: 100%"></iframe>
        <div class="form-btn-wrapper">
          <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
            Close
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sliderFn from "../../../mixins/sliderFn";
export default {
  mixins: [sliderFn],
  data: () => ({}),
  computed: {
    formFile() {
      if (this.$store.state.formPatientCrm.formDetails) {
        return this.$store.state.formPatientCrm.formDetails;
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit("togglePDFDrawer", true);
    },
  },
};
</script>
