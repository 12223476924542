<template>
  <v-row>
    <v-col cols="3" v-for="(staff, index) in filteredStaffs" :key="staff.id">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :ripple="false"
            :elevation="hover ? 10 : 4"
            class="cursor-pointer overflow-hidden rounded-md transition-swing"
            :class="{ 'active-card': staff.id === formObj.soldby_id ? true : false }"
            @click="formObj.soldby_id = staff.id"
          >
            <div
              style="height: 150px; background: rgb(236, 237, 240)"
              class="d-flex justify-center align-center"
            >
              <img src="../../../../../images/image-placeholder.svg" height="40" width="40" />
            </div>
            <v-card-text class="text-center text-subtitle-2 font-weight-bold">
              {{ staff.name }}
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import globalFn from "../../../../mixins/globalFn";

export default {
  props: ["formObj", "search"],
  mixins: [globalFn],
  computed: {
    staffs() {
      const arrayOfObjects = this.$store.state.staff.all;
      return arrayOfObjects.map((obj) => ({
        ...obj,
        name: this.capitalizeFirstLetterEachWord(obj.name),
      }));
    },
    filteredStaffs() {
      return this.staffs.filter((item) => item.name.toLowerCase().includes(this.search));
    },
  },
};
</script>
