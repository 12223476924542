<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card class="widget-card">
          <v-row>
            <template v-for="(item, index) in widgets">
              <v-col cols="12" md="3" sm="6">
                <div
                  class="widget"
                  :class="item.active ? 'selected' : ''"
                  @click="widgetFilter(item)"
                >
                  <span class="active" v-if="item.active">
                    <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                  </span>
                  <div class="content">
                    <div class="text">{{ item.name }}</div>
                    <div class="number">{{ item.count }}</div>
                  </div>
                  <span class="bottom-arrow" v-if="item.active"></span>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :loading="loading"
            >
              <template v-slot:item.user="{ item }">
                <div class="name-column" v-if="item.user !== null">
                  <div class="user-name" v-if="item.user.name">
                    {{ item.user.name }}
                  </div>
                  <div class="d-flex user-name" v-if="!item.user.name && item.user.social_handle">
                    <img
                      :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="item.user.source_name !== null"
                    />
                    <span> @{{ item.user.social_handle }} </span>
                  </div>
                  <p class="user-location">
                    {{ item.user.branch_name }}
                  </p>
                  <p class="user-location mt-2">
                    <v-chip
                      small
                      class="este-chip status-chip"
                      :text-color="item.user.user_type_name === 'Lead' ? 'white' : 'black'"
                      :color="
                        item.user.user_type_name === 'Lead'
                          ? 'rgba(0, 0, 0, 0.4)'
                          : 'rgba(223, 180, 71, 0.4)'
                      "
                    >
                      {{ item.user.user_type_name | capitalize }}
                    </v-chip>
                  </p>
                </div>
              </template>
              <template v-slot:item.phone="{ item }">
                <p
                  class="mt-4 ml-n1"
                  v-if="
                    item.user !== null &&
                    item.user.contact_no !== null &&
                    item.user.contact_no !== ''
                  "
                >
                  <span class="contact">
                    <a :href="`tel:${item.user.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.user.contact_no }}</a
                    >
                  </span>
                </p>
                <p
                  class="mt-4 ml-n1 mb-4"
                  v-if="item.user !== null && item.user.email !== null && item.user.email !== ''"
                >
                  <span class="contact">
                    <a :href="`mailto:${item.user.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.user.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.callback_date="{ item }">
                <p class="date-sec date-mute">
                  <v-btn class="badge badge-grey" icon>
                    <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                  </v-btn>
                  {{ item.created_at | moment("DD/MM/YY") }}
                </p>
                <template v-if="item.callback_date !== null">
                  <p class="date-sec vertical-bar">|</p>
                  <p class="date-sec">
                    <v-btn class="badge badge-grey text" icon>
                      <v-icon size="20" color="black" class="mr-2">mdil-calendar</v-icon>
                      <span>{{ item.callback_date | moment("DD/MM/YY") }}</span>
                      <span v-if="item.callback_time !== null">@{{ item.callback_time }}</span>
                    </v-btn>
                  </p>
                </template>
                <template v-else>
                  <p class="date-sec vertical-bar">|</p>
                  <p class="date-sec">
                    <v-btn @click="editItem(item)" class="badge badge-grey action" icon>
                      <v-icon size="20" color="black" class="mr-2">mdil-calendar</v-icon>
                      Book Now
                    </v-btn>
                  </p>
                </template>
              </template>
              <template v-slot:item.treatments="{ item }">
                <div class="mb-2">
                  <template v-if="item.user.campaigns && item.user.campaigns.length > 0">
                    <template v-for="campaign in item.user.campaigns">
                      <v-chip
                        small
                        class="este-chip text-uppercase"
                        text-color="white"
                        :color="campaign.color"
                      >
                        {{ campaign.chip | capitalize }}
                      </v-chip>
                    </template>
                  </template>
                </div>
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0, 2)">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </template>
                <span class="badge-label" v-if="item.treatments.length > 2"
                  >+{{ item.treatments.length - 2 }}</span
                >
              </template>
              <template v-slot:item.responsible="{ item }">
                <div class="profile-icon" alt="avatar" v-if="item.consultant !== null">
                  <p>{{ getInitials(item.consultant.name) }}</p>
                </div>
              </template>
              <template v-slot:item.urgency_name="{ item }">
                <v-chip
                  small
                  class="este-chip status-chip"
                  text-color="#221F20"
                  :color="priorityColor(item.urgency)"
                  v-if="item.urgency_name !== null"
                >
                  {{ item.urgency_name | capitalize }}
                </v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('callback');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    :outlined="stat.outline"
                    :text-color="stat.text ? stat.color : '#221F20'"
                    :color="stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Call Back</a></li>
                      <li><a @click="request(item)">Create Appointment</a></li>
                      <li>
                        <a class="text-mute" @click="archiveItem(item)" v-if="item.status !== 7"
                          >Archive Call Back</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import CallbackWidgets from "../../../mixins/CallbackWidgets";
import eventBus from "../../../eventBus.js";
import moment from "moment";
export default {
  name: "Complaints",
  mixins: [globalFn, sliderFn, status, CallbackWidgets],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      snackbar: true,
      showCalendar: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "user", align: "left", sortable: false },
        { text: "Contact Details", value: "phone", align: "left", sortable: false },
        { text: "Deals / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        { text: "Priority", value: "urgency_name", align: "left", sortable: false },
        { text: "Call Back Date", value: "callback_date", sortable: false, align: "left" },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      CallbackParams: {
        attributes: "user,user.campaigns,branch,treatments,consultant,documents,comments",
        search: "",
        filter: {
          callback_date_from: "",
          callback_date_to: "",
          callback_time_from: "",
          callback_time_to: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
      },
      showCallbackParams:
        "?attributes=user,user.campaigns,branch,treatments,consultant,documents,comments",
      message: "",
      action: "",
      actionText: "",
      calendarData: [],
      start: "",
      end: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("callbacksTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchCallback", this.searchCallback);
  },
  created() {
    window.Echo.channel("new-callback").listen(".callback-created", (e) => {
      this.options.page = 1;
      let res = [];
      res.type = "success";
      res.treatments = [];
      res.treatments = e.data.treatments;
      let name = e.data.name ? e.data.name : `@ ${e.data.social_handle}`;
      res.message =
        e.data.treatments.length > 0
          ? `New Callback Added: ${name} for`
          : `New Callback Added: ${name}`;

      if (this.user.user_type_name === "Admin") {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      } else if (
        this.user.permission_branches.some((el) => el.id === e.data.branch_id) ||
        this.user.branch_id === e.data.branch_id
      ) {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      }
    });
  },
  methods: {
    async searchCallback(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.CallbackParams.widget_filter = "";
      }
      this.CallbackParams.search = data.search;
      this.CallbackParams.filter.callback_date_from = data.callback_date_from;
      this.CallbackParams.filter.callback_date_to = data.callback_date_to;
      this.CallbackParams.filter.callback_time_from = data.callback_time_from;
      this.CallbackParams.filter.callback_time_to = data.callback_time_to;
      this.CallbackParams.filter.status = data.status;
      this.CallbackParams.filter.branch = data.branch;
      this.CallbackParams.filter.source = data.source;
      this.CallbackParams.filter.assigned = data.assigned;
      this.CallbackParams.filter.assigned_to = data.assigned_to;
      this.CallbackParams.filter.source_blank = data.source_blank;

      await this.loadCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      this.options.page = 1;
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.CallbackParams.widget_filter = item.active ? item.slug : "";
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("callback/GET_CALLBACK", {
          data: this.CallbackParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      let result = [];
      await this.$store
        .dispatch("callback/SHOW_CALLBACK", { id: item.id, param: this.showCallbackParams })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "callback/editPane");
          } else {
            this.openDrawer("add", "750", "callback/editTab");
          }
          result = res.data;
        });
      this.$root.$emit("loadLeadDetails", result);
    },
    handleFunction(funName) {
      this[funName]();
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this call back?";
      this.actionText = "Archive";
      this.action = "archiveCallback";
    },
    async archiveCallback() {
      this.selectedItem.status = 7;
      await this.$store
        .dispatch("callback/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    request(item) {
      this.selectedItem = item;
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "appointments/addPane");
      } else {
        this.openDrawer("add", "750", "appointments/addTab");
      }
      localStorage.setItem("appointmentClientId", item.user_id);
      localStorage.setItem("appointmentUserType", item.user.user_type_name);
    },
  },
};
</script>
