<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Quick Add Lead</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Social Handle </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  :rules="rules.social_handle"
                  @input="isTyping = true"
                  v-model="form.social_handle.value"
                  flat
                  hide-details="auto"
                  class="solo-cust social-handle-input"
                  :class="form.social_handle.error ? 'v-input--has-state error--text' : ''"
                >
                  <template slot="append-outer">
                    <v-select
                      :items="source"
                      :rules="rules.source_id"
                      v-model="form.source_id"
                      item-text="name"
                      item-value="id"
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                      :class="form.social_handle.error ? 'v-input--has-state error--text' : ''"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                      <template slot="selection" slot-scope="data">
                        <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px" />
                      </template>
                      <template slot="item" slot-scope="data">
                        <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px" />
                      </template>
                    </v-select>
                  </template>
                </v-text-field>
                <span class="quick-validation d-flex align-center" v-if="form.social_handle.error">
                  <v-chip
                    small
                    :height="20"
                    class="este-chip status-chip mr-2"
                    :text-color="form.social_handle.error.includes('Lead') ? 'white' : 'black'"
                    :color="
                      form.social_handle.error.includes('Lead')
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(223, 180, 71, 0.4)'
                    "
                  >
                    {{ form.social_handle.error.includes("Lead") ? "Lead" : "Client" }}
                  </v-chip>
                  {{
                    form.social_handle.error.includes("Lead")
                      ? form.social_handle.error.replace("Lead", "")
                      : form.social_handle.error.replace("Client", "")
                  }}
                  <p class="ml-2">
                    <a @click="editItem" class="text-decoration-underline"
                      >Edit {{ form.social_handle.error.includes("Lead") ? "Lead" : "Client" }}
                    </a>
                  </p>
                </span>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <label>
                  <span class="label-txt"> Contact No </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  @input="isTyping = true"
                  v-model="form.contact_no.value"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                  :class="form.contact_no.error ? 'v-input--has-state error--text' : ''"
                ></v-text-field>
                <span class="quick-validation" v-if="form.contact_no.error">
                  <v-chip
                    small
                    :height="20"
                    class="este-chip status-chip mr-0"
                    :text-color="form.contact_no.error.includes('Lead') ? 'white' : 'black'"
                    :color="
                      form.contact_no.error.includes('Lead')
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(223, 180, 71, 0.4)'
                    "
                  >
                    {{ form.contact_no.error.includes("Lead") ? "Lead" : "Client" }}
                  </v-chip>
                  {{
                    form.contact_no.error.includes("Lead")
                      ? form.contact_no.error.replace("Lead", "")
                      : form.contact_no.error.replace("Client", "")
                  }}
                  <v-btn
                    text
                    :ripple="false"
                    class="text-decoration-underline text-capitalize"
                    @click="editItem"
                  >
                    Edit {{ form.contact_no.error.includes("Lead") ? "Lead" : "Client" }}</v-btn
                  >
                </span>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <label>
                  <span class="label-txt"> Email </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  @input="isTyping = true"
                  :class="form.email.error ? 'v-input--has-state error--text' : ''"
                  v-model="form.email.value"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      value="100"
                      color="red"
                      absolute
                      height="2"
                    ></v-progress-linear>
                  </template>
                </v-text-field>
                <span class="quick-validation" v-if="form.email.error">
                  <v-chip
                    small
                    :height="20"
                    class="este-chip status-chip mr-0"
                    :text-color="form.email.error.includes('Lead') ? 'white' : 'black'"
                    :color="
                      form.email.error.includes('Lead')
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(223, 180, 71, 0.4)'
                    "
                  >
                    {{ form.email.error.includes("Lead") ? "Lead" : "Client" }}
                  </v-chip>
                  {{
                    form.email.error.includes("Lead")
                      ? form.email.error.replace("Lead", "")
                      : form.email.error.replace("Client", "")
                  }}
                  <a @click="editItem" class="text-decoration-underline"
                    >Edit {{ form.email.error.includes("Lead") ? "Lead" : "Client" }}</a
                  >
                </span>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Treatments </span>
                </label>
                <v-autocomplete
                  v-model="form.treatment_ids"
                  :rules="rules.treatment_ids"
                  :items="treatments"
                  item-text="name"
                  item-value="id"
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Campaigns: </span>
                </label>
                <v-autocomplete
                  v-model="form.campaign_ids"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      class="este-chip tag-chip px-3"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <span class="pr-2">
                        {{ data.item.name }}
                      </span>
                      <v-icon color="primary" small @click.stop="removeCampaign(data.item)">
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Branches </span>
                </label>
                <v-autocomplete
                  v-model="form.branch_id"
                  :rules="rules.branch_id"
                  :items="branches"
                  item-text="branch"
                  item-value="id"
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="">
                <label>
                  <span class="label-txt"> Leave Notes </span>
                </label>
                <v-text-field
                  v-model="form.comment"
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="solo-cust"
                  placeholder="Add a Note"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn
          class="cancel-btn btn-gap"
          :class="IsDuplicate && markAsChaseAndCallback ? 'px-10' : 'px-15'"
          depressed
          rounded
          outlined
          x-large
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
          v-if="!IsDuplicate"
        >
          Add Lead
        </v-btn>
        <v-btn
          color="chase"
          class="b-blue black--text mr-2"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="markToChase(false, 'chase')"
          v-if="IsDuplicate && markAsChase"
        >
          Mark to Chase
        </v-btn>
        <v-btn
          color="chase"
          class="b-blue black--text mr-2"
          depressed
          rounded
          x-large
          :disabled="callbackDisabled"
          @click="markToChase(false, 'chase')"
          v-if="IsDuplicate && markAsChaseAndCallback"
        >
          Mark to Chase
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue black--text mr-2"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="markToChase(false, 'new')"
          v-if="IsDuplicate"
        >
          Mark As New
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import sliderFn from "../../../mixins/sliderFn";
import _ from "lodash";
export default {
  mixins: [status, globalFn, sliderFn],
  data: () => ({
    loading: false,
    isTyping: false,
    markAsChase: false,
    markAsChaseAndCallback: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    IsDuplicate: false,
    sourceIcon: "",
    emailErrorMessage: "",
    contactErrorMessage: "",
    socialErrorMessage: "",
    form: {
      social_handle: {
        value: "",
        error: "",
      },
      email: {
        value: "",
        error: "",
      },
      contact_no: {
        value: "",
        error: "",
      },
      treatment_ids: "",
      branch_id: "",
      source_id: "",
      comment: "",
      campaign_ids: [],
    },
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      treatment_ids: [(v) => !!v || "Please select treatments"],
      source_id: [(v) => !!v || "Please select source"],
      social_handle: [(v) => !!v || "Please enter social handle"],
      contact_no: [],
    },
    ExistUserId: "",
    callbackDisabled: false,
    validateUserType: "",
  }),
  watch: {
    "form.email.value": _.debounce(function () {
      this.isTyping = false;
      if (this.form.email.value) {
        this.checkForDuplicate();
      } else {
        this.form.email.error = "";
      }
    }, 3000),
    "form.contact_no.value": _.debounce(function () {
      this.isTyping = false;
      if (this.form.contact_no.value) {
        this.checkForDuplicate();
      } else {
        this.form.contact_no.error = "";
      }
    }, 3000),
    "form.social_handle.value": _.debounce(function () {
      this.isTyping = false;
      if (this.form.social_handle.value) {
        this.checkForDuplicate();
      } else {
        this.form.social_handle.error = "";
      }
    }, 3000),
    "form.source_id": _.debounce(function () {
      this.isTyping = false;
      if (this.form.social_handle.value) {
        this.checkForDuplicate();
      } else {
        this.form.social_handle.error = "";
      }
    }, 3000),
  },
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("campaign/LIST", "");
  },
  methods: {
    async removeCampaign(item) {
      const index = this.form.campaign_ids.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.form.campaign_ids.splice(index, 1);
      } else {
        const i = this.form.campaign_ids.findIndex((x) => x === item.id);
        if (i >= 0) this.form.campaign_ids.splice(i, 1);
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      if (this.$router.currentRoute.meta.slug === "customer") {
        this.$root.$emit("customerTableUpdate", "");
      } else {
        this.$root.$emit("leadsTableUpdate", "");
      }
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    checkForDuplicate() {
      if (!this.form.social_handle.value && !this.form.email.value && !this.form.contact_no.value) {
        this.form.contact_no.error = "";
        this.form.email.error = "";
        this.form.social_handle.error = "";
        this.markAsChase = false;
        this.markAsChaseAndCallback = false;
        this.IsDuplicate = false;
      } else {
        this.CheckEmailDuplicate();
        this.CheckContactDuplicate();
        this.CheckSocialDuplicate();
      }
    },
    async CheckEmailDuplicate() {
      if (this.form.email.value) {
        let formData = {};
        formData.email = this.form.email.value;
        await this.validate(formData, "email");
        this.form.email.error = this.IsDuplicate ? this.emailErrorMessage : "";
      }
    },
    async CheckContactDuplicate() {
      if (this.form.contact_no.value) {
        let formData = {};
        formData.contact_no = this.form.contact_no.value;
        await this.validate(formData, "contact");
        this.form.contact_no.error = this.IsDuplicate ? this.contactErrorMessage : "";
      }
    },
    async CheckSocialDuplicate() {
      if (this.form.social_handle.value) {
        let formData = {};
        let isValid = this.isValidUrl(this.form.social_handle.value);
        console.log(isValid);
        if (isValid) {
          let url = new URL(this.form.social_handle.value);
          this.form.source_id = this.getSourceId(url.origin);
        }
        formData.source_id = this.form.source_id;
        formData.social_handle = this.form.social_handle.value;
        await this.validate(formData, "social");
        this.form.social_handle.error = this.IsDuplicate ? this.socialErrorMessage : "";
      }
    },
    async validate(formData, type) {
      this.IsDuplicate = false;
      this.markAsChase = false;
      this.markAsChaseAndCallback = false;
      this.emailErrorMessage = "";
      this.contactErrorMessage = "";
      this.socialErrorMessage = "";
      this.validateUserType = "";
      this.ExistUserId = "";
      await this.$store
        .dispatch("leads/VALIDATE_LEAD", formData)
        .then((response) => {
          if (response.result === "success" && response.data.status) {
            this.loading = false;
            this.IsDuplicate = response.data.status;
            this.ExistUserId = response.data.user_id;
            this.validateUserType = response.data.user_type_name;
            this.markAsChaseAndCallback = response.data.user_type_name === "Client" ? true : false;
            this.markAsChase = response.data.user_type_name === "Lead" ? true : false;
            if (type === "email") {
              this.emailErrorMessage = response.message;
            }
            if (type === "contact") {
              this.contactErrorMessage = response.message;
            }
            if (type === "social") {
              this.socialErrorMessage = response.message;
            }
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.source.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async create() {
      let formData = {};
      formData.contact_no = this.form.contact_no.value;
      formData.comment = this.form.comment;
      if (this.form.email.value) {
        formData.email = this.form.email.value;
      }
      formData.source_id = this.form.source_id;
      formData.social_handle = this.form.social_handle.value;
      formData.branch_id = this.form.branch_id;
      formData.treatment_ids = this.form.treatment_ids;
      formData.user_exists = this.IsDuplicate;

      formData.campaign_ids = [];
      this.form.campaign_ids.forEach((value) => {
        if (value.id) {
          formData.campaign_ids.push(value.id);
        } else {
          formData.campaign_ids.push(value);
        }
      });

      if (this.form.contact_no === "" && this.form.email === "") {
        let response = [];
        response.type = "error";
        response.message = "Please enter either email address/contact number";
        this.$store.dispatch("snackbar/SHOW", response);
        this.$refs.form.validate();
      } else {
        if (
          this.$refs.form.validate() &&
          this.form.social_handle.error === "" &&
          this.form.email.error === "" &&
          this.form.contact_no.error === ""
        ) {
          this.loading = true;
          await this.$store
            .dispatch("leads/QUICK_LEAD", formData)
            .then((response) => {
              if (response.result === "success") {
                this.loading = false;
                this.$refs.form.reset();
                this.loadData();
              } else if (response.result === "fail") {
                response.type = "error";
                this.loading = false;
                this.$store.dispatch("snackbar/SHOW", response);
              }
            })
            .catch((err) => {
              this.loading = false;
              err.type = "error";
              this.$store.dispatch("snackbar/SHOW", err);
            });
        }
      }
    },

    async markToChase(callback, type) {
      let formData = {};
      formData.contact_no = this.form.contact_no.value;
      formData.comment = this.form.comment;
      if (this.form.email.value) {
        formData.email = this.form.email.value;
      }
      formData.source_id = this.form.source_id;
      formData.social_handle = this.form.social_handle.value;
      formData.branch_id = this.form.branch_id;
      formData.treatment_ids = this.form.treatment_ids;
      formData.campaign_ids = this.form.campaign_ids;
      formData.user_exists = this.IsDuplicate;
      formData.user_id = this.ExistUserId;
      formData.user_type = this.validateUserType;
      formData.status = type === "chase" ? 20 : 3;

      this.loading = true;
      await this.$store
        .dispatch("leads/QUICK_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            if (callback) {
              this.createCallback();
            } else {
              this.$refs.form.reset();
              this.loadData();
            }
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async createCallback() {
      this.callbackDisabled = true;
      let formData = {};
      formData.user_id = this.ExistUserId;
      formData.treatment_ids = this.form.treatment_ids;

      await this.$store
        .dispatch("callback/CREATE_CALLBACK_USER", formData)
        .then((response) => {
          if (response.result === "success") {
            this.callbackDisabled = false;
            this.loadData();
            this.$refs.form.reset();
          } else if (response.result === "fail") {
            response.type = "error";
            this.callbackDisabled = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.callbackDisabled = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async markToNew() {
      let action =
        this.validateUserType === "Lead" ? "leads/ARCHIVE_LEAD" : "clients/ARCHIVE_CLIENT";
      let status = this.validateUserType === "Lead" ? 3 : 1;
      await this.$store
        .dispatch(action, { id: this.ExistUserId, status: status })
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.$refs.form.reset();
            this.loadData();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async editItem() {
      if (this.validateUserType === "Lead") {
        await this.$store
          .dispatch("leads/SHOW_LEAD", {
            id: this.ExistUserId,
            param:
              "?attributes=staff,branch,leadDetails,reasons,symptoms,treatments,source,campaigns",
          })
          .then((res) => {
            if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
              this.openDrawer("add", "1100", "leads/editPane");
            } else {
              this.openDrawer("add", "750", "leads/editTab");
            }
          });
      } else if (this.validateUserType === "Client") {
        await this.$store
          .dispatch("clients/SHOW_CLIENT", {
            id: this.ExistUserId,
            param:
              "?attributes=staff,branch,clientDetails,reasons,symptoms,treatments,documents,comments,source,campaigns",
          })
          .then((res) => {
            if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
              this.openDrawer("add", "1100", "clients/editPane");
            } else {
              this.openDrawer("add", "750", "clients/editTab");
            }
          });
      }
    },
  },
};
</script>
