<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Add Staff</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#personal">Personal Details</v-tab>
          <!-- <v-tab href="#treatments">Treatments</v-tab> -->
          <v-tab href="#permissions">Permissions</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="personal" :eager="true">
            <v-form ref="personalForm">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="6" md="2" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt">Title</span>
                        <v-select
                          autocomplete="off"
                          :items="titles"
                          :rules="rules.title"
                          v-model="form.title"
                          solo
                          outlined
                          flat
                          class="solo-cust"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </label>
                    </div>
                  </v-col>
                  <v-col cols="6" md="10" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Name </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          v-model="form.name"
                          :rules="rules.name"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Phone No. </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        v-model="form.contact_no"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-phone"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Email Address </span>
                      <v-text-field
                        autocomplete="off"
                        v-model="form.email"
                        :rules="rules.email"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-email"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Address </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.address_one"
                        hide-details="auto"
                        class="solo-cust mb-2"
                      ></v-text-field>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.address_two"
                        hide-details="auto"
                        class="solo-cust mar-8"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Town / City </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.city"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt"> Post Code </span>
                          <v-text-field
                            autocomplete="off"
                            solo
                            outlined
                            flat
                            v-model="form.postcode"
                            hide-details="auto"
                            class="solo-cust"
                          ></v-text-field>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        :rules="rules.branch_id"
                        item-value="id"
                        item-text="branch"
                        v-model="form.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Role: </span>
                      <v-select
                        autocomplete="off"
                        :items="roles"
                        :rules="rules.role_type"
                        item-value="id"
                        item-text="name"
                        v-model="form.role_type"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Commission % </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.commission"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Pabau ID </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          v-model="form.pabau_id"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="treatments" :eager="true">
            <v-form ref="treatmentsForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatments </span>
                    </label>
                    <v-autocomplete
                      v-model="form.skill_treatments"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="treatmentsSelectToggle">
                          <v-list-item-action>
                            <v-checkbox v-model="treatmentSelect"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.skill_treatments.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Consultations </span>
                    </label>
                    <v-autocomplete
                      v-model="form.skill_consultations"
                      :items="consultations"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="consultationsSelectToggle">
                          <v-list-item-action>
                            <v-checkbox v-model="consultationsSelect"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.skill_consultations.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="permissions" :eager="true">
            <v-form ref="permissionsForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatments </span>
                    </label>
                    <v-autocomplete
                      v-model="form.permission_treatments"
                      :items="perTreatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="perTreatmentsSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="perTreatmentsSelect"
                              @input="perTreatmentsSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.permission_treatments.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Consultations </span>
                    </label>
                    <v-autocomplete
                      v-model="form.permission_consultations"
                      :items="perConsultations"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="perConsultationsSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="perConsultationsSelect"
                              @input="perConsultationsSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.permission_consultations.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Branches </span>
                    </label>
                    <v-autocomplete
                      v-model="form.permission_branches"
                      :items="branches"
                      item-text="branch"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="branchChange">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="brancAllSelect"
                              @input="branchChange"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.branch }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.permission_branches.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Warehouse </span>
                    </label>
                    <v-autocomplete
                      v-model="form.permission_warehouses"
                      :items="warehouses"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="warehouseChange">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="warehouseAllSelect"
                              @input="warehouseChange"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.permission_warehouses.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Can see All Branch Data </span>
                    </label>
                    <v-radio-group hide-details="auto" mandatory v-model="form.no_branch_data" row>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="Yes"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="No"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Modules </span>
                    </label>
                    <v-autocomplete
                      v-model="form.permission_modules"
                      :rules="rules.permission_modules"
                      :items="modules"
                      multiple
                      item-text="name"
                      item-value="id"
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="moduleSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="moduleSelect"
                              @input="moduleSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="addStaff"
        >
          Add Staff
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    brancAllSelect: false,
    warehouseAllSelect: false,
    moduleSelect: false,
    treatmentSelect: false,
    consultationsSelect: false,
    perTreatmentsSelect: false,
    perConsultationsSelect: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    form: {
      title: "",
      name: "",
      address_one: "",
      address_two: "",
      city: "",
      postcode: "",
      contact_no: "",
      branch_id: "",
      email: "",
      skill_consultations: [],
      permission_consultations: [],
      skill_treatments: [],
      permission_treatments: [],
      permission_branches: [],
      permission_warehouses: [],
      permission_modules: [],
      status: 1,
      commission: "",
      role_type: "",
      job_title: "",
      pabau_id: "",
      no_branch_data: 0,
    },
    rules: {
      title: [(v) => !!v || "Please select title"],
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      role_type: [(v) => !!v || "Please select role"],
      permission_modules: [],
    },
  }),
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    perTreatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    consultations() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    perConsultations() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    warehouses() {
      if (this.$store.state.warehouse.all) {
        return this.$store.state.warehouse.all;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("warehouse/LIST", "");
  },
  methods: {
    branchChange() {
      this.brancAllSelect = !this.brancAllSelect;
      if (this.brancAllSelect === true) {
        this.form.permission_branches = [];
        this.branches.forEach((value) => {
          this.form.permission_branches.push(parseInt(value.id));
        });
      } else {
        this.form.permission_branches = [];
      }
    },
    warehouseChange() {
      this.warehouseAllSelect = !this.warehouseAllSelect;
      if (this.warehouseAllSelect === true) {
        this.form.permission_warehouses = [];
        this.warehouses.forEach((value) => {
          this.form.permission_warehouses.push(parseInt(value.id));
        });
      } else {
        this.form.permission_warehouses = [];
      }
    },
    moduleSelectToggle() {
      this.moduleSelect = !this.moduleSelect;
      if (this.moduleSelect === true) {
        this.form.permission_modules = [];
        this.modules.forEach((value) => {
          this.form.permission_modules.push(value.id);
        });
      } else {
        this.form.permission_modules = [];
      }
    },
    perTreatmentsSelectToggle() {
      this.perTreatmentsSelect = !this.perTreatmentsSelect;
      if (this.perTreatmentsSelect === true) {
        this.form.permission_treatments = [];
        this.perTreatments.forEach((value) => {
          this.form.permission_treatments.push(parseInt(value.id));
        });
      } else {
        this.form.permission_treatments = [];
      }
    },
    treatmentsSelectToggle() {
      this.treatmentSelect = !this.treatmentSelect;
      if (this.treatmentSelect === true) {
        this.form.skill_treatments = [];
        this.treatments.forEach((value) => {
          this.form.skill_treatments.push(parseInt(value.id));
        });
      } else {
        this.form.skill_treatments = [];
      }
    },
    consultationsSelectToggle() {
      this.consultationsSelect = !this.consultationsSelect;
      if (this.consultationsSelect === true) {
        this.form.skill_consultations = [];
        this.consultations.forEach((value) => {
          this.form.skill_consultations.push(parseInt(value.id));
        });
      } else {
        this.form.skill_consultations = [];
      }
    },
    perConsultationsSelectToggle() {
      this.perConsultationsSelect = !this.perConsultationsSelect;
      if (this.perConsultationsSelect === true) {
        this.form.permission_consultations = [];
        this.perConsultations.forEach((value) => {
          this.form.permission_consultations.push(parseInt(value.id));
        });
      } else {
        this.form.permission_consultations = [];
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("staffTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async addStaff() {
      this.tab = "personal";
      if (this.$refs.personalForm.validate()) {
        if (this.form.permission_modules.length === 0) {
          this.tab = "permissions";
          let response = [];
          response.type = "error";
          response.message = "Please select Module permissions";
          this.$store.dispatch("snackbar/SHOW", response);
          this.rules.permission_modules = [(v) => !!v || "Please select permission modules"];
        } else {
          this.loading = true;
          let formData = {};
          formData.title = this.form.title;
          formData.name = this.form.name;
          formData.address_one = this.form.address_one;
          formData.address_two = this.form.address_two;
          formData.city = this.form.city;
          formData.postcode = this.form.postcode;
          formData.contact_no = this.form.contact_no;
          formData.branch_id = this.form.branch_id;
          formData.email = this.form.email;
          formData.status = this.form.status;
          formData.commission = this.form.commission;
          formData.role_type = this.form.role_type;
          formData.pabau_id = this.form.pabau_id;
          formData.no_branch_data = this.form.no_branch_data;

          // formData.skill_treatments = []
          // this.form.skill_treatments.forEach(value => {
          //   if (value.id) {
          //     formData.skill_treatments.push({ id: value.id });
          //   } else {
          //     formData.skill_treatments.push(value);
          //   }
          // });

          // formData.skill_consultations = []
          // this.form.skill_consultations.forEach(value => {
          //   if (value.id) {
          //     formData.skill_consultations.push(value.id);
          //   } else {
          //     formData.skill_consultations.push(value);
          //   }
          // });

          formData.permission_consultations = [];
          this.form.permission_consultations.forEach((value) => {
            if (value.id) {
              formData.permission_consultations.push(value.id);
            } else {
              formData.permission_consultations.push(value);
            }
          });

          formData.permission_treatments = [];
          this.form.permission_treatments.forEach((value) => {
            if (value.id) {
              formData.permission_treatments.push(value.id);
            } else {
              formData.permission_treatments.push(value);
            }
          });

          formData.permission_branches = [];
          this.form.permission_branches.forEach((value) => {
            if (value.id) {
              formData.permission_branches.push(value.id);
            } else {
              formData.permission_branches.push(value);
            }
          });

          formData.permission_warehouses = [];
          this.form.permission_warehouses.forEach((value) => {
            if (value.id) {
              formData.permission_warehouses.push(value.id);
            } else {
              formData.permission_warehouses.push(value);
            }
          });

          formData.permission_modules = [];
          this.form.permission_modules.forEach((value) => {
            if (value) {
              formData.permission_modules.push(value);
            }
          });
          await this.$store
            .dispatch("staff/CREATE_STAFF", formData)
            .then((response) => {
              if (response.result === "success") {
                this.loading = false;
                this.loadData();
              } else if (response.result === "fail") {
                response.type = "error";
                this.loading = false;
                this.$store.dispatch("snackbar/SHOW", response);
              }
            })
            .catch((err) => {
              this.loading = false;
              err.type = "error";
              this.$store.dispatch("snackbar/SHOW", err);
            });
        }
      }
    },
  },
};
</script>
