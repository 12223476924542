import collection from "../../../api/collections/Photos";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    albumPhotos: [],
  },
  mutations: {
    SET_PHOTOS(state, data) {
      state.list = data;
    },
    SET_ALBUM_PHOTOS(state, data) {
      state.albumPhotos = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_PHOTOS", data.data.data);
          return data.data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async GET_BY_ALBUM({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_ALBUM_PHOTOS", data.data.data);
          return data.data;
        },
        commit,
        dispatch,
        "GET_BY_ALBUM",
        true
      );
    },
    async UPLOAD({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.upload(payload);

          if (data.result === "success") {
            dispatch("GET_PHOTOS");
          }

          return data;
        },
        commit,
        dispatch,
        "UPLOAD",
        true
      );
    },
    async DELETE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);

          if (data.result === "success") {
            dispatch("GET_PHOTOS");
          }

          return data;
        },
        commit,
        dispatch,
        "DELETE",
        true
      );
    },
  },
};
