export default {
  data: () => ({
    staff: [],
    unassignedCheck: false,
    SourceBlank: true,
    BranchBlank: true,
    BrachDefault: [],
    SourceDefault: [],
    StatusDefault: [],
    StatusToggle: false,
    BranchToggle: true,
    SourceToggle: true,
  }),
  mounted() {
    this.loadStaffMems();
  },
  methods: {
    async loadDefaults() {
      await this.loadDefaultBranch();
      await this.loadDefaultSource();
      await this.loadDefaultStatus();
      await this.searchKey();
    },
    async loadDefaultBranch() {
      await this.$store.dispatch("branches/GET_ALL_BRANCH", "").then((res) => {
        this.BrachDefault =
          this.user.user_type_name === "Admin" ? res.data : this.user.permission_branches;
      });
      this.BrachDefault = this.BrachDefault.map(function (el) {
        let o = Object.assign({}, el);
        o.active = true;
        return o;
      });

      this.filter.branch = this.BrachDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
      this.BranchToggle = true;
      this.BranchBlank = true;
    },
    async loadDefaultSource() {
      await this.$store.dispatch("source/GET_SOURCE", "").then((res) => {
        this.SourceDefault = res.data;
      });
      this.SourceDefault = this.SourceDefault.map(function (el) {
        let o = Object.assign({}, el);
        o.active = true;
        return o;
      });
      this.filter.source = this.SourceDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
      this.SourceToggle = true;
      this.SourceBlank = true;
    },
    async loadDefaultStatus() {
      let slug = await this.getSlug();
      if (
        [
          "leads",
          "clients",
          "callback",
          "appointments",
          "complaints",
          "insights",
          "pabau",
          "customer",
          "offers",
          "opportunities",
          "support-tickets",
          "notifications",
        ].includes(slug)
      ) {
        this.StatusDefault = this.SortAtoZ(
          this.statusTags.filter(function (el) {
            return el.slug.includes(slug.replace(/s$/, ""));
          })
        );
        this.StatusDefault.forEach((value) => {
          value.active = !value.hidden ? true : false;
        });
        this.filter.status = this.StatusDefault.filter(function (el) {
          return el.active === true;
        }).map(function (el) {
          return el.id;
        });
      }
    },
    async loadStaffMems() {
      let params =
        this.$router.currentRoute.meta.slug === "app-treatments"
          ? `?type=treatment`
          : this.$router.currentRoute.meta.slug === "appointments"
          ? `?type=consultation`
          : "";
      let slug = await this.getSlug();
      if (slug === "customer") {
        let arr = [];
        await this.$store
          .dispatch("leads/ASSIGNED_TO", params)
          .then((response) => {
            if (response.result === "success") {
              response.data = response.data.map(function (el) {
                let item = Object.assign({}, el);
                item.active = false;
                return item;
              });
              if (response.data) {
                response.data.forEach((item) => {
                  arr.push(item);
                });
              }
            } else if (response.result === "fail") {
              response.type = "error";
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });

        await this.$store
          .dispatch("clients/ASSIGNED_TO", params)
          .then((response) => {
            if (response.result === "success") {
              response.data = response.data.map(function (el) {
                let item = Object.assign({}, el);
                item.active = false;
                return item;
              });
              if (response.data) {
                response.data.forEach((item) => {
                  arr.push(item);
                });
              }
            } else if (response.result === "fail") {
              response.type = "error";
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });

        this.staff = arr;
      } else {
        slug = slug === "support-tickets" ? "complaints" : slug;
        if (
          ["leads", "clients", "callback", "appointments", "complaints", "opportunities"].includes(
            slug
          )
        ) {
          let action = `${slug}/ASSIGNED_TO`;
          await this.$store
            .dispatch(action, params)
            .then((response) => {
              if (response.result === "success") {
                response.data = response.data.map(function (el) {
                  let item = Object.assign({}, el);
                  item.active = false;
                  return item;
                });
                this.staff = response.data;
              } else if (response.result === "fail") {
                response.type = "error";
                this.$store.dispatch("snackbar/SHOW", response);
              }
            })
            .catch((err) => {
              err.type = "error";
              this.$store.dispatch("snackbar/SHOW", err);
            });
        }
      }
    },

    async getSlug() {
      switch (this.$router.currentRoute.meta.slug) {
        case "call-back":
          return "callback";
          break;
        case "app-treatments":
          return "appointments";
          break;
        case "app-treatments":
          return "appointments";
          break;
        case "dashboard":
          return "opportunities";
          break;
        default:
          return this.$router.currentRoute.meta.slug;
      }
    },
    async branchToggle(item) {
      this.filter.branch = [];
      item.active = !item.active;
      this.filter.branch = this.BrachDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },
    async sourceToggle(item) {
      this.filter.source = [];
      item.active = !item.active;
      this.filter.source = this.SourceDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },

    async filterUnassign() {
      this.unassignedCheck = !this.unassignedCheck;
      this.filter.assigned_to = this.unassignedCheck ? 1 : 0;
      this.filter.assigned = [];
      this.staff.forEach((value) => {
        value.active = false;
      });
    },

    async statusToggle(item) {
      this.filter.status = [];
      item.active = !item.active;
      this.filter.status = this.StatusDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },
    async staffToggle(item) {
      this.unassignedCheck = false;
      this.filter.assigned_to = 0;
      this.filter.assigned = [];
      item.active = !item.active;
      this.filter.assigned = this.staff
        .filter(function (el) {
          return el.active === true;
        })
        .map(function (el) {
          return el.id;
        });
    },
    async satisfactionToggle(item) {
      this.filter.satisfaction = [];
      item.active = !item.active;
      this.filter.satisfaction = this.satisfaction
        .filter(function (el) {
          return el.active === true;
        })
        .map(function (el) {
          return el.slug;
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    StatusCheckUncheck() {
      if (this.StatusToggle) {
        this.StatusDefault.map(function (el) {
          return (el.active = true);
        });
      } else {
        this.StatusDefault.map(function (el) {
          return (el.active = false);
        });
      }
      this.filter.status = this.StatusDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },
    BranchCheckUncheck() {
      if (this.BranchToggle) {
        this.BrachDefault.map(function (el) {
          return (el.active = true);
        });
        this.BranchBlank = true;
      } else {
        this.BrachDefault.map(function (el) {
          return (el.active = false);
        });
        this.BranchBlank = false;
      }
      this.filter.branch_blank = this.BranchBlank ? 1 : 0;
      this.filter.branch = this.BrachDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },
    SourceCheckUncheck() {
      if (this.SourceToggle) {
        this.SourceDefault.map(function (el) {
          return (el.active = true);
        });

        this.SourceBlank = true;
      } else {
        this.SourceDefault.map(function (el) {
          return (el.active = false);
        });
        this.SourceBlank = false;
      }
      this.filter.source_blank = this.SourceBlank ? 1 : 0;
      this.filter.source = this.SourceDefault.filter(function (el) {
        return el.active === true;
      }).map(function (el) {
        return el.id;
      });
    },

    async selectSourceBlank() {
      this.SourceBlank = !this.SourceBlank;
      this.filter.source_blank = this.SourceBlank ? 1 : 0;
    },

    async selectBranchBlank() {
      this.BranchBlank = !this.BranchBlank;
      this.filter.branch_blank = this.BranchBlank ? 1 : 0;
    },

    checkAll() {
      //commeting this to result all statuses when on search keyword results.
      this.StatusToggle = false;
      this.StatusCheckUncheck();

      this.BranchToggle = true;
      this.BranchCheckUncheck();

      this.SourceToggle = true;
      this.SourceBlank = true;
      this.SourceCheckUncheck();
    },
  },
};
