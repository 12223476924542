<template>
  <v-card class="border" width="400">
    <v-row class="pa-3" align="center">
      <v-col cols="5" class="d-flex align-center justify-center">
        <div>
          <v-icon color="grey-lighten-2" size="40">mdi-account-circle</v-icon>
        </div>
        <div class="text-body-2 ml-2">{{ date | moment("MMM DD,YYYY") }}</div>
      </v-col>
      <v-col cols="7" class="d-flex align-center justify-end" style="gap: 0.5rem">
        <v-btn icon small elevation="0" color="blue">
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn icon small elevation="0" color="grey" @click="addHoliday">
          <v-icon>mdi-briefcase-variant</v-icon>
        </v-btn>

        <v-chip small outlined color="blue"> Soon</v-chip>

        <v-btn icon elevation="0" small @click="$emit('expandForm')" class="ml-1">
          <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider :thickness="2"></v-divider>

    <v-form ref="form" @submit.prevent="update">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="mb-1"><v-icon>mdi-clock-outline</v-icon> Choose time</div>

            <v-menu
              ref="startTime"
              v-model="menu.startTime"
              :close-on-content-click="false"
              :return-value.sync="formObj.start_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  flat
                  hide-details="auto"
                  solo
                  class="advance-input"
                  :rules="rules.startTime"
                  :value="formObj.start_time"
                  placeholder="Start Time"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                :allowed-minutes="allowedStep"
                no-title
                ampm-in-title
                format="24hr"
                v-model="formObj.start_time"
                full-width
                @click:minute="$refs.startTime.save(formObj.start_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <div class="mb-1">Duration: {{ getDuration }}</div>

            <v-menu
              ref="endTime"
              v-model="menu.endTime"
              :close-on-content-click="false"
              :return-value.sync="formObj.end_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  flat
                  hide-details="auto"
                  solo
                  class="advance-input"
                  :rules="rules.startTime"
                  :value="formObj.end_time"
                  placeholder="End Time"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                :allowed-minutes="allowedStep"
                no-title
                ampm-in-title
                format="24hr"
                v-model="formObj.end_time"
                full-width
                @click:minute="$refs.endTime.save(formObj.end_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-capitalize">
              <v-icon>mdi-dresser-outline</v-icon>
              Or change from Shift template
            </div>
          </v-col>

          <v-col>
            <v-btn
              cols="12"
              v-for="duration in durations"
              :key="duration"
              elevation="0"
              outlined
              color="cyan"
              class="my-2 text-capitalize"
              block
              @click="updateDuration(duration.value)"
            >
              {{ duration.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-4">
        <div>
          <v-btn
            elevation="0"
            text
            color="red"
            class="text-capitalize mr-2"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>

          <v-btn elevation="0" outlined color="blue" class="text-capitalize" type="submit">
            Update
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import modalFn from "../../../mixins/modalFn";
export default {
  mixins: [status, globalFn, modalFn],
  props: ["date"],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    form: {
      name: "",
    },
    rules: {
      startTime: [(v) => !!v || "Please enter start time"],
      endTime: [(v) => !!v || "Please enter end time"],
    },
    menu: {
      startTime: false,
      endTime: false,
    },
    formObj: {
      start_time: "00:00",
      end_time: "00:00",
    },
    durations: [
      {
        text: "09:00 am - 05:00 pm",
        value: {
          startTime: "09:00",
          endTime: "17:00",
        },
      },
      {
        text: "09:00 am - 08:00 pm",
        value: {
          startTime: "09:00",
          endTime: "20:00",
        },
      },
      {
        text: "08:00 am - 06:00 pm",
        value: {
          startTime: "08:00",
          endTime: "18:00",
        },
      },
    ],
  }),
  computed: {
    rotaDetails() {
      return this.$store.state.rota.item;
    },
    getDuration() {
      return this.calculateDuration(this.formObj.start_time, this.formObj.end_time);
    },
  },
  watch: {
    rotaDetails(newVal) {
      this.formObj.start_time = newVal.start_time;
      this.formObj.end_time = newVal.end_time;
    },
  },
  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const payload = {
          id: this.rotaDetails.id,
          user_id: this.rotaDetails.user_id,
          branch_id: this.rotaDetails.branch_id,
          date: this.date,
          type: this.rotaDetails.shift_type,
          ...this.formObj,
        };

        await this.$store
          .dispatch("rota/UPDATE_SHIFT", payload)
          .then((res) => {
            if (res.result === "success") {
              this.$root.$emit("fetchRota");
              this.loading = false;
              this.$refs.form.reset();
              this.$store.dispatch("snackbar/SHOW", res);
              this.$emit("close");
            } else if (res.result === "fail") {
              res.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    updateDuration(duration) {
      this.formObj.start_time = duration.startTime;
      this.formObj.end_time = duration.endTime;
    },
    addHoliday() {
      this.openModal("add", 400, "Scheduler/holiday");
      this.$root.holiday_rota = {
        user_id: this.rotaDetails.user_id,
        start_date: this.date,
      };
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss"></style>
