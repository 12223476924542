<template>
  <div>
    <div class="fill-height slider-cont">
      <v-card class="vertical-slide fill-height">
        <v-card-text class="pa-0">
          <v-row dense class="e-row">
            <v-col cols="6" md="3" class="e-col pa-0 ma-0">
              <v-toolbar class="left-toolbar" flat> </v-toolbar>
              <div class="nav-lists tab-drawer fill-height ma-0">
                <v-list dense>
                  <template v-for="(m, index) in tabMenu">
                    <v-list-item
                      class="menu"
                      :class="paneTab === m.slug ? 'active' : ''"
                      :key="index"
                      @click="showTab(m.slug)"
                    >
                      <v-list-item-action class="menu-icon">
                        <v-icon>{{ m.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="menu-txt">
                        <v-list-item-title>
                          {{ m.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </v-col>
            <v-col cols="6" md="9" class="e-col pa-0 ma-0">
              <v-toolbar class="right-toolbar" flat>
                <div class="form-title">Add Call Back</div>
              </v-toolbar>
              <div class="slide-right">
                <div class="form-wrap px-10">
                  <div class="side-pane" v-show="clientUnit">
                    <v-form ref="form">
                      <div class="tab-content-wrapper">
                        <v-row dense class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <div v-if="dropdown">
                              <label>
                                <span class="label-txt">Find Client:</span>
                                <v-autocomplete
                                  v-model="form.user_id"
                                  :rules="rules.user_id"
                                  :items="items"
                                  item-text="name"
                                  item-value="id"
                                  chips
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  hide-details="auto"
                                  class="este-autocomplete-input"
                                  @change="clientChange"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                  <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should rend.er selected items -->
                                    <span v-if="data.item.name !== null">{{ data.item.name }}</span>
                                    <span
                                      v-if="
                                        data.item.name === null && data.item.social_handle !== null
                                      "
                                    >
                                      <img
                                        v-if="data.item.source_id !== null"
                                        :src="`/images/${getSourceName(data.item.source_id)}.svg`"
                                        style="position: relative; top: 3px"
                                      />
                                      <span class="ml-1">@{{ data.item.social_handle }}</span>
                                    </span>
                                    <span class="ml-1" v-if="data.item.email !== null">
                                      - {{ data.item.email }}</span
                                    >
                                    <span class="ml-1" v-if="data.item.contact_no !== null">
                                      - {{ data.item.contact_no }}</span
                                    >
                                  </template>
                                  <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    <span v-if="data.item.name !== null">{{ data.item.name }}</span>
                                    <span
                                      v-if="
                                        data.item.name === null && data.item.social_handle !== null
                                      "
                                    >
                                      <img
                                        v-if="data.item.source_id !== null"
                                        :src="`/images/${getSourceName(data.item.source_id)}.svg`"
                                        style="position: relative; top: 3px"
                                      />
                                      <span class="ml-1">@{{ data.item.social_handle }}</span>
                                    </span>
                                    <span class="ml-1" v-if="data.item.email !== null">
                                      - {{ data.item.email }}</span
                                    >
                                    <span class="ml-1" v-if="data.item.contact_no !== null">
                                      - {{ data.item.contact_no }}</span
                                    >
                                  </template>
                                </v-autocomplete>
                              </label>
                            </div>
                            <div class="client-info" v-if="!dropdown && selectedUser">
                              <div class="name" v-if="selectedUser.name !== null">
                                {{ selectedUser.name }}
                              </div>
                              <div
                                class="d-flex name"
                                v-if="
                                  selectedUser.name === null && selectedUser.social_handle !== null
                                "
                              >
                                <img
                                  :src="`/images/${selectedUser.source_name.toLowerCase()}.svg`"
                                  class="mr-2"
                                  v-if="selectedUser.source_name !== null"
                                />
                                <span> @{{ selectedUser.social_handle }} </span>
                              </div>
                              <p class="mt-4">
                                <span class="contact" v-if="selectedUser.contact_no !== null">
                                  <a :href="`tel:${selectedUser.contact_no}`">
                                    <img src="/images/phone.svg" height="15" />
                                    {{ selectedUser.contact_no }}</a
                                  >
                                </span>
                                <span class="contact" v-if="selectedUser.email !== null">
                                  <a :href="`tel:${selectedUser.email}`">
                                    <img src="/images/email.svg" height="15" />
                                    {{ selectedUser.email }}</a
                                  >
                                </span>
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row dense class="e-row" v-show="showDealsDropdown">
                          <v-col cols="12" md="12" class="e-col">
                            <div>
                              <label>
                                <span class="label-txt">Deals:</span>
                                <v-autocomplete
                                  v-model="form.deal_id"
                                  :items="deals"
                                  item-text="deal_info"
                                  item-value="id"
                                  chips
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  hide-details="auto"
                                  class="este-autocomplete-input"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-autocomplete>
                              </label>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Treatment / Service: </span>
                            </label>
                            <v-autocomplete
                              v-model="form.treatment_id"
                              :items="treatments"
                              item-text="name"
                              item-value="id"
                              multiple
                              chips
                              autocomplete="off"
                              solo
                              outlined
                              flat
                              hide-details="auto"
                              class="este-autocomplete-input"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Call Back Date: </span>
                              <v-menu
                                v-model="callbackDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    autocomplete="off"
                                    :value="dateUkFormat(form.callback_date)"
                                    solo
                                    outlined
                                    v-on="on"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                    append-icon="mdil-calendar"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="form.callback_date"
                                  no-title
                                  @input="callbackDateMenu = false"
                                ></v-date-picker>
                              </v-menu>
                            </label>
                          </v-col>
                          <v-col cols="12" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Call Back Time:</span>
                              <v-menu
                                ref="callbackTimeMenu"
                                v-model="callbackTimeMenu"
                                :close-on-content-click="false"
                                :return-value.sync="form.callback_time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    outlined
                                    flat
                                    hide-details="auto"
                                    solo
                                    class="solo-cust"
                                    v-model="form.callback_time"
                                    append-icon="mdil-clock"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  :allowed-minutes="allowedStep"
                                  no-title
                                  ampm-in-title
                                  format="24hr"
                                  v-if="callbackTimeMenu"
                                  v-model="form.callback_time"
                                  full-width
                                  @click:minute="$refs.callbackTimeMenu.save(form.callback_time)"
                                ></v-time-picker>
                              </v-menu>
                            </label>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="6" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Preferred Branch: </span>
                              <v-select
                                autocomplete="off"
                                :items="branches"
                                item-value="id"
                                item-text="branch"
                                :rules="rules.branch_id"
                                v-model="form.branch_id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                                @change="staffByBranch"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </label>
                          </v-col>
                          <v-col cols="6" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Urgency </span>
                            </label>
                            <v-select
                              autocomplete="off"
                              :items="priority"
                              v-model="form.urgency"
                              item-text="name"
                              item-value="id"
                              solo
                              outlined
                              flat
                              class="solo-cust"
                              hide-details="auto"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Select Contact </span>
                              <v-select
                                autocomplete="off"
                                :items="staff"
                                v-model="form.consultant_id"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </label>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Status </span>
                            </label>
                            <v-select
                              autocomplete="off"
                              :items="
                                SortAtoZ(
                                  statusTags.filter(function (el) {
                                    return el.slug.includes('callback');
                                  })
                                )
                              "
                              v-model="form.status"
                              :rules="rules.status"
                              item-text="name"
                              item-value="id"
                              solo
                              outlined
                              flat
                              class="solo-cust"
                              hide-details="auto"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </div>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="b-blue"
                      depressed
                      @click="create"
                      :disabled="loading"
                      rounded
                      x-large
                    >
                      Add Call Back
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import add from "./add.js";
export default {
  mixins: [globalFn, status, add, sidePaneMenu],
  data: () => ({
    loading: false,
    tabMenu: [
      {
        name: "Client Details",
        icon: "mdil-account",
        slug: "client",
      },
    ],
  }),
  mounted() {
    this.paneTab = "client";
    this.clientUnit = true;
  },
};
</script>
