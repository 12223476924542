import http from "../../http/common";

const resource = "/callback";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

  calendar: (payload, params) => http.post(`${resource}/get-by-week${params}`, payload),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

  comment: (payload) => http.post(`${resource}/add-comment`, payload),

  upload: (payload) => http.post(`${resource}/upload-docs`, payload),

  callbackUser: (payload) => http.post(`${resource}/create-by-id`, payload),

  callbackCount: (payload) => http.post(`${resource}/callback-count`, payload),

  assignedTo: (payload) => http.get(`${resource}/staff-list`, payload),

  callbacksByUserId: (id) => http.get(`${resource}/get-by-user/${id}`),
};
