<template>
  <v-card class="delete-alert">
    <v-card-title>
      Confirm
      <a class="float-right pa-6" @click="close"><img src="/images/close.svg" alt="Close" /></a>
    </v-card-title>
    <v-card-text> Are you sure you want to delete this holiday shift ? </v-card-text>
    <v-card-actions class="d-flex justify-center align-center">
      <div class="delete-wrapper">
        <v-btn
          class="cancel-btn btn-gap"
          depressed
          rounded
          outlined
          @click="close"
          color="dark"
          x-large
        >
          No
        </v-btn>
        <v-btn class="delete-btn" color="red" depressed @click="submit" rounded x-large>
          Yes
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    formObj: null,
  }),
  mounted() {
    this.formObj = this.$root.delete_holiday_rota;
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
      this.$root.delete_holiday_rota = null;
    },
    async submit() {
      await this.$store
        .dispatch("rota/DELETE_HOLIDAY", this.formObj)
        .then((res) => {
          if (res.result === "success") {
            this.$root.$emit("fetchRota");
            this.loading = false;
            // this.$refs.form.reset();
            this.$store.dispatch("snackbar/SHOW", res);
            this.close();
          } else if (res.result === "fail") {
            res.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
