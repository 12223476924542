<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0 pt-3">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.type="{ item }">
                <span
                  class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                  v-if="item.medical_form_type"
                >
                  {{ item.medical_form_type.name | capitalize }}</span
                >
              </template>
              <template v-slot:item.description="{ item }">
                <div
                  class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor width-400"
                >
                  <span class="font-14 line-h-20 font-weight--regular text-black-100 ml-2 py-3">
                    {{ item.description }}
                  </span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="previewItem(item)">Preview</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import globalFn from "../../../../mixins/globalFn";
import sliderFn from "../../../../mixins/sliderFn";
import status from "../../../../mixins/status";
export default {
  name: "Clients",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        {
          text: "Name",
          value: "title",
          align: "left",
          sortable: false,
        },
        {
          text: "Type",
          value: "type",
          align: "left",
          sortable: false,
        },
        { text: "Created", value: "created_at", sortable: false },
        {
          text: "actions",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      formParams: {
        // attributes: "permissionTreatments,permissionConsultations",
        attributes: "",
        search: "",
      },
      selectedItem: "",
      params: "?attributes=medicalFormType",
      message: "",
      action: "",
      actionText: "",
    };
  },
  computed: {
    formList() {
      return this.$store.state.form.forms;
    },
  },
  watch: {
    options: {
      // handler($event) {
      //     const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
      //     if ($event.sortBy[0]) {
      //         this.sortBy = $event.sortBy[0];
      //         this.sortOrder = sortOrder;
      //         this.paginate($event);
      //     }
      // },
      // deep: true,
    },
    formList(newVal) {
      this.items = newVal.data;
      this.totalCount = newVal.total;
    },
  },
  mounted() {
    this.readTheDataFromApi();
    this.$root.$on("formTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchForm", this.getSearchForm);
  },
  created() {},
  methods: {
    async getSearchForm(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.formParams.search = data.search;
        await this.readTheDataFromApi();
      } else {
        this.formParams.search = "";
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("form/GET_FORM", {
        params: `${this.params}&page=${this.page}&per_page=${this.itemsPerPage}&search=${this.formParams.search}`,
      });
      // .then((res) => {
      //     this.items = res.data.data;
      //     this.totalCount = res.data.total;
      // });
      this.loading = false;
    },
    async previewItem(item) {
      await this.$store
        .dispatch("form/SHOW_FORM", {
          id: item.id,
          params: "?attributes=medicalFormType,elements.answers",
        })
        .then((res) => {
          this.openDrawer("add", "750", "settings/forms/preview");
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
  },
};
</script>
