<template>
  <v-tabs v-model="activeTab">
    <v-tab href="#details" class="text-capitalize spacing-normal">Details</v-tab>
    <v-tab href="#intelligence" class="text-capitalize spacing-normal">Intelligence</v-tab>

    <v-tab-item :eager="true" value="details">
      <v-form ref="form">
        <div class="tab-content-wrapper">
          <v-row dense class="e-row">
            <v-col cols="6" md="2" class="e-col">
              <div>
                <label>
                  <span class="label-txt">Title</span>
                  <v-select
                    autocomplete="off"
                    :items="titles"
                    v-model="itemData.title"
                    solo
                    outlined
                    flat
                    class="solo-cust"
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </label>
              </div>
            </v-col>
            <v-col cols="6" md="10" class="e-col">
              <div>
                <label>
                  <span class="label-txt"> Name </span>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    v-model="itemData.name"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                  ></v-text-field>
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row class="e-row">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Social Handle </span>
              </label>
              <v-text-field
                autocomplete="off"
                solo
                outlined
                v-model="itemData.social_handle"
                flat
                hide-details="auto"
                class="solo-cust social-handle-input"
              >
                <template slot="append-outer">
                  <v-select
                    autocomplete="off"
                    v-model="itemData.source_id"
                    :items="socialMedia"
                    item-text="name"
                    item-value="id"
                    solo
                    outlined
                    flat
                    class="este-autocomplete-input"
                    hide-details="auto"
                  >
                    <template v-slot:selection="data">
                      <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px" />
                    </template>
                    <template v-slot:item="data">
                      <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px" />
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="e-row">
            <v-col cols="12" md="6" class="e-col">
              <label>
                <span class="label-txt"> Phone No. </span>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  v-model="itemData.contact_no"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                  append-icon="mdil-phone"
                ></v-text-field>
              </label>
            </v-col>
            <v-col cols="12" md="6" class="e-col">
              <label>
                <span class="label-txt"> Email Address </span>
                <v-text-field
                  autocomplete="off"
                  v-model="itemData.email"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="solo-cust"
                  append-icon="mdil-email"
                ></v-text-field>
              </label>
            </v-col>
          </v-row>
          <v-row class="e-row">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Address </span>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  v-model="itemData.address_one"
                  hide-details="auto"
                  class="solo-cust mb-2"
                ></v-text-field>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  v-model="itemData.address_two"
                  hide-details="auto"
                  class="solo-cust mar-8"
                ></v-text-field>
              </label>
            </v-col>
          </v-row>
          <v-row class="e-row">
            <v-col cols="12" md="6" class="e-col">
              <label>
                <span class="label-txt"> Town / City </span>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  v-model="itemData.city"
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </label>
            </v-col>
            <v-col cols="12" md="6" class="e-col">
              <v-row no-gutters>
                <v-col cols="6">
                  <label>
                    <span class="label-txt"> Post Code </span>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      v-model="itemData.postcode"
                      hide-details="auto"
                      class="solo-cust"
                    ></v-text-field>
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="e-row">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt"> Preferred Branch: </span>
                <v-select
                  autocomplete="off"
                  :items="branches"
                  :rules="rules.branch_id"
                  item-value="id"
                  item-text="branch"
                  v-model="itemData.branch_id"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </label>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-tab-item>
    <v-tab-item :eager="true" value="intelligence">
      <v-card flat class="pt-8">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><b>First visit:</b> {{ dateUkFormat(summary.first_visit) }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><b>Last visit:</b> {{ dateUkFormat(summary.last_visit) }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><b>Total visit:</b> {{ summary.total_visit }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <b>Last appointment:</b>
              {{ summary.last_appointment }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><b>Last treatment:</b> {{ summary.last_treatment }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status.js";
export default {
  mixins: [globalFn, status],
  name: "PersonalTab",
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  data: () => ({
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      status: [],
    },
    contactChange: false,
    emailChange: false,
    socailHandle: false,
    activeTab: "details",
    summary: {},
  }),
  computed: {
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    user() {
      if (this.$store.state.user.item) {
        return this.$store.state.user.item;
      }
    },
  },
  mounted() {
    this.$root.$on(`PersonalUpdate${this.userId}`, this.PersonalUpdate);
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("user/GET_USER", `?user_id=${this.userId}`);
    this.loadSummary();
  },
  watch: {
    "user.social_handle": _.debounce(function () {
      if (this.user.social_handle) {
        this.autoSelectSocialIcon();
      }
    }, 1000),
    "user.contact_no"(val) {
      this.contactChange = true;
    },
    "user.email"(val) {
      this.emailChange = true;
    },
    "user.social_handle"(val) {
      this.socailHandle = true;
    },
  },
  methods: {
    async autoSelectSocialIcon() {
      let isValid = this.isValidUrl(this.user.social_handle);
      if (isValid) {
        let url = new URL(this.user.social_handle);
        this.user.source = this.getSourceId(url.origin);
      }
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async PersonalUpdate() {
      console.log(this.contactChange);
      let formData = {};
      formData.id = this.user.id;
      formData.title = this.user.title;
      formData.name = this.user.name;
      formData.address_one = this.user.address_one;
      formData.address_two = this.user.address_two;
      formData.city = this.user.city;
      formData.postcode = this.user.postcode;
      formData.branch_id = this.user.branch_id;
      if (this.contactChange) {
        formData.contact_no = this.user.contact_no;
      }
      if (this.socailHandle) {
        formData.social_handle = this.user.social_handle;
      }

      formData.source_id = this.user.source_id;

      if (this.user.email && this.emailChange) {
        formData.email = this.user.email;
      }
      await this.$store
        .dispatch("leads/UPDATE_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
          } else if (response.result === "fail") {
            this.loading = false;
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async loadSummary() {
      this.summary = {};
      await this.$store.dispatch("appointments/GET_SUMMARY", { id: this.userId }).then((res) => {
        this.summary = res.data;
      });
    },
  },
};
</script>
