import collection from "../../../api/collections/Source";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_SOURCE(state, data) {
      state.list = data;
    },
    SET_SOURCE_ITEM(state, data) {
      state.item = data;
    },
    RESET_SOURCE(state, data) {
      state.item = {};
    },
    SET_SOURCE_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_SOURCE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_SOURCE", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_SOURCE",
        true
      );
    },
  },
};
