import collection from "../../../api/collections/Complaints";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    action: [],
    priority: [],
    assignedTo: [],
    escalation: [],
    category: [],
    item: {},
    pagination: {},
    widget: {},
  },
  mutations: {
    SET_COMPLAINT(state, data) {
      state.list = data;
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data;
    },
    SET_NEXT_ACTION(state, data) {
      state.action = data;
    },
    SET_PRIORITY(state, data) {
      state.priority = data;
    },
    SET_CATEGORY(state, data) {
      state.category = data;
    },
    SET_ESCALATION(state, data) {
      state.escalation = data;
    },
    SET_COMPLAINT_ITEM(state, data) {
      state.item = data;
    },
    RESET_COMPLAINT(state, data) {
      state.item = {};
    },
    SET_COMPLAINT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {};
    },
  },
  actions: {
    async GET_COMPLAINT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_COMPLAINT", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_COMPLAINT",
        true
      );
    },
    async CREATE_COMPLAINT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_COMPLAINT",
        true
      );
      return data;
    },
    async SHOW_COMPLAINT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_COMPLAINT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_COMPLAINT",
        false
      );
      return data;
    },
    async UPDATE_COMPLAINT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_COMPLAINT",
        true
      );

      return data;
    },
    async UPDATE_STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.updateStatus(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_STATUS",
        true
      );

      return data;
    },

    async GET_NEXT_ACTION({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.nextAction(payload);
          commit("SET_NEXT_ACTION", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_NEXT_ACTION",
        true
      );
    },

    async GET_PRIORITY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.priority(payload);
          commit("SET_PRIORITY", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_PRIORITY",
        true
      );
    },

    async GET_CATEGORY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.category(payload);
          commit("SET_CATEGORY", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CATEGORY",
        true
      );
    },

    async GET_ESCALATION({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.escalation(payload);
          commit("SET_ESCALATION", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ESCALATION",
        true
      );
    },

    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.comment(payload);
          return data;
        },
        commit,
        dispatch,
        "ADD_COMMENT",
        true
      );
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.upload(payload);
          return data;
        },
        commit,
        dispatch,
        "UPLOAD_DOCS",
        true
      );
      return data;
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.assignedTo(payload);
          commit("SET_ASSIGNED_TO", data.data);
          return data;
        },
        commit,
        dispatch,
        "ASSIGNED_TO",
        true
      );
    },
    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.widgetCount(payload);
          commit("SET_WIDGET_COUNT", data.data);
          return data;
        },
        commit,
        dispatch,
        "WIDGET_COUNT",
        true
      );
    },
  },
};
