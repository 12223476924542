<template>
  <v-card class="mt-6 pb-5">
    <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
      Points History
    </v-card-title>
    <v-divider class="my-5"></v-divider>
    <v-card-text class="px-7 py-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left font-14 line-h-17 font-weight--bold text-black-200">Date</th>
              <th class="text-left font-14 line-h-17 font-weight--bold text-black-200">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex flex-column">
                  <p class="mb-0 font-14 line-h-17 font-weight--regular text-black-300">
                    07 Jul ‘22
                  </p>
                  <p class="mb-0 font-14 line-h-17 font-weight--regular text-black-300">15:32</p>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <p class="font-16 line-h-21 font-weight--regular text-black-100 mb-1">
                    No answer. Voicemail left.
                  </p>
                  <p class="font-12 line-h-16 font-weight--regular text-black-200">
                    By David Williams
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "WidgetCallbackNotes",
  data() {
    return {
      points: [],
    };
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    async getPoints() {
      this.loading = true;
      await this.$store
        .dispatch("contacts/POINTS_HISTORY", { user_id: this.$router.currentRoute.params.id })
        .then((res) => {
          this.points = res.data;
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="css" scoped></style>
