<template>
  <v-col cols="12" sm="12" md="6" class="has-border">
    <label class="login-label">Payments History:</label>
    <label class="sort-label float-right" v-if="items.length > 0"
      >Remaining Balance: {{ balance | currency("£") }}
    </label>
    <v-skeleton-loader
      v-if="showSkeleton"
      :loading="loading"
      type="table-row-divider@2"
    ></v-skeleton-loader>
    <v-data-table
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      v-if="!showSkeleton"
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :hide-default-footer="true"
      dense
      class="inner-table"
    >
      <template v-slot:item.amount="{ item }">
        {{ item.amount | currency("£") }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | moment("DD/MM/YY") }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn
          class="px-3 btn-inline"
          min-width="32"
          height="32"
          icon
          color="#D34141"
          :loading="loading"
          @click="deletePayment(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
import { mapActions } from "vuex";
export default {
  name: "Payments",
  mixins: [globalFn],
  props: {
    invoiceId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Amount",
          value: "amount",
          sortable: false,
        },
        { text: "Method", value: "payment_method", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "", value: "status", sortable: false },
      ],
      showSkeleton: false,
      loading: false,
      sortBy: "id",
      sortDesc: false,
      items: [],
      pageSize: 10,
      page: 1,
      balance: 0,
    };
  },
  mounted() {
    this.showSkeleton = true;
    this.loading = true;
    this.payments();
    this.$root.$on("UpdatePaymentHistory", this.payments);
  },
  methods: {
    ...mapActions({}),
    async payments() {
      this.showSkeleton = true;
      this.loading = true;
      let formData = {};
      formData.invoice_id = this.invoiceId;
      await this.$store
        .dispatch("invoicePayments/GET_INVOICE_PAYMENT_LIST", { data: formData, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.items = response.data;
            let lastElement = this.items[this.items.length - 1];
            this.balance = lastElement.balance;
            this.showSkeleton = false;
            this.loading = false;
          } else if (response.result === "fail") {
            console.log(response.error);
          }
        })
        .catch((err) => {
          console.log(err);
          this.showSkeleton = false;
          this.loading = false;
        });
    },
    async deletePayment(item) {
      await this.$store
        .dispatch("invoicePayments/DELETE_INVOICE_PAYMENT", item.id)
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.payments();
          } else if (res.result === "fail") {
            this.loading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
<style scoped>
.show-btns {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>
