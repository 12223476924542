import http from "../../http/common";

const resource = "/staff";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

  get_consultants: (payload) => http.post(`${resource}/get-consultants`, payload),

  getConsultantShifts: (payload) => http.post(`${resource}/get-consultant-shifts`, payload),

  getList: (payload, params) => http.post(`${resource}/get-list${params}`, payload),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

  upload: (payload) => http.post(`${resource}/upload-docs`, payload),

  upload_image: (payload) => http.post(`${resource}/upload-image`, payload),

  playerid: (payload) => http.post(`${resource}/update-playerid`, payload),

  update_color: (payload) => http.post(`${resource}/update-color`, payload),
};
