<template>
  <div class="fill-height slider-cont">
    <div class="pb-0 tab-brd">
      <v-tabs v-model="activeTab" class="cust-tabs" @change="loadfinancials">
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <template v-for="item in tabs" :key="item.slug">
          <v-tab :href="`#${item.slug}`">
            {{ item.name }}
            <span class="text-red-100" v-if="item.slug === 'outstanding'"
              >({{ status[item.slug] | currency("£") }})</span
            >
            <span v-else>({{ status[item.slug] || 0 }})</span>
          </v-tab>
        </template>
      </v-tabs>
    </div>
    <div class="right-pane">
      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabs" :eager="true" :value="tab.slug">
          <div v-if="loading" class="pt-8 text-center">
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </div>
          <div v-else-if="!loading && !invoices.length" class="pt-8 text-center">
            <div class="text-subtitle-1">No data available</div>
          </div>
          <div v-else class="mt-6">
            <v-data-table :fixed-header="true" :headers="headers[activeTab]" :items="invoices">
              <template v-slot:item.invoice="{ item }">
                {{ item.created_at }}
              </template>
              <template v-slot:item.payment="{ item }">
                {{ item.created_at | moment("DD/MM/YYYY") }}
              </template>
              <template v-slot:item.location="{ item }">
                <template v-if="activeTab === 'payments'">
                  {{ item.taken_by?.branch_name }}
                </template>
                <template v-else>
                  {{ item.user?.branch }}
                </template>
              </template>
              <template v-slot:item.inv="{ item }"> #{{ item.id }} </template>
              <template v-slot:item.employee="{ item }">
                <div v-if="activeTab === 'payments'" class="text-capitalize">
                  {{ item.taken_by?.name }}
                </div>
                <div v-else class="text-capitalize">
                  {{ item.sold_by?.name }}
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip small color="rgba(74, 206, 40, 0.2)" class="font-weight-medium">
                  {{ item.payment_status }}
                </v-chip>
              </template>
              <template v-slot:item.method="{ item }">
                {{ item.payment_method }}
              </template>
              <template v-slot:item.amount="{ item }">
                <template v-if="activeTab === 'payments'">
                  {{ addPoundSymbol(item.total) }}
                </template>
                <template v-else-if="activeTab === 'outstanding'">
                  {{ addPoundSymbol(item.balance) }}
                </template>
                <template v-else-if="activeTab === 'invoice'">
                  {{ addPoundSymbol(item.total) }}
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-tooltip bottom v-if="activeTab === 'payments'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        v-bind="attrs"
                        class="mr-2"
                        @click="printSummary(item.id, 'payments')"
                      >
                        <v-icon>mdi-printer-outline</v-icon>
                      </v-btn>
                    </template>

                    Payment summary
                  </v-tooltip>

                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        v-bind="attrs"
                        @click="printSummary(item.deal_id, 'invoice')"
                      >
                        <v-icon>mdi-printer-outline</v-icon>
                      </v-btn>
                    </template>

                    Deal summary
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import sliderFn from "../../mixins/sliderFn.js";
import status from "../../mixins/status.js";
import EditAppointment from "../../templates/forms/leads/EditAppointment";
export default {
  mixins: [globalFn, status, sliderFn],
  props: {
    contact: {
      required: true,
    },
  },
  components: {
    EditAppointment,
  },
  data() {
    return {
      drawer: false,
      invoices: [],
      status: [],
      tabs: [
        {
          name: "Invoices",
          slug: "invoice",
        },
        {
          name: "Outstanding",
          slug: "outstanding",
        },
        {
          name: "Payments",
          slug: "payments",
        },
      ],
      headers: {
        invoice: [
          { text: "Invoice", value: "invoice", align: "left", sortable: false },
          { text: "Location ", value: "location", sortable: false, align: "left" },
          { text: "Employee", value: "employee", sortable: false, align: "left" },
          { text: "Status", value: "status", sortable: false, align: "left" },
          { text: "Amount", value: "amount", sortable: false, align: "right" },
          { text: "Actions", value: "actions", sortable: false, align: "right" },
        ],
        payments: [
          { text: "Payment", value: "payment", align: "left", sortable: false },
          { text: "Inv", value: "inv", sortable: false, align: "left" },
          { text: "Location", value: "location", sortable: false, align: "left" },
          { text: "Employee", value: "employee", sortable: false, align: "left" },
          { text: "Method", value: "method", sortable: false, align: "left" },
          { text: "Payment Amount", value: "amount", sortable: false, align: "right" },
          { text: "Actions", value: "actions", sortable: false, align: "right" },
        ],
        outstanding: [
          { text: "Invoice", value: "invoice", align: "left", sortable: false },
          { text: "Location ", value: "location", sortable: false, align: "left" },
          { text: "Employee", value: "employee", sortable: false, align: "left" },
          { text: "Status", value: "status", sortable: false, align: "left" },
          { text: "Amount", value: "amount", sortable: false, align: "right" },
          { text: "Actions", value: "actions", sortable: false, align: "right" },
        ],
      },
      activeTab: "invoice",
      loading: false,
    };
  },
  mounted() {
    this.loadFinance();
    this.loadStatusCount();
  },
  watch: {
    activeTab(newVal) {
      this.loadFinance();
    },
  },
  methods: {
    async loadStatusCount() {
      await this.$store
        .dispatch("financials/GET_FINANCIAL_STATUS_COUNT", {
          user_id: this.contact.id,
        })
        .then((res) => {
          this.status = res.data;
        });
    },

    async loadFinance() {
      this.loading = true;
      await this.$store
        .dispatch("financials/GET_FINANCIAL_BY_TYPE", {
          user_id: this.contact.id,
          type: this.activeTab,
          sort_order: "DESC",
          sort_by: "id",
        })
        .then((res) => {
          this.invoices = res.data;
        })
        .finally(() => (this.loading = false));
    },
    async toggleApppointmentDrawer() {
      this.drawer = false;
      await this.loadfinancials();
    },
    async printSummary(id, type) {
      const dispatchName = type === "payments" ? "GET_PAYMENT_SUMMARY" : "GET_DEAL_SUMMARY";
      const payload = type === "payments" ? { payment_id: id } : { deal_id: id };

      this.$store.dispatch(`financials/${dispatchName}`, payload).then((res) => {
        const pdfUrl = res.data.pdf_url;

        var link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank";

        var event = new MouseEvent("click");
        link.dispatchEvent(event);
      });
    },
  },
};
</script>
