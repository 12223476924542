import http from "../../http/common";

const resource = "/insight";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

  list: (payload) => http.get(`${resource}/get-all`, payload),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  pushback: (id) => http.post(`${resource}/push-back/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

  comment: (payload) => http.post(`${resource}/add-comment`, payload),

  upload: (payload) => http.post(`${resource}/upload-docs`, payload),
};
