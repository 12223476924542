<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card class="widget-card">
          <v-row>
            <template
              v-for="(item, index) in widgets.filter(function (el) {
                return el.module.includes('client');
              })"
            >
              <v-col cols="12" md="3" sm="6">
                <div
                  class="widget"
                  :class="item.active ? 'selected' : ''"
                  @click="widgetFilter(item)"
                >
                  <span class="active" v-if="item.active">
                    <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                  </span>
                  <div class="content">
                    <div class="text">{{ item.name }}</div>
                    <div class="number">{{ item.count }}</div>
                  </div>
                  <span class="bottom-arrow" v-if="item.active"></span>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :item-class="AddRowClass"
            >
              <template v-slot:item.name="{ item }">
                <div class="name-column">
                  <template v-if="item.client_satisfaction !== null">
                    <img src="/images/happy.svg" v-if="item.client_satisfaction === 'happy'" />
                    <img src="/images/sad.svg" v-else-if="item.client_satisfaction === 'sad'" />
                    <img
                      src="/images/neutral.svg"
                      v-else-if="item.client_satisfaction === 'neutral'"
                    />
                    <img src="/images/vip-gold.svg" v-else />
                  </template>
                </div>
                <div class="name-column">
                  <div class="user-name" v-if="item.name">
                    {{ item.name }}
                    <v-tooltip color="primary" text-color="#141715" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1 reward-chip cursor"
                          v-if="item.points_earned"
                        >
                          <v-icon color="primary" size="15" class="mr-1"
                            >mdi-star-four-points-outline</v-icon
                          >
                          {{ item.points_earned.total_points }}
                        </v-chip>
                      </template>
                      <span class="content">
                        <p>Points Spent: {{ item.points_earned.points_spent }}</p>
                        <p>Points Balance: {{ item.points_earned.points_balance }}</p>
                      </span>
                    </v-tooltip>
                  </div>
                  <div
                    class="d-flex user-name align-center"
                    v-if="!item.name && item.social_handle"
                  >
                    <img
                      :src="`/images/${item.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="item.source_name !== null"
                    />
                    <span> @{{ item.social_handle }} </span>
                    <v-tooltip color="primary" text-color="#141715" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1 reward-chip cursor"
                          v-if="item.points_earned"
                        >
                          <v-icon color="primary" size="15" class="mr-1"
                            >mdi-star-four-points-outline</v-icon
                          >
                          {{ item.points_earned.total_points }}
                        </v-chip>
                      </template>
                      <span class="content">
                        <p>Points Spent: {{ item.points_earned.points_spent }}</p>
                        <p>Points Balance: {{ item.points_earned.points_balance }}</p>
                      </span>
                    </v-tooltip>
                  </div>
                  <p class="user-location">
                    {{ item.branch_name }}
                  </p>
                </div>
              </template>
              <template v-slot:item.contact_no="{ item }">
                <p class="mt-4 ml-n1" v-if="item.contact_no !== null && item.contact_no !== ''">
                  <span class="contact">
                    <a :href="`tel:${item.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.contact_no }}</a
                    >
                  </span>
                </p>
                <p class="mt-4 ml-n1 mb-4" v-if="item.email !== null && item.email !== ''">
                  <span class="contact">
                    <a :href="`mailto:${item.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.responsible="{ item }">
                <template v-if="item.client_details !== null">
                  <template v-if="item.client_details.responsible !== null">
                    <div class="profile-icon" alt="avatar">
                      <p>{{ getInitials(item.client_details.responsible.name) }}</p>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <span class="date-sec date-mute">{{ item.updated_at | moment("DD/MM/YY") }}</span>
                <v-chip class="ml-1 count-chip" v-if="item.activity_count">
                  <img src="/images/bolt-grey.svg" class="mr-1" />
                  {{ item.activity_count }}
                </v-chip>
              </template>
              <template v-slot:item.client_details="{ item }">
                <template v-if="item.client_details !== null">
                  <p
                    class="date-sec date-mute"
                    v-if="item.client_details.next_appointment_date !== null"
                  >
                    <v-btn class="badge badge-grey" icon>
                      <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                    </v-btn>
                    {{ item.client_details.next_appointment_date | moment("DD/MM/YY") }}
                  </p>
                  <p
                    class="date-sec vertical-bar"
                    v-if="
                      item.client_details.next_appointment_date !== null &&
                      item.client_details.next_appointment_treatment !== null
                    "
                  >
                    |
                  </p>
                  <template v-if="item.client_details.next_appointment_treatment !== null">
                    <p class="date-sec">
                      <v-chip small class="este-chip tag-chip" text-color="black">
                        {{ item.client_details.next_appointment_treatment }}
                      </v-chip>
                    </p>
                  </template>
                  <p
                    class="date-sec vertical-bar"
                    v-if="
                      item.client_details.next_appointment_treatment !== null && item.callbackStatus
                    "
                  >
                    |
                  </p>
                </template>
                <template v-if="item.callbackStatus">
                  <p class="date-sec">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      Call Back
                      <v-icon class="ml-1" color="black" size="15">mdil-phone</v-icon>
                    </v-chip>
                  </p>
                </template>
              </template>
              <template v-slot:item.treatments="{ item }">
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0, 3)">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </template>
                <span class="badge-label" v-if="item.treatments.length > 3"
                  >+{{ item.treatments.length - 3 }}</span
                >
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('client');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    :outlined="stat.outline"
                    :text-color="stat.text ? stat.color : '#221F20'"
                    :color="stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Client</a></li>
                      <li><a @click="convert(item)">Convert to Lead</a></li>
                      <li><a @click="addComplaint(item)">Add Complaint</a></li>
                      <li><a @click="request(item)">Request Appointment</a></li>
                      <li><a @click="requestCallback(item)">Request Call Back</a></li>
                      <li>
                        <a class="text-mute" @click="archive(item)" v-if="item.status !== 7"
                          >Archive Client</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import Widgets from "../../../mixins/Widgets";
export default {
  name: "Clients",
  mixins: [globalFn, sliderFn, status, Widgets],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Contact Details", value: "contact_no", align: "left", sortable: false },
        { text: "Services / Treatments", value: "treatments", align: "left", sortable: false },
        {
          text: "Next Appointment / Call Back",
          value: "client_details",
          sortable: false,
          align: "left",
        },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        { text: "Last Updated", value: "updated_at", sortable: false, align: "left" },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      clientsParams: {
        attributes: "staff,branch,clientDetails,symptoms,treatments,source",
        search: "",
        filter: {
          appointment_from: "",
          appointment_to: "",
          status: [],
          branch: [],
          source: [],
          satisfaction: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
      },
      showClientsParams:
        "?attributes=staff,branch,clientDetails,reasons,symptoms,treatments,documents,comments,source,callbacks,appointments",
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("clientsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchClients", this.getSearchClients);
    this.$root.$on("AppointmentEvent", this.changeClientStatus);
    this.$root.$on("CallbackEvent", this.changeClientStatus);
  },
  methods: {
    AddRowClass(item) {
      if (item.client_details) {
        if (
          item.client_details.next_appointment_date &&
          item.client_details.next_appointment_treatment &&
          item.callbackStatus
        ) {
          return "callback-row";
        }
      }
    },
    async getSearchClients(data) {
      console.log(data.widget_filter);
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.clientsParams.widget_filter = "";
      }
      this.clientsParams.search = data.search;
      this.clientsParams.filter.appointment_from = data.appointment_from;
      this.clientsParams.filter.appointment_to = data.appointment_to;
      this.clientsParams.filter.status = data.status;
      this.clientsParams.filter.branch = data.branch;
      this.clientsParams.filter.source = data.source;
      this.clientsParams.filter.satisfaction = data.satisfaction;
      this.clientsParams.filter.assigned = data.assigned;
      this.clientsParams.filter.assigned_to = data.assigned_to;
      this.clientsParams.filter.source_blank = data.source_blank;
      await this.loadCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      this.options.page = 1;
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.clientsParams.widget_filter = item.active ? item.slug : "";
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("clients/GET_CLIENT", {
          data: this.clientsParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      await this.$store
        .dispatch("clients/SHOW_CLIENT", { id: item.id, param: this.showClientsParams })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "clients/editPane");
          } else {
            this.openDrawer("add", "750", "clients/editTab");
          }
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async convert(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = " Please confirm you want to convert this client to lead?";
      this.actionText = "Convert to client";
      this.action = "convertClientToLead";
    },
    async convertClientToLead() {
      await this.$store
        .dispatch("clients/CONVERT_CLIENT_TO_LEAD", { client_id: this.selectedItem.id })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archive(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this client?";
      this.actionText = "Archive";
      this.action = "archiveClient";
    },

    async archiveClient() {
      this.selectedItem.status = 7;
      await this.archiveEntry();
    },
    async changeClientStatus(data) {
      debugger;
      this.selectedItem.status = data;
      await this.archiveEntry();
    },
    async archiveEntry() {
      await this.$store
        .dispatch("clients/ARCHIVE_CLIENT", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    addComplaint(item) {
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "complaints/addPane");
      } else {
        this.openDrawer("add", "750", "complaints/addTab");
      }
      localStorage.setItem("complaintClientID", item.id);
    },
    request(item) {
      this.selectedItem = item;
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "appointments/addPane");
      } else {
        this.openDrawer("add", "750", "appointments/addTab");
      }
      localStorage.setItem("appointmentClientId", item.id);
      localStorage.setItem("appointmentUserType", "Client");
    },
    requestCallback(item) {
      this.selectedItem = item;
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "callback/addPane");
      } else {
        this.openDrawer("add", "750", "callback/addTab");
      }
      localStorage.setItem("callbackUserId", item.id);
    },
  },
};
</script>
