<template>
  <calendarView v-if="showComponent" />
</template>
<script>
import calendarView from "./calendarView";
export default {
  components: {
    calendarView,
  },
  data: () => ({
    showComponent: false,
  }),
  mounted() {
    this.preRequest();
  },
  methods: {
    async preRequest() {
      await this.$store.dispatch("machines/GET_MACHINE_LIST", "");
      await this.$store.dispatch("staff/GET_CONSULTANTS", "");
      this.showComponent = true;
    },
  },
};
</script>
