<template>
  <div>
    <div class="fill-height slider-cont">
      <v-card class="vertical-slide fill-height">
        <v-card-text class="pa-0">
          <v-row dense class="e-row">
            <v-col cols="6" md="3" class="e-col pa-0 ma-0">
              <v-toolbar class="left-toolbar" flat> </v-toolbar>
              <v-tabs v-model="navigationTab" vertical class="left-nav mt-0">
                <v-tab href="#personal">
                  <v-icon left> mdil-account </v-icon>Personal Details
                </v-tab>
                <v-tab href="#lead" v-if="callback.user.user_type_name === 'Lead'">
                  <v-icon left> mdil-star </v-icon>Lead Details
                </v-tab>
                <v-tab href="#client" v-if="callback.user.user_type_name === 'Client'">
                  <v-icon left> mdil-star </v-icon>Client Details
                </v-tab>
                <v-tab href="#callback"> <v-icon left> mdil-phone </v-icon>Call Back </v-tab>
                <v-tab href="#insight">
                  <v-icon left> mdil-chart-areaspline </v-icon>Insight
                </v-tab>
                <v-tab href="#activity"> <v-icon left> mdi-history </v-icon>Activity </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="6" md="9" class="e-col pa-0 ma-0">
              <v-toolbar class="right-toolbar" flat>
                <div class="form-title">View Call Back</div>
              </v-toolbar>
              <div class="slide-right">
                <div class="form-wrap px-10">
                  <v-tabs-items v-model="navigationTab">
                    <v-tab-item value="personal" :eager="true">
                      <v-form ref="form">
                        <div class="tab-content-wrapper">
                          <v-row dense class="e-row">
                            <v-col cols="6" md="2" class="e-col">
                              <div>
                                <label>
                                  <span class="label-txt">Title</span>
                                  <v-select
                                    autocomplete="off"
                                    :items="titles"
                                    v-model="callback.user.title"
                                    solo
                                    outlined
                                    flat
                                    class="solo-cust"
                                    hide-details="auto"
                                  >
                                    <template slot="append">
                                      <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                  </v-select>
                                </label>
                              </div>
                            </v-col>
                            <v-col cols="6" md="10" class="e-col">
                              <div>
                                <label>
                                  <span class="label-txt"> Name </span>
                                  <v-text-field
                                    autocomplete="off"
                                    solo
                                    outlined
                                    v-model="callback.user.name"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                  ></v-text-field>
                                </label>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Social Handle </span>
                              </label>
                              <v-text-field
                                autocomplete="off"
                                solo
                                outlined
                                v-model="callback.user.social_handle"
                                flat
                                hide-details="auto"
                                class="solo-cust social-handle-input"
                              >
                                <template slot="append-outer">
                                  <v-select
                                    autocomplete="off"
                                    v-model="callback.user.source_id"
                                    :items="socialMedia"
                                    item-text="name"
                                    item-value="id"
                                    solo
                                    outlined
                                    flat
                                    class="este-autocomplete-input"
                                    hide-details="auto"
                                  >
                                    <template v-slot:selection="data">
                                      <img
                                        :src="`images/${trimLowerCase(data.item.name)}.svg`"
                                        height="20px"
                                      />
                                    </template>
                                    <template v-slot:item="data">
                                      <img
                                        :src="`images/${trimLowerCase(data.item.name)}.svg`"
                                        height="20px"
                                      />
                                    </template>
                                    <template slot="append">
                                      <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                  </v-select>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Phone No. </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  v-model="callback.user.contact_no"
                                  flat
                                  hide-details="auto"
                                  class="solo-cust"
                                  append-icon="mdil-phone"
                                ></v-text-field>
                              </label>
                            </v-col>
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Email Address </span>
                                <v-text-field
                                  autocomplete="off"
                                  v-model="callback.user.email"
                                  solo
                                  outlined
                                  flat
                                  hide-details="auto"
                                  class="solo-cust"
                                  append-icon="mdil-email"
                                ></v-text-field>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Address </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="callback.user.address_one"
                                  hide-details="auto"
                                  class="solo-cust mb-2"
                                ></v-text-field>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="callback.user.address_two"
                                  hide-details="auto"
                                  class="solo-cust mar-8"
                                ></v-text-field>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Town / City </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="callback.user.city"
                                  hide-details="auto"
                                  class="solo-cust"
                                ></v-text-field>
                              </label>
                            </v-col>
                            <v-col cols="12" md="6" class="e-col">
                              <v-row no-gutters>
                                <v-col cols="6">
                                  <label>
                                    <span class="label-txt"> Post Code </span>
                                    <v-text-field
                                      autocomplete="off"
                                      solo
                                      outlined
                                      flat
                                      v-model="callback.user.postcode"
                                      hide-details="auto"
                                      class="solo-cust"
                                    ></v-text-field>
                                  </label>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Preferred Branch: </span>
                                <v-select
                                  autocomplete="off"
                                  :items="branches"
                                  :rules="rules.branch_id"
                                  item-value="id"
                                  item-text="branch"
                                  v-model="callback.user.branch_id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="lead" :eager="true">
                      <v-form ref="leadForm">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Reason for Treatment: </span>
                              </label>
                              <v-combobox
                                v-model="callback.user.user_details.reasons"
                                :items="reasons"
                                item-text="name"
                                item-value="id"
                                multiple
                                :search-input.sync="reasonSearch"
                                solo
                                outlined
                                flat
                                chips
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        No results matching "<strong>{{ reasonSearch }}</strong
                                        >". Press <kbd>enter</kbd> to create a new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2" v-if="data.item.name">
                                      {{ data.item.name }}
                                    </span>
                                    <span class="pr-2" v-else>
                                      {{ data.item }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeReason(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Symptoms / Condition: </span>
                              </label>
                              <v-autocomplete
                                v-model="callback.user.user_details.symptoms"
                                :items="symptoms"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                                @change="symptomChange"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeSymptoms(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Suggested Treatments: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip
                                  small
                                  class="este-chip status-chip mb-2"
                                  text-color="black"
                                  @click="suggTreat(treat)"
                                >
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Service Recommended: </span>
                              </label>
                              <v-autocomplete
                                v-model="callback.user.user_details.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeService(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Enquiry Date </span>
                                <v-menu
                                  v-model="enquiryMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="
                                        dateUkFormat(callback.user.other_details.enquiry_date)
                                      "
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="callback.user.other_details.enquiry_date"
                                    no-title
                                    @input="enquiryMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Follow Up Date </span>
                                <v-menu
                                  v-model="followUpMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="
                                        dateUkFormat(callback.user.other_details.follow_up_date)
                                      "
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="callback.user.other_details.follow_up_date"
                                    no-title
                                    @input="followUpMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">Follow Up Time:</span>
                                <v-menu
                                  ref="followUpTimeMenu"
                                  v-model="followUpTimeMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="callback.user.other_details.follow_up_time"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      outlined
                                      flat
                                      hide-details="auto"
                                      solo
                                      class="solo-cust"
                                      v-model="callback.user.other_details.follow_up_time"
                                      append-icon="mdil-clock"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    :allowed-minutes="allowedStep"
                                    no-title
                                    ampm-in-title
                                    format="24hr"
                                    v-if="followUpTimeMenu"
                                    v-model="callback.user.other_details.follow_up_time"
                                    full-width
                                    @click:minute="
                                      $refs.followUpTimeMenu.save(
                                        callback.user.other_details.follow_up_time
                                      )
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Source: </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="source"
                                v-model="callback.user.source_id"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Responsible </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="callback.user.other_details.responsible_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Lead Status </span>
                                <v-select
                                  autocomplete="off"
                                  :item-disabled="statusDisable"
                                  :items="
                                    SortAtoZ(
                                      statusTags.filter(function (el) {
                                        return el.slug.includes('lead');
                                      })
                                    )
                                  "
                                  item-value="id"
                                  item-text="name"
                                  v-model="callback.user.status"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="client" :eager="true">
                      <v-form ref="form">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Symptoms / Condition: </span>
                              </label>
                              <v-autocomplete
                                v-model="callback.user.user_details.symptoms"
                                :items="symptoms"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                                @change="symptomChange"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    color=""
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeSymptoms(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Suggested Treatments: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip
                                  small
                                  class="este-chip status-chip mb-2"
                                  text-color="black"
                                  @click="suggTreat(treat)"
                                >
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Service Recommended: </span>
                              </label>
                              <v-autocomplete
                                v-model="callback.user.user_details.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeService(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Responsible </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="callback.user.other_details.responsible_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Status </span>
                                <v-select
                                  autocomplete="off"
                                  :item-disabled="statusDisable"
                                  :items="
                                    SortAtoZ(
                                      statusTags.filter(function (el) {
                                        return el.slug.includes('client');
                                      })
                                    )
                                  "
                                  item-value="id"
                                  item-text="name"
                                  v-model="callback.user.status"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="">
                              <label>
                                <span class="label-txt"> Client Satisfaction: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="(item, index) in satisfaction">
                                <div
                                  v-model="callback.user.client_satisfaction"
                                  :class="[
                                    'emoji-wrap',
                                    `${lowerCase(item.name)}-emoji`,
                                    { active: activeIndex === index },
                                    { active: callback.user.client_satisfaction === item.slug },
                                  ]"
                                  @click="setActive(item, index)"
                                  :key="item.name"
                                >
                                  <div class="icon">
                                    <img :src="`${item.image}`" />
                                  </div>
                                  <div class="text">{{ item.name }}</div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="callback" :eager="true">
                      <v-form ref="callbackForm">
                        <div class="tab-content-wrapper">
                          <v-row dense class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <div class="client-info">
                                <div class="name" v-if="callback.user.name !== null">
                                  {{ callback.user.name }}
                                </div>
                                <div
                                  class="d-flex name"
                                  v-if="
                                    callback.user.name === null &&
                                    callback.user.social_handle !== null
                                  "
                                >
                                  <img
                                    :src="`/images/${callback.user.source_name.toLowerCase()}.svg`"
                                    class="mr-2"
                                    v-if="callback.user.source_name !== null"
                                  />
                                  <span> @{{ callback.user.social_handle }} </span>
                                </div>
                                <p class="mt-4">
                                  <span class="contact" v-if="callback.user.contact_no !== null">
                                    <a :href="`tel:${callback.user.contact_no}`">
                                      <img src="/images/phone.svg" height="15" />
                                      {{ callback.user.contact_no }}</a
                                    >
                                  </span>
                                  <span class="contact" v-if="callback.user.email !== null">
                                    <a :href="`tel:${callback.user.email}`">
                                      <img src="/images/email.svg" height="15" />
                                      {{ callback.user.email }}</a
                                    >
                                  </span>
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Treatment / Service: </span>
                              </label>
                              <v-autocomplete
                                v-model="callback.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item.name }}</span>
                                  </v-chip>
                                  <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ callback.treatments.length - 1 }} others)
                                  </span>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Call Back Date: </span>
                                <v-menu
                                  v-model="callbackDateMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="dateUKformat(callback.callback_date)"
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="callback.callback_date"
                                    no-title
                                    @input="callbackDateMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt">Call Back Time:</span>
                                <v-menu
                                  ref="callbackTimeMenu"
                                  v-model="callbackTimeMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      outlined
                                      flat
                                      hide-details="auto"
                                      solo
                                      class="solo-cust"
                                      v-model="callback.callback_time"
                                      label="From"
                                      append-icon="mdil-clock"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    :allowed-minutes="allowedStep"
                                    no-title
                                    ampm-in-title
                                    format="24hr"
                                    v-if="callbackTimeMenu"
                                    v-model="callback.callback_time"
                                    full-width
                                    @click:minute="
                                      $refs.callbackTimeMenu.save(callback.callback_time)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="6" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Preferred Branch: </span>
                                <v-select
                                  autocomplete="off"
                                  :items="branches"
                                  item-value="id"
                                  item-text="branch"
                                  :rules="rules.branch_id"
                                  v-model="callback.branch_id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                  @change="staffByBranch"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                            <v-col cols="6" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Urgency </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="priority"
                                v-model="callback.urgency"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Select Consultant </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="callback.consultant_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Status </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="
                                  SortAtoZ(
                                    statusTags.filter(function (el) {
                                      return el.slug.includes('callback');
                                    })
                                  )
                                "
                                v-model="callback.status"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="insight" :eager="true">
                      <tab-insight
                        :itemData="callback"
                        :userId="callback.user_id"
                        :keyId="callback.id"
                      ></tab-insight>
                    </v-tab-item>
                    <v-tab-item value="activity" :eager="true">
                      <tab-activity
                        :itemData="callback"
                        :userId="callback.user_id"
                        :keyId="callback.id"
                      ></tab-activity>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="b-blue"
                      depressed
                      @click="update"
                      :loading="loading"
                      rounded
                      x-large
                    >
                      Save Changes
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import edit from "./edit.js";
import TabActivity from "../../../components/TabItems/TabActivity";
import TabInsight from "../../../components/TabItems/TabInsight";
export default {
  components: {
    TabActivity,
    TabInsight,
  },
  mixins: [globalFn, status, edit, sidePaneMenu],
};
</script>
