<template>
  <v-row>
    <v-col cols="12">
      <div class="font-14 line-h-17 font-weight--semibold text-black-100">
        {{ element.title }}
        <span class="red--text required" v-if="element.is_required === 1">*</span>
      </div>
    </v-col>
    <v-col cols="12" v-if="element.description">
      <div
        class="font-13 line-h-16 text-black-300 font-weight--semibold"
        v-html="element.description"
      ></div>
    </v-col>
    <v-col cols="12">
      <v-checkbox
        v-for="(item, index) in element.answers"
        :key="item.id"
        :label="item.title"
        :value="item.id"
        off-icon="mdi-checkbox-blank-circle-outline"
        on-icon="mdi-check-circle"
        class="mt-0"
        v-model="answer_id"
        hide-details="auto"
      >
        <template v-slot:label>
          <span class="font-13 line-h-16 text-black-300 font-weight--semibold mt-0">{{
            item.title
          }}</span>
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "form-card",
  props: ["element"],
  data: () => ({
    answer_id: [],
  }),
  computed: {},
  watch: {
    answer_id: _.debounce(function () {
      this.submit();
    }, 1000),
  },
  methods: {
    async submit() {
      this.$emit("updateAnswer", { element_id: this.element.id, answer_id: this.answer_id });
    },
  },
};
</script>
<style lang="scss" scoped></style>
