require("./bootstrap");
import Vue from "vue";
window.Vue = require("vue");

import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";
import App from "../js/components/AppComponent";
Vue.use(require("vue-moment"));

import Vue2Filters from "vue2-filters";

import moment from "moment";

Vue.filter("moment", function (value, format) {
  if (value === null || value === undefined || format === undefined) {
    return "";
  }
  if (format === "from") {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});

Vue.use(Vue2Filters);

Vue.config.devtools = false;

Vue.config.productionTip = false;

import OneSignalVue from "onesignal-vue";

Vue.use(OneSignalVue);

import VueIntercom from "@mathieustan/vue-intercom";

Vue.use(VueIntercom, { appId: "h9hf1tl6" });

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import { VueEditor } from "vue2-editor";

Vue.component(VueEditor);

import InlineSvg from "vue-inline-svg";
Vue.component("inline-svg", InlineSvg);

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

const app = new Vue({
  el: "#app",
  vuetify,
  router,
  store,
  components: { App: App },
});
