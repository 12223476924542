<template>
  <v-card class="border" style="overflow: hidden">
    <v-form ref="form" @submit.prevent="update">
      <v-row>
        <v-col cols="7" style="background-color: #f8f9fa">
          <v-row class="py-3 px-4" align="center">
            <v-col cols="12" class="d-flex align-center">
              <div>
                <v-icon color="grey-lighten-2" size="40">mdi-account-circle</v-icon>
              </div>
              <div class="text-subtitle-1 text-capitalize ml-2">
                {{ rotaDetails.user?.title }} {{ rotaDetails.user?.name }} - Edit a shift
              </div>
            </v-col>
          </v-row>
          <v-divider :thickness="2"></v-divider>

          <v-card-text>
            <v-row>
              <v-col cols="5">
                <div class="d-flex align-center">
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    :value="dateUKformat(formObj.date)"
                    disabled
                    prepend-icon="mdi-clock-outline"
                    append-icon="mdil-calendar"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="7" class="d-flex align-center" style="gap: 1rem">
                <v-menu
                  ref="startTime"
                  v-model="menu.startTime"
                  :close-on-content-click="false"
                  :return-value.sync="formObj.start_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      hide-details="auto"
                      solo
                      class="advance-input"
                      :value="formObj.start_time"
                      :rules="rules.start_time"
                      readonly
                      dense
                      placeholder="Start Time"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu.startTime"
                    :allowed-minutes="allowedStep"
                    no-title
                    ampm-in-title
                    format="24hr"
                    v-model="formObj.start_time"
                    full-width
                    @click:minute="$refs.startTime.save(formObj.start_time)"
                  ></v-time-picker>
                </v-menu>

                <v-menu
                  ref="endTime"
                  v-model="menu.endTime"
                  :close-on-content-click="false"
                  :return-value.sync="formObj.end_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      hide-details="auto"
                      solo
                      class="advance-input"
                      :value="formObj.end_time"
                      :rules="rules.end_time"
                      readonly
                      dense
                      placeholder="End Time"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu.endTime"
                    :allowed-minutes="allowedStep"
                    no-title
                    ampm-in-title
                    format="24hr"
                    v-model="formObj.end_time"
                    full-width
                    @click:minute="$refs.endTime.save(formObj.end_time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="text-right"> Duration: {{ getDuration }}</v-col>
              <v-col cols="12">
                <v-icon class="mr-2">mdi-repeat</v-icon>
                <v-btn
                  color="#ADE8F4"
                  elevation="0"
                  class="text-capitalize"
                  @click="showRepeatPanel = true"
                >
                  Repeat
                </v-btn>

                <!-- <v-menu offset-y v-model="menu.color" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="menu.color"
                      ><v-icon>mdi-format-color-fill</v-icon></v-btn
                    >
                  </template>

                  <v-color-picker
                    hide-canvas
                    hide-inputs
                    hide-sliders
                    hide-mode-switch
                    mode="hexa"
                    show-swatches
                  ></v-color-picker>
                </v-menu> -->
              </v-col>
              <v-col cols="12">
                <v-select
                  placeholder="Select Your branch"
                  prepend-icon="mdi-map-marker-outline"
                  dense
                  outlined
                  :items="branches"
                  v-model="formObj.branch_id"
                  :rules="rules.branch_id"
                ></v-select>
                <!-- <v-col cols="12" class="d-flex align-center">
              <v-icon class="mr-2">mdi-briefcase-variant-outline</v-icon>
              <v-text-field hide-details dense outlined placeholder="selected services only"></v-text-field>
            </v-col> -->
                <v-select
                  placeholder="Assigned To Room"
                  prepend-icon="mdi-dock-window"
                  dense
                  outlined
                  :items="roomItems"
                  v-model="formObj.room_id"
                ></v-select>

                <v-textarea
                  hide-details
                  prepend-icon="mdi-note-outline"
                  placeholder="Add new note"
                  dense
                  outlined
                  v-model="formObj.note"
                  :disabled="formObj.note.length >= 100"
                  height="100"
                ></v-textarea>

                <div class="text-right mt-1">{{ formObj.note.length }}/100</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>

        <v-col cols="5" v-if="!showRepeatPanel">
          <v-card-text>
            <div class="text-grey text-subtitle-1 font-weight-bold mb-3">Employee Assignment</div>
            <v-divider class="my-4"></v-divider>

            <v-text-field
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
              outlined
              placeholder="Search by Employee Name"
            ></v-text-field>

            <v-divider class="my-4"></v-divider>

            <v-expansion-panels flat>
              <v-expansion-panel v-for="item in accordions" :key="item.title">
                <v-expansion-panel-header
                  >{{ item.title }} ({{ item.count }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="text-center pa-2" v-if="item.count <= 0">No data available</div>
                  <div class="d-flex" style="gap: 1rem" v-else>
                    <v-tooltip v-for="consultant in item.consultants" :key="consultant.id" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="grey-lighten-2" size="30" v-bind="attrs" v-on="on"
                          >mdi-account-circle</v-icon
                        >
                      </template>
                      <span>{{ consultant.name }}</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>

        <v-col cols="5" v-else>
          <v-card-text>
            <div class="text-grey text-subtitle-1 font-weight-bold mb-3">Repeat Options</div>
            <v-divider class="my-4"></v-divider>

            <v-row>
              <v-col cols="12">
                <v-label class="font-weight-medium">Repeat every</v-label>

                <div class="d-flex align-center mt-2">
                  <div class="text-subtitle-1 mr-4">Week:</div>

                  <div style="width: 100px">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      v-model="formObj.repeat.weeks"
                    ></v-text-field>
                  </div>
                </div>

                <div class="my-4">
                  <v-label class="font-weight-medium">Repeat on </v-label>
                  <v-btn-toggle
                    v-model="formObj.repeat.days"
                    dense
                    multiple
                    color="#0096c7"
                    class="mt-2"
                  >
                    <v-btn v-for="(day, index) in days" :key="index">{{ day }}</v-btn>
                  </v-btn-toggle>
                </div>

                <div>
                  <v-label class="font-weight-medium">Repeat Until</v-label>
                  <v-menu
                    ref="repeatUntil"
                    class="mt-2"
                    v-model="menu.repeatUntil"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateUkFormat(formObj.repeat.end_date)"
                        v-bind="attrs"
                        v-on="on"
                        flat
                        append-icon="mdil-calendar"
                        autocomplete="off"
                        solo
                        hide-details
                        class="search-input"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="formObj.repeat.end_date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu.repeatUntil = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.repeatUntil.save(date)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>

        <v-divider></v-divider>
      </v-row>

      <v-card-actions class="d-flex justify-space-between px-4 pt-6">
        <v-btn elevation="0" text color="red" class="text-capitalize" @click="deleteShift">
          Delete Shift
        </v-btn>

        <div>
          <v-btn
            elevation="0"
            text
            color="red"
            class="text-capitalize mr-2"
            v-if="showRepeatPanel"
            @click="showRepeatPanel = false"
          >
            Cancel
          </v-btn>

          <v-btn v-else elevation="0" text color="red" class="text-capitalize mr-2" @click="close">
            Cancel
          </v-btn>

          <v-btn elevation="0" type="submit" outlined color="blue" class="text-capitalize">
            {{ showRepeatPanel ? "Set repeat option" : "Update" }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import modalFn from "../../../mixins/modalFn.js";
import globalFn from "../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn, modalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    form: {
      name: "",
    },
    rules: {
      start_time: [(v) => !!v || "Start Time is required"],
      end_time: [(v) => !!v || "End Time is required"],
      branch_id: [(v) => !!v || "Branch is required"],
      // room_id: [(v) => !!v || "Room is required"],
    },
    // color: null,
    menu: {
      startTime: false,
      endTime: false,
      repeatUntil: false,
      // color: false,
    },
    accordions: {
      shift: {
        title: "Who else is working ?",
        count: 0,
        consultants: [],
      },
      available: {
        title: "Who else is available ?",
        count: 0,
        consultants: [],
      },
      holiday: {
        title: "Vacation",
        count: 0,
        consultants: [],
      },
    },
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    showRepeatPanel: false,
    formObj: {
      id: null,
      user_id: null,
      branch_id: null,
      date: null,
      start_time: "00:00",
      note: "",
      type: "",
      end_time: "00:00",
      room_id: null,
      repeat: {
        days: [],
        weeks: 0,
        end_date: null,
      },
    },
  }),
  mounted() {
    this.showRepeatPanel = this.$root.show_repeat;
  },
  watch: {
    rotaDetails: {
      handler(newVal) {
        for (let key in this.formObj) {
          if (["date", "type"].includes(key)) {
            this.formObj[key] = this.rotaDetails[key === "date" ? "rota_date" : "shift_type"];
          } else if (this.rotaDetails[key]) this.formObj[key] = this.rotaDetails[key];
        }

        this.loadData();
      },
      deep: true,
    },
  },
  destroyed() {
    this.$root.show_repeat = false;
  },
  computed: {
    rotaDetails() {
      return this.$store.state.rota.item;
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList.map((item) => {
          return {
            text: item.branch,
            value: item.id,
          };
        });
      }
    },
    roomItems() {
      return this.$store.state.rooms.list?.data.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
    getDuration() {
      return this.calculateDuration(this.formObj.start_time, this.formObj.end_time);
    },
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
    },
    async loadData() {
      const res = await this.$store
        .dispatch("rota/USER_COUNT", {
          date: this.rotaDetails.rota_date,
          branch_id: this.rotaDetails.branch_id,
        })
        .then((res) => {
          ["holiday", "shift", "available"].forEach((shift_type) => {
            if (shift_type === "shift") this.accordions[shift_type].count = res.data["working"];
            else this.accordions[shift_type].count = res.data[shift_type];

            this.$store
              .dispatch("rota/USER_SHIFT", {
                date: this.formObj.date,
                branch_id: this.formObj.branch_id,
                shift_type,
              })
              .then((response) => {
                this.accordions[shift_type].consultants = response.data;
              });
          });
        });
    },
    async update() {
      if (this.$refs.form.validate()) {
        console.log(this.rotaDetails);
        this.loading = true;

        let payload = { ...this.formObj };
        if (this.formObj.repeat.end_date) {
          delete payload.repeat["weeks"];
        } else {
          delete payload.repeat["end_date"];
        }

        let dispatchName;
        if (this.rotaDetails.hasOwnProperty("user_id")) {
          payload.user_id = this.rotaDetails.user_id;
          dispatchName = "UPDATE_SHIFT";
        } else {
          payload.machine_id = this.rotaDetails.machine_id;
          dispatchName = "UPDATE_MACHINE";
        }

        await this.$store
          .dispatch(`rota/${dispatchName}`, payload)
          .then((res) => {
            if (res.result === "success") {
              this.$root.$emit("fetchRota");
              this.loading = false;
              this.$refs.form.reset();
              this.$store.dispatch("snackbar/SHOW", res);
              this.close();
            } else if (res.result === "fail") {
              res.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async deleteShift() {
      this.close();
      setTimeout(() => this.openModal("add", 400, "Scheduler/delete"), 500);
    },
  },
};
</script>
