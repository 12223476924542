var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 search-cont"},[_vm._l((_vm.actions),function(a,index){return [(_vm.screen === a.screen && _vm.slug === a.slug)?[(
          (_vm.slug === 'appointments' ||
            _vm.slug === 'app-treatments' ||
            _vm.slug === 'leads' ||
            _vm.slug === 'call-back') &&
          a.calendar === 'true'
        )?_c('v-btn',{staticClass:"b-btn b-calendar mr-4",attrs:{"href":`/calendar/${_vm.slug}`,"icon":"","color":"primary","depressed":""}},[_c('img',{attrs:{"src":"/images/calendar.svg"}})]):_vm._e(),_vm._v(" "),(_vm.slug === 'calendar')?_c('v-btn',{staticClass:"b-btn b-calendar mr-4",attrs:{"icon":"","color":"primary","depressed":""},on:{"click":_vm.loadBackScreen}},[_c('img',{attrs:{"src":"/images/list.svg"}})]):_vm._e(),_vm._v(" "),(a.btn)?_c('v-btn',{staticClass:"b-btn b-small mr-4",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleFunction(a)}}},[_vm._v("\n        "+_vm._s(a.btnText)+"\n      ")]):_vm._e()]:_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }