import collection from "../../api/collections/Stock";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    history: [],
    all: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET(state, data) {
      state.list = data;
    },
    SET_SHOW_BY_PRODUCT(state, data) {
      state.history = data;
    },
    SET_LIST(state, data) {
      state.all = data;
    },
    SET_ITEM(state, data) {
      state.item = data;
    },
    RESET(state, data) {
      state.item = {};
    },
    SET_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async SHOW_BY_PRODUCT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.stockByProduct(payload);
          commit("SET_SHOW_BY_PRODUCT", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_BY_PRODUCT",
        true
      );
    },
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.list(payload);
          commit("SET_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "LIST",
        true
      );
    },
    async CREATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE",
        true
      );
      return data;
    },
    async SHOW({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW",
        false
      );
      return data;
    },
    async UPDATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );

      return data;
    },
    async DELETE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE",
        true
      );
      return data;
    },
  },
};
