<template>
  <div class="fill-height slider-cont">
    <div class="modal-inside pb-0 tab-brd">
      <div class="form-title mb-24">Appointment Details</div>
      <v-tabs v-model="navigationTab" class="cust-tabs">
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <v-tab href="#notes"> Appointment Notes </v-tab>
      </v-tabs>
    </div>
    <div class="modal-inside py-0 right-pane">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="notes" :eager="true">
          <tab-appointment-notes
            :itemData="appointment"
            :userId="appointment.user_id"
            :keyId="appointment.id"
          ></tab-appointment-notes>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="form-btn-wrapper">
      <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
        Close
      </v-btn>
    </div>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
import eventBus from "../../../eventBus.js";
import TabAppointmentNotes from "../../../components/TabItems/AppointmentsNotes";
export default {
  mixins: [globalFn, status],
  components: {
    TabAppointmentNotes,
  },
  data: () => ({
    navigationTab: "notes",
    loading: false,
    activeIndex: null,
  }),
  computed: {
    appointment() {
      if (this.$store.state.appointments.item) {
        if (!this.$store.state.appointments.item.user) {
          this.$store.state.appointments.item.user = {};
        }
        this.treatment_id = this.$store.state.appointments.item?.treatments?.[0].id;
        return this.$store.state.appointments.item;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
  },
};
</script>
