<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <div class="card-title">
            <v-row>
              <v-col sm="4" md="3" lg="2" xl="2">
                <div>
                  <label><span class="label-txt">Report Type</span></label>
                  <v-select
                    autocomplete="off"
                    v-model="reportType"
                    :items="reports"
                    item-value="id"
                    item-text="name"
                    solo
                    outlined
                    flat
                    class="form-input"
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col sm="4" md="4" lg="3" xl="2">
                <div>
                  <label><span class="label-txt">Date Range</span></label>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRange"
                        :rules="rules.date"
                        v-bind="attrs"
                        v-on="on"
                        prepend-inner-icon="mdil-calendar"
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="form-input"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" range no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col sm="4" md="4" lg="4" xl="2">
                <v-btn
                  type="submit"
                  class="mt-5 b-btn b-small b-blue px-10 form-search"
                  :loading="loading"
                  depressed
                  @click="submitHandler"
                >
                  Download
                </v-btn>
                <a id="downloadCSV" download="" :href="file" style="display: none">Download it</a>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card flat class="pa-0 mt-5 pt-5" v-if="emailReportPermission">
          <div class="card-title pt-0">
            <h4 class="headerContent mb-5">Email Reports</h4>
            <v-row>
              <v-col cols="5" md="5" lg="5" xl="5">
                <v-simple-table class="email-report">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" v-if="user.user_type_name === 'Admin'">Staff</th>
                        <th class="text-left">Report Type</th>
                        <th class="text-left">Period</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in schedulers" :key="index">
                        <td v-if="user.user_type_name === 'Admin'">{{ item.user_name }}</td>
                        <td>{{ getReportName(item.report_type) }}</td>
                        <td>{{ getPeriodName(item.date_range_type) }}</td>
                        <td>
                          <v-chip
                            small
                            class="este-chip tag-chip"
                            text-color="black"
                            @click="DeleteScheduler(item)"
                          >
                            Unschedule
                            <v-icon class="ml-1" size="14" color="red"
                              >mdi-stop-circle-outline</v-icon
                            >
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="7" md="7" lg="7" xl="7" v-if="user.user_type_name === 'Admin'">
                <h4 class="form-title mb-4">Schedule for Staff</h4>
                <v-form ref="ScheduleForm">
                  <v-row>
                    <v-col sm="3" md="3" lg="3" xl="3">
                      <div>
                        <label><span class="label-txt">Staff</span></label>
                        <v-select
                          autocomplete="off"
                          v-model="schedule.user_id"
                          :items="staff"
                          :rules="rules.staff"
                          item-value="id"
                          item-text="name"
                          solo
                          outlined
                          flat
                          class="form-input"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xl="3">
                      <div>
                        <label><span class="label-txt">Report Type</span></label>
                        <v-select
                          autocomplete="off"
                          v-model="schedule.report_type"
                          :items="reports"
                          :rules="rules.report_type"
                          item-value="id"
                          item-text="name"
                          solo
                          outlined
                          flat
                          class="form-input"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xl="3">
                      <div>
                        <label><span class="label-txt">Period</span></label>
                        <v-select
                          autocomplete="off"
                          v-model="schedule.date_range_type"
                          :rules="rules.date_range_type"
                          :items="dateRangeTypes"
                          item-value="id"
                          item-text="name"
                          solo
                          outlined
                          flat
                          class="form-input"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col sm="2" md="2" lg="2" xl="2">
                      <div>
                        <label><span class="label-txt">Set Time</span></label>
                        <v-menu
                          ref="TimeMenu"
                          v-model="TimeMenu"
                          :close-on-content-click="false"
                          :return-value.sync="schedule.time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              flat
                              hide-details="auto"
                              solo
                              class="form-input"
                              :rules="rules.time"
                              v-model="schedule.time"
                              label="Time"
                              append-icon="mdil-clock"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            :allowed-minutes="allowedStep"
                            no-title
                            ampm-in-title
                            format="24hr"
                            v-if="TimeMenu"
                            v-model="schedule.time"
                            full-width
                            @click:minute="$refs.TimeMenu.save(schedule.time)"
                          ></v-time-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col sm="12" md="12" lg="12" xl="12">
                      <v-btn
                        class="mt-5 b-btn b-small b-blue px-10 form-search"
                        depressed
                        @click="CreateSchedule"
                      >
                        Schedule
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="7" md="7" lg="7" xl="7" v-if="user.user_type_name !== 'Admin'">
                <h4 class="form-title mb-4">Schedule</h4>
                <v-form ref="ScheduleForm">
                  <v-row>
                    <v-col sm="3" md="3" lg="3" xl="3">
                      <div>
                        <label><span class="label-txt">Report Type</span></label>
                        <v-select
                          autocomplete="off"
                          v-model="schedule.report_type"
                          :items="reports"
                          :rules="rules.report_type"
                          item-value="id"
                          item-text="name"
                          solo
                          outlined
                          flat
                          class="form-input"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xl="3">
                      <div>
                        <label><span class="label-txt">Period</span></label>
                        <v-select
                          autocomplete="off"
                          :item-disabled="PeriodDisables"
                          v-model="schedule.date_range_type"
                          :rules="rules.date_range_type"
                          :items="dateRangeTypes"
                          item-value="id"
                          item-text="name"
                          solo
                          outlined
                          flat
                          class="form-input"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col sm="2" md="2" lg="2" xl="2">
                      <div>
                        <label><span class="label-txt">Set Time</span></label>
                        <v-menu
                          ref="TimeMenu"
                          v-model="TimeMenu"
                          :close-on-content-click="false"
                          :return-value.sync="schedule.time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              flat
                              hide-details="auto"
                              solo
                              class="form-input"
                              :rules="rules.time"
                              v-model="schedule.time"
                              label="Time"
                              append-icon="mdil-clock"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            :allowed-minutes="allowedStep"
                            no-title
                            ampm-in-title
                            format="24hr"
                            v-if="TimeMenu"
                            v-model="schedule.time"
                            full-width
                            @click:minute="$refs.TimeMenu.save(schedule.time)"
                          ></v-time-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col sm="2" md="2" lg="2" xl="2">
                      <v-btn
                        class="mt-5 b-btn b-small b-blue px-10 form-search"
                        depressed
                        @click="CreateSchedule"
                      >
                        Schedule
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "Reports",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      menu: false,
      TimeMenu: false,
      loading: false,
      valid: false,
      emailReportPermission: false,
      rules: {
        date: [],
        date_range_type: [(v) => !!v || "Please select period"],
        report_type: [(v) => !!v || "Please select report type"],
        time: [(v) => !!v || "Please select time"],
      },
      user: "",
      date: [],
      file: "",
      reports: [
        {
          id: "commission",
          code: "commission_report",
          name: "Commission Report",
        },
        {
          id: "invoice",
          code: "invoice_report",
          name: "Purchase Invoice Report",
        },
        {
          id: "cash_payment",
          code: "cash_payment_report",
          name: "Cash Payment Report",
        },
        {
          id: "social_media",
          code: "social_media_report",
          name: "Social Media Report",
        },
        {
          id: "lead_source",
          code: "lead_source_report",
          name: "Lead Source Report",
        },
        {
          id: "social_media_chased",
          code: "social_media_chased_report",
          name: "Social & Chase Report",
        },
      ],
      reportType: "",
      schedule: {
        date_range_type: "",
        report_type: "",
        user_id: "",
        time: "",
      },
      dateRangeTypes: [
        { name: "Today", id: "today" },
        { name: "Yesterday", id: "yesterday" },
        { name: "Last 7 days", id: "last_7_days" },
        { name: "Last Week", id: "last_week" },
        { name: "Last Month", id: "last_month" },
      ],
      schedulers: [],
    };
  },
  watch: {},
  computed: {
    dateRange() {
      if (this.date.length) {
        const a = this.formatDate(this.date[0]);
        const b = this.formatDate(this.date[1]);
        return a + "-" + b;
      } else {
        const curr = new Date(); // get current date
        const first = curr.getDate() - ((curr.getDay() + 6) % 7); // First day as Monday of the week
        const last = first + 6; // last day is the first day + 6

        const firstday = new Date(curr.setDate(first)).toISOString().split("T")[0];
        const lastday = new Date(curr.setDate(last)).toISOString().split("T")[0];
        const a = this.formatDate(firstday);
        const b = this.formatDate(lastday);
        return a + "-" + b;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  mounted() {
    this.loadPermissions();
  },
  created() {
    window.Echo.channel("update-staff").listen(".staff-updated", (e) => {
      this.loadPermissions();
    });
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: "", params: "" });
  },
  methods: {
    ...mapActions({
      USER,
    }),
    async loadPermissions() {
      await this.USER().then((res) => {
        this.user = res.data;
      });
      await this.loadReports();
      await this.CheckEmailReportPermission();
      await this.loadSchedulers();
    },
    async loadReports() {
      if (this.user.user_type_name !== "Admin") {
        this.reports = this.reports.filter((el) => {
          return this.user.permission_modules.some((item) => {
            return item.module === el.code;
          });
        });
      }
    },
    CheckEmailReportPermission() {
      if (this.user.user_type_name !== "Admin") {
        this.emailReportPermission = this.user.permission_modules.some(
          (e) => e.module === "email_report"
        );
      } else {
        this.emailReportPermission = true;
      }
    },
    getReportName(item) {
      let res = this.reports.find((x) => x.id === item);
      return res ? res.name : "";
    },
    getPeriodName(item) {
      let res = this.dateRangeTypes.find((x) => x.id === item);
      return res ? res.name : "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async submitHandler() {
      if (this.date === "") {
        this.rules.date = [(v) => !!v || "Address field can not be empty"];
      } else {
        await this.readTheDataFromApi();
      }
    },
    async readTheDataFromApi() {
      let start_date = "";
      let end_date = "";
      if (this.dateRange.length) {
        const dates = this.dateRange.split("-");
        start_date = this.formatDateRange(dates[0]);
        end_date = this.formatDateRange(dates[1]);
      }
      this.loading = true;
      await this.$store
        .dispatch(
          "reports/GET_COMMISSION",
          `?type=${this.reportType}&start_date=${start_date}&end_date=${end_date}`
        )
        .then((res) => {
          if (res.result === "success") {
            this.file = res.data.file_url;
            this.downloadFile();
          } else if (res.result === "fail") {
            this.loading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    downloadFile() {
      let self = this;
      setTimeout(function () {
        self.loading = false;
        document.getElementById("downloadCSV").click();
      }, 2000);
    },
    formatDateRange(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async loadSchedulers() {
      let filters =
        this.user.user_type_name !== "Admin"
          ? `?filters=[{"user_id":[{"equ":${this.user.id}}]}]`
          : "";
      await this.$store
        .dispatch("scheduler/GET_SCHEDULER", filters)
        .then((res) => {
          if (res.result === "success") {
            this.schedulers = res.data.data;
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    PeriodDisables(item) {
      if (this.schedulers) {
        let res = this.schedulers.find((x) => x.date_range_type === item.id);
        return res ? true : false;
      }
    },
    async CreateSchedule() {
      if (this.$refs.ScheduleForm.validate()) {
        let formData = {};
        formData.date_range_type = this.schedule.date_range_type;
        formData.report_type = this.schedule.report_type;
        formData.user_id =
          this.user.user_type_name === "Admin" ? this.schedule.user_id : this.user.id;
        formData.schedule_time = this.schedule.time;

        await this.$store
          .dispatch("scheduler/CREATE_SCHEDULER", formData)
          .then((response) => {
            if (response.result === "success") {
              response.type = "success";
              this.$store.dispatch("snackbar/SHOW", response);
              this.loadSchedulers();
              this.$refs.ScheduleForm.reset();
            } else if (response.result === "fail") {
              response.type = "error";
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async DeleteScheduler(item) {
      await this.$store
        .dispatch("scheduler/DELETE_SCHEDULER", item.id)
        .then((res) => {
          if (res.result === "success") {
            res.type = "success";
            this.$store.dispatch("snackbar/SHOW", res);
            this.loadSchedulers();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
