<template>
  <div class="body-wrapper">
    <v-container fluid class="pa-0">
      <v-row class="ma-0 postiton--relative">
        <v-col cols="12" md="8" class="pa-0 bg-surface-100">
          <div class="d-flex flex-column align-center justify-center surface--wrap">
            <v-img
              contain
              lazy-src="/images/login-logo.png"
              src="/images/login-logo.png"
              max-width="170"
            ></v-img>
            <template v-if="!verificationDialog">
              <v-form ref="form" @submit.prevent="validate" v-model="valid" lazy-validation>
                <div class="mb-4">
                  <v-text-field
                    v-model="form.email"
                    required
                    :rules="rules.email"
                    hide-details="auto"
                    autofocus
                    filled
                    class="mt-1 b-input"
                    placeholder="Email"
                  ></v-text-field>
                </div>
                <div class="btn-wrapper justify-space-between">
                  <v-btn text class="b-btn btn-text mr-5" @click="goto('/login')">Go Back</v-btn>
                  <v-btn
                    type="submit"
                    width="40%"
                    class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                    depressed
                    :loading="loading"
                    >Reset Password</v-btn
                  >
                </div>
              </v-form>
            </template>
            <div class="login-form-wrap text-center info-box" v-if="verificationDialog">
              <div class="mb-4">
                <img src="/images/email-action.svg" class="mb-5" alt="" />
                <p class="heading mb-5">Please check your email</p>
                <p class="body mb-5">
                  If your account exists, we will send you an email with details on how to reset
                  your password
                </p>
                <div class="text-center">
                  <v-btn
                    block
                    class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                    @click="goto('/login')"
                    depressed
                    >Go to Login</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pa-0 bg-author"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { PASSWORD_RESET_LINK } from "../../../../store/action-types";
import TheLogo from "../../../../components/TheLogo.vue";
export default {
  name: "ResetPage",
  components: {
    TheLogo,
  },
  data: () => ({
    valid: true,
    loading: false,
    form: {
      email: "",
    },
    verificationDialog: false,
    rules: {
      email: [],
    },
    emailRules: [
      (v) => !!v || "Please enter a valid email address",
      (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
    ],
  }),
  mounted() {},
  methods: {
    ...mapActions({
      PASSWORD_RESET_LINK,
    }),
    goto(path) {
      this.$router.push(path);
    },
    validate: function () {
      this.rules.email = [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ];
      if (this.$refs.form.validate()) {
        this.sendPasswordResetLink();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async sendPasswordResetLink() {
      this.loading = true;
      try {
        await this.PASSWORD_RESET_LINK(this.form)
          .then((res) => {
            if (res.result === "success" && res.status_code === 200) {
              this.loading = false;
              this.verificationDialog = true;
            } else if (res.result === "fail") {
              this.loading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      } catch (error) {
        console.dir(error);
        this.$store.dispatch("snackbar/SHOW", {
          title: "Error!",
          type: "error",
          message: error.message,
        });
      }
    },
    async resendVerificationEmail(email) {
      this.notVerifiedDialog = false;
      if (this.form.email) {
        this.USER_VERIFY(`?email=${this.form.email}`).then((r) => {
          this.notVerifiedDialog = false;
          this.$refs.form.reset();
          this.verificationDialog = true;
        });
      }
    },
  },
};
</script>
<style></style>
