<template>
  <div>
    <component :is="loadComponent" :element="element" @updateAnswer="collectAnswer"></component>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "formelement",
  props: {
    element: {
      required: true,
    },
  },
  computed: {
    loadComponent() {
      return require(`./${this.element.type.replace(/_/g, "")}.vue`).default;
    },
  },
  methods: {
    collectAnswer(data) {
      this.$emit("userAnswer", data);
    },
  },
};
</script>
