<template>
  <v-card text :ripple="false" class="my-4 consent-card">
    <v-card-text>
      <v-row>
        {{ element }}
        <v-col cols="7">
          <div class="text-subtitle-1 text-capitalize">
            {{ element.title }}
            <v-chip color="red" outlined small class="ml-2" v-show="element.is_required === 1">
              Required
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" v-if="element.description">
          <div class="text-body-2 text-capitalize" v-html="element.description"></div>
        </v-col>
        <v-col cols="12">
          <template>
            <v-text-field
              v-if="type === 'short_answer'"
              autocomplete="off"
              solo
              outlined
              flat
              hide-details="auto"
              class="solo-cust"
              placeholder="Short answer"
            ></v-text-field>
            <v-textarea
              v-else-if="type === 'long_answer'"
              autocomplete="off"
              solo
              outlined
              flat
              hide-details="auto"
              class="solo-cust"
              placeholder="Long answer"
            ></v-textarea>
            <div v-else-if="['date', 'time', 'date_time'].includes(type)">
              <v-row>
                <v-col :cols="type === 'date_time' ? 6 : 12">
                  <v-menu
                    v-if="['date', 'date_time'].includes(type)"
                    v-model="datepickerMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        prepend-inner-icon="mdil-calendar"
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        :value="dateUkFormat(date)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="datepickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col :cols="type === 'date_time' ? 6 : 12">
                  <v-menu
                    v-if="['time', 'date_time'].includes(type)"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        hide-details="auto"
                        solo
                        class="solo-cust mt-5"
                        label="To"
                        append-icon="mdil-clock"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      full-width
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="type === 'checkbox'">
              <v-checkbox
                v-for="(item, index) in answerItem"
                :key="item.id"
                :label="item.text"
                :value="item.value"
                off-icon="mdi-checkbox-blank-circle-outline"
                on-icon="mdi-check-circle"
              ></v-checkbox>
            </div>
            <v-radio-group v-else-if="type === 'radio'">
              <v-radio
                v-for="(item, index) in answerItem"
                :key="item.id"
                :label="item.text"
                :value="item.value"
                off-icon="mdi-checkbox-blank-circle-outline"
                on-icon="mdi-check-circle"
              ></v-radio>
            </v-radio-group>
            <v-select
              v-else-if="type === 'dropdown'"
              :items="answerItem"
              class="solo-cust text-subtitle-1 mr-2"
              autocomplete="off"
              solo
              outlined
              flat
              hide-details="auto"
            >
            </v-select>
            <v-select
              v-else-if="type === 'dropdown_multi_select'"
              multiple
              small-chips
              deletable-chips
              :items="answerItem"
              autocomplete="off"
              solo
              outlined
              flat
              hide-details="auto"
              class="solo-cust"
            >
            </v-select>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FormElementType from "./FormElementType.vue";

export default {
  name: "form-card",
  props: ["element", "type", "answers"],
  components: {
    FormElementType,
  },
  data: () => ({
    formData: [],
  }),
  computed: {
    answerItem() {
      return this.answers.map((item) => {
        return {
          text: item.title,
          value: item.id,
        };
      });
    },
  },
  watch: {},
  methods: {
    handleSubmit() {
      // Emitting a custom event named "form-submit" with form data
      this.$emit("form-submit", this.formData);
    },
  },
};
</script>
