<template>
  <v-card flat :elevation="0">
    <v-card-title class="d-flex align-center">
      <template v-if="!selectedImg.length">
        <div v-if="showAlbum" class="d-flex align-center">
          <v-btn icon small class="mr-4" @click="showAlbum = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-breadcrumbs
            class="pa-0"
            :items="[
              {
                text: 'Ablums',
                disabled: true,
              },
              {
                text: albumType === 'untitled' ? 'Untitled' : albumItem.name,
                disabled: true,
              },
            ]"
            divider=">"
          ></v-breadcrumbs>
        </div>
        <v-spacer></v-spacer>
        <div v-if="showAlbum" class="text-capitalize text-subtitle-1 mr-4">
          {{ albumItem.name }} {{ albumItem.created_at | moment("DD MMM YYYY") }}
        </div>

        <div>
          <v-menu offset-y max-width="200" left content-class="user-action">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" :elevation="0" v-on="on" v-bind="attrs">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Create
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn plain :elevation="0" small @click="openModal('add', 400, 'Photos/create')">
                  <v-icon small class="mr-2">mdi-folder-outline</v-icon>
                  New Album
                </v-btn>
              </v-list-item>

              <v-list-item>
                <v-btn plain :elevation="0" small @click="openModal('add', 600, 'Photos/upload')">
                  <v-icon small class="mr-2">mdi-tray-arrow-up</v-icon>
                  Photo upload
                </v-btn>
              </v-list-item>

              <!-- <v-list-item>
                <v-btn plain :elevation="0" small @click="openModal('add', 600, 'Photos/camera')">
                  <v-icon small class="mr-2">mdi-camera-outline</v-icon>
                  Take photos
                </v-btn>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </div>
      </template>

      <div v-else class="d-flex align-center w-100" style="gap: 1rem">
        <v-spacer></v-spacer>
        <div>
          <v-btn elevation="0" depressed class="text-capitalize" @click="removePhoto">
            <v-icon class="mr-2">mdi-delete-outline</v-icon>
            Delete
          </v-btn>

          <v-menu offset-y max-width="200" left content-class="user-action">
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" depressed class="text-capitalize" v-on="on" v-bind="attrs">
                <v-icon class="mr-2">mdi-forward</v-icon>
                Move to
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="album in albumList" :key="album.id" @click="moveTo(album.id)">
                {{ album.name }}
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn elevation="0" depressed class="text-capitalize">
            <v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
            Download ({{ selectedImg.length }})
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text style="min-height: 250px">
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        style="height: 250px; width: 100%"
      >
        <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
      </div>

      <div v-if="!loading">
        <div v-if="!showAlbum">
          <div
            v-if="isAlbumEmpty && !photos.length"
            style="min-height: 250px"
            class="d-flex justify-center align-center w-100"
          >
            <span class="text-center text-capitalize text-h5"> No Photos Available </span>
          </div>
          <div class="grid-wrapper">
            <template v-for="album in albumList">
              <album-card
                v-if="album.photos.length"
                :key="album.id"
                :album="album"
                :photos="album.photos"
                :name="album.name"
                :showOptions="true"
                @refresh="fetchAlbums"
                @viewAlbum="viewAlbum({ id: album.id, type: 'album' })"
              />
            </template>

            <album-card
              v-if="photos.length"
              :photos="photos"
              name="Untitled"
              :showOptions="false"
              @viewAlbum="viewAlbum({ type: 'untitled' })"
            />
          </div>
        </div>

        <div v-else class="d-flex flex-wrap" style="gap: 1rem">
          <div
            v-if="albumType === 'album' ? !albumPhotos.length : !photos.length"
            style="min-height: 250px"
            class="d-flex justify-center align-center w-100"
          >
            <span class="text-center text-capitalize text-h5"> No Photos Available </span>
          </div>

          <template v-else>
            <view-album
              v-if="albumType === 'album'"
              :photos="albumPhotos"
              v-model="selectedImg"
              @refresh="viewAlbum({ id: albumItem.id, type: albumType })"
            />

            <view-album
              v-else-if="albumType === 'untitled'"
              :photos="photos"
              v-model="selectedImg"
              @refresh="fetchPhotos"
            />
          </template>
        </div>

        <div v-if="totalPage" class="my-4 d-flex align-center justify-end w-100">
          <v-pagination
            :length="totalPage"
            :total="pagination.total"
            color="blue"
            v-model="pagination.page"
            @input="paginate"
          ></v-pagination>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import modalFn from "../../../mixins/modalFn";
import albumCard from "./album/card.vue";
import viewAlbum from "./album/view.vue";
export default {
  mixins: [modalFn],
  components: {
    albumCard,
    viewAlbum,
  },
  data: () => ({
    loading: false,
    showAlbum: false,
    albumType: null,
    selectedImg: [],
    pagination: {
      page: 1,
      per_page: 15,
      total: 0,
    },
  }),
  computed: {
    albumList() {
      return this.$store.state.albums.list;
    },
    albumItem() {
      return this.$store.state.albums.item;
    },
    albumPhotos() {
      return this.$store.state.photos.albumPhotos;
    },
    photos() {
      return this.$store.state.photos.list;
    },
    totalPage() {
      return Math.round(this.pagination.total / this.pagination.per_page);
    },
    isAlbumEmpty() {
      // Checks if each album is empty
      const sum = this.albumList.reduce((total, album) => (total += album.photos.length), 0);
      return sum === 0 ? true : false;
    },
  },
  async mounted() {
    await Promise.allSettled([this.fetchAlbums(), this.fetchPhotos()]);

    this.$root.$on("fetchAlbums", this.fetchAlbums);

    this.pagination.total = this.albumList.length;
  },
  watch: {
    async showAlbum(newVal) {
      this.resetPagination();

      if (!newVal) {
        this.selectedImg = [];
        this.albumType = null;
        this.$store.commit("photos/SET_ALBUM_PHOTOS", []);

        await Promise.allSettled(this.fetchAlbums(), this.fetchPhotos());
      }

      // Upading pagination total
      if (!newVal) {
        this.pagination.total = this.albumList.length;
      } else if (this.showAlbum) {
        if (this.albumType === "album") {
          this.pagination.total = this.albumPhotos.length;
        } else if (this.albumType === "untitled") {
          this.pagination.total = this.photos.length;
        }
      }
    },
  },
  methods: {
    fetchAlbums() {
      this.loading = true;
      this.$store
        .dispatch(
          "albums/GET",
          `?attributes=photos&sort_order=ASC&sort_by=id&user_id=${this.$route.params.id}&per_page=${this.pagination.per_page}&page=${this.pagination.page}`
        )
        .finally(() => (this.loading = false));
    },
    viewAlbum({ id, type }) {
      this.loading = true;
      this.showAlbum = true;
      this.albumType = type;

      if (type === "album") {
        this.$store.dispatch("albums/GET_BY_ID", { id, params: "?attributes=photos" });
        this.$store
          .dispatch("photos/GET_BY_ALBUM", {
            album_id: id,
            user_id: this.$route.params.id,
            per_page: this.pagination.per_page,
            page: this.pagination.page,
          })
          .finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    },
    async fetchPhotos() {
      await this.$store.dispatch("photos/GET", {
        user_id: this.$route.params.id,
        per_page: this.pagination.per_page,
        page: this.pagination.page,
      });
    },
    // ALBUM CRUD
    async removePhoto() {
      if (this.albumType === "album") {
        await this.$store
          .dispatch("albums/REMOVE_PHOTOS", {
            album_id: this.albumItem.id,
            photos: this.selectedImg,
          })
          .then((res) => {
            if (res.result === "success") {
              this.selectedImg = [];
              this.viewAlbum({ id: this.albumItem.id, type: this.albumType });
            }
          });
      } else if (this.albumType === "untitled") {
        this.selectedImg.forEach((id, index) => {
          setTimeout(async () => {
            await this.$store.dispatch("photos/DELETE", { id }).then((res) => {
              if (res.result === "success") {
                this.selectedImg = [];
                this.fetchPhotos();
              }
            });
          }, 300 * index);
        });
      }
    },
    async moveTo(album_id) {
      await this.$store
        .dispatch("albums/ADD_PHOTOS", {
          album_id,
          photos: this.selectedImg,
        })
        .then((res) => {
          if (res.result === "success") {
            this.selectedImg = [];
            if (this.albumType === "album") {
              this.viewAlbum({ id: this.albumItem.id, type: this.albumType });
            } else if (this.albumType === "untitled") {
              this.fetchPhotos();
            }
          }
        });
    },
    resetPagination() {
      this.pagination.page = 1;
      this.pagination.total = 0;
    },
    paginate() {
      if (!this.showAlbum) {
        this.fetchAlbums();
      } else if (this.showAlbum) {
        if (this.albumType === "album") {
          this.viewAlbum({ id: this.albumItem.id, type: this.albumType });
        } else if (this.albumType === "untitled") {
          this.fetchPhotos();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-wrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
}
</style>
