<template>
  <v-card class="border" width="400">
    <v-row class="pa-3" align="center">
      <v-col cols="5" class="d-flex align-center justify-center">
        <div>
          <v-icon color="grey-lighten-2" size="40">mdi-account-circle</v-icon>
        </div>
        <div class="text-body-2 ml-2">{{ date | moment("MMM DD,YYYY") }}</div>
      </v-col>
      <v-col cols="7" class="d-flex align-center justify-end">
        <v-btn
          icon
          text
          elevation="0"
          color="red"
          class="text-capitalize mr-2"
          @click="deleteHoliday"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider :thickness="2"></v-divider>

    <v-form ref="form" @submit.prevent="updateHoliday">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-label class="text-subtitle-1 text-capitalize">Holiday date</v-label>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateUkFormat(holiday_date)"
                  :rules="rules.holiday_date"
                  v-bind="attrs"
                  v-on="on"
                  flat
                  prepend-inner-icon="mdil-calendar"
                  autocomplete="off"
                  solo
                  class="search-input"
                >
                  <template v-slot:append> </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="holiday_date"
                no-title
                scrollable
                :allowed-dates="disablePastDates"
                @change="menu = false"
              >
                <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-4">
        <div>
          <v-btn
            elevation="0"
            text
            color="red"
            class="text-capitalize mr-2"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>

          <v-btn elevation="0" outlined color="blue" class="text-capitalize" type="submit">
            Update
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import modalFn from "../../../mixins/modalFn";
export default {
  mixins: [status, globalFn, modalFn],
  props: ["date", "consultant", "rota"],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    menu: false,
    holiday_date: null,
    rules: {
      holiday_date: [(v) => !!v || "Please enter holiday date"],
    },
  }),
  methods: {
    async updateHoliday() {
      if (this.$refs.form.validate()) {
        const payload = {
          id: this.rota.id,
          user_id: this.consultant.id,
          holiday_date: this.holiday_date,
        };

        await this.$store
          .dispatch("rota/UPDATE_HOLIDAY", payload)
          .then((res) => {
            if (res.result === "success") {
              this.$root.$emit("fetchRota");
              this.loading = false;
              this.$refs.form.reset();
              this.$store.dispatch("snackbar/SHOW", res);
              this.$emit("close");
            } else if (res.result === "fail") {
              res.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async deleteHoliday() {
      this.$root.delete_holiday_rota = {
        user_id: this.consultant.id,
        start_date: this.date,
        end_date: this.date,
      };

      this.openModal("edit", 400, "Scheduler/holiday/delete");
      this.$emit("close");
    },
    disablePastDates(date) {
      return date > this.date;
    },
  },
};
</script>

<style scoped lang="scss"></style>
