export default {
  data: () => ({
    loading: false,
    dropdown: true,
    selectedUser: null,
    callbackDateMenu: false,
    callbackTimeMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    form: {
      user_id: 0,
      deal_id: 0,
      treatment_id: [],
      callback_date: new Date().toISOString().slice(0, 10),
      callback_time: "",
      branch_id: "",
      consultant_id: "",
      status: 11,
      urgency: 0,
    },
    items: [],
    rules: {
      user_id: [(v) => !!v || "Please select client"],
      branch_id: [(v) => !!v || "Please select branch"],
      status: [(v) => !!v || "Please select status"],
    },
    showClientsParams: "?attributes=treatments",
    showLeadParams: "?attributes=treatments",
    clientParams: "?sort_by=name&sort_order=ASC",
    leadParams: {
      attributes: "staff,branch,leadDetails,reasons,symptoms,treatments",
      search: "",
      filter: {
        date_from: "",
        date_to: "",
        status: [],
        branch: [],
      },
    },
    staff: [],
    staffParams: {
      branch: "",
      type: "callback",
    },
    updateLeadStatus: "",
    updateClientStatus: "",
    deals: [],
    showDealsDropdown: false,
  }),
  computed: {
    callbackDateUkFormat() {
      if (!this.form.callback_date) return null;
      const [year, month, day] = this.form.callback_date.split("-");
      return `${day}/${month}/${year}`;
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
  },
  mounted() {
    this.selectClient();
    this.staffParams.branch = this.user.branch_id;
    this.getStaffList();
    this.updateStatus();
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("complaints/GET_PRIORITY");
    this.$store.dispatch("source/GET_SOURCE", "");
  },
  watch: {
    "form.callback_date": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "form.callback_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
  },
  methods: {
    async selectClient() {
      if (this.$router.currentRoute.meta.slug === "customer") {
        this.dropdown = false;
        this.form.user_id = parseInt(localStorage.getItem("callbackUserId"));
        await this.setUserId();
      } else {
        this.dropdown = true;
        let LeadArr = await this.getLeads();
        let ClientArr = await this.getClients();
        this.items = [...LeadArr, ...ClientArr];
      }
    },
    async getLeads() {
      let data = [];
      await this.$store.dispatch("leads/GET_LEADS_LIST").then((res) => {
        data = res.data;
      });
      return data;
    },
    async getClients() {
      let data = [];
      await this.$store
        .dispatch("clients/GET_CLIENT_LIST", `?sort_by=name&sort_order=ASC`)
        .then((res) => {
          data = res.data;
        });
      return data;
    },
    async clientChange() {
      await this.$store.dispatch("user/GET_USER", `?user_id=${this.form.user_id}`).then((res) => {
        this.SelectedUserType = res.data.user_type_name;
      });
      await this.setUserId();
    },
    async setUserId() {
      await this.$store.dispatch("user/GET_USER", `?user_id=${this.form.user_id}`).then((res) => {
        this.selectedUser = res.data;
        this.form.branch_id = res.data.branch_id;
        this.staffParams.branch = res.data.branch_id;
        this.form.treatment_id = [];
        res.data.treatments.forEach((value) => {
          this.form.treatment_id.push(value.id);
        });
      });

      await this.getStaffList();
    },
    async staffByBranch() {
      this.staffParams.branch = this.form.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      localStorage.removeItem("callbackUserId");
    },
    updateStatus() {
      if (this.form.callback_date && this.form.callback_time) {
        this.form.status = 5;
      } else if (this.form.callback_date && !this.form.callback_time) {
        this.form.status = 11;
      }
    },
    loadData() {
      localStorage.removeItem("callbackUserId");
      this.$store.commit("rightSlider/RESET_SLIDER");
      if (this.$router.currentRoute.meta.slug === "leads") {
        this.$root.$emit("leadsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "clients") {
        this.$root.$emit("clientsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "customer") {
        this.$root.$emit("customerTableUpdate", "");
      } else {
        this.$root.$emit("callbacksTableUpdate", "");
      }
    },
    async create() {
      this.updateLeadStatus = 26;
      this.updateClientStatus = 26;
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("callback/CREATE_CALLBACK", this.form)
          .then((response) => {
            if (response.result === "success") {
              this.archiveEntry();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async archiveEntry() {
      let action =
        this.SelectedUserType === "Lead" ? "leads/ARCHIVE_LEAD" : "clients/ARCHIVE_CLIENT";
      let status =
        this.SelectedUserType === "Lead" ? this.updateLeadStatus : this.updateClientStatus;
      await this.$store
        .dispatch(action, { id: this.form.user_id, status: status })
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            if (this.SelectedUserType === "Lead") {
              this.removeFollowupDateForLead();
            } else {
              this.loadData();
            }
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async removeFollowupDateForLead() {
      let formData = {};
      formData.id = this.form.user_id;
      formData.follow_up_date = null;
      formData.follow_up_time = null;
      await this.$store
        .dispatch("leads/UPDATE_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
