<template>
  <v-card class="delete-alert">
    <v-form ref="form" @submit.prevent="submit">
      <v-card-title class="px-6">
        Edit Album
        <a class="float-right pa-6" @click="close"><img src="/images/close.svg" alt="Close" /></a>
      </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          outlined
          v-model="name"
          :rules="rules.name"
          placeholder="Album name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-center align-center">
        <div class="delete-wrapper">
          <v-btn
            class="cancel-btn btn-gap"
            depressed
            rounded
            outlined
            @click="close"
            color="dark"
            x-large
          >
            Cancel
          </v-btn>
          <v-btn class="delete-btn" color="success" depressed type="submit" rounded x-large>
            Submit
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    name: "",
    rules: {
      name: [(v) => !!v || "Please enter album name"],
    },
  }),
  computed: {
    albumItem() {
      return this.$store.state.albums.item;
    },
  },
  watch: {
    albumItem(newVal) {
      this.name = newVal.name;
    },
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("albums/UPDATE", { id: this.albumItem.id, body: { name: this.name } })
          .then((res) => {
            if (res.result === "success") {
              this.$root.$emit("fetchAlbums");
              this.$store.dispatch("snackbar/SHOW", res);
            } else if (res.result === "fail") {
              res.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          })
          .finally(() => {
            this.close();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
