import collection from "../../api/collections/Contacts";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    appointments: [],
    opportunities: [],
    tickets: [],
    deals: [],
    points: [],
    notes: [],
    count: {},
  },
  mutations: {
    SET(state, data) {
      state.list = data;
    },
    SET_CONTACT_BY_ID(state, data) {
      state.item = data;
    },
    SET_APPOINTMENTS(state, data) {
      state.appointments = data;
    },
    SET_OPPORTUNITIES(state, data) {
      state.opportunities = data;
    },

    SET_SUPPORT_TICKETS(state, data) {
      state.tickets = data;
    },
    SET_POINTS_HISTORY(state, data) {
      state.points = data;
    },
    SET_CALLBACK_NOTES(state, data) {
      state.notes = data;
    },
    SET_DEALS(state, data) {
      state.deals = data;
    },
    SET_WIDGET_COUNT(state, data) {
      state.count = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getData(payload);
          commit("SET", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async CONTACT_BY_ID({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getContactById(payload);
          commit("SET_CONTACT_BY_ID", data.data);
          return data;
        },
        commit,
        dispatch,
        "CONTACT_BY_ID",
        true
      );
    },

    async APPOINTMENTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getAppointments(payload);
          commit("SET_APPOINTMENTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "APPOINTMENTS",
        true
      );
    },

    async POINTS_HISTORY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getPointsHistory(payload);
          commit("SET_POINTS_HISTORY", data.data);
          return data;
        },
        commit,
        dispatch,
        "POINTS_HISTORY",
        true
      );
    },

    async CALLBACK_NOTES({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getCallbackNotes(payload);
          commit("SET_CALLBACK_NOTES", data.data);
          return data;
        },
        commit,
        dispatch,
        "CALLBACK_NOTES",
        true
      );
    },

    async DEALS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getDeals(payload);
          commit("SET_DEALS", data.data);
          return data;
        },
        commit,
        dispatch,
        "DEALS",
        true
      );
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getWidgetCount(payload);
          commit("SET_WIDGET_COUNT", data.data);
          return data;
        },
        commit,
        dispatch,
        "WIDGET_COUNT",
        true
      );
    },

    async ENROLL_USER({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.enrollUser(payload);
          return data;
        },
        commit,
        dispatch,
        "ENROLL_USER",
        true
      );
    },

    async CONTACT_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getContactList(payload);
          return data;
        },
        commit,
        dispatch,
        "CONTACT_LIST",
        true
      );
    },

    async CREATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE",
        true
      );
    },

    async UPDATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );
    },

    async OPPORTUNITIES({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getOpportunities(payload);
          commit("SET_OPPORTUNITIES", data.data);
          return data;
        },
        commit,
        dispatch,
        "OPPORTUNITIES",
        true
      );
    },

    async SUPPORT_TICKETS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getSupportTickets(payload);
          commit("SET_SUPPORT_TICKETS", data.data);
          return data;
        },
        commit,
        dispatch,
        "SUPPORT_TICKETS",
        true
      );
    },

    async LOYALTY_POINTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getLoyalty(payload);
          commit("SET_LOYALTY_POINTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "LOYALTY_POINTS",
        true
      );
    },

    async RESET_APP_USER_PASSWORD({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.resetAppUserPassword(payload);
          return data;
        },
        commit,
        dispatch,
        "RESET_APP_USER_PASSWORD",
        true
      );
    },
  },
};
