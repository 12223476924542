<template>
  <v-card class="pb-5">
    <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
      Callbacks Notes
    </v-card-title>
    <v-divider class="my-5"></v-divider>
    <v-card-text class="px-7 py-0">
      <template v-if="notes.length > 0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left font-14 line-h-17 font-weight--bold text-black-200">Date</th>
                <th class="text-left font-14 line-h-17 font-weight--bold text-black-200">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in notes">
                <template v-for="(value, key) in item.new_values">
                  <tr v-if="key === 'comment'">
                    <td>
                      <div class="d-flex flex-column">
                        <p class="mb-0 font-14 line-h-17 font-weight--regular text-black-300">
                          {{ item.created_at }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <p class="font-16 line-h-21 font-weight--regular text-black-100 mb-1">
                          {{ value }}
                        </p>
                        <p class="font-12 line-h-16 font-weight--regular text-black-200">
                          By {{ item.owner_name }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <template v-else>
        <p>No records found!</p>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "WidgetCallbackNotes",
  data() {
    return {
      notes: [],
    };
  },
  mounted() {
    this.getNotes();
  },
  methods: {
    async getNotes() {
      this.loading = true;
      await this.$store
        .dispatch("contacts/CALLBACK_NOTES", { user_id: this.$router.currentRoute.params.id })
        .then((res) => {
          this.notes = res.data;
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="css" scoped></style>
