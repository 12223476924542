<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Edit Template Shift</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">Start Time</span>
                </label>

                <v-menu
                  v-model="menu.startTime"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="formObj.start_time"
                      :rules="rules.start_time"
                      solo
                      outlined
                      v-on="on"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu.startTime"
                    :allowed-minutes="allowedStep"
                    no-title
                    ampm-in-title
                    format="24hr"
                    v-model="formObj.start_time"
                    full-width
                    @click:minute="menu.startTime = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">End Time</span>
                </label>

                <v-menu
                  v-model="menu.endTime"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="formObj.end_time"
                      solo
                      outlined
                      v-on="on"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                      :rules="rules.end_time"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu.endTime"
                    :allowed-minutes="allowedStep"
                    no-title
                    ampm-in-title
                    format="24hr"
                    v-model="formObj.end_time"
                    full-width
                    @click:minute="menu.endTime = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="edit"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    menu: {
      startTime: false,
      endTime: false,
    },
    formObj: {
      start_time: null,
      end_time: null,
    },
    rules: {
      start_time: [(v) => !!v || "Please enter a start time"],
      end_time: [(v) => !!v || "Please enter a end time"],
    },
  }),
  computed: {
    shiftItem() {
      return this.$store.state.shiftTemplate.item;
    },
  },
  mounted() {
    for (let key in this.formObj) {
      this.formObj[key] = this.shiftItem[key];
    }
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$store.dispatch("shiftTemplate/GET");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async edit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("shiftTemplate/UPDATE", { id: this.shiftItem.id, body: { ...this.formObj } })
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
