import collection from "../../api/collections/Offers";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
  },
  mutations: {
    SET_OFFERS(state, data) {
      state.list = data;
    },
    SET_OFFER_BY_ID(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_OFFERS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },

    async UPDATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );
    },

    async SHOW({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.byId(payload);
          commit("SET_OFFER_BY_ID", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW",
        true
      );
    },

    async CREATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE",
        true
      );
    },

    async delete({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "delete",
        true
      );
    },
  },
};
