import collection from "../../../api/collections/Rooms";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_ROOM(state, data) {
      state.list = data;
    },
    SET_ALL_ROOM(state, data) {
      state.allList = data;
    },
    SET_ROOM_ITEM(state, data) {
      state.item = data;
    },
    RESET_BRANCH(state, data) {
      state.item = {};
    },
    SET_ROOM_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_ROOM({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_ROOM", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ROOM",
        true
      );
    },
    async GET_ALL_BRANCH({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_ROOM", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_ROOM",
        true
      );
    },
    async CREATE_ROOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_ROOM",
        true
      );
      return data;
    },
    async SHOW_ROOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_ROOM_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_ROOM",
        false
      );
      return data;
    },
    async UPDATE_ROOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_ROOM",
        true
      );

      return data;
    },
    async DELETE_ROOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_ROOM",
        true
      );
      return data;
    },
  },
};
