<template>
  <v-card flat :height="750" class="mt-12">
    <v-row class="px-4">
      <v-col cols="12" lg="3">
        <!-- <v-btn fab text small class="prev" color="prev grey darken-2" @click="prev">
          <v-icon large> mdi-chevron-left </v-icon>
        </v-btn> -->

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              flat
              :value="`${dateUKformat(filter.start_date) || ''}-${
                dateUKformat(filter.end_date) || ''
              }`"
              prepend-inner-icon="mdil-calendar"
              select="off"
              solo
              hide-details
              class="search-input"
            >
              <template v-slot:append> </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="getWeekDates(date)"> OK </v-btn>
          </v-date-picker>
        </v-menu>

        <!-- 
        <v-btn fab text small class="next" color=" grey darken-2 mt-1 ml-1" @click="next">
          <v-icon large> mdi-chevron-right </v-icon>
        </v-btn> -->
      </v-col>
      <!-- <v-col cols="0" lg="1" class="d-none d-lg-block"> </v-col> -->
      <v-col cols="4" lg="3">
        <v-select
          prepend-inner-icon="mdi-map-marker-outline"
          placeholder="Select a branch"
          hide-details
          outlined
          dense
          multiple
          clearable
          :items="branches"
          :loading="!branches.length"
          v-model="filter.branch_id"
          @change="loadStaffAndMachine"
          item-value="id"
          item-text="branch"
          class="text-capitalize"
        >
          <template v-slot:prepend-item>
            <v-list-item v-if="branches.length">
              <v-list-item-action>
                <v-checkbox v-model="selectAllBranch"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0">
              <span>{{ item.branch }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filter.consultant_id.length - 1 }} Others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" lg="3">
        <v-select
          v-model="filter.consultant_id"
          :items="staffItems"
          :loading="staffLoading"
          prepend-inner-icon="mdi-account-multiple"
          multiple
          chips
          small-chips
          deletable-chips
          outlined
          select="off"
          dense
          hide-details
          placeholder="Select a consultant"
          clearable
          class="text-capitalize"
        >
          <template v-slot:prepend-item>
            <v-list-item v-if="staffItems.length">
              <v-list-item-action>
                <v-checkbox v-model="selectAllStaff"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filter.consultant_id.length - 1 }} Others)
            </span>
          </template>
        </v-select>
      </v-col>

      <v-col cols="4" lg="3">
        <v-select
          v-model="filter.machine_id"
          :items="machineItems"
          :loading="machineLoading"
          prepend-inner-icon="mdi-tools"
          multiple
          chips
          small-chips
          deletable-chips
          outlined
          select="off"
          dense
          hide-details
          placeholder="Select a machine"
          class="text-capitalize"
        >
          <template v-slot:prepend-item>
            <v-list-item v-if="machineItems.length">
              <v-list-item-action>
                <v-checkbox v-model="selectAllMachines"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filter.machine_id.length - 1 }} Others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-divider :thickness="2" class="my-4"></v-divider>

    <v-card-text v-if="loading" class="d-flex justify-center align-center" style="height: 100%">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-text>

    <v-card-text v-else>
      <Board :week-dates="weekDates" />
    </v-card-text>
  </v-card>
</template>

<script>
import Board from "../../../components/Scheduler/board.vue";
import globalFn from "../../../mixins/globalFn";

export default {
  name: "schedular",
  mixins: [globalFn],
  components: {
    Board,
  },
  data: () => ({
    loading: false,
    date: new Date().toISOString().slice(0, 10),
    user: JSON.parse(localStorage.getItem("user")),
    menu: false,
    weekDates: [],
    filter: {
      start_date: null,
      end_date: null,
      consultant_id: [],
      machine_id: [],
      branch_id: [],
    },
    staffLoading: false,
    machineLoading: false,

    // checkbox
    selectAllBranch: false,
    selectAllStaff: false,
    selectAllMachines: false,
  }),
  computed: {
    branches() {
      return this.user.user_type_name === "Admin"
        ? this.$store.state.branches.allList.map((item) => ({
            ...item,
            branch: this.capitalizeFirstLetterEachWord(item.branch),
          }))
        : this.user.permission_branches.map((item) => ({
            ...item,
            branch: this.capitalizeFirstLetterEachWord(item.branch),
          }));
      // return this.$store.state.branches.allList.map((item) => ({
      //   text: item.branch,
      //   value: item.id,
      // }));
    },
    staffItems() {
      return this.$store.state.staff.consultants.map((item) => ({
        text: this.capitalizeFirstLetterEachWord(item.name),
        value: item.id,
      }));
    },
    machineItems() {
      return this.$store.state.machines.all.map((item) => ({
        text: this.capitalizeFirstLetterEachWord(item.name),
        value: item.id,
      }));
    },
  },
  mounted() {
    this.loading = true;
    this.getWeekDates(new Date());

    this.filter.branch_id = this.user.user_type_name === "Admin" ? [] : [this.user.branch_id];

    this.fetchRota().finally(() => (this.loading = false));
    this.$store.dispatch("staff/GET_CONSULTANTS", {
      attributes: "permissionBranches",
      branch_id: this.user.user_type_name === "Admin" ? [] : [this.user.branch_id],
    });
    this.$store.dispatch("machines/GET_MACHINE_LIST", {
      branch_id: this.user.user_type_name === "Admin" ? [] : [this.user.branch_id],
    });
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("rooms/GET_ROOM", `?attributes=treatments&sort_order=ASC&sort_by=id`);
    this.$root.$on("fetchRota", this.fetchRota);
  },
  watch: {
    filter: {
      handler: function (val, oldVal) {
        this.$store.dispatch("rota/GET", this.filter);
      },
      deep: true,
    },
    selectAllStaff(newVal) {
      if (newVal) {
        this.filter.consultant_id = [];
        this.staffItems.forEach((item) => {
          this.filter.consultant_id.push(parseInt(item.value));
        });
      } else {
        this.filter.consultant_id = [];
      }
    },
    selectAllMachines(newVal) {
      if (newVal) {
        this.filter.machine_id = [];
        this.machineItems.forEach((item) => {
          this.filter.machine_id.push(parseInt(item.value));
        });
      } else {
        this.filter.machine_id = [];
      }
    },
  },
  methods: {
    async fetchRota() {
      await this.$store.dispatch("rota/GET", this.filter);
    },
    getWeekDates(date) {
      let standardDate = new Date(date);
      let dayOfWeek = standardDate.getDay();

      let sundayDate = new Date(date);
      sundayDate.setDate(standardDate.getDate() - dayOfWeek);

      let weekDates = [];

      for (let i = 0; i < 7; i++) {
        let date = new Date(sundayDate);
        date.setDate(sundayDate.getDate() + i);
        let formattedDate = date.toISOString().split("T")[0];

        weekDates.push(formattedDate);
      }

      this.filter.start_date = weekDates[0];
      this.filter.end_date = weekDates[weekDates.length - 1];

      this.weekDates = weekDates;
      this.menu = false;
    },
    async loadStaffAndMachine() {
      this.staffLoading = true;
      this.machineLoading = true;

      await this.$store
        .dispatch("staff/GET_CONSULTANTS", {
          attributes: "permissionBranches",
          branch_id: this.filter.branch_id,
        })
        .finally(() => (this.staffLoading = false));

      await this.$store
        .dispatch("machines/GET_MACHINE_LIST", { branch_id: this.filter.branch_id })
        .finally(() => (this.machineLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
