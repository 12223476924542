<template>
  <div>
    <component :is="loadComponent" :screen="screen" :slug="slug"></component>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Search",
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  computed: {
    loadComponent() {
      if (!this.slug || this.slug === "settings") {
        return require("./SearchMolecules/General.vue").default;
      }
      return require(`./SearchMolecules/${this.screen.replace(/\s/g, "")}.vue`).default;
    },
  },
};
</script>
