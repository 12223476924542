export default (to, from, next) => {
  if (localStorage.getItem("token") !== null && localStorage.getItem("token").length > 0) {
    next();
    // if (localStorage.getItem('role') !== null && (localStorage.getItem('role') === 'Admin' || localStorage.getItem('role') === 'Staff')) {
    //   next()
    // } else {
    //   next('/auth/login')
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('role')
    // }
  } else {
    next("/auth/login");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  }
};
