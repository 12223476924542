<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-sheet class="pt-0" height="80vh">
              <div class="pa-5" v-if="selectedContact">
                <div class="dd-flex">
                  <p class="font-18 line-h-22 text-black-100 font-weight--bold me-2">
                    Booking an Appointment for:
                  </p>
                  <v-chip
                    small
                    color="#221F20"
                    class="este-chip mb-2 float-right"
                    text-color="#FFFFFF"
                    @click="clear"
                  >
                    Reset
                  </v-chip>
                </div>
                <div class="d-flex my-4">
                  <span
                    class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                    v-if="selectedContact.user && selectedContact.user.name"
                  >
                    {{ selectedContact.user.name }}
                  </span>
                  <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                    <img
                      :src="`/images/${selectedContact.user.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="selectedContact.user.source_name !== null"
                    />
                    {{ selectedContact.user.social_handle }}
                  </span>
                </div>
                <div class="d-flex">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                    :class="
                      selectedContact.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                    "
                    >{{ selectedContact.user.user_type_name }}</span
                  >
                  <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
                    <span class="mr-1">
                      <inline-svg src="/images/icons/map.svg" />
                    </span>
                    {{ selectedContact.user.branch_name }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="selectedContact.user.email"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/at.svg" />
                    </span>
                    {{ selectedContact.user.email }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="selectedContact.user.contact_no"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/mobile.svg" />
                    </span>
                    {{ selectedContact.user.contact_no }}
                    <v-tooltip
                      content-class="copy-tooltip"
                      :open-on-hover="false"
                      :open-on-click="true"
                      color="#000"
                      text-color="#fff"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="copyContent(selectedContact.user.contact_no)"
                          v-on="on"
                          size="15"
                          class="ml-2"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copied to clipboard</span>
                    </v-tooltip>
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="selectedContact.user.social_handle !== null"
                  >
                    <img
                      :src="`/images/${selectedContact.user.source_name.toLowerCase()}.svg`"
                      height="15"
                      class="mr-2"
                      v-if="selectedContact.user.source_name !== null"
                    />
                    {{ selectedContact.user.social_handle }}
                  </span>
                </div>
              </div>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
              </v-overlay>
              <!-- <div v-if="categories.length === 0">
                <p class="font-14 lh-18 font-weight--semibold text-red-100 pl-5">
                No consultants/machines are available for the chosen branch; consider selecting a different branch from the advanced filters.</p>
              </div> -->
              <v-calendar
                :weekdays="weekday"
                :event-overlap-mode="mode"
                :first-interval="firstInterval"
                :event-overlap-threshold="30"
                :interval-count="intervalCount"
                interval-minutes="15"
                class="custom-calender pa-0"
                ref="calendar"
                v-model="focus"
                :events="events"
                :event-more="true"
                :event-ripple="false"
                category-show-all
                :categories="categories"
                category-text="name"
                :event-color="getEventColor"
                :type="type"
                @click:event="viewEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
                @mousemove:time-category="hoverToSeeTime"
                @mouseleave:time-category="removeHover"
                interval-width="100"
              >
                <template v-slot:category="{ category }">
                  <div class="category-wrap">
                    <div class="d-flex">
                      <div
                        class="width-40 height-40 bg-gray-300 text-white-100 font-20 font-weight--bold d-flex rounded-100 mx-auto align-center justify-center mr-2"
                      >
                        {{ getInitials(category.name) }}
                      </div>
                      <p class="my-3 font-16 lh-18 font-weight--bold text-black-100 text-center">
                        {{ category.name | capitalize }}
                      </p>
                    </div>
                  </div>
                </template>
                <template v-slot:interval="{ date, time, category }">
                  <div
                    :class="
                      isSlotAvailable(date, time, category)
                        ? 'shift-available'
                        : 'no-shift-available'
                    "
                  ></div>
                </template>
                <template v-slot:day-body="{ date, week, timeToY, category }">
                  <div
                    v-if="category.name == hoverCategory && date === hoverDate && showSlot"
                    class="slot-hover"
                    :style="{ top: `${timeToY(hoverY)}px` }"
                  >
                    <p
                      class="pa-2 mt-2 ml-2 bg-gold-100 cursor"
                      @click="markSlot(date, week, timeToY(hoverY), category)"
                    >
                      <span class="mr-2">{{ hoverY }}</span>
                      <span>{{ category.name | capitalize }}</span>
                    </p>
                  </div>
                </template>
                <template v-slot:event="{ event }">
                  <v-menu
                    v-model="event.menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    max-width="400"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="job-card" v-bind="attrs" v-on="on" @click.stop>
                        <span class="title-slot">
                          {{ event.name | capitalize }}
                        </span>

                        <span class="text-white-100"
                          >({{ event.treatment_name | capitalize }})</span
                        >
                        <span class="time-slot">{{ event.from }} - {{ event.to }}</span>
                      </div>
                    </template>
                    <v-card max-width="400" class="rounded-6">
                      <v-list class="pa-3 bg-surface-100 position-relative">
                        <v-icon
                          class="position-absolute cursor"
                          style="right: 20px; z-index: 2"
                          @click="event.menu = false"
                          >mdi-close</v-icon
                        >
                        <v-list-item>
                          <v-list-item-avatar>
                            <div
                              class="width-40 height-40 bg-gray-300 text-white-100 font-20 font-weight--bold d-flex rounded-100 mx-auto align-center justify-center"
                              v-if="event.user.name"
                            >
                              {{ getInitials(event.user.name) }}
                            </div>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="cursor"
                              :class="{ 'text-decoration-underline': event.hovered }"
                              @mouseover="event.hovered = true"
                              @mouseout="event.hovered = false"
                              @click="viewContact(event.user.id)"
                              >{{ event.user.name | capitalize }}</v-list-item-title
                            >
                            <v-list-item-subtitle class="d-flex align-center">
                              <span
                                class="font-14 line-h-17 font-weight--bold text-black-100"
                                v-if="event.user.contact_no"
                              >
                                {{ event.user.contact_no }}
                                <v-tooltip
                                  content-class="copy-tooltip"
                                  :open-on-hover="false"
                                  :open-on-click="true"
                                  color="#000"
                                  text-color="#fff"
                                  top
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      @click="copyContent(event.user.contact_no)"
                                      v-on="on"
                                      size="15"
                                      >mdi-content-copy</v-icon
                                    >
                                  </template>
                                  <span>Copied to clipboard</span>
                                </v-tooltip>
                              </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="d-flex align-center">
                              <span
                                class="font-13 line-h-16 font-weight--semibold text-black-300"
                                v-if="event.user.email"
                              >
                                {{ event.user.email }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list class="pa-3 bg-surface-100 height-150 d-flex flex-column">
                        <v-list-item>
                          <div class="status__wrap">
                            <span
                              class="cursor"
                              :class="event.status === 49 ? 'waiting' : 'bg-white-100'"
                              @click="markStatus(event, 49)"
                            >
                              <v-icon size="17">mdi-clock-outline</v-icon>
                            </span>
                            <span
                              class="cursor"
                              :class="event.status === 47 ? 'arrived' : 'bg-white-100'"
                              @click="markStatus(event, 47)"
                            >
                              <v-icon size="17">mdi-home-outline</v-icon>
                            </span>
                            <span
                              class="cursor"
                              :class="event.status === 18 ? 'complete' : 'bg-white-100'"
                              @click="markStatus(event, 18)"
                            >
                              <v-icon size="17">mdi-check-circle-outline</v-icon>
                            </span>
                            <span
                              class="cursor"
                              :class="event.status === 48 ? 'running-late' : 'bg-white-100'"
                              @click="markStatus(event, 48)"
                            >
                              <v-icon size="17">mdi-timer-sand-empty</v-icon>
                            </span>
                          </div>
                        </v-list-item>
                        <v-list-item>
                          <div class="d-flex align-center justify-space-between w-100">
                            <v-btn
                              class="bg-white-100 text-black-100 letter-s-0 height-40 text-capitalize width-220"
                              depressed
                              small
                              @click="editAppointment(event)"
                            >
                              <v-icon size="17">mdi-square-edit-outline</v-icon> Edit Appointment
                            </v-btn>
                            <v-btn
                              class="bg-red-100 text-white-100 letter-s-0 height-40 text-capitalize width-100"
                              depressed
                              small
                            >
                              <v-icon size="17">mdi-close</v-icon> Cancel
                            </v-btn>
                          </div>
                        </v-list-item>
                      </v-list>
                      <v-card-actions class="pa-3">
                        <v-btn
                          block
                          color="secondary"
                          class="b-blue letter-s-0 height-40 text-capitalize"
                          depressed
                          rounded
                          small
                        >
                          Checkout
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
              </v-calendar>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import eventBus from "../../../eventBus.js";
import sliderFn from "../../../mixins/sliderFn";
import globalFn from "../../../mixins/globalFn";
export default {
  mixins: [sliderFn, globalFn],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    focus: "",
    selectedContact: "",
    intervalCount: 87,
    firstInterval: 6,
    screen: "",
    mode: "column",
    type: "category",
    overlay: false,
    showSlot: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    filters: {
      attributes: "user,treatments,consultant",
      date: null,
      branches: [],
      consultants: [],
      treatments: [],
      machines: [],
    },
    consultants: [],
    staff: [],
    categories: [],
    appData: [],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    isHover: false,
    hoverY: "",
    hoverDate: "",
    hoverCategory: "",
    hoverCategoryIsMachine: "",
  }),
  computed: {
    members() {
      let data = this.$store.state.staff.consultants;
      return data.map(function (el) {
        let item = Object.assign({}, el);
        item.isMachine = 0;
        return item;
      });
    },
    machines() {
      let data = this.$store.state.machines.all;
      return data.map(function (el) {
        let item = Object.assign({}, el);
        item.isMachine = 1;
        return item;
      });
    },
    consultantShifts() {
      return this.$store.state.staff.shifts;
    },
    machineShifts() {
      return this.$store.state.machines.shifts;
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
    eventBus.$on("PrevAction", this.PrevAction);
    eventBus.$on("NextAction", this.NextAction);
    eventBus.$on("loadDataForDateRange", this.updateRange);
    eventBus.$on("searchCalendarAppointments", this.filterCalendarAppointments);
    eventBus.$on("setDateForCalendar", this.setDateForCalendar);
    eventBus.$on("refreshCalendarData", this.getAppointmentsData);
  },
  created() {
    window.Echo.channel("new-appointment").listen(".appointment-created", (e) => {
      let res = [];
      res.type = "success";
      res.error = {};
      res.error.details = [];
      res.error.details = e.data.treatments;
      let name = e.data.name ? e.data.name : `@ ${e.data.social_handle}`;
      res.message =
        e.data.treatments.length > 0
          ? `New Appointment Added: ${name} for`
          : `New Appointment Added: ${name}`;
      let from = new Date(this.filters.start_date);
      let to = new Date(this.filters.end_date);
      let check = new Date(e.data.callback_date);
      if (this.user.user_type_name === "Admin") {
        this.$store.dispatch("snackbar/SHOW", res);
        this.loadCategories();
      } else if (
        check >= from &&
        check <= to &&
        (this.user.permission_branches.some((el) => el.id === e.data.branch_id) ||
          this.user.branch_id === e.data.branch_id)
      ) {
        this.$store.dispatch("snackbar/SHOW", res);
        this.loadCategories();
      }
    });
  },
  methods: {
    clear() {
      localStorage.removeItem("toBeScheduleAppointment");
      this.selectedContact = null;
      this.loadBranches();
    },
    setDateForCalendar(data) {
      console.log("setDateFor");
      console.log(data);
      this.focus = data.date;
    },
    hoverToSeeTime({ date, time, category }) {
      if (this.hoverCat) {
        this.hoverCat = "";
      }
      //console.log("work")
      //takes the date.time last two digits to manipulate the start and end
      let lastTwoTimeDigit = time.split(":");
      if (lastTwoTimeDigit[1] === "0") {
        lastTwoTimeDigit[1] + "0";
      }
      let lastTwoTimeDigit2 = Number(lastTwoTimeDigit[1]);

      //start and end function helps find the start and end time position of tile
      let start = this.findStart(lastTwoTimeDigit2);
      if (`${start}` === "0") {
        start = `${start}` + "0";
      }
      this.hoverY = `${lastTwoTimeDigit[0]}:${start}`;
      this.hoverDate = date;
      this.hoverCategory = category.name;
      this.hoverCategoryIsMachine = category.isMachine;

      console.log(category.isMachine);
      console.log(this.hoverCategoryIsMachine);

      const isEvent = this.checkEventAvailability();
      const isShift = this.hoverCategoryIsMachine
        ? this.checkMachineShiftAvailability(this.hoverDate, this.hoverY, this.hoverCategory)
        : this.checkConsultantShiftAvailability(this.hoverDate, this.hoverY, this.hoverCategory);

      this.showSlot = !isEvent && isShift ? true : false;
      this.isHover = true;
    },

    isSlotAvailable(date, time, category) {
      console.log(date, time, category);

      const isShift = category.isMachine
        ? this.checkMachineShiftAvailability(date, time, category.name)
        : this.checkConsultantShiftAvailability(date, time, category.name);

      return isShift;
    },

    checkMachineShiftAvailability(date, time, category) {
      console.log("here");

      for (const shift of this.machineShifts) {
        const targetTimeMs = new Date(`${date} ${time}`).getTime();
        const shiftStartTime = new Date(`${date} ${shift.start_time}`).getTime();
        const shiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();

        if (
          targetTimeMs >= shiftStartTime &&
          targetTimeMs <= shiftEndTime &&
          shift.machine.name === category
        ) {
          return true; // Shift time and category match for this appointment
        }
      }

      return false; // Shift time or category do not match for any appointment
    },

    checkConsultantShiftAvailability(date, time, category) {
      for (const shift of this.consultantShifts) {
        const targetTimeMs = new Date(`${date} ${time}`).getTime();
        const shiftStartTime = new Date(`${date} ${shift.start_time}`).getTime();
        const shiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();

        if (
          targetTimeMs >= shiftStartTime &&
          targetTimeMs <= shiftEndTime &&
          shift.user.name === category
        ) {
          return true; // Shift time and category match for this appointment
        }
      }

      return false; // Shift time or category do not match for any appointment
    },

    checkEventAvailability() {
      for (const appointment of this.events) {
        const targetTimeMs = new Date(`${this.hoverDate} ${this.hoverY}`).getTime();
        const startTime = new Date(appointment.start).getTime();
        const endTime = new Date(appointment.end).getTime();

        if (
          targetTimeMs >= startTime &&
          targetTimeMs <= endTime &&
          appointment.category === this.hoverCategory
        ) {
          return true; // Event time and category match for this appointment
        }
      }

      return false; // Event time or category do not match for any appointment
    },

    removeHover() {
      this.isHover = false;
      this.hoverY = "";
      this.hoverDate = "";
    },

    findStart(lastTwoTimeDigit) {
      const start = lastTwoTimeDigit - (lastTwoTimeDigit % 15);
      return start;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = this.$router.currentRoute.params.screen === "appointments" ? "category" : "day";
    },
    PrevAction() {
      this.$refs.calendar.prev();
    },
    NextAction() {
      this.$refs.calendar.next();
    },
    async filterCalendarAppointments(data) {
      console.log(`search filter`, data);
      if (data) {
        this.filters.date = data.date;
        this.filters.branches = data.branches;
        this.filters.treatments = data.treatments;
        this.filters.consultants = data.consultants;
        this.filters.machines = data.machines;
      } else {
        this.filters.date = null;
        this.filters.branches = [];
        this.filters.treatments = [];
        this.filters.consultants = [];
        this.filters.machines = [];
      }
      await this.loadCategories();
    },
    setBranchId(data) {
      console.log("testing");
      this.filters.branches.push(data);
    },
    async updateRange({ start, end }) {
      eventBus.$emit("updateDaterange", start.date);
      this.filters.date = start.date;
      this.loadBranches();
    },

    async loadBranches() {
      this.selectedContact = JSON.parse(localStorage.getItem("toBeScheduleAppointment"));
      if (this.selectedContact) {
        this.filters.branches.push(this.selectedContact.user.branch_id);
      } else {
        this.filters.branches.push(this.user.branch_id);
      }
      eventBus.$emit("setBranchId", this.filters.branches);
      console.log(this.filters.branches);
      await this.loadCategories();
    },

    async loadCategories() {
      console.log(this.filters.branches);

      console.log(this.members);
      console.log(this.machines);

      const namesArray = [...this.members, ...this.machines];

      this.categories = namesArray.filter((record) =>
        this.filters.branches.includes(record.branch_id)
      );

      console.log(this.categories);
      await this.loadShifts();
      await this.getAppointmentsData();
    },

    async loadShifts() {
      await this.$store.dispatch("machines/GET_MACHINE_SHIFTS", {
        date: this.filters.date,
        branch_id: this.filters.branches,
      });
      await this.$store.dispatch("staff/GET_CONSULTANT_SHIFTS", {
        date: this.filters.date,
        branch_id: this.filters.branches,
      });
    },

    async getAppointmentsData() {
      console.log("aaa");
      console.log(this.filters);
      this.overlay = true;
      this.appData = [];
      this.filters.branches = [...new Set(this.filters.branches)];
      await this.$store
        .dispatch("appointments/GET_CALENDAR_DAY", this.filters)
        .then((res) => {
          if (res.result === "success") {
            res.data = res.data.map(function (el) {
              let item = Object.assign({}, el);
              item.color = "#DFB447";
              item.menu = false;
              item.hovered = false;
              return item;
            });

            this.appData = res.data;
            this.events = this.appData;
            this.overlay = false;
          } else if (res.result === "fail") {
            this.loader = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loader = false;
        });
    },
    getEventColor(event) {
      return event.color;
    },
    async viewEvent(event) {
      console.log(event);
      // const routeData = this.$router.resolve({ path: `/contact/${event.event.user_id}` });
      // window.open(routeData.href, '_blank');
    },
    async viewContact(id) {
      const routeData = this.$router.resolve({ path: `/contact/${id}` });
      window.open(routeData.href, "_blank");
    },
    markSlot(date, week, timeToY, category) {
      console.log(date, week, timeToY, category);
      console.log(this.hoverDate, this.hoverCategory, this.hoverY);
      const data = {};
      data.date = this.hoverDate;
      data.category = category;
      data.fromTime = this.hoverY;

      this.openDrawer("add", "750", "calendar/appointment");

      setTimeout(function () {
        eventBus.$emit("calendarSlotData", data);
        eventBus.$emit(
          "toBeScheduleAppointment",
          JSON.parse(localStorage.getItem("toBeScheduleAppointment"))
        );
      }, 500);
    },

    async editAppointment(event) {
      event.menu = false;
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: event.appointment_id,
          param: "?attributes=user,treatments,consultations,consultant,branch",
        })
        .then((res) => {
          this.openDrawer("add", "750", "calendar/editAppointment");
        });
    },
    async markStatus(event, status) {
      console.log(event, status);
      await this.$store
        .dispatch("appointments/UPDATE_STATUS", {
          id: event.appointment_id,
          status: status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.getAppointmentsData();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
