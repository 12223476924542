<template>
  <v-card class="pb-5">
    <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
      Appointments
    </v-card-title>
    <v-divider class="my-5"></v-divider>
    <v-card-text class="px-7 py-0">
      <template v-if="appointments.length > 0">
        <div v-for="(item, index) in appointments" :key="index">
          <div class="d-flex justify-space-between align-center mb-5">
            <div class="d-flex flex-column appointment--chip">
              <p class="ml-3 font-20 line-h-20 font-weight--regular text-black-600 mb-2">
                {{ item.start_date | moment("MMMM Do YYYY") }} {{ item.start_time }} –
                {{ item.end_time }}
                <span
                  class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 border"
                  >{{ item.appointment_status }}</span
                >
              </p>
              <p class="ml-3 font-16 line-h-20 font-weight--regular text-gray-300 mb-2">
                {{ item.service }}
              </p>
              <p class="ml-3 font-14 line-h-18 font-weight--regular text-black-500 mb-2">
                With
                <span class="text-gold-100">{{ item.staff_name }}</span>
              </p>
            </div>
            <!-- <div>
							<v-btn :elevation="0" class="bg-gray-100 font-12 line-h-15 font-weight--bold text-capitalize px-4 py-2">View</v-btn>
						</div> -->
          </div>
        </div>
      </template>
      <template v-else>
        <p>No records found!</p>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "widgetAppoinments",
  data() {
    return {
      appointments: [],
    };
  },
  mounted() {
    this.getPabauAppointments();
  },
  methods: {
    async getPabauAppointments() {
      this.loading = true;
      await this.$store
        .dispatch("contacts/APPOINTMENTS", { user_id: this.$router.currentRoute.params.id })
        .then((res) => {
          this.appointments = res.data;
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="css" scoped></style>
