<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <div class="name-column">
                  <template v-if="item.user.client_satisfaction !== null">
                    <img src="/images/happy.svg" v-if="item.user.client_satisfaction === 'happy'" />
                    <img
                      src="/images/sad.svg"
                      v-else-if="item.user.client_satisfaction === 'sad'"
                    />
                    <img
                      src="/images/neutral.svg"
                      v-else-if="item.user.client_satisfaction === 'neutral'"
                    />
                    <img src="/images/vip-gold.svg" v-else />
                  </template>
                </div>
                <div class="name-column">
                  <div class="user-name" v-if="item.user.name">
                    {{ item.user.name }}
                  </div>
                  <div class="d-flex user-name" v-if="!item.user.name && item.user.social_handle">
                    <img
                      :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="item.user.source_name !== null"
                    />
                    <span> @{{ item.user.social_handle }} </span>
                  </div>
                  <p class="user-location">
                    {{ item.user.branch_name }}
                  </p>
                </div>
              </template>
              <template v-slot:item.phone="{ item }">
                <p
                  class="mt-4 ml-n1"
                  v-if="item.user.contact_no !== null && item.user.contact_no !== ''"
                >
                  <span class="contact">
                    <a :href="`tel:${item.user.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.user.contact_no }}</a
                    >
                  </span>
                </p>
                <p
                  class="mt-4 ml-n1 mb-4"
                  v-if="item.user.email !== null && item.user.email !== ''"
                >
                  <span class="contact">
                    <a :href="`mailto:${item.user.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.user.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.date_raised="{ item }">
                <p class="date-sec date-mute" v-if="item.date_raised !== null">
                  <v-btn class="badge badge-grey" icon>
                    <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                  </v-btn>
                  {{ item.date_raised | moment("DD/MM/YY") }}
                </p>
                <template v-if="item.next_action_date !== null">
                  <p class="date-sec vertical-bar">|</p>
                  <p class="date-sec">
                    <v-btn class="badge badge-grey" icon>
                      <v-icon size="20" color="black">mdil-calendar</v-icon>
                    </v-btn>
                    {{ item.next_action_date | moment("DD/MM/YY") }}
                  </p>
                </template>
              </template>
              <template v-slot:item.category="{ item }">
                <v-chip
                  small
                  class="este-chip tag-chip"
                  text-color="black"
                  v-if="item.category !== null"
                >
                  {{ item.category | capitalize }}
                </v-chip>
              </template>
              <template v-slot:item.next_action_name="{ item }">
                <v-chip
                  small
                  class="este-chip tag-chip"
                  text-color="black"
                  v-if="item.next_action_name !== null"
                >
                  {{ item.next_action_name | capitalize }}
                </v-chip>
              </template>
              <template v-slot:item.urgency_name="{ item }">
                <v-chip
                  small
                  class="este-chip status-chip"
                  text-color="#221F20"
                  :color="priorityColor(item.urgency)"
                  v-if="item.urgency_name !== null"
                >
                  {{ item.urgency_name | capitalize }}
                </v-chip>
              </template>
              <template v-slot:item.responsible="{ item }">
                <div class="profile-icon" alt="avatar">
                  <p>{{ getInitials(item.responsible.name) }}</p>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('complaint');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    :outlined="stat.outline"
                    :text-color="stat.text ? stat.color : '#221F20'"
                    :color="stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Complaint</a></li>
                      <li><a @click="markItem(item)">Mark as Closed</a></li>
                      <li>
                        <a class="text-mute" @click="archiveItem(item)" v-if="item.status !== 7"
                          >Archive Complaint</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "Complaints",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Contact Details", value: "phone", align: "left", sortable: false },
        { text: "Complaint Type", value: "category", align: "left", sortable: false },
        { text: "Next Action", value: "next_action_name", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        { text: "Date Raised", value: "date_raised", sortable: false, align: "left" },
        { text: "Priority", value: "urgency_name", align: "left", sortable: false },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      ComplaintParams: {
        attributes: "user,responsible",
        search: "",
        filter: {
          date_raised_from: "",
          date_raised_to: "",
          next_action_date_from: "",
          next_action_date_to: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
      },
      showComplaintParams: "?attributes=user,responsible,documents,comments",
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("complaintsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchComplaints", this.getSearchComplaints);
  },
  created() {},
  methods: {
    async getSearchComplaints(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.ComplaintParams.search = data.search;
        this.ComplaintParams.filter.date_raised_from = data.date_raised_from;
        this.ComplaintParams.filter.date_raised_to = data.date_raised_to;
        this.ComplaintParams.filter.next_action_date_from = data.next_action_date_from;
        this.ComplaintParams.filter.next_action_date_to = data.next_action_date_to;
        this.ComplaintParams.filter.status = data.status;
        this.ComplaintParams.filter.branch = data.branch;
        this.ComplaintParams.filter.source = data.source;
        this.ComplaintParams.filter.assigned = data.assigned;
        this.ComplaintParams.filter.assigned_to = data.assigned_to;
        this.ComplaintParams.filter.source_blank = data.source_blank;
        await this.readTheDataFromApi();
      } else {
        this.ComplaintParams.search = "";
        this.ComplaintParams.filter.date_raised_from = "";
        this.ComplaintParams.filter.date_raised_to = "";
        this.ComplaintParams.filter.next_action_date_from = "";
        this.ComplaintParams.filter.next_action_date_to = "";
        this.ComplaintParams.filter.status = [];
        this.ComplaintParams.filter.branch = [];
        this.ComplaintParams.filter.source = [];
        this.ComplaintParams.filter.assigned = [];
        this.ComplaintParams.filter.assigned_to = 0;
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("complaints/GET_COMPLAINT", {
          data: this.ComplaintParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      await this.$store
        .dispatch("complaints/SHOW_COMPLAINT", { id: item.id, param: this.showComplaintParams })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "complaints/editPane");
          } else {
            this.openDrawer("add", "750", "complaints/editTab");
          }
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async markItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = " Please confirm you want to close this complaint?";
      this.actionText = "Confirm";
      this.action = "markAsClosed";
    },
    async markAsClosed() {
      this.selectedItem.status = 10;
      await this.$store
        .dispatch("complaints/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this complaint?";
      this.actionText = "Archive";
      this.action = "archiveComplaint";
    },
    async archiveComplaint() {
      this.selectedItem.status = 7;
      await this.$store
        .dispatch("complaints/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
