export default {
  data: () => ({
    loading: false,
    activeIndex: null,
    showAddPayment: false,
    showPaymentHistory: true,
    enquiryMenu: false,
    followUpMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    addedCustomerId: 0,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    email: "",
    form: {
      title: "",
      name: "",
      address_one: "",
      address_two: "",
      city: "",
      postcode: "",
      contact_no: "",
      branch_id: "",
      email: "",
      client_satisfaction: "",
      symptoms: [],
      service_recommended: [],
      status: 1,
      responsible_id: "",
      is_receive_sms: 0,
      source_id: 0,
      social_handle: "",
    },
    isDealFormChanged: false,
    dealForm: {
      user_id: 0,
      deal_info: "",
      treatments: [],
      total: "",
      soldby_id: "",
      no_of_sessions: 0,
    },
    rules: {
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      total: [(v) => !!v || "Please enter deal total"],
      sessions: [(v) => !!v || "Please select sessions"],
      deal_info: [(v) => !!v || "Please enter deal information"],
      treatments: [(v) => !!v || "Please select treatments"],
    },
    satisfaction: [
      {
        name: "Sad",
        slug: "sad",
        image: "images/sad.svg",
      },
      {
        name: "Neutral",
        slug: "neutral",
        image: "images/neutral.svg",
      },
      {
        name: "Happy",
        slug: "happy",
        image: "images/happy.svg",
      },
      {
        name: "VIP",
        slug: "vip",
        image: "images/vip.svg",
      },
    ],
    suggestedTreatments: [],
    staff: [],
    staffParams: {
      branch: "",
      type: "",
    },
  }),
  watch: {
    "dealForm.deal_info": function (newVal, oldVal) {
      if (newVal) {
        this.isDealFormChanged = true;
      }
    },
    "dealForm.no_of_sessions": function (newVal, oldVal) {
      if (newVal) {
        this.isDealFormChanged = true;
      }
    },
    "dealForm.total": function (newVal, oldVal) {
      if (newVal) {
        this.isDealFormChanged = true;
      }
    },
    "dealForm.treatments": function (newVal, oldVal) {
      if (newVal) {
        this.isDealFormChanged = true;
      }
    },
  },
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
  },
  mounted() {
    this.loadSoldBy();
    this.getStaffList();
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
    this.$store.dispatch("source/GET_SOURCE", "");
  },
  methods: {
    loadSoldBy() {
      this.dealForm.soldby_id = this.user.id;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("clientsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async removeTreatment(item) {
      const index = this.dealForm.treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.dealForm.treatments.splice(index, 1);
      } else {
        const i = this.dealForm.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.dealForm.treatments.splice(i, 1);
      }
    },
    async removeService(item) {
      const index = this.form.service_recommended.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.form.service_recommended.splice(index, 1);
      } else {
        const i = this.form.service_recommended.findIndex((x) => x === item.id);
        if (i >= 0) this.form.service_recommended.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.form.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.form.symptoms.splice(index, 1);
      } else {
        const i = this.form.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.form.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeReason(item) {
      const index = this.form.reasons.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.form.reasons.splice(index, 1);
      } else {
        const i = this.form.reasons.findIndex((x) => x === item.id);
        if (i >= 0) this.form.reasons.splice(i, 1);
      }
    },
    async symptomChange() {
      const ids = [];
      this.form.symptoms.forEach((value) => {
        if (value.id) {
          ids.push({ id: value.id });
        } else {
          ids.push(value);
        }
      });
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", { symptoms: ids })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async staffByBranch() {
      this.staffParams.branch = this.form.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async validate() {
      if (this.form.contact_no === "" && this.form.email === "") {
        let response = [];
        response.type = "error";
        response.message = "Please enter either email address/contact number";
        this.$store.dispatch("snackbar/SHOW", response);
        this.tab = "personal";
        this.showTab("personal");
        this.$refs.form.validate();
      } else {
        this.tab = "personal";
        this.showTab("personal");
        if (this.$refs.form.validate()) {
          if (this.isDealFormChanged === true) {
            this.tab = "deal";
            if (this.$refs.dealForm.validate()) {
              if (this.dealForm.treatments.length > 1) {
                let response = [];
                response.type = "error";
                response.message = "Please choose only one treatment per Deal";
                this.$store.dispatch("snackbar/SHOW", response);
              } else {
                const deal = true;
                this.createClient(deal);
              }
            }
          } else {
            const deal = false;
            this.createClient(deal);
          }
        }
      }
    },
    async createClient(deal) {
      let formData = {};
      formData.title = this.form.title;
      formData.name = this.form.name;
      formData.address_one = this.form.address_one;
      formData.address_two = this.form.address_two;
      formData.city = this.form.city;
      formData.postcode = this.form.postcode;
      formData.branch_id = this.form.branch_id;
      formData.responsible_id = this.form.responsible_id;
      formData.social_handle = this.form.social_handle;
      if (this.form.email) {
        formData.email = this.form.email;
      }
      if (this.form.contact_no) {
        formData.contact_no = this.form.contact_no;
      }
      if (this.form.source_id) {
        formData.source_id = this.form.source_id;
      }

      formData.client_satisfaction = this.form.client_satisfaction;
      formData.status = this.form.status;
      formData.is_receive_sms = this.form.is_receive_sms;

      formData.service_recommended = [];
      this.form.service_recommended.forEach((value) => {
        if (value.id) {
          formData.service_recommended.push(value.id);
        } else {
          formData.service_recommended.push(value);
        }
      });

      formData.symptoms = [];
      this.form.symptoms.forEach((value) => {
        if (value.id) {
          formData.symptoms.push(value.id);
        } else {
          formData.symptoms.push(value);
        }
      });

      this.loading = true;
      await this.$store
        .dispatch("clients/CREATE_CLIENT", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.addedCustomerId = response.data.id;
            if (deal) {
              this.createDeal();
            } else {
              this.loadData();
            }
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async createDeal() {
      this.dealForm.user_id = this.addedCustomerId;
      await this.$store
        .dispatch("deals/CREATE_DEAL", this.dealForm)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.form.client_satisfaction = item.name.toLowerCase();
    },
    async suggTreat(item) {
      let exists = this.form.service_recommended.some((el) => el.id === item.id)
        ? true
        : this.form.service_recommended.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.form.service_recommended.push(item.id);
      } else {
        const index = this.form.service_recommended.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.form.service_recommended.splice(index, 1);
        } else {
          const i = this.form.service_recommended.findIndex((x) => x === item.id);
          if (i >= 0) this.form.service_recommended.splice(i, 1);
        }
      }
    },
  },
};
