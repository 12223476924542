<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              :single-expand="true"
              :expanded.sync="expanded"
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.deal_info="{ item }">
                <div class="py-2">
                  <div
                    class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor width-400 py-2"
                  >
                    <span class="font-13 line-h-17 font-weight--bold text-black-100 ml-2">
                      {{ item.deal_info }}
                    </span>
                  </div>
                  <div class="d-flex align-center mt-2 ml-2">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.client?.name }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                    >
                      {{ item.client.branch_name }}</span
                    >
                  </div>
                </div>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
                  {{ item.created_at | moment("DD/MM/YY") }}
                </span>
              </template>
              <template v-slot:item.total="{ item }">
                <p class="total mb-2 font-13 line-h-16 font-weight--regular">
                  {{ item.total | currency("£") }}
                </p>
                <p
                  class="total red--text font-13 line-h-16 font-weight--regular"
                  v-if="item.latest_payment !== null"
                >
                  B: {{ item.latest_payment.amount | currency("£") }}
                </p>
                <p class="total green--text font-13 line-h-16 font-weight--regular" v-else>
                  B: £0.00
                </p>
              </template>
              <template v-slot:item.points_earned="{ item }">
                <!-- <p class="total mb-2"><span class="tiny-text">Total: </span>{{item.points_earned.total_points }}</p> -->
                <!-- <p class="total mb-2 red--text"><span class="tiny-text">Spent: </span>{{item.points_earned.points_spent }}</p> -->
                <p class="total mb-2 green--text font-13 line-h-16 font-weight--regular">
                  <span class="tiny-text">Points: </span>{{ item.points_earned.points_balance }}
                </p>
                <p class="total mb-2 green--text font-13 line-h-16 font-weight--regular">
                  <span class="tiny-text">£: </span
                  >{{ getPoundsValue(item.points_earned.points_balance) }}
                </p>
              </template>
              <template v-slot:item.treatments="{ item }">
                <template v-if="item.deal_treatments.length > 0">
                  <template v-for="tag in item.deal_treatments.slice(0, 1)">
                    <span
                      small
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                      text-color="black"
                    >
                      {{ tag.treatment_name | capitalize }}
                    </span>
                  </template>
                </template>
                <span
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                  v-if="item.deal_treatments.length > 1"
                  >+{{ item.deal_treatments.length - 1 }}</span
                >
              </template>
              <template v-slot:item.sold_by="{ item }">
                <v-tooltip
                  content-class="este-tooltip"
                  color="#000"
                  text-color="#fff"
                  top
                  v-if="item.consultant !== null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.sold_by.name) }}
                    </span>
                  </template>
                  <span v-if="item.responsible !== null">{{ item.sold_by.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.action="{ item }">
                <!-- <span
                  class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 badge--chip border cursor"
                  @click="toggleRow(item)"
                  v-if="!expanded.includes(item)"
                  >View</span
                >
                <v-chip
                  small
                  class="este-chip tag-chip"
                  @click="toggleRow(item)"
                  v-if="expanded.includes(item)"
                >
                  Hide</v-chip
                > -->
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="toggleRow(item)" v-if="!expanded.includes(item)">View</a></li>
                      <li><a @click="toggleRow(item)" v-if="expanded.includes(item)">Hide</a></li>
                      <li><a @click="archiveItem(item)">Archive Deal</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
              <!-- Expand row item here -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expanded-table pa-0">
                  <v-row no-gutters class="child">
                    <v-col cols="12" sm="12" md="6" class="has-border">
                      <label class="login-label">Payments:</label>
                      <payments-history
                        class="mt-6"
                        :dealId="item.id"
                        :userId="item.user_id"
                      ></payments-history>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="has-border">
                      <label class="login-label">Appointments:</label>
                      <appointments-history
                        class="mt-6"
                        :dealId="item.id"
                        :userId="item.user_id"
                      ></appointments-history>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="child">
                    <v-col cols="12" sm="12" md="12" class="no-border">
                      <label class="login-label">Products:</label>
                      <product-history
                        class="mt-6"
                        :dealId="item.id"
                        :userId="item.user_id"
                      ></product-history>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <!-- End expand row item here -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import DealWidgets from "../../../mixins/DealWidgets";
import PaymentsHistory from "../../../components/DealMolecules/Payments.vue";
import AppointmentsHistory from "../../../components/DealMolecules/Appointments.vue";
import ProductHistory from "../../../components/DealMolecules/Products.vue";
export default {
  name: "Complaints",
  components: {
    AppointmentsHistory,
    PaymentsHistory,
    ProductHistory,
  },
  mixins: [globalFn, sliderFn, status, DealWidgets],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "deal_info", align: "left", sortable: false },
        { text: "Type / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Sold By", value: "sold_by", align: "left", sortable: false },
        { text: "Total", value: "total", align: "left", sortable: false },
        { text: "Points Earned", value: "points_earned", align: "left", sortable: false },
        { text: "Date Created", value: "created_at", sortable: false, align: "left" },
        { text: "Actions", value: "action", sortable: false, align: "end" },
      ],
      search: "",
      appointmentId: null,
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      payload: {
        // attributes: "client,soldBy",
        attributes: "client,soldBy,payments,dealTreatments",
        search: "",
        filter: {
          deal_from: "",
          deal_to: "",
          branch: [],
          source: [],
          source_blank: 0,
        },
        widget_filter: "",
      },
      message: "",
      action: "",
      actionText: "",
      expanded: [],
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("searchDeals", this.getsearchDeals);
    this.$root.$on("readTheDataFromApi", this.readTheDataFromApi);
  },
  computed: {
    points() {
      if (this.$store.state.points.item) {
        return this.$store.state.points.item;
      }
    },
  },
  created() {
    this.$store.dispatch("points/GET", "");
  },
  methods: {
    async getsearchDeals(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.payload.widget_filter = "";
      }
      this.payload.search = data.search;
      this.payload.filter.deal_from = data.deal_from;
      this.payload.filter.deal_to = data.deal_to;
      this.payload.filter.branch = data.branch;
      this.payload.filter.source = data.source;
      this.payload.filter.source_blank = data.source_blank;
      // await this.loadDealsCount()
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      this.options.page = 1;
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.payload.widget_filter = item.active ? item.slug : "";
      await this.readTheDataFromApi();
    },
    getPoundsValue(balance) {
      let sum = 0;
      sum = (balance * this.points.amount_for_points) / this.points.points_spend;
      return sum.toFixed(2);
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("deals/GET_ALL_DEAL", {
          data: this.payload,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    handleFunction(funName) {
      this[funName]();
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this deal?";
      this.actionText = "Archive";
      this.action = "archiveDeal";
    },
    async archiveDeal() {
      await this.$store
        .dispatch("deals/DELETE_DEAL", this.selectedItem.id)
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async toggleRow(item) {
      const index = this.expanded.indexOf(item);
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },
  },
};
</script>
