<template>
  <v-card flat>
    <v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
      <v-toolbar-title>
        <div class="form-title">Edit Support Ticket</div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="navigationTab"
      :vertical="vertical"
      class="este__tabs"
      :class="responsiveClass"
    >
      <v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
      <template v-for="(item, index) in navigation">
        <v-tab :href="`#${item.slug}`">
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
    <div class="tab__content__wrapper">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="ticket" :eager="true">
          <div class="content__text">
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div class="d-flex mb-4">
                  <span
                    class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                    v-if="complaint.user && complaint.user.name"
                  >
                    {{ complaint.user.name }}
                  </span>
                  <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                    {{ complaint.user.social_handle }}
                  </span>
                  <template v-for="(val, index) in statusTags">
                    <span
                      class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                      :style="
                        val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                      "
                      :key="index"
                      v-if="complaint.user.status === val.id"
                      >{{ val.name }}</span
                    >
                  </template>
                </div>
                <div class="d-flex">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                    :class="
                      complaint.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                    "
                    >{{ complaint.user.user_type_name }}</span
                  >
                  <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
                    <span class="mr-1">
                      <inline-svg src="/images/icons/map.svg" />
                    </span>
                    {{ complaint.user.branch_name }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="complaint.user.email"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/at.svg" />
                    </span>
                    {{ complaint.user.email }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="complaint.user.contact_no"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/mobile.svg" />
                    </span>
                    {{ complaint.user.contact_no }}
                    <v-tooltip
                      content-class="copy-tooltip"
                      :open-on-hover="false"
                      :open-on-click="true"
                      color="#000"
                      text-color="#fff"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyContent(complaint.user.contact_no)" v-on="on" size="15"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copied to clipboard</span>
                    </v-tooltip>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div>
                  <label>
                    <span class="label-txt">Support Ticket Type:</span>
                  </label>
                </div>
                <v-select
                  autocomplete="off"
                  :readonly="true"
                  :items="ticketTypes"
                  v-model="complaint.support_ticket_type"
                  item-text="label"
                  item-value="id"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <div class="complaint--wrap">
              <v-form ref="complaintForm">
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Date Raised </span>
                    </label>
                    <v-menu
                      v-model="dateRaisedMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          :value="dateUkFormat(complaint.date_raised)"
                          solo
                          outlined
                          v-on="on"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                          append-icon="mdil-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="complaint.date_raised"
                        no-title
                        @input="dateRaisedMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Complaint Type </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="category"
                      v-model="complaint.category_id"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Escalation Level </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="escalation"
                      v-model="complaint.escalation_level"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Responsible </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="staff"
                      :rules="rules.responsible_id"
                      v-model="complaint.responsible_id"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Complaint Overview </span>
                    </label>
                    <v-textarea
                      class="text-area"
                      solo
                      outlined
                      v-model="complaint.description"
                      flat
                      hide-details="auto"
                      rows="5"
                      row-height="25"
                      placeholder="Base of complaint, who it was raised with, what it relates to, actions take so far"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Deals List </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="dealsbyclient"
                      v-model="complaint.deal_id"
                      item-text="deal_info"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                      @change="loadDealInformation(form.deal_id)"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Pabau ID / Link </span>
                    </label>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      v-model="complaint.pabau_id"
                      hide-details="auto"
                      class="solo-cust mb-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Any medical conditions? </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="medical_conditions"
                      v-model="complaint.medical_condition"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Machine Used </span>
                    </label>
                    <v-textarea
                      class="text-area"
                      solo
                      outlined
                      v-model="complaint.machine_used"
                      flat
                      hide-details="auto"
                      rows="5"
                      row-height="25"
                      placeholder="Machine, temperature, any client comments/concerns on higher/lower temperature from client, side effects mentioned."
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Products Sold </span>
                    </label>
                    <v-textarea
                      class="text-area"
                      solo
                      outlined
                      v-model="complaint.products_sold"
                      flat
                      hide-details="auto"
                      rows="5"
                      row-height="25"
                      placeholder="Items that were sold, why where they sold, were they part of the package or additional."
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Were complimentary sessions offered? </span>
                    </label>
                    <v-textarea
                      class="text-area"
                      solo
                      outlined
                      v-model="complaint.complimentary_sessions"
                      flat
                      hide-details="auto"
                      rows="5"
                      row-height="25"
                      placeholder="Details of what was offered, why they were offered, what was the outcome."
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Next Action </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="nextActions"
                      v-model="complaint.next_action"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Next Action Date </span>
                    </label>
                    <v-menu
                      v-model="nextActionDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          :value="dateUkFormat(complaint.next_action_date)"
                          solo
                          outlined
                          v-on="on"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                          append-icon="mdil-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="complaint.next_action_date"
                        no-title
                        @input="nextActionDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Urgency </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="priority"
                      v-model="complaint.urgency"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="
                        SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('complaint');
                          })
                        )
                      "
                      v-model="complaint.status"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="activity" :eager="true">
          <div class="content__text">
            <tab-activity-history
              screenType="support-ticket"
              :itemData="complaint"
              :userId="complaint.user_id"
              :keyId="complaint.id"
            ></tab-activity-history>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed rounded x-large @click="update">
          Save Changes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import TabActivityHistory from "../../../components/TabItems/TabActivityHistory";
export default {
  mixins: [globalFn, status],
  components: {
    TabActivityHistory,
  },
  data: () => ({
    contactLoading: false,
    staff: [],
    navigationTab: null,
    ticketIndex: null,
    actionName: null,
    dateRaisedMenu: false,
    nextActionDateMenu: false,
    callbackDateMenu: false,
    callbackTimeMenu: false,
    navigation: [
      {
        name: "Ticket Details",
        icon: "mdil-account",
        slug: "ticket",
        module: "ticket",
        form: true,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        module: "activity",
        form: false,
      },
    ],
    ticketType: null,
    user_id: 0,
    opportunity_id: 0,
    complaintForm: {
      user_id: 0,
      support_ticket_type: "",
      // deal_id: 0,
      date_raised: "",
      category_id: 0,
      description: "",
      next_action: 0,
      next_action_date: "",
      responsible_id: 0,
      urgency: 0,
      status: 9,
      complimentary_sessions: null,
      escalation_level: null,
      products_sold: null,
      machine_used: null,
      pabau_id: null,
      medical_condition: null,
      treatment_id: [],
      callback_date: new Date().toISOString().slice(0, 10),
      callback_time: "",
      branch_id: "",
    },
    rules: {
      branch_id: [(v) => !!v || "Please select branch"],
      status: [(v) => !!v || "Please select status"],
      responsible_id: [(v) => !!v || "Please select responsible person"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    contacts: [],
  }),
  computed: {
    responsiveClass() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? "lg"
        : "md";
    },
    vertical() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? true
        : false;
    },
    complaint() {
      if (this.$store.state.complaints.item) {
        return this.$store.state.complaints.item;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    nextActions() {
      if (this.$store.state.complaints.action) {
        return this.$store.state.complaints.action;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
    category() {
      if (this.$store.state.complaints.category) {
        return this.$store.state.complaints.category;
      }
    },
    escalation() {
      if (this.$store.state.complaints.escalation) {
        return this.$store.state.complaints.escalation;
      }
    },
    dealsbyclient() {
      if (this.$store.state.deals.all) {
        return this.$store.state.deals.all;
      }
    },
  },
  created() {
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("complaints/GET_PRIORITY");
    this.$store.dispatch("complaints/GET_NEXT_ACTION");
    this.$store.dispatch("complaints/GET_CATEGORY");
    this.$store.dispatch("complaints/GET_ESCALATION");
  },
  mounted() {
    this.staffByBranch();
  },
  methods: {
    async staffByBranch() {
      this.staffParams.branch = this.complaint.user.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      if (this.$router.currentRoute.meta.slug === "support-tickets") {
        this.$root.$emit("SupportTicketsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "view-customer") {
        this.$root.$emit("refreshTicketsWidget", "");
      }
      this.$store.commit("rightSlider/RESET_SLIDER");
    },

    async update() {
      if (this.$refs.complaintForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("complaints/UPDATE_COMPLAINT", this.complaint)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
