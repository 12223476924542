<template>
  <v-card
    flat
    :elevation="0"
    color="#CAF0F8"
    tonal
    height="80%"
    rounded="sm"
    class="shift-slot"
    @click="expandForm()"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="text-caption font-weight-semibold text-black">
        {{ rota.branch?.branch }}
      </div>

      <v-btn icon x-small @click="setRepeat">
        <v-icon>mdi-repeat</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text-black">
      <div class="text-caption font-weight-medium">
        <!-- {{ rota.start_time }} - {{ rota.end_time }} -->
        {{ `${rota.rota_date} ${rota.start_time}` | moment("HH:mm") }} -
        {{ `${rota.rota_date} ${rota.end_time}` | moment("HH:mm") }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Update from "./update.vue";
import modalFn from "../../../mixins/modalFn";
export default {
  mixins: [modalFn],
  props: ["consultant", "date", "rota"],
  components: { Update },
  data: () => ({
    type: "shift",
  }),
  methods: {
    async expandForm() {
      this.$store.dispatch("rota/SHOW", {
        id: this.rota.id,
        type: this.consultant.hasOwnProperty("user_type") ? "user" : "machine",
      });
      this.openModal("edit", 1100, "Scheduler/update");
    },
    setRepeat() {
      this.openModal("edit", 1100, "Scheduler/update");
      this.$root.show_repeat = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-slot {
  border: 1px solid #0096c7 !important;
  border-left-width: 5px !important;
}
</style>
