<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :options.sync="options"
            >
              <template v-slot:item.name="{ item }">
                <div class="user-name" v-if="item.name">
                  {{ item.name | capitalize }}
                </div>
                <div class="d-flex user-name" v-if="!item.name && item.social_handle">
                  <img
                    :src="`/images/${item.source_name.toLowerCase()}.svg`"
                    class="mr-2"
                    v-if="item.source_name !== null"
                  />
                  <span> @{{ item.social_handle }} </span>
                </div>
                <p class="user-location">
                  {{ item.branch_name }}
                </p>
              </template>
              <template v-slot:item.consultant="{ item }">
                <template v-for="item in item.phone">
                  <template v-for="(value, index) in item.calls">
                    <div class="my-6 height-60">
                      <div class="user-name">
                        {{ value.owner_name | capitalize }}
                      </div>
                      <p class="user-location" v-if="value.branch_name">
                        {{ value.branch_name }}
                      </p>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.description="{ item }">
                <template v-for="item in item.phone">
                  <template v-for="(value, index) in item.calls">
                    <div class="d-flex my-6 height-60">
                      <ReadMore :comment="item.comment"></ReadMore>
                      <v-btn class="badge badge-grey ml-2" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)" v-if="value.is_marked"
                          >mdi-phone-check-outline</v-icon
                        >
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)" v-if="!value.is_marked"
                          >mdi-phone-off-outline</v-icon
                        >
                      </v-btn>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.date="{ item }">
                <template v-for="item in item.phone">
                  <template v-for="(value, index) in item.calls">
                    <p class="date-sec date-mute my-6 height-60" v-if="value.created_at !== null">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn>
                      {{ value.created_at | moment("MMMM Do YYYY") }} @
                      {{ value.created_at | moment("h:mm") }}
                    </p>
                  </template>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import ReadMore from "./ReadMore";
export default {
  name: "LeadActivityReport",
  mixins: [globalFn, sliderFn, status],
  components: {
    ReadMore,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Consultant", align: "left", value: "consultant", sortable: false },
        { text: "Name", align: "start", value: "name", sortable: false },
        { text: "Description", align: "left", value: "description", sortable: false },
        { text: "Date Updated", align: "left", value: "date", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      payload: {
        search: "",
        date: "",
        user_id: [],
        branch_id: [],
        staff: [],
        per_page: 10,
        page: 1,
      },
      showLeadParams:
        "?attributes=staff,branch,leadDetails,reasons,symptoms,treatments,source,campaigns",
      showClientsParams:
        "?attributes=staff,branch,clientDetails,reasons,symptoms,treatments,documents,comments,source,campaigns",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("searchPhoneActivityTable", this.searchCalls);
  },
  methods: {
    async searchCalls(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.payload.search = data.search;
        this.payload.date = data.date;
        this.payload.user_id = data.user_id;
        this.payload.branch_id = data.branch;
        this.payload.staff = data.staff;
        await this.readTheDataFromApi();
      } else {
        this.payload.search = null;
        this.payload.date = null;
        this.payload.user_id = [];
        this.payload.branch_id = [];
        this.payload.staff = [];
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("customers/PHONE_ACTIVITY_REPORT", this.payload).then((res) => {
        this.items = res.data.data;
        this.totalCount = res.data.total;
        this.loading = false;
      });
    },

    async editItem(item) {
      if (item.user_type_name === "Lead") {
        await this.$store
          .dispatch("leads/SHOW_LEAD", { id: item.id, param: this.showLeadParams })
          .then((res) => {
            if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
              this.openDrawer("add", "1100", "leads/editPane");
            } else {
              this.openDrawer("add", "750", "leads/editTab");
            }
          });
      } else if (item.user_type_name === "Client") {
        await this.$store
          .dispatch("clients/SHOW_CLIENT", { id: item.id, param: this.showClientsParams })
          .then((res) => {
            if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
              this.openDrawer("add", "1100", "clients/editPane");
            } else {
              this.openDrawer("add", "750", "clients/editTab");
            }
          });
      }
    },
  },
};
</script>
