<template>
  <div>
    <client-only>
      <div class="signature__brd mb-2">
        <VueSignaturePad
          width="100%"
          height="190px"
          ref="signaturePad"
          :options="{ onEnd, backgroundColor: 'rgba( 0, 0, 0, 0.1)' }"
        />
      </div>
    </client-only>
    <div class="text__right clear__sign-btn d-flex align-center justify-end">
      <a href="javascript: void(0);" @click.prevent="clearSign" class="clear__sign">
        Clear Signature
      </a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$root.$on("clearSign", () => {
      this.$refs.signaturePad.clearSignature();
    });
  },
  methods: {
    clearSign() {
      this.$refs.signaturePad.clearSignature();
      this.onEnd();
    },
    onEnd() {
      const data = this.$refs.signaturePad.saveSignature();
      this.$emit("signed", data);
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
    },
  },
};
</script>
