<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-row>
          <template v-for="(item, index) in widgets">
            <v-col cols="12" md="3" v-if="hasPermission(item)">
              <div
                class="widget"
                :class="item.active ? 'selected' : ''"
                @click="widgetFilter(item)"
              >
                <span class="active" v-if="item.active">
                  <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                </span>
                <div class="content">
                  <div class="mb-5">
                    <span class="m-0">
                      <inline-svg width="34" height="34" :src="item.svg" />
                    </span>
                  </div>
                  <p class="number">{{ item.count }}</p>
                  <p class="text">{{ item.name }}</p>
                </div>
                <span class="bottom-arrow" v-if="item.active"></span>
              </div>
            </v-col>
          </template>
        </v-row>
        <v-card flat class="pa-0 mt-5">
          <v-card-text class="pa-0 pt-3">
            <v-skeleton-loader
              class="widget-card"
              v-show="loading"
              :loading="loading"
              type="table-row-divider@6"
            ></v-skeleton-loader>
            <v-data-table
              :fixed-header="true"
              :item-class="itemRowBackground"
              v-show="!loading"
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <div
                  class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor"
                  @click="$router.push({ path: `/view-contact/${item.user.id}` })"
                >
                  <span
                    class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                    v-if="item.user && item.user.name"
                  >
                    {{ item.user.name }}
                  </span>
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border"
                    v-if="!item.user.name && !item.user.social_handle"
                    >Not Supplied</span
                  >
                  <span
                    class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                    v-if="item.user && item.user.name === null"
                  >
                    <img
                      :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="item.user.source_name"
                    />
                    {{ item.user.social_handle }}
                  </span>
                  <span
                    class="position-absolute small mt-n5 ml-n1 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                    :class="item.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                  >
                    {{ item.user.user_type_name === "Lead" ? "L" : "C" }}
                  </span>
                </div>
                <div class="d-flex align-center mt-2 ml-2">
                  <span
                    class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2"
                    v-if="item.user.contact_no"
                  >
                    {{ item.user.contact_no }}
                    <v-tooltip
                      content-class="copy-tooltip"
                      :open-on-hover="false"
                      :open-on-click="true"
                      color="#000"
                      text-color="#fff"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyContent(item.user.contact_no)" v-on="on" size="15"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copied to clipboard</span>
                    </v-tooltip>
                  </span>
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                    >{{ item.user.branch_name }}</span
                  >
                </div>
              </template>
              <template v-slot:item.type="{ item }">
                <span
                  v-if="item.support_ticket_type"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                >
                  {{ item.support_ticket_type | capitalize }}
                </span>
              </template>
              <template v-slot:item.urgency="{ item }">
                <span
                  v-if="item.urgency_name"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                >
                  {{ item.urgency_name | capitalize }}
                </span>
              </template>
              <template v-slot:item.sla="{ item }">
                <div class="d-flex flex-column">
                  <template v-if="item.support_ticket_type !== 'complaint'">
                    <span
                      class="d-flex font-13 line-h-16 font-weight--semibold text-black-300"
                      v-if="item.callback_date"
                    >
                      {{ item.callback_date | moment("DD/MM/YY") }} {{ item.callback_time }}
                      <img
                        src="/images/icons/duotune/arrows/arr050.svg"
                        class="text-black-100 ma-0 ml-1"
                        width="18"
                        height="18"
                      />
                    </span>
                  </template>
                  <template v-else>
                    <span
                      class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1"
                      v-if="item.date_raised"
                    >
                      {{ item.date_raised | moment("DD/MM/YY") }}
                    </span>
                    <span
                      class="d-flex font-13 line-h-16 font-weight--bold text-black-300"
                      v-if="item.next_action_date"
                    >
                      {{ item.next_action_date | moment("DD/MM/YY") }}
                      <img
                        src="/images/icons/duotune/arrows/arr050.svg"
                        class="text-black-100 ma-0 ml-1"
                        width="18"
                        height="18"
                      />
                    </span>
                  </template>
                </div>
              </template>
              <template v-slot:item.responsible="{ item }">
                <v-tooltip
                  content-class="este-tooltip"
                  color="#000"
                  text-color="#fff"
                  top
                  v-if="item.responsible !== null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.responsible.name) }}
                    </span>
                  </template>
                  <span v-if="item.responsible !== null">{{ item.responsible.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <div class="d-flex flex-column width-100">
                  <span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1">
                    {{ item.updated_at }}
                  </span>
                  <div class="d-flex">
                    <QuickComment :data="item" class="mr-2" />
                    <CallbackNote :data="item" />
                  </div>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>
                <div class="mt-2">
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                    v-if="item.branch_name"
                    >{{ item.branch_name }}</span
                  >
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="d-flex align-center justify-end">
                  <v-btn
                    v-if="item.is_urgent"
                    :elevation="0"
                    class="bg-red-100 btn-action mr-2"
                    @click="flagStatus(item, 0)"
                  >
                    <inline-svg
                      class="white--text"
                      width="16"
                      height="16"
                      src="/images/icons/duotune/maps/map001.svg"
                    />
                  </v-btn>
                  <v-btn
                    v-if="!item.is_urgent"
                    :elevation="0"
                    class="bg-gray-100 btn-action mr-2"
                    @click="flagStatus(item, 1)"
                  >
                    <inline-svg
                      class="text-black-100"
                      width="16"
                      height="16"
                      src="/images/icons/duotune/maps/map001.svg"
                    />
                  </v-btn>
                  <v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
                    <img height="16" src="/images/icons/duotune/general/gen055.svg" />
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import SupportTicketWidgets from "../../../mixins/SupportTicketWidgets";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import eventBus from "../../../eventBus.js";
import QuickComment from "./QuickComment.vue";
import CallbackNote from "./CallbackNote.vue";
export default {
  name: "SupportTickets",
  mixins: [globalFn, sliderFn, status, SupportTicketWidgets, sidePaneMenu],
  components: {
    QuickComment,
    CallbackNote,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      showSkeleton: false,
      modalDialog: false,
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Priority", value: "urgency", sortable: false },
        { text: "Staff", value: "responsible", sortable: false },
        { text: "sla", value: "sla", sortable: false },
        { text: "Updated", value: "updated_at", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "action", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 20,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      payload: {
        attributes: "user,responsible",
        search: "",
        filter: {
          callback_date_from: "",
          callback_date_to: "",
          callback_time_from: "",
          callback_time_to: "",
          date_raised_from: "",
          date_raised_to: "",
          next_action_date_from: "",
          next_action_date_to: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
        per_page: 20,
        page: 1,
        sort_by: "updated_at",
        sort_order: "desc",
      },
      message: "",
      action: "",
      actionText: "",
      flagStatusValue: 0,
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("SupportTicketsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchSupportTickets", this.searchSupportTickets);
  },
  methods: {
    async searchSupportTickets(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.payload.widget_filter = "";
      }
      this.payload.search = data.search;
      this.payload.filter.callback_date_from = data.callback_date_from;
      this.payload.filter.callback_date_to = data.callback_date_to;
      this.payload.filter.callback_time_from = data.callback_time_from;
      this.payload.filter.callback_time_to = data.callback_time_to;
      this.payload.filter.date_raised_from = data.date_raised_from;
      this.payload.filter.date_raised_to = data.date_raised_to;
      this.payload.filter.next_action_date_from = data.next_action_date_from;
      this.payload.filter.next_action_date_to = data.next_action_date_to;
      this.payload.filter.status = data.status;
      this.payload.filter.branch = data.branch;
      this.payload.filter.source = data.source;
      this.payload.filter.source_blank = data.source_blank;
      this.payload.filter.assigned = data.assigned;
      this.payload.filter.assigned_to = data.assigned_to;

      await this.refreshScreen();
    },

    itemRowBackground(item) {
      return item.is_urgent ? "bg-red-200" : "";
    },

    async refreshScreen() {
      await this.loadCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.payload.widget_filter = item.active ? item.slug : "";
      this.options.page = 1;
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("complaints/GET_COMPLAINT", this.payload).then((res) => {
        this.items = res.data.data;
        this.totalCount = res.data.total;
      });
      this.loading = false;

      if (this.payload.search && this.items.length === 0) {
        this.$root.$emit("showCreateNew", true);
      } else {
        this.$root.$emit("showCreateNew", false);
      }
    },
    handleFunction(funName) {
      this[funName]();
    },
    async flagStatus(item, val) {
      this.selectedItem = item;
      this.flagStatusValue = val;
      this.modalDialog = true;
      this.message = `Please confirm you want to flag this ticket?`;
      this.actionText = "Confirm";
      this.action = "flagOpportunity";
    },
    async flagOpportunity() {
      await this.$store
        .dispatch(`complaints/UPDATE_COMPLAINT`, {
          id: this.selectedItem.id,
          user_id: this.selectedItem.user_id,
          is_urgent: this.flagStatusValue,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.refreshScreen();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async editItem(item) {
      await this.$store
        .dispatch("complaints/SHOW_COMPLAINT", {
          id: item.id,
          param: `?attributes=user,responsible,documents,comments,treatments`,
        })
        .then((res) => {
          if (item.support_ticket_type === "complaint") {
            this.openDrawer("add", "1100", "supportTickets/complaint");
          } else {
            this.openDrawer("add", "1100", "supportTickets/callback");
          }
        });
    },
  },
};
</script>
