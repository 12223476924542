import http from "../../http/common";

const resource = "/documents";

export default {
  get: (payload) => http.get(`${resource}${payload}`),

  getUserDocument: (payload) => http.post(`${resource}/get-user-documents`, payload),

  uploadUserDocument: (payload) => http.post(`${resource}/upload-user-documents`, payload),

  delete: (payload) => http.post(`${resource}/delete-docs`, payload),
};
