<template>
  <v-card class="delete-alert">
    <v-card-title>
      <div>
        <v-icon class="mr-2">mdi-file-outline</v-icon>
        Upload Picture
      </div>
      <a class="float-right pa-6" @click="close"><img src="/images/close.svg" alt="Close" /></a>
    </v-card-title>

    <v-card-text>
      <v-img :src="imageUrl" height="300" width="100%" aspect-ratio="16/9" />

      <v-btn :elevation="0" @click="$refs.fileInput.click()" class="text-capitalize mt-4"
        >Choose picture</v-btn
      >
    </v-card-text>

    <input type="file" ref="fileInput" style="visibility: hidden" @change="handleUpload" />

    <v-card-actions class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-btn
            block
            depressed
            rounded
            outlined
            @click="close"
            color="dark"
            x-large
            class="text-capitalize"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="success"
            block
            depressed
            @click="submit"
            rounded
            x-large
            :loading="uploading"
            :disabled="!file"
            class="text-capitalize"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    imageUrl: null,
    file: null,
    uploading: false,
  }),
  mounted() {
    this.imageUrl = this.contact.picture;
  },
  computed: {
    contact() {
      return this.$store.state.contacts.item;
    },
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
    },
    async submit() {
      this.uploading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("image", this.file);

      await this.$store
        .dispatch("staff/UPLOAD_IMAGE", formData)
        .then((res) => {
          if (res.result === "success") {
            this.$store.dispatch("contacts/CONTACT_BY_ID", {
              user_id: this.$route.params.id,
            });
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            res.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        })
        .finally(() => {
          this.uploading = false;
          this.close();
        });
    },
    handleUpload(event) {
      const file = event.target.files[0];

      if (file) {
        this.file = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
