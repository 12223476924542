<template>
  <div>
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in consentFormData" :key="item.name">
                      <template v-if="item.status === 18">
                        <td>
                          <template v-if="item.patient_signature !== null">
                            <img height="50" :src="item.patient_signature" alt="" />
                          </template>
                          <template v-if="item.staff_signature !== null">
                            <img height="50" :src="item.staff_signature" alt="" />
                          </template>
                        </td>
                      </template>

                      <template v-else>
                        <td>
                          <template v-if="item.medical_form.title !== null">
                            <span>{{ item.medical_form.title }}</span>
                          </template>
                        </td>
                      </template>

                      <td class="">
                        <template v-if="item.status === 18">
                          <v-btn @click="showForm(item)" fab dark small color="secondary">
                            <v-icon dark> mdi-printer-outline </v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-chip @click="previewItem(item)" class="este-chip tag-chip px-3">
                            <span class="pr-2">
                              <a>Submit Response</a>
                            </span>
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-navigation-drawer
                class="este-drawer"
                v-model="drawer"
                stateless
                app
                right
                fixed
                temporary
                width="750"
              >
                <consent-form></consent-form>
              </v-navigation-drawer>
            </template>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import sliderFn from "../../mixins/sliderFn.js";
import status from "../../mixins/status.js";
import ConsentForm from "../../templates/forms/consent/consent-form.vue";
export default {
  mixins: [globalFn, status, sliderFn],
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  components: {
    ConsentForm,
  },
  data() {
    return {
      drawer: false,
      appointments: [],
      consentFormData: [],
      headers: [
        { text: "Room Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Treatments", value: "treatments", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  mounted() {
    this.$root.$on("toggleApppointmentDrawer", this.toggleApppointmentDrawer);
    this.loadForms();
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("appointments/SHOW_APPOINTMENT", {
        id: item.id,
        param: "?attributes=user,treatments,consultations,consultant,branch",
      });
    },
    async showForm(item) {
      let id = item.id;
      console.log("formid", typeof id);
      await this.$store.dispatch("formPatientCrm/GENERATE_PDF", { id: id }).then((res) => {
        this.drawer = true;
      });
    },
    async loadForms() {
      this.consentFormData = [];
      let id = this.userId;
      await this.$store.dispatch("formPatientCrm/GET_PATIENT_FORM", `?id=${id}`).then((res) => {
        this.consentFormData = res.data;
      });
    },
    async previewItem(item) {
      let id = item.id;
      await this.$store.dispatch("formPatientCrm/SHOW_FORM", `?id=${id}`).then((res) => {
        this.openDrawer("add", "750", "settings/forms/preview");
      });
    },
  },
};
</script>
