<template>
  <div>
    <div class="cell-item" v-for="item in callbacks">
      <v-row dense class="e-row">
        <v-col cols="6" md="6">
          <template v-for="tag in item.treatments">
            <v-chip small class="este-chip tag-chip" text-color="black">
              {{ tag.name | capitalize }}
            </v-chip>
          </template>
        </v-col>
        <v-col cols="6" md="6">
          <span class="cell-date"
            >Callback Date:
            <span>{{ item.callback_date | moment("DD/MM/YY") }}</span>
            <span v-if="item.callback_time !== null">@{{ item.callback_time }}</span>
          </span>
        </v-col>
      </v-row>
      <v-row dense class="e-row">
        <v-col cols="6" md="9">
          <div class="d-flex align-center justify-space-between">
            <div>
              Priority :
              <v-chip
                small
                class="este-chip status-chip"
                text-color="#221F20"
                :color="priorityColor(item.urgency)"
                v-if="item.urgency_name !== null"
              >
                {{ item.urgency_name | capitalize }}
              </v-chip>
            </div>
            <div>
              Status:
              <template
                v-for="stat in SortAtoZ(
                  statusTags.filter(function (el) {
                    return el.slug.includes('callback');
                  })
                )"
                v-if="stat.id === item.status"
              >
                <v-chip
                  small
                  class="este-chip"
                  :class="stat.outline ? 'tag-chip' : 'status-chip'"
                  :outlined="stat.outline"
                  :text-color="stat.text ? stat.color : '#221F20'"
                  :color="stat.color"
                >
                  {{ stat.name | capitalize }}
                </v-chip>
              </template>
            </div>
            <div class="d-flex align-center">
              Responsible:
              <div class="ml-2 profile-icon" alt="avatar" v-if="item.consultant_name !== null">
                <p>{{ getInitials(item.consultant_name) }}</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                <v-icon large>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <div class="logout-block">
              <ul>
                <li><a @click="editItem(item)">Edit</a></li>
              </ul>
            </div>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-navigation-drawer
      class="este-drawer"
      v-model="drawer"
      stateless
      app
      right
      fixed
      temporary
      width="750"
    >
      <edit-callback></edit-callback>
    </v-navigation-drawer>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import sliderFn from "../../mixins/sliderFn.js";
import status from "../../mixins/status.js";
import EditCallback from "../../templates/forms/leads/EditCallback";
export default {
  mixins: [globalFn, status, sliderFn],
  components: {
    EditCallback,
  },
  data() {
    return {
      drawer: false,
      callbacks: [],
    };
  },
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  mounted() {
    this.$root.$on("toggleCallbackDrawer", this.toggleCallbackDrawer);
    this.loadCallbacks();
  },
  methods: {
    async editItem(item) {
      await this.$store
        .dispatch("callback/SHOW_CALLBACK", {
          id: item.id,
          param: "?attributes=user,branch,treatments,consultant,documents,comments",
        })
        .then((res) => {
          this.drawer = true;
        });
    },
    async loadCallbacks() {
      this.callbacks = [];
      await this.$store.dispatch("callback/CALLBACKS_BY_USERID", this.userId).then((res) => {
        this.callbacks = res.data;
      });
    },
    async toggleCallbackDrawer() {
      this.drawer = false;
      await this.loadCallbacks();
    },
  },
};
</script>
