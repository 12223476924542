<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Points</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Points per £ spent </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  :rules="[positiveNumber]"
                  solo
                  outlined
                  v-model="point.points_amount_per"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Points per referral £ spent </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  :rules="[positiveNumber]"
                  solo
                  outlined
                  v-model="point.referred_points_amount_per"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Points used </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  :rules="[positiveNumber]"
                  solo
                  outlined
                  v-model="point.points_spend"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Per £ Spent </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  :rules="[positiveNumber]"
                  solo
                  outlined
                  v-model="point.amount_for_points"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
        >
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    form: {
      points_amount_per: null,
      referred_points_amount_per: null,
      points_spend: null,
      amount_for_points: null,
    },
  }),
  computed: {
    point() {
      if (this.$store.state.points.item) {
        return this.$store.state.points.item;
      } else {
        return this.form;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$store.dispatch("points/GET", "");
    },
    async create() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("points/UPDATE", this.point)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.close();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
