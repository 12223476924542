<template>
  <v-dialog v-model="logQuickComment" max-width="550" class="rounded-6">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="badge--chip d-flex align-center justify-center cursor mr-2"
        :class="showPlus ? 'bg-gold-100' : 'bg-gray-100'"
        @mouseover="showPlus = true"
        @mouseleave="showPlus = false"
      >
        <v-tooltip
          content-class="este-tooltip"
          color="#000"
          text-color="#fff"
          top
          v-if="opportunity.pin_note !== null"
        >
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              src="/images/icons/comment.svg"
              class="text-black-100 ma-0 mr-1"
              width="13"
              height="13"
            />
          </template>
          <span v-if="opportunity.pin_note !== null">{{ opportunity.pin_note }}</span>
        </v-tooltip>

        <img
          v-if="opportunity.pin_note === null"
          src="/images/icons/comment.svg"
          class="text-black-100 ma-0 mr-1"
          width="13"
          height="13"
        />
        <span class="font-weight--semibold text-black-300 font-11 line-h-13">
          {{ opportunity.comment_count }}
        </span>
        <inline-svg class="ml-1" src="/images/plus.svg" v-if="showPlus" />
      </div>
    </template>
    <v-card class="rounded-6">
      <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold">
        Log Comment
      </v-card-title>
      <v-divider class="my-5"></v-divider>
      <v-card-text>
        <p class="font-13 line-h-16 font-weight--semibold text-black-300 mb-8">
          Please type in the comment below
        </p>
        <v-form ref="form">
          <div class="d-flex align-center">
            <v-textarea
              class="text-area"
              solo
              outlined
              :rules="rules.comment"
              v-model="comment"
              flat
              hide-details="auto"
              rows="5"
              row-height="25"
              placeholder="Comment here"
            ></v-textarea>
          </div>
        </v-form>
      </v-card-text>
      <v-divider class="my-5"></v-divider>
      <v-card-actions class="d-flex justify-end align-center">
        <div class="d-flex align-center">
          <v-btn
            class="height-35 border rounded-6 text-capitalize px-3 py-2 letter-s-0 mr-2 font-12 line-h-15 font-weight--regular"
            depressed
            outlined
            x-large
            @click="logQuickComment = false"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="addComment"
            color="secondary"
            class="height-35 text-capitalize rounded-6 letter-s-0 bg-gold-100 px-3 py-2 font-12 line-h-15 font-weight--bold"
            depressed
            x-large
          >
            Save
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "QuickComment",
  mixins: [status],
  props: {
    opportunity: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      logQuickComment: false,
      showPlus: false,
      comment: "",
      rules: {
        comment: [(v) => !!v || "Please enter your comment"],
      },
    };
  },
  methods: {
    async addComment() {
      let formData = {};
      formData.id = this.opportunity.id;
      formData.comment = this.comment;
      formData.category = "comment";
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch(`opportunities/ADD_COMMENT`, formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.logQuickComment = false;
              this.$root.$emit("opportunitiesTable", "");
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
<style lang="css" scoped></style>
