<template>
  <div>
    <div class="tab-content-wrapper">
      <v-form ref="commentForm">
        <v-row class="e-row">
          <v-col cols="12" md="12" class="">
            <label>
              <span class="label-txt"> Add Notes </span>
            </label>
            <v-text-field
              :rules="rules.comment"
              v-model="comment"
              autocomplete="off"
              solo
              outlined
              flat
              hide-details="auto"
              class="solo-cust"
              placeholder="Add a note"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row dense class="e-row mt-5">
        <v-col cols="12" md="6" class="e-col"> </v-col>
        <v-col cols="12" md="6" class="e-col">
          <v-btn class="b-act-comment" depressed rounded x-large @click="addComment">
            Add Note
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense class="e-row">
        <v-col cols="12" md="12" class="e-col">
          <label>
            <span class="label-txt pb-5"> Appointment Notes History </span>
          </label>
        </v-col>
        <v-col cols="12" md="12" class="e-col">
          <template v-if="loading">
            <v-skeleton-loader class="px-10" type="article"></v-skeleton-loader>
          </template>
          <template v-else>
            <v-timeline align-top dense class="pt-0 ml-n10">
              <v-timeline-item
                class="doc-timeline"
                v-for="(note, index) in allnotes.data"
                :key="index"
                color="secondary"
                x-small
                fill-dot
              >
                <v-row dense class="e-row">
                  <v-col cols="12" md="12" class="e-col doc-item">
                    <div class="date">
                      {{ note.created_at | moment("MMMM Do YYYY") }} at
                      {{ note.created_at | moment("h:mm:ss a") }}
                    </div>
                    <div class="file-section">
                      <div class="caption mb-0">
                        <span class="font-14">{{ note.note }} </span>
                      </div>
                    </div>
                    <!-- <div class="added-user">
                      <span class="float-left user"> By John Doe</span>
                    </div> -->
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ReadMoreText from "./ReadMoreText.vue";
import eventBus from "../../eventBus.js";
import globalFn from "../../mixins/globalFn.js";
export default {
  name: "NotesTab",
  mixins: [globalFn],
  components: {
    ReadMoreText,
  },
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  data: () => ({
    title: "",
    slug: "",
    loading: false,
    selectedPreview: "",
    showPreviewDialog: false,
    comment: "",
    user: JSON.parse(localStorage.getItem("user")),
    rules: {
      comment: [(v) => !!v || "Please enter comment"],
    },
    audit: [],
    docs: [],
    action: "",
    actionType: "",
    actionId: "",
  }),
  watch: {
    $route: function (val) {
      this.title = val.meta.name;
      this.slug = val.meta.slug;
    },
  },
  computed: {
    allnotes() {
      if (this.$store.state.appointmentNotes.allList) {
        return this.$store.state.appointmentNotes.allList;
      }
    },
  },
  mounted() {
    this.loadNotes();
  },
  methods: {
    async loadNotes() {
      this.loading = true;
      await this.$store.dispatch("appointmentNotes/GET_ALL_NOTES", `?appintment_id=${this.keyId}`);
      this.loading = false;
    },

    async addComment() {
      if (this.$refs.commentForm.validate()) {
        let formData = new FormData();
        formData.append(`appointment_id`, this.keyId);
        formData.append(`note`, this.comment);

        await this.$store
          .dispatch("appointmentNotes/CREATE_NOTES", formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadNotes();
              this.$refs.commentForm.reset();
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
