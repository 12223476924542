<template>
  <div class="fill-height slider-cont">
    <div class="modal-inside pb-0 tab-brd">
      <div class="form-title mb-24">Edit Appointment</div>
      <v-tabs v-model="navigationTab" class="cust-tabs">
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <v-tab href="#appointment"> Appointment Details </v-tab>
      </v-tabs>
    </div>
    <div class="modal-inside py-0 right-pane">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="appointment" :eager="true">
          <v-form ref="appointmentForm">
            <div class="tab-content-wrapper">
              <v-row dense class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <div class="client-info">
                    <template v-if="appointment.user !== null">
                      <div class="name">{{ appointment.user.name }}</div>
                      <div
                        class="d-flex user-name"
                        v-if="!appointment.user.name && appointment.user.social_handle"
                      >
                        <img
                          :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                          class="mr-2"
                        />
                        <span> @{{ appointment.user.social_handle }} </span>
                      </div>
                    </template>
                    <p class="mt-4">
                      <span
                        class="contact"
                        v-if="appointment.user !== null && appointment.user.contact_no !== null"
                      >
                        <a :href="`tel:${appointment.user.contact_no}`">
                          <img src="/images/phone.svg" height="15" />
                          {{ appointment.user.contact_no }}</a
                        >
                      </span>
                      <span
                        class="contact"
                        v-if="appointment.user !== null && appointment.user.email !== null"
                      >
                        <a :href="`tel:${appointment.user.email}`">
                          <img src="/images/email.svg" height="15" />
                          {{ appointment.user.email }}</a
                        >
                      </span>
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Treatment / Service: </span>
                  </label>
                  {{ appointment.appointment_type }}
                  <v-autocomplete
                    v-model="appointment.treatments"
                    :items="treatments"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                    autocomplete="off"
                    solo
                    outlined
                    flat
                    hide-details="auto"
                    class="este-autocomplete-input"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ appointment.treatments.length - 1 }} others)
                      </span>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Appointment Date: </span>
                    <v-menu
                      v-model="appointmentDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          :value="dateUkFormat(appointment.appointment_date)"
                          solo
                          outlined
                          v-on="on"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                          append-icon="mdil-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="appointment.appointment_date"
                        no-title
                        @input="
                          appointmentDateMenu = false;
                          updateStatus();
                        "
                      ></v-date-picker>
                    </v-menu>
                  </label>
                </v-col>
                <v-col cols="6" md="6" class="e-col">
                  <v-row>
                    <v-col cols="6">
                      <label>
                        <span class="label-txt">Appointment Time:</span>
                        <v-menu
                          ref="appointmentFromTimeMenu"
                          v-model="appointmentFromTimeMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              flat
                              hide-details="auto"
                              solo
                              class="solo-cust"
                              v-model="appointment.appointment_from_time"
                              label="From"
                              append-icon="mdil-clock"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            :allowed-minutes="allowedStep"
                            no-title
                            ampm-in-title
                            format="24hr"
                            v-if="appointmentFromTimeMenu"
                            v-model="appointment.appointment_from_time"
                            full-width
                            @click:minute="
                              $refs.appointmentFromTimeMenu.save(appointment.appointment_from_time)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <label>
                        <v-menu
                          ref="appointmentToTimeMenu"
                          v-model="appointmentToTimeMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              flat
                              hide-details="auto"
                              solo
                              class="solo-cust mt-5"
                              v-model="appointment.appointment_to_time"
                              label="To"
                              append-icon="mdil-clock"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            :allowed-minutes="allowedStep"
                            no-title
                            ampm-in-title
                            format="24hr"
                            v-if="appointmentToTimeMenu"
                            v-model="appointment.appointment_to_time"
                            full-width
                            @click:minute="
                              $refs.appointmentToTimeMenu.save(appointment.appointment_to_time)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Preferred Branch: </span>
                    <v-select
                      autocomplete="off"
                      :items="branches"
                      item-value="id"
                      item-text="branch"
                      v-model="appointment.branch_id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                      @change="staffByBranch"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Select Consultant </span>
                    <v-select
                      autocomplete="off"
                      :items="staff"
                      v-model="appointment.consultant_id"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Appointment Type </span>
                  </label>
                  <v-select
                    autocomplete="off"
                    :items="appointmentType"
                    v-model="appointment.appointment_type"
                    solo
                    outlined
                    flat
                    class="solo-cust"
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Status </span>
                  </label>
                  <v-select
                    autocomplete="off"
                    :items="
                      SortAtoZ(
                        statusTags.filter(function (el) {
                          return el.slug.includes('appointment');
                        })
                      )
                    "
                    v-model="appointment.status"
                    item-text="name"
                    item-value="id"
                    solo
                    outlined
                    flat
                    class="solo-cust"
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="e-row" v-if="appointment.user.user_type_name === 'Client'">
                <v-col cols="12" md="12" class="">
                  <label>
                    <span class="label-txt"> Client Satisfaction: </span>
                  </label>
                </v-col>
                <v-col cols="12" md="12" class="e-col">
                  <template v-for="(item, index) in satisfaction">
                    <div
                      v-model="appointment.client_satisfaction"
                      :class="[
                        'emoji-wrap',
                        `${lowerCase(item.name)}-emoji`,
                        { active: activeIndex === index },
                        { active: appointment.client_satisfaction === item.slug },
                      ]"
                      @click="setActive(item, index)"
                      :key="item.name"
                    >
                      <div class="icon">
                        <img :src="`${item.image}`" />
                      </div>
                      <div class="text">{{ item.name }}</div>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="form-btn-wrapper">
      <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
        Cancel
      </v-btn>
      <v-btn
        color="secondary"
        class="b-blue"
        depressed
        rounded
        x-large
        :loading="loading"
        @click="update"
      >
        Save Changes
      </v-btn>
    </div>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
export default {
  mixins: [globalFn, status],
  data: () => ({
    navigationTab: "appointment",
    loading: false,
    activeIndex: null,
    appointmentDateMenu: false,
    appointmentFromTimeMenu: false,
    appointmentToTimeMenu: false,
    staff: [],
    staffParams: {
      branch: "",
      type: "appointment",
    },
  }),
  watch: {
    "appointment.appointment_date": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "appointment.appointment_from_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "appointment.appointment_to_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
  },
  mounted() {
    this.staffByBranch();
  },
  computed: {
    appointment() {
      if (this.$store.state.appointments.item) {
        if (!this.$store.state.appointments.item.user) {
          this.$store.state.appointments.item.user = {};
        }
        return this.$store.state.appointments.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
  },
  methods: {
    updateStatus() {
      if (
        this.appointment.appointment_date &&
        this.appointment.appointment_from_time &&
        this.appointment.appointment_to_time
      ) {
        this.appointment.status = 5;
      } else if (
        this.appointment.appointment_date &&
        !this.appointment.appointment_from_time &&
        !this.appointment.appointment_to_time
      ) {
        this.appointment.status = 11;
      }
    },
    async staffByBranch() {
      this.staff = [];
      this.staffParams.branch = this.appointment.branch_id;
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    close() {
      this.$root.$emit("toggleApppointmentDrawer", true);
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.appointment.client_satisfaction = item.name.toLowerCase();
    },
    async update() {
      let formData = {};
      formData.id = this.appointment.id;
      formData.user_id = this.appointment.user_id;
      formData.appointment_type = this.appointment.appointment_type;
      formData.appointment_from_time = this.appointment.appointment_from_time;
      formData.appointment_to_time = this.appointment.appointment_to_time;
      formData.branch_id = this.appointment.branch_id;
      formData.consultant_id = this.appointment.consultant_id;
      formData.client_satisfaction = this.appointment.client_satisfaction;
      formData.status = this.appointment.status;
      formData.appointment_date = this.appointment.appointment_date;
      formData.treatment_id = [];
      this.appointment.treatments.forEach((value) => {
        if (value.id) {
          formData.treatment_id.push(value.id);
        } else {
          formData.treatment_id.push(value);
        }
      });
      if (this.$refs.appointmentForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("appointments/UPDATE_APPOINTMENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.close();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
