<template>
  <div>
    <div class="fill-height slider-cont">
      <v-card class="vertical-slide fill-height">
        <v-card-text class="pa-0">
          <v-row dense class="e-row">
            <v-col cols="6" md="3" class="e-col pa-0 ma-0">
              <v-toolbar class="left-toolbar" flat> </v-toolbar>
              <div class="nav-lists tab-drawer fill-height ma-0">
                <v-list dense>
                  <v-list-item class="menu active">
                    <v-list-item-action class="menu-icon">
                      <v-icon>mdil-calendar</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="menu-txt">
                      <v-list-item-title> Appointments </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-col cols="6" md="9" class="e-col pa-0 ma-0">
              <v-toolbar class="right-toolbar" flat>
                <div class="form-title">View Appointment</div>
              </v-toolbar>
              <div class="slide-right">
                <div class="form-wrap px-10">
                  <div class="side-pane" v-if="appointmentUnit">
                    <v-form ref="appointmentForm">
                      <div class="tab-content-wrapper">
                        <v-row dense class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <div class="client-info">
                              <p class="name" v-if="pabauData.customer_name !== null">
                                {{ pabauData.customer_name | capitalize }}
                              </p>
                              <p class="mt-4">
                                <span class="contact" v-if="pabauData.Mobile !== null">
                                  <a :href="`tel:${pabauData.Mobile}`">
                                    <img src="/images/phone.svg" height="15" />
                                    {{ pabauData.Mobile }}</a
                                  >
                                </span>
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Branch: </span>
                            </label>
                            <span class="bg-round" v-if="pabauData.branch_name !== null">
                              {{ pabauData.branch_name | capitalize }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Consultant: </span>
                            </label>
                            <span class="bg-round" v-if="pabauData.staff_name !== null">
                              {{ pabauData.staff_name | capitalize }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Treatment / Service: </span>
                            </label>
                            <span class="bg-round" v-if="pabauData.service !== null">
                              {{ pabauData.service }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Appointment Date: </span>
                            </label>
                            <span class="bg-round" v-if="pabauData.start_date !== null">
                              {{ pabauData.start_date | moment("DD/MM/YY") }}
                            </span>
                          </v-col>
                          <v-col cols="12" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Appointment Time: </span>
                            </label>
                            <span class="bg-round" v-if="pabauData.start_time !== null">
                              {{ pabauData.start_time }} - {{ pabauData.end_time }}
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </div>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import eventBus from "../../../eventBus.js";
export default {
  mixins: [sidePaneMenu],
  data: () => ({
    pabauData: [],
    appointmentUnit: false,
  }),
  mounted() {
    this.paneTab = "appointment";
    this.appointmentUnit = true;
    eventBus.$on("loadPabauDetails", this.loadPabauDetails);
  },
  methods: {
    async loadPabauDetails(data) {
      this.pabauData = data;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
  },
};
</script>
