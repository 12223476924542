<template>
  <v-card flat :elevation="0">
    <v-card-title class="d-flex align-center justify-end">
      <div>
        <v-btn color="secondary" :elevation="0" @click="openUpload">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Create
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text class="pa-0 pt-3">
      <v-skeleton-loader
        class="widget-card"
        v-show="loading"
        :loading="loading"
        type="table-row-divider@6"
      ></v-skeleton-loader>
      <v-data-table :fixed-header="true" v-show="!loading" :headers="headers" :items="documentList">
        <template v-slot:item.uploaded_at="{ item }">
          {{ item.created_at | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn :href="item.url" target="_blank" small :elevation="0">View</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import modalFn from "../../mixins/modalFn";
export default {
  mixins: [modalFn],
  data: () => ({
    loading: false,
    documentList: [],
    headers: [
      {
        text: "Uploaded At",
        align: "start",
        value: "uploaded_at",
        sortable: false,
      },
      { text: "Uploaded by", value: "uploaded_by_name", align: "center", sortable: false },
      { text: "Actions", value: "actions", align: "end", sortable: false },
    ],
  }),
  async mounted() {
    await this.fetchUserDocs();

    this.$root.$on("fetchUserDocs", this.fetchUserDocs);
  },
  methods: {
    fetchUserDocs() {
      this.loading = true;
      this.$store
        .dispatch("docs/GET_USER_DOCS", {
          user_id: this.$route.params.id,
          sort_by: "id",
          sort_order: "DESC",
        })
        .then((res) => {
          this.documentList = res;
        })
        .finally(() => (this.loading = false));
    },
    async openUpload() {
      this.openModal("add", 600, "documents/upload");
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
