import collection from "../../../api/collections/Albums";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
  },
  mutations: {
    SET_ALBUMS(state, data) {
      state.list = data;
    },
    SET_ALBUM_ITEM(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_ALBUMS", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async GET_BY_ID({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getById(payload.id, payload.params);
          commit("SET_ALBUM_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_BY_ID",
        true
      );
    },
    async CREATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);

          return data;
        },
        commit,
        dispatch,
        "CREATE",
        true
      );
    },
    async UPDATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload.body);

          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );
    },
    async DELETE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);

          return data;
        },
        commit,
        dispatch,
        "DELETE",
        true
      );
    },
    async ADD_PHOTOS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.addPhotos(payload);

          return data;
        },
        commit,
        dispatch,
        "ADD_PHOTOS",
        true
      );
    },
    async REMOVE_PHOTOS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.removePhotos(payload);

          return data;
        },
        commit,
        dispatch,
        "REMOVE_PHOTOS",
        true
      );
    },
  },
};
