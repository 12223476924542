import http from "../../http/common";

const resource = "/photos";

export default {
  get: (payload) => http.post(`${resource}/get`, payload),

  upload: (payload) => http.post(`${resource}/upload`, payload),

  delete: (payload) => http.post(`${resource}/delete`, payload),
};
