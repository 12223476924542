import http from "../http/common";

const resource = "/opportunity";

export default {
  create: (payload) => http.post(`${resource}/create`, payload),
  show: (payload) => http.post(`${resource}/show`, payload),
  update: (payload) => http.post(`${resource}/update`, payload),
  get: (payload) => http.post(`${resource}/get`, payload),
  status: (payload) => http.post(`${resource}/update-status`, payload),
  count: (payload) => http.post(`${resource}/lead-count`, payload),
  comment: (payload) => http.post(`${resource}/add-comment`, payload),
  assignedTo: (payload) => http.post(`${resource}/staff-list`, payload),
  upload: (payload) => http.post(`${resource}/upload-docs`, payload),
  reminder: (payload) => http.post(`${resource}/reminder-check`, payload),
};
