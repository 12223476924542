<template>
  <div class="d-flex flex-wrap" style="gap: 1rem">
    <div v-for="(photo, index) in photos" :key="photo.id">
      <img :src="photo.url" />

      <div class="d-flex align-center justify-space-between">
        <v-checkbox :value="photo.id" v-model="setInput"></v-checkbox>

        <v-menu max-width="200" offset-y left content-class="user-action">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-icon class="mr-2" small>mdi-tray-arrow-down</v-icon> Download
            </v-list-item>
            <!-- <v-list-item>
              <v-icon class="mr-2" small>mdi-image-outline</v-icon> Add to album
            </v-list-item> -->
            <v-list-item class="text-red" @click="deletePhoto(photo.id)">
              <v-icon class="mr-2" color="red" small>mdi-delete-outline</v-icon> Delete
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    photos: Array,
  },
  computed: {
    setInput: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    async deletePhoto(id) {
      await this.$store.dispatch("photos/DELETE", { id }).then((res) => {
        if (res.result === "success") {
          this.$emit("refresh");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  border-radius: 8px;
  height: 130px;
  width: 150px;
  object-fit: cover;
}
</style>
