<template>
  <div class="tab-content-wrapper">
    <v-data-table
      class="point-history-table"
      :hide-default-footer="true"
      :headers="headers"
      :items="history"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("DD/MM/YY @ H:mm") }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "TabPoint",
  props: {
    userId: {
      type: Number, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Type", value: "type", sortable: false },
        { text: "Points", value: "points", sortable: false },
        { text: "Date", value: "created_at", sortable: false },
      ],
    };
  },
  computed: {
    history() {
      return this.$store.state.points.list;
    },
  },
  mounted() {
    this.$root.$on("refreshPoints", this.refreshPoints);
  },
  methods: {
    refreshPoints() {
      this.$store.dispatch("points/POINTS_HISTORY", { user_id: this.userId });
    },
  },
};
</script>
<style lang="css" scoped></style>
