import http from "../../http/common";

const resource = "/treatment";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload) => http.get(`${resource}${payload}`),

  getList: (payload) => http.get(`${resource}/get-all${payload}`),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  sell: (payload) => http.post(`${resource}/get-sell-treatments`, payload),
};
