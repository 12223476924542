<template>
  <div>
    <div class="tab-content-wrapper">
      <v-form ref="commentForm">
        <v-row class="e-row">
          <v-col cols="12" md="6" class="e-col">
            <div class="d-flex mt-2 mb-4">
              <v-menu
                v-model="enquiryDateFromMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    :value="dateUkFormat(filter.enquiry_date_from)"
                    solo
                    outlined
                    v-on="on"
                    flat
                    hide-details="auto"
                    class="advance-input"
                    placeholder="Date from"
                    prepend-inner-icon="mdil-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.enquiry_date_from"
                  no-title
                  @input="enquiryDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="enquiryDateToMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    :value="dateUkFormat(filter.enquiry_date_to)"
                    solo
                    outlined
                    v-on="on"
                    flat
                    hide-details="auto"
                    class="advance-input"
                    placeholder="Date to"
                    prepend-inner-icon="mdil-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.enquiry_date_to"
                  no-title
                  @input="enquiryDateToMenu = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <v-btn class="mx-2" fab dark large color="secondary">
              <v-icon dark> mdi-upload-box-outline </v-icon>
            </v-btn>
            <input
              type="file"
              multiple
              id="filePicker"
              @change="onFileChange($event.target.name, $event.target.files)"
              style="display: none"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row dense class="e-row">
        <v-col cols="12" md="12" class="e-col" v-if="audit.length > 0">
          <label>
            <span class="label-txt pb-5"> Latest Updates {{ itemData.user_type_name }} </span>
          </label>
        </v-col>
        <v-col cols="12" md="12" class="e-col">
          <template v-if="auditLoading">
            <v-skeleton-loader class="px-10" type="article"></v-skeleton-loader>
          </template>
          <template v-else>
            <v-timeline align-top dense class="pt-0 ml-n10">
              <v-timeline-item
                class="doc-timeline"
                v-for="(doc, index) in allnotes.data"
                :key="index"
                color="secondary"
                x-small
                fill-dot
              >
                <v-row dense class="e-row">
                  <v-col cols="12" md="12" class="e-col doc-item">
                    <div class="date">
                      {{ doc.created_at | moment("MMMM Do YYYY") }} at
                      {{ doc.created_at | moment("h:mm:ss a") }}
                    </div>
                    <div class="file-section">
                      <div class="caption mb-0">
                        <span class="font-14">{{ doc.note }} </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ReadMoreText from "./ReadMoreText.vue";
import eventBus from "../../eventBus.js";
import globalFn from "../../mixins/globalFn.js";
export default {
  name: "NotesTab",
  mixins: [globalFn],
  components: {
    ReadMoreText,
  },
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  data: () => ({
    title: "",
    slug: "",
    auditLoading: false,
    selectedPreview: "",
    showPreviewDialog: false,
    comment: "",
    user: JSON.parse(localStorage.getItem("user")),
    rules: {
      comment: [(v) => !!v || "Please enter comment"],
    },
    audit: [],
    docs: [],
    action: "",
    actionType: "",
    actionId: "",
  }),
  watch: {
    $route: function (val) {
      this.title = val.meta.name;
      this.slug = val.meta.slug;
    },
  },
  computed: {
    allnotes() {
      if (this.$store.state.appointmentNotes.allList) {
        return this.$store.state.appointmentNotes.allList;
      }
    },
  },
  mounted() {
    this.loadAudit();
  },
  methods: {
    async loadAudit() {
      this.auditLoading = true;
      await this.$store.dispatch("appointmentNotes/GET_ALL_NOTES", `?appintment_id=${this.keyId}`);
      this.auditLoading = false;
    },
    async onFileChange(fieldName, files) {
      let formData = new FormData();
      formData.append(`${this.actionId}`, this.keyId);

      for (let file of files) {
        formData.append("docs[]", file, file.name);
      }
      try {
        await this.$store
          .dispatch(`${this.action}/UPLOAD_DOCS`, formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadDocs();
            } else if (res.result === "fail") {
              this.loading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } catch (e) {
        console.log("error: ", e);
        this.loading = false;
        this.cancel();
      }
    },
    async addComment() {
      if (this.$refs.commentForm.validate()) {
        let formData = new FormData();
        formData.append(`appointment_id`, this.keyId);
        formData.append(`note`, this.comment);

        await this.$store
          .dispatch("appointmentNotes/CREATE_NOTES", formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadAudit();
              this.$refs.commentForm.reset();
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
