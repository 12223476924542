<template>
  <v-menu offset-y :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <div class="wrapper" v-bind="attrs" v-on="on" :style="{ height: height }">
        <div class="empty-slot">
          <v-icon class="add-icon" color="blue">mdi-plus</v-icon>
        </div>
      </div>
    </template>

    <Create @close="menu = false" @expandForm="expandForm" :consultant="consultant" :date="date" />
  </v-menu>
</template>

<script>
import Create from "./create.vue";
import modalFn from "../../../mixins/modalFn";
export default {
  mixins: [modalFn],
  props: ["height", "consultant", "date"],
  components: {
    Create,
  },
  data: () => ({
    date: new Date(),
    menu: false,
  }),
  methods: {
    async expandForm() {
      this.openModal("add", 1100, "Scheduler/add");
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  cursor: pointer;
}

.wrapper:hover .empty-slot {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.empty-slot {
  opacity: 0;
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
  border-radius: 4px;
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
