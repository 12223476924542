var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tabs',{on:{"change":_vm.loadAppointments},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.slug,staticClass:"text-capitalize spacing-normal",attrs:{"href":`#${item.slug}`}},[_vm._v("\n      "+_vm._s(item.name)+"\n      ("+_vm._s(_vm.getStatusCount(item.slug))+")\n    ")])}),_vm._v(" "),_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{attrs:{"eager":true,"value":tab.slug}},[_c('div',[_vm._l((_vm.appointments),function(item){return _c('div',{staticClass:"cell-item"},[_c('v-row',{staticClass:"e-row",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_vm._l((item.treatments),function(tag){return [_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":"","text-color":"black"}},[_vm._v("\n                "+_vm._s(_vm._f("capitalize")(tag.name))+"\n              ")])]})],2),_vm._v(" "),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('span',{staticClass:"cell-date"},[_vm._v("Appointment Date:\n              "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.appointment_date,"DD/MM/YY")))]),_vm._v(" "),(item.appointment_from_time !== null)?_c('span',[_vm._v(":"+_vm._s(item.appointment_from_time))]):_vm._e(),_vm._v(" "),(item.appointment_to_time !== null)?_c('span',[_vm._v("\n                - "+_vm._s(item.appointment_to_time))]):_vm._e()])])],1),_vm._v(" "),_c('v-row',{staticClass:"e-row",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"9"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v("\n                Appointment Type :\n                "),_c('v-chip',{staticClass:"este-chip status-chip",attrs:{"small":"","text-color":item.appointment_type === 'Consultation' ? 'white' : 'black',"color":item.appointment_type === 'Consultation'
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(223, 180, 71, 0.4)'}},[_vm._v("\n                  "+_vm._s(_vm._f("capitalize")(item.appointment_type))+"\n                ")])],1),_vm._v(" "),_c('div',[_vm._v("\n                Status:\n                "),_vm._l((_vm.SortAtoZ(
                    _vm.statusTags.filter(function (el) {
                      return el.slug.includes('appointment');
                    })
                  )),function(stat){return (stat.id === item.status)?[_c('v-chip',{staticClass:"este-chip",class:stat.outline ? 'tag-chip' : 'status-chip',attrs:{"small":"","outlined":stat.outline,"text-color":stat.text ? stat.color : '#221F20',"color":stat.color}},[_vm._v("\n                    "+_vm._s(_vm._f("capitalize")(stat.name))+"\n                  ")])]:_vm._e()})],2),_vm._v(" "),_c('div',{staticClass:"d-flex align-center"},[_vm._v("\n                Responsible:\n                "),(item.consultant_name !== null)?_c('div',{staticClass:"ml-2 profile-icon",attrs:{"alt":"avatar"}},[_c('p',[_vm._v("\n                    "+_vm._s(_vm.getInitials(item.consultant_name))+"\n                  ")])]):_vm._e()])])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({staticClass:"float-right",attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])])])])])],1)],1)],1)}),_vm._v(" "),_c('v-navigation-drawer',{staticClass:"este-drawer",attrs:{"stateless":"","app":"","right":"","fixed":"","temporary":"","width":"750"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('edit-appointment')],1)],2)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }