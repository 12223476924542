<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Insights</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#insight">Insight</v-tab>
          <v-tab href="#client">Client</v-tab>
          <v-tab href="#deal">Deal</v-tab>
          <v-tab href="#appointment">Appointment</v-tab>
          <!-- <v-tab href="#activity" @change="handleFunction('loadAudit')">Activity</v-tab> -->
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="insight" :eager="true">
            <div class="tab-content-wrapper">
              <template v-for="(sell, key) in sells">
                <template v-for="(item, k) in sell">
                  <template v-if="k === 'deal'">
                    <label>
                      <span class="tab-title">
                        {{ item.deal_info }}
                      </span>
                    </label>
                  </template>
                  <template v-else>
                    <div class="cell-item">
                      <v-row dense class="e-row">
                        <v-col cols="6" md="9">
                          <img src="/images/bolt.svg" />
                          <span class="cell-title" v-if="k === 'repeat_treatments'"
                            >Repeat Treatment Opportunity</span
                          >
                          <span class="cell-title" v-else-if="k === 'products_upsell'"
                            >Product Upsell Opportunity</span
                          >
                          <span class="cell-title" v-else-if="k === 'service_upsell'"
                            >Service Upsell Opportunity</span
                          >
                        </v-col>
                        <v-col cols="6" md="3">
                          <span class="cell-date"
                            >Generated {{ insight.updated_at | moment("DD/MM/YY") }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row dense class="e-row">
                        <v-col cols="6" md="9">
                          <template v-for="val in item">
                            <v-chip small class="este-chip tag-chip" text-color="black">
                              {{ val.name | capitalize }}
                            </v-chip>
                          </template>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-menu
                            max-width="200"
                            :nudge-width="200"
                            offset-y
                            left
                            content-class="user-action"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                                <v-icon large>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <div class="logout-block">
                              <ul>
                                <li><a @click="createAppointment">Create Appointment</a></li>
                                <li><a @click="archive" class="text-mute">Archive</a></li>
                              </ul>
                            </div>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item value="client" :eager="true">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="6" md="2" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt">Title</span>
                        <v-select
                          autocomplete="off"
                          :items="titles"
                          v-model="insight.user.title"
                          solo
                          outlined
                          flat
                          class="solo-cust"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </label>
                    </div>
                  </v-col>
                  <v-col cols="6" md="10" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Name </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          v-model="insight.user.name"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Phone No. </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        v-model="insight.user.contact_no"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-phone"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Email Address </span>
                      <v-text-field
                        autocomplete="off"
                        v-model="insight.user.email"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-email"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Address </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="insight.user.address_one"
                        hide-details="auto"
                        class="solo-cust mb-2"
                      ></v-text-field>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="insight.user.address_two"
                        hide-details="auto"
                        class="solo-cust mar-8"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Town / City </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="insight.user.city"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt"> Post Code </span>
                          <v-text-field
                            autocomplete="off"
                            solo
                            outlined
                            flat
                            v-model="insight.user.postcode"
                            hide-details="auto"
                            class="solo-cust"
                          ></v-text-field>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        v-model="insight.user.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="">
                    <label>
                      <span class="label-txt"> Client Satisfaction: </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="12" class="e-col">
                    <template v-for="(item, index) in satisfaction">
                      <div
                        :class="[
                          'emoji-wrap',
                          `${lowerCase(item.name)}-emoji`,
                          { active: activeIndex === index },
                          { active: insight.user.client_satisfaction === item.slug },
                        ]"
                        @click="setActive(item, index)"
                        :key="item.name"
                      >
                        <div class="icon">
                          <img :src="`${item.image}`" />
                        </div>
                        <div class="text">{{ item.name }}</div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="deal" :eager="true">
            <tab-deal :userId="insight.user.id" keyId="0"></tab-deal>
          </v-tab-item>
          <v-tab-item value="appointment" :eager="true">
            <v-form ref="appointmentForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatment / Service: </span>
                    </label>
                    <v-autocomplete
                      v-model="form.treatment_id"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Date: </span>
                      <v-menu
                        v-model="appointmentDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            autocomplete="off"
                            v-model="appointmentDateUkFormat"
                            solo
                            outlined
                            v-on="on"
                            flat
                            hide-details="auto"
                            class="solo-cust"
                            append-icon="mdil-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.appointment_date"
                          no-title
                          @input="appointmentDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <v-row>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt">Appointment Time:</span>
                          <v-menu
                            ref="appointmentFromTimeMenu"
                            v-model="appointmentFromTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_from_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust"
                                v-model="form.appointment_from_time"
                                label="From"
                                append-icon="mdil-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentFromTimeMenu"
                              v-model="form.appointment_from_time"
                              full-width
                              @click:minute="
                                $refs.appointmentFromTimeMenu.save(form.appointment_from_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                          <v-menu
                            ref="appointmentToTimeMenu"
                            v-model="appointmentToTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_to_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust mt-5"
                                v-model="form.appointment_to_time"
                                label="To"
                                append-icon="mdil-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentToTimeMenu"
                              v-model="form.appointment_to_time"
                              full-width
                              @click:minute="
                                $refs.appointmentToTimeMenu.save(form.appointment_to_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        :rules="rules.branch_id"
                        v-model="form.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Type </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="appointmentType"
                      v-model="form.appointment_type"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Select Responsibility </span>
                      <v-select
                        autocomplete="off"
                        :items="staff"
                        v-model="form.consultant_id"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="appointmentStatus"
                      v-model="form.status"
                      :rules="rules.status"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="activity">
            <div class="tab-content-wrapper">
              <v-form ref="commentForm">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="">
                    <label>
                      <span class="label-txt"> Leave Comment </span>
                    </label>
                    <v-text-field
                      :rules="rules.comment"
                      v-model="comment"
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="solo-cust"
                      placeholder="Add a comment"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-row dense class="e-row mt-5">
                <v-col cols="6" md="6" class="e-col"> </v-col>
                <v-col cols="6" md="6" class="e-col">
                  <v-btn class="b-act-comment" depressed rounded x-large @click="addComment">
                    Add Comment
                  </v-btn>
                  <v-btn
                    class="b-act-upload mr-2"
                    depressed
                    rounded
                    outlined
                    x-large
                    @click="openFilePicker"
                  >
                    Upload File
                  </v-btn>
                  <input
                    type="file"
                    multiple
                    id="filePicker"
                    @change="onFileChange($event.target.name, $event.target.files)"
                    style="display: none"
                  />
                </v-col>
              </v-row>
              <v-row dense class="e-row">
                <v-col cols="12" md="12" class="e-col" v-if="audit.length > 0">
                  <label>
                    <span class="label-txt pb-5"> Latest Updates </span>
                  </label>
                </v-col>
                <v-col cols="12" md="12" class="e-col">
                  <template v-if="auditLoading">
                    <v-skeleton-loader class="px-10" type="article"></v-skeleton-loader>
                  </template>
                  <template v-else>
                    <v-timeline align-top dense class="pt-0 ml-n10">
                      <v-timeline-item
                        class="doc-timeline"
                        v-for="(doc, index) in docs"
                        :key="index"
                        color="secondary"
                        x-small
                        fill-dot
                      >
                        <v-row dense class="e-row">
                          <v-col cols="12" md="12" class="e-col doc-item">
                            <div class="date">
                              {{ doc.created_at | moment("MMMM Do YYYY") }} at
                              {{ doc.created_at | moment("h:mm:ss a") }}
                            </div>
                            <div class="file-section" style="height: 140px">
                              <div class="caption mb-0">
                                <span class="float-left"> </span>
                                <span class="float-right download-text"
                                  >Download files ({{ doc.filesize }} in total)</span
                                >
                              </div>
                              <v-avatar class="doc-img">
                                <v-img
                                  src="/images/pdf.png"
                                  class="img"
                                  v-if="doc.filetype === 'pdf'"
                                >
                                  <div class="content-overlay">
                                    <img src="/images/zoom-in.svg" />
                                  </div>
                                </v-img>
                                <v-img :src="`${doc.url}`" class="img" v-else>
                                  <div class="content-overlay">
                                    <img src="/images/zoom-in.svg" />
                                  </div>
                                </v-img>
                              </v-avatar>
                            </div>
                            <div class="added-user">
                              <span class="float-left user"> By {{ doc.uploaded_by_name }}</span>
                              <span class="float-right user">
                                <v-icon color="rgba(0, 0, 0, 0.6)" size="16" class="mt-n1"
                                  >mdil-delete</v-icon
                                >
                                <span class="delete">Delete Files</span>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                    <v-timeline align-top dense class="pt-0 ml-n10">
                      <template v-for="(item, index) in audit">
                        <v-timeline-item
                          class="doc-timeline"
                          v-if="item.new_values"
                          v-for="(value, key) in item.new_values"
                          :key="index.key"
                          color="secondary"
                          x-small
                          fill-dot
                        >
                          <v-row dense class="e-row">
                            <v-col cols="12" md="12" class="e-col doc-item">
                              <div class="date">
                                {{ item.created_at | moment("MMMM Do YYYY") }} at
                                {{ item.created_at | moment("h:mm:ss a") }}
                              </div>
                              <div class="file-section" :class="key === 'status' ? 'status' : ''">
                                <div class="caption">
                                  <span class="float-left">
                                    <p class="type">
                                      {{ key.replace(/_/g, " ") | capitalize }} changed to
                                      {{ value | capitalize }}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div class="added-user">
                                <span class="float-left user"> By {{ item.owner_name }}</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </template>
                    </v-timeline>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :loading="loading"
          rounded
          x-large
          @click="addAppointment"
        >
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
import TabDeal from "../../../components/TabItems/TabDeal";
export default {
  mixins: [globalFn, status],
  components: {
    TabDeal,
  },
  data: () => ({
    loading: false,
    auditLoading: false,
    comment: "",
    activeIndex: null,
    appointmentDateMenu: false,
    appointmentFromTimeMenu: false,
    appointmentToTimeMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    dealLoading: false,
    showDealForm: false,
    audit: [],
    sells: [],
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    rules: {
      deal_info: [(v) => !!v || "Please enter deal information"],
      total: [(v) => !!v || "Please enter total amount"],
      comment: [(v) => !!v || "Please enter comment"],
      branch_id: [(v) => !!v || "Please select branch"],
      status: [(v) => !!v || "Please select status"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    dealForm: {
      user_id: 0,
      deal_info: "",
      treatments: [],
      total: "",
      soldby_id: "",
      no_of_sessions: 0,
    },
    form: {
      user_id: 0,
      treatment_id: [],
      appointment_type: "Consultation",
      appointment_date: "",
      appointment_from_time: "",
      appointment_to_time: "",
      branch_id: "",
      consultant_id: "",
      client_satisfaction: "",
      status: 11,
    },
    deals: [],
    docs: [],
  }),
  computed: {
    insight() {
      if (this.$store.state.insights.item) {
        return this.$store.state.insights.item;
      }
    },
    appointmentDateUkFormat() {
      if (!this.form.appointment_date) return null;
      const [year, month, day] = this.form.appointment_date.split("-");
      return `${day}/${month}/${year}`;
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  mounted() {
    this.loadDeals();
    this.loadSells();
    this.$root.$on("showPaymentHistoryTab", this.loadPaymentHistory);
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" });
  },
  methods: {
    handleFunction(funName) {
      this[funName]();
    },
    async loadSells() {
      await this.$store
        .dispatch("treatments/SELL_TREATMENT", { deal_id: this.insight.deal_id })
        .then((res) => {
          this.sells = res.data;
        });
    },
    async loadAudit() {
      this.auditLoading = true;
      await this.$store
        .dispatch("audit/GET_AUDIT", `?filters=[{"user_id":[{"equ":${this.insight.user.id}}]}]`)
        .then((res) => {
          this.audit = res.data.data;
          this.auditLoading = false;
        });
      this.loadDocs();
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("insightsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.form.client_satisfaction = item.name.toLowerCase();
    },
    async addComment() {
      if (this.$refs.commentForm.validate()) {
        await this.$store
          .dispatch("clients/ADD_COMMENT", {
            client_id: this.insight.user.id,
            comment: this.comment,
          })
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadAudit();
              this.$refs.commentForm.reset();
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    openFilePicker() {
      document.getElementById("filePicker").click();
    },
    async onFileChange(fieldName, files) {
      let formData = new FormData();
      formData.append("client_id", this.insight.user.id);

      for (let file of files) {
        formData.append("docs[]", file, file.name);
      }
      try {
        await this.$store
          .dispatch("clients/UPLOAD_DOCS", formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadDocs();
            } else if (res.result === "fail") {
              this.loading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } catch (e) {
        console.log("error: ", e);
        this.loading = false;
        this.cancel();
      }
    },
    async loadDocs() {
      this.auditLoading = true;
      try {
        await this.$store
          .dispatch(
            "docs/GET_DOCS",
            `?id=${this.insight.user.id}&type=client&sort_by=id&sort_order=desc`
          )
          .then((res) => {
            if (res.result === "success") {
              this.auditLoading = false;
              this.docs = res.data;
            } else if (res.result === "fail") {
              this.auditLoading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.auditLoading = false;
            console.log(err);
          });
      } catch (e) {
        console.log("error: ", e);
        this.auditLoading = false;
        this.cancel();
      }
    },
    async createAppointment() {
      this.tab = "appointment";
    },
    async addDeal() {
      this.tab = "deal";
    },
    async archive() {
      await this.$store
        .dispatch("insights/ARCHIVE_INSIGHT", { id: this.insight.id, status: 7 })
        .then((res) => {
          if (res.result === "success") {
            this.loadData();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async addAppointment() {
      this.tab = "appointment";
      this.form.appointment_date = this.changeDateFormat(this.appointmentDateUkFormat);
      if (this.$refs.appointmentForm.validate()) {
        await this.$store
          .dispatch("appointments/CREATE_APPOINTMENT", this.form)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
