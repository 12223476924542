<template>
  <div class="d-flex">
    <v-btn
      href="https://video.drift.com/v/abkLsHEYG95/"
      target="_blank"
      class="b-btn b-small mr-4"
      color="red"
      depressed
      v-if="!$vuetify.breakpoint.smAndDown && showVideoTraining === 'true'"
    >
      Video Training
    </v-btn>
    <v-text-field
      flat
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      label="Search for a appointment…"
      solo
      hide-details
      class="search-input"
      @input="isTyping = true"
      v-model="search"
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="reset"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" md="6" sm="6">
                <span class="heading">Date Range:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="enquiryDateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.start_date)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.start_date"
                      no-title
                      @input="enquiryDateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="enquiryDateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.end_date)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.end_date"
                      no-title
                      @input="enquiryDateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <span class="heading">Time:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    ref="timeFromMenu"
                    v-model="timeFromMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        hide-details="auto"
                        solo
                        class="advance-input"
                        v-model="filter.start_time"
                        prepend-inner-icon="mdil-clock"
                        placeholder="Time From"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-if="timeFromMenu"
                      v-model="filter.start_time"
                      full-width
                      @click:minute="$refs.timeFromMenu.save(filter.start_time)"
                    ></v-time-picker>
                  </v-menu>
                  <v-menu
                    ref="timeToMenu"
                    v-model="timeToMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        hide-details="auto"
                        solo
                        class="advance-input"
                        v-model="filter.end_time"
                        prepend-inner-icon="mdil-clock"
                        placeholder="Time To"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :allowed-minutes="allowedStep"
                      no-title
                      ampm-in-title
                      format="24hr"
                      v-if="timeToMenu"
                      v-model="filter.end_time"
                      full-width
                      @click:minute="$refs.timeToMenu.save(filter.end_time)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="6" sm="6">
                <span class="heading"
                  >Branch:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="BranchToggle"
                    @click="handleFunction('BranchCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="branch in BrachDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="branch.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="branchToggle(branch)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn";
import advancedSearch from "../../mixins/advancedSearch";
import status from "../../mixins/status";
import eventBus from "../../eventBus.js";
import _ from "lodash";
export default {
  name: "appointmentsearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false,
    showDatePicker: false,
    enquiryDateFromMenu: false,
    enquiryDateToMenu: false,
    timeFromMenu: false,
    timeToMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    params: "?&sort_order=DESC&sort_by=name",
    filter: {
      search: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      branch: [],
    },
    search: "",
    isTyping: false,
    showVideoTraining: process.env.MIX_SHOW_VIDEO_TRAINING,
  }),
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.filter.search = this.search;
        this.checkAll();
        this.searchKey();
      }
    },
  },
  mounted() {
    this.loadDefaults();
  },
  methods: {
    async searchKey() {
      this.$root.$emit("searchPabauAppointmentsTable", this.filter);
    },
    async reset() {
      await this.resetFilter();
      await this.loadDefaults();
    },
    async resetFilter() {
      this.filter.search = "";
      this.filter.start_date = "";
      this.filter.end_time = "";
      this.filter.end_date = "";
      this.filter.start_time = "";
    },
  },
};
</script>
