export default {
  data: () => ({
    widgets: [
      {
        name: "Live Opportunities",
        active: false,
        slug: "live",
        module: ["customer"],
        svg: "/images/icons/duotune/general/mail-open.svg",
      },
      {
        name: "Active Contacts",
        active: false,
        slug: "active",
        module: ["customer"],
        svg: "/images/icons/duotune/general/user.svg",
      },
    ],
  }),
  mounted() {
    this.loadCount();
  },
  methods: {
    async loadCount() {
      let params = {};
      params.enquiry_from = this.payload.filter.enquiry_from;
      params.enquiry_to = this.payload.filter.enquiry_to;
      params.followup_from = this.payload.filter.followup_from;
      params.followup_from_time = this.payload.filter.followup_from_time;
      params.followup_to = this.payload.filter.followup_to;
      params.followup_to_time = this.payload.filter.followup_to_time;
      params.status = this.payload.filter.status;
      params.branch = this.payload.filter.branch;
      params.source = this.payload.filter.source;
      params.assigned = this.payload.filter.assigned;
      params.assigned_to = this.payload.filter.assigned_to;
      params.source_blank = this.payload.filter.source_blank;
      let counts = {};
      await this.$store.dispatch("contacts/WIDGET_COUNT", params).then((res) => {
        counts = res.data;
      });
      this.widgets = this.widgets.map(function (el) {
        let item = Object.assign({}, el);
        item.count = counts.hasOwnProperty(el.slug) ? counts[el.slug] : 0;
        return item;
      });
    },
  },
};
