<template>
  <div :class="data.length > 50 ? 'width-200' : ''">
    <template v-if="!readMore">
      <span
        class="font-13 line-h-16 font-weight--semibold text-black-300"
        v-if="data.length > 50"
        >{{ data.slice(0, 50).concat("...") }}</span
      >
      <span class="font-13 line-h-16 font-weight--semibold text-black-300" v-else>{{ data }}</span>
      <p
        class="font-12 line-h-17 font-weight--regular cursor text-decoration-underline mt-1"
        v-if="!readMore && data.length > 50"
        @click="readMore = !readMore"
      >
        Read more
      </p>
    </template>
    <template v-if="readMore">
      <span class="font-13 line-h-16 font-weight--semibold text-black-300 text-capitalize">{{
        data
      }}</span>
      <p
        v-if="readMore"
        class="font-12 line-h-17 cursor font-weight--regular text-decoration-underline mt-1"
        @click="readMore = !readMore"
      >
        Read less
      </p>
    </template>
  </div>
</template>
<script>
export default {
  name: "ReadMore",
  props: {
    data: {
      required: true,
    },
  },

  data() {
    return {
      readMore: false,
    };
  },
};
</script>
<style lang="css" scoped></style>
