<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.user="{ item }">
                <div class="name-column">
                  <p class="user-name">
                    {{ item.user.name }}
                  </p>
                  <p class="user-location">
                    {{ item.user.branch_name }}
                  </p>
                </div>
              </template>
              <template v-slot:item.phone="{ item }">
                <p
                  class="mt-4 ml-n1"
                  v-if="item.user.contact_no !== null && item.user.contact_no !== ''"
                >
                  <span class="contact">
                    <a :href="`tel:${item.user.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.user.contact_no }}</a
                    >
                  </span>
                </p>
                <p
                  class="mt-4 ml-n1 mb-4"
                  v-if="item.user.email !== null && item.user.email !== ''"
                >
                  <span class="contact">
                    <a :href="`mailto:${item.user.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.user.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.next_followup="{ item }">
                <p class="date-sec date-mute">
                  <v-btn class="badge badge-grey" icon>
                    <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                  </v-btn>
                  {{ item.next_followup | moment("DD/MM/YY") }}
                </p>
              </template>
              <template v-slot:item.treatment="{ item }">
                <div class="mb-3">
                  <v-chip small class="este-chip tag-chip" text-color="black">
                    {{ item.treatment.name | capitalize }}
                  </v-chip>
                </div>
                <p class="user-location ml-2">
                  {{ item.type_name }}
                </p>
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('insight');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    text-color="#221F20"
                    :color="stat.outline ? '' : stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">View Insight</a></li>
                      <li><a @click="pushBack(item)">Push Back</a></li>
                      <li>
                        <a class="text-mute" @click="archiveItem(item)" v-if="item.status !== 7"
                          >Archive</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import eventBus from "../../../eventBus.js";
import moment from "moment";
export default {
  name: "Complaints",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      showCalendar: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "user", align: "left", sortable: false },
        { text: "Contact Details", value: "phone", align: "left", sortable: false },
        { text: "Services / Treatments", value: "treatment", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        { text: "Date", value: "next_followup", sortable: false, align: "left" },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      InsightParams: {
        attributes: "user,deal,appointment,treatment",
        search: "",
        filter: {
          followup_date_from: "",
          followup_date_to: "",
          status: [],
          branch: [],
        },
      },
      showInsightParams: "?attributes=user,deal,appointment,treatment",
      message: "",
      action: "",
      actionText: "",
      calendarData: [],
      start: "",
      end: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("insightsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchInsights", this.getSearchAppointments);
  },
  created() {},
  methods: {
    async getSearchAppointments(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.InsightParams.search = data.search;
        this.InsightParams.filter.followup_date_from = data.followup_date_from;
        this.InsightParams.filter.followup_date_to = data.followup_date_to;
        this.InsightParams.filter.status = data.status;
        this.InsightParams.filter.branch = data.branch;
        await this.readTheDataFromApi();
      } else {
        this.InsightParams.search = "";
        this.InsightParams.filter.followup_date_from = "";
        this.InsightParams.filter.followup_date_to = "";
        this.InsightParams.filter.status = [];
        this.InsightParams.filter.branch = [];
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("insights/GET_INSIGHT", {
          data: this.InsightParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      let result = [];
      await this.$store
        .dispatch("insights/SHOW_INSIGHT", { id: item.id, param: this.showInsightParams })
        .then((res) => {
          this.openDrawer("add", "750", "insights/view");
          result = res.data;
        });
      this.$root.$emit("loadLeadDetails", result);
    },
    async deal(item) {
      let result = [];
      await this.$store
        .dispatch("insights/SHOW_INSIGHT", { id: item.id, param: this.showInsightParams })
        .then((res) => {
          this.openDrawer("add", "750", "deals/add");
          result = res.data;
        });
      this.$root.$emit("updateDealUserId", result);
    },
    handleFunction(funName) {
      this[funName]();
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this Insight?";
      this.actionText = "Archive";
      this.action = "archiveAppointment";
    },
    async pushBack(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to push back this Insight?";
      this.actionText = "Confirm";
      this.action = "pushBackInsight";
    },
    async pushBackInsight() {
      await this.$store
        .dispatch("insights/PUSHBACK_INSIGHT", this.selectedItem.id)
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            res.message = "Push back failed.Please update the period for the treatment, try again";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archiveAppointment() {
      this.selectedItem.status = 7;
      await this.$store
        .dispatch("insights/ARCHIVE_INSIGHT", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
