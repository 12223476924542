import eventBus from "../../../eventBus.js";
export default {
  data: () => ({
    loading: false,
    reminderLoading: false,
    enquiryMenu: false,
    followUpMenu: false,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    activeIndex: null,
    appointmentDateMenu: false,
    appointmentFromTimeMenu: false,
    appointmentToTimeMenu: false,
    followUpTimeMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    rules: {
      user_id: [(v) => !!v || "Please select client"],
      branch_id: [(v) => !!v || "Please select branch"],
    },
    clientParams: "?sort_by=name&sort_order=ASC",
    staff: [],
    reasonSearch: null,
    sourceSearch: null,
    suggestedTreatments: [],
    staffParams: {
      branch: "",
      type: "appointment",
    },
    navigationTab: null,
    navigation: [
      {
        name: "Personal Details",
        icon: "mdil-account",
        slug: "personal",
        form: true,
      },
      // {
      //     name: "Lead Details",
      //     icon: "mdil-star",
      //     slug: "lead",
      //     form: true,
      // },
      // {
      //     name: "Client Details",
      //     icon: "mdil-star",
      //     slug: "client",
      //     form: true,
      // },
      {
        name: "Edit Appointment",
        icon: "mdil-calendar",
        slug: "edit-appointment",
        form: true,
      },
      {
        name: "Deals",
        icon: "mdil-book",
        slug: "deal",
        form: false,
      },
      {
        name: "Insights",
        icon: "mdil-chart-areaspline",
        slug: "insight",
        form: false,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        form: false,
      },
      {
        name: "Appointments",
        icon: "mdil-calendar",
        slug: "appointments",
        form: true,
      },
      {
        name: "Consent Forms",
        icon: "mdi-information-outline",
        slug: "consent",
        form: true,
      },
      {
        name: "Appointment Notes",
        icon: "mdi-information-outline",
        slug: "notes",
        form: true,
      },
      {
        name: "Document Uploads",
        icon: "mdi-upload-box-outline",
        slug: "upload-document",
        form: true,
      },
      {
        name: "Financials",
        icon: "mdi-history",
        slug: "financials",
        form: false,
      },
      // {
      //     name: "Packages",
      //     icon: "mdi-history",
      //     slug: "packages",
      //     form: false,
      // },
      // {
      //     name: "Communication",
      //     icon: "mdi-history",
      //     slug: "communication",
      //     form: false,
      // },
      // {
      //     name: "EMR",
      //     icon: "mdi-history",
      //     slug: "emr",
      //     form: false,
      //     submenu: [
      //         {
      //             name: "Consent",
      //             slug: "consent",
      //             form: false,
      //         },
      //         {
      //             name: "Treatment Note",
      //             slug: "treatment-note",
      //             form: false,
      //         },
      //         {
      //             name: "Photos",
      //             slug: "photos",
      //             form: false,
      //         },
      //         {
      //             name: "Documents",
      //             slug: "documents",
      //             form: false,
      //         },
      //         {
      //             name: "Prescriptions",
      //             slug: "prescriptions",
      //             form: false,
      //         },
      //         {
      //             name: "Medical History",
      //             slug: "medial-history",
      //             form: false,
      //         },
      //         {
      //             name: "Lab Tests",
      //             slug: "lab-test",
      //             form: false,
      //         },
      //     ],
      // },
      // {
      //     name: "Gift Voucher",
      //     icon: "mdi-history",
      //     slug: "gift-voucher",
      //     form: false,
      // },
      // {
      //     name: "Loyalty",
      //     icon: "mdi-history",
      //     slug: "loyalty",
      //     form: false,
      // },
      // {
      //     name: "Task & Recalls",
      //     icon: "mdi-history",
      //     slug: "task-recalls",
      //     form: false,
      // },
    ],
    contactChange: false,
    emailChange: false,
    socailHandle: false,
  }),
  watch: {
    "appointment.user.social_handle": _.debounce(function () {
      if (this.lead.social_handle) {
        this.autoSelectSocialIcon();
      }
      this.socailHandle = true;
    }, 1000),
    "appointment.user.contact_no"(val) {
      this.contactChange = true;
    },
    "appointment.user.email"(val) {
      this.emailChange = true;
    },
    "appointment.user.social_handle"(val) {
      this.socailHandle = true;
    },
    "appointment.appointment_date": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "appointment.appointment_from_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "appointment.appointment_to_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
  },
  mounted() {
    this.navigationTab = "appointment";
    this.staffByBranch();
  },
  computed: {
    appointment() {
      if (this.$store.state.appointments.item) {
        if (!this.$store.state.appointments.item.user.other_details) {
          this.$store.state.appointments.item.user.other_details = {};
        }
        return this.$store.state.appointments.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    clients() {
      if (this.$store.state.clients.all) {
        return this.$store.state.clients.all;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("clients/GET_CLIENT_LIST", this.clientParams);
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
  },
  methods: {
    updateStatus() {
      if (
        this.appointment.appointment_date &&
        this.appointment.appointment_from_time &&
        this.appointment.appointment_to_time
      ) {
        this.appointment.status = 5;
      } else if (
        this.appointment.appointment_date &&
        !this.appointment.appointment_from_time &&
        !this.appointment.appointment_to_time
      ) {
        this.appointment.status = 11;
      }
    },
    async autoSelectSocialIcon() {
      let isValid = this.isValidUrl(this.appointment.user.social_handle);
      if (isValid) {
        let url = new URL(this.appointment.user.social_handle);
        this.appointment.user.source_id = this.getSourceId(url.origin);
      }
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async removeService(item) {
      const index = this.appointment.user.user_details.treatments.findIndex(
        (x) => x.id === item.id
      );
      if (index >= 0) {
        this.appointment.user.user_details.treatments.splice(index, 1);
      } else {
        const i = this.appointment.user.user_details.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.appointment.user.user_details.treatments.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.appointment.user.user_details.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.appointment.user.user_details.symptoms.splice(index, 1);
      } else {
        const i = this.appointment.user.user_details.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.appointment.user.user_details.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeReason(item) {
      if (item.id) {
        const index = this.appointment.user.user_details.reasons.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.appointment.user.user_details.reasons.splice(index, 1);
        } else {
          const i = this.appointment.user.user_details.reasons.findIndex((x) => x === item.id);
          if (i >= 0) this.appointment.user.user_details.reasons.splice(i, 1);
        }
      } else {
        const index = this.appointment.user.user_details.reasons.findIndex((x) => x.id === item);
        if (index >= 0) {
          this.appointment.user.user_details.reasons.splice(index, 1);
        } else {
          const i = this.appointment.user.user_details.reasons.findIndex((x) => x === item);
          if (i >= 0) this.appointment.user.user_details.reasons.splice(i, 1);
        }
      }
    },
    async symptomChange() {
      const ids = [];
      this.appointment.user.user_details.symptoms.forEach((value) => {
        if (value.id) {
          ids.push({ id: value.id });
        } else {
          ids.push(value);
        }
      });
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", {
          symptoms: ids,
        })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async suggTreat(item) {
      let exists = this.appointment.user.user_details.treatments.some((el) => el.id === item.id)
        ? true
        : this.appointment.user.user_details.treatments.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.appointment.user.user_details.treatments.push(item.id);
      } else {
        const index = this.appointment.user.user_details.treatments.findIndex(
          (x) => x.id === item.id
        );
        if (index >= 0) {
          this.appointment.user.user_details.treatments.splice(index, 1);
        } else {
          const i = this.appointment.user.user_details.treatments.findIndex((x) => x === item.id);
          if (i >= 0) this.appointment.user.user_details.treatments.splice(i, 1);
        }
      }
    },
    async staffByBranch() {
      this.staff = [];
      this.staffParams.branch = this.appointment.branch_id;
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", {
          data: this.staffParams,
          params: "",
        })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      eventBus.$emit("StatsRefresh");
      if (this.$router.currentRoute.meta.slug === "app-treatments") {
        this.$root.$emit("treatmentsTableUpdate", "");
      } else {
        this.$root.$emit("appointmentsTableUpdate", "");
        this.$root.$emit("updateGlobalSearch", "");
      }
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.appointment.client_satisfaction = item.name.toLowerCase();
    },
    async update() {
      let formData = {};
      formData.id = this.appointment.id;
      formData.user_id = this.appointment.user_id;
      formData.appointment_type = this.appointment.appointment_type;
      formData.appointment_from_time = this.appointment.appointment_from_time;
      formData.appointment_to_time = this.appointment.appointment_to_time;
      formData.branch_id = this.appointment.branch_id;
      formData.consultant_id = this.appointment.consultant_id;
      formData.client_satisfaction = this.appointment.client_satisfaction;
      formData.status = this.appointment.status;
      formData.appointment_date = this.appointment.appointment_date;
      formData.treatment_id = [];
      this.appointment.treatments.forEach((value) => {
        if (value.id) {
          formData.treatment_id.push(value.id);
        } else {
          formData.treatment_id.push(value);
        }
      });
      if (this.$refs.appointmentForm.validate()) {
        this.loading = true;
        await this.updatePersonal();
        await this.$store
          .dispatch("appointments/UPDATE_APPOINTMENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async updatePersonal() {
      let formData = {};
      formData.id = this.appointment.user.id;
      formData.title = this.appointment.user.title;
      formData.name = this.appointment.user.name;
      formData.address_one = this.appointment.user.address_one;
      formData.address_two = this.appointment.user.address_two;
      formData.city = this.appointment.user.city;
      formData.postcode = this.appointment.user.postcode;
      formData.branch_id = this.appointment.user.branch_id;
      if (this.contactChange) {
        formData.contact_no = this.appointment.user.contact_no;
      }
      if (this.socailHandle) {
        formData.social_handle = this.appointment.user.social_handle;
      }
      if (this.appointment.user.email && this.emailChange) {
        formData.email = this.appointment.user.email;
      }

      formData.status = this.appointment.user.status;
      formData.responsible_id = this.appointment.user.other_details.responsible_id;
      formData.source_id = this.appointment.user.source_id;

      formData.service_recommended = [];
      this.appointment.user.user_details.treatments.forEach((value) => {
        if (value.id) {
          formData.service_recommended.push(value.id);
        } else {
          formData.service_recommended.push(value);
        }
      });

      formData.symptoms = [];
      this.appointment.user.user_details.symptoms.forEach((value) => {
        if (value.id) {
          formData.symptoms.push(value.id);
        } else {
          formData.symptoms.push(value);
        }
      });

      if (this.appointment.user.user_type_name === "Lead") {
        formData.reasons = [];
        this.appointment.user.user_details.reasons.forEach((value) => {
          if (value.id) {
            formData.reasons.push({ id: value.id });
          } else {
            formData.reasons.push(value);
          }
        });
        formData.enquiry_date = this.appointment.user.other_details.enquiry_date;
        formData.follow_up_date = this.appointment.user.other_details.follow_up_date;
        formData.follow_up_time = this.appointment.user.other_details.follow_up_time;
      }

      if (this.appointment.user.user_type_name === "Client") {
        formData.client_satisfaction = this.appointment.user.client_satisfaction;
      }

      let action =
        this.appointment.user.user_type_name === "Lead"
          ? "leads/UPDATE_LEAD"
          : "clients/UPDATE_CLIENT";
      this.loading = true;
      await this.$store
        .dispatch(action, formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            this.loading = false;
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async SendReminder(item) {
      this.reminderLoading = true;
      await this.$store
        .dispatch("sms/SEND_SMS", { id: item.id, model: "appointment" })
        .then((res) => {
          if (res.result === "success") {
            this.reminderLoading = false;
            res.type = "success";
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            this.reminderLoading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.reminderLoading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    shouldRenderTab(item) {
      if (item.slug === "client") {
        return this.appointment.user.user_type_name === "Client";
      } else if (item.slug === "lead") {
        return this.appointment.user.user_type_name === "Lead";
      } else {
        return true; // Render all other tabs
      }
    },
  },
};
