<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Form</div>
        <v-form ref="form">
          <div
            id="forms-wrapper"
            class="tab-content-wrapper"
            style="height: 85vh; overflow-y: auto"
          >
            <v-row>
              <v-col cols="12">
                <v-card text class="consent-card">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          flat
                          hide-details="auto"
                          class="solo-cust"
                          placeholder="Form title"
                          v-model="formData.title"
                          :rules="rules.title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <vue-editor v-model="formData.description" />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="formTypes"
                          placeholder="Select form type"
                          autocomplete="off"
                          solo
                          outlined
                          flat
                          hide-details="auto"
                          class="solo-cust text-capitalize"
                          v-model="formData.medical_form_type_id"
                          :rules="rules.medical_form_type_id"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          autocomplete="off"
                          :ripple="false"
                          hide-details="auto"
                          label="Required patient sign"
                          off-icon="mdi-checkbox-blank-circle-outline"
                          on-icon="mdi-check-circle"
                          v-model="formData.require_patient_sign"
                          :true-value="1"
                          :false-value="0"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          autocomplete="off"
                          :ripple="false"
                          hide-details="auto"
                          label="Required consultant sign"
                          off-icon="mdi-checkbox-blank-circle-outline"
                          on-icon="mdi-check-circle"
                          v-model="formData.require_consultant_sign"
                          :true-value="1"
                          :false-value="0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="11" v-for="(item, index) in formElements">
                <FormElement
                  :key="item.id"
                  :formDetails="item"
                  @deleteForm="deleteForm"
                  @close="close"
                  :formId="formId"
                />
              </v-col>
              <v-col cols="1" class="position-relative">
                <FloatingToolbar @addNewForm="addNewForm" />
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="addForm"
        >
          Add Form
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
import FormElement from "../../../../components/FormBuilder/FormElement.vue";
import FloatingToolbar from "../../../../components/FormBuilder/FloatingToolbar.vue";
import { VueEditor } from "vue2-editor";
const initialValue = {
  title: "",
  description: "",
  type: "short_answer",
  is_required: false,
  medical_form_id: null,
  order: 1,
};

export default {
  mixins: [status, globalFn],
  components: {
    FormElement,
    FloatingToolbar,
    VueEditor,
  },
  data: () => ({
    formData: {
      title: "",
      description: "",
      medical_form_type_id: null,
      require_patient_sign: 0,
      require_consultant_sign: 0,
    },
    formElements: [
      {
        id: 1,
        title: "",
        description: "",
        type: "short_answer",
        is_required: false,
        medical_form_id: null,
        order: 1,
      },
    ],
    rules: {
      title: [(v) => !!v || "Please enter a title"],
      medical_form_type_id: [(v) => !!v || "Please select a form type"],
    },
    formId: null,
  }),
  computed: {
    formTypes() {
      const types = this.$store.state.formType.types;

      return types.map((type) => {
        return {
          value: type.id,
          text: this.formatStr(type.name),
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("formType/GET_FORM_TYPE");
  },
  methods: {
    async addForm() {
      if (this.$refs.form.validate()) {
        const res = await this.$store.dispatch("form/CREATE_FORM", this.formData).then((res) => {
          this.formId = res.data.id;
        });
      }
    },
    close() {
      this.$root.$emit("formTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    addNewForm() {
      let order = this.formElements[this.formElements.length - 1]?.order;

      const body = order
        ? {
            id: Math.random(),
            ...initialValue,
            order: ++order,
          }
        : {
            id: Math.random(),
            ...initialValue,
          };

      this.formElements.push(body);

      setTimeout(() => {
        let content = document.getElementById("forms-wrapper");

        content.scrollTo({
          top: content.scrollHeight - content.clientHeight,
          behavior: "smooth",
        });
      }, 200);
    },
    deleteForm(id) {
      const newArr = this.formElements
        .filter((item) => item.id !== id)
        .map((item, index) => ({
          ...item,
          order: ++index,
        }));

      this.formElements = newArr;

      if (this.formElements.length < 1) this.addNewForm();
    },
    formatStr(str) {
      return str.split("_").join(" ");
    },
  },
};
</script>
<style lang="scss">
#forms-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  position: relative;
  padding: 1rem;
}

.forms-wrapper::-webkit-scrollbar {
  display: none;
}

.forms-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
