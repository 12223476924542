<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0 pt-3">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :options.sync="options"
            >
              <template v-slot:item.user_ids="{ item }">
                <span
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                  v-if="item.user_ids.length > 0"
                  >{{ item.user_ids.length }}</span
                >
              </template>
              <template v-slot:item.body="{ item }">
                <span class="font-14 line-h-17 font-weight--semibold text-black-100">
                  {{ item.body }}
                </span>
              </template>
              <template v-slot:item.sent_by="{ item }">
                <span
                  v-if="item.sent_by !== null"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                >
                  {{ getInitials(item.sent_by.name) }}
                </span>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>
              </template>
              <template v-slot:item.schedule_send_date="{ item }">
                <div class="d-flex flex-column">
                  <span class="font-13 line-h-16 fw-semibold text-black-300 mb-1">{{
                    item.schedule_send_date | moment("DD/MM/YY")
                  }}</span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-end">
                  <v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
                    <img height="16" src="/images/icons/duotune/general/gen055.svg" />
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Delete' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "Notifications",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Message", value: "body", sortable: false },
        { text: "Sent By", value: "sent_by", sortable: false },
        { text: "Sent To", value: "user_ids", sortable: false },
        { text: "Date Sent", value: "schedule_send_date", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "actions", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      payload: {
        per_page: 10,
        page: 1,
        search: "",
        start_date: "",
        end_date: "",
        branch: [],
        status: [],
        contacts: [],
      },
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("refreshNotificationsTable", this.readTheDataFromApi);
    this.$root.$on("searchNotifications", this.searchNotifications);
  },
  created() {},
  methods: {
    async searchNotifications(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.payload.search = data.search;
        this.payload.start_date = data.start_date;
        this.payload.end_date = data.end_date;
        this.payload.branch = data.branch;
        this.payload.contacts = data.contacts;
        this.payload.status = data.status;
        await this.readTheDataFromApi();
      } else {
        this.payload.search = "";
        this.payload.start_date = "";
        this.payload.end_date = "";
        this.payload.branch = [];
        this.payload.contacts = [];
        this.payload.status = [];
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("notifications/GET", this.payload).then((res) => {
        this.items = res.data.data;
        this.totalCount = res.data.total;
        this.loading = false;
      });
    },

    async editItem(item) {
      await this.$store.dispatch("notifications/SHOW", { id: item.id }).then((res) => {
        this.openDrawer("add", "750", "notifications/update");
      });
    },

    handleFunction(funName) {
      this[funName]();
    },
    async updateStatus(item, value) {
      this.selectedItem = item;
      this.selectedItem.status = value;
      this.modalDialog = true;
      const status = value === 1 ? "Active" : "Inactive";
      this.message = `Please confirm you want to mark as ${status}?`;
      this.actionText = "Confirm";
      this.action = "update";
    },

    async deleteItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = " Please confirm you want to delete it?";
      this.actionText = "Delete";
      this.action = "delete";
    },

    async update() {
      await this.$store
        .dispatch("notifications/UPDATE", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async delete() {
      await this.$store
        .dispatch("notifications/delete", { id: this.selectedItem.id })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
