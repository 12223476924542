<template>
  <v-row>
    <v-col cols="12">
      <div class="font-14 line-h-17 font-weight--semibold text-black-100">
        {{ element.title }}
        <span class="red--text required" v-if="element.is_required === 1">*</span>
      </div>
    </v-col>
    <v-col cols="12" v-if="element.description">
      <div
        class="font-13 line-h-16 text-black-300 font-weight--semibold"
        v-html="element.description"
      ></div>
    </v-col>
    <v-col cols="12">
      <FormElementType :type="element.type" :answers="element.answers" />
    </v-col>
  </v-row>
</template>
<script>
import FormElementType from "./FormElementType.vue";

export default {
  name: "form-card",
  props: ["element"],
  components: {
    FormElementType,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
