import collection from "../../../api/collections/Rota";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
  },
  mutations: {
    SET_ROTA_LIST(state, data) {
      state.list = data;
    },
    SET_ROTA_ITEM(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_ROTA_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },

    async SHOW({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.show(payload);
          commit("SET_ROTA_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW",
        false
      );
      return data;
    },

    async CREATE_HOLIDAY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create_holiday(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_HOLIDAY",
        true
      );

      return data;
    },

    async UPDATE_HOLIDAY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update_holiday(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_HOLIDAY",
        true
      );

      return data;
    },

    async DELETE_HOLIDAY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete_holiday(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_HOLIDAY",
        true
      );

      return data;
    },

    async CREATE_SHIFT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create_shift(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_SHIFT",
        true
      );

      return data;
    },

    async DELETE_SHIFT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete_shift(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_SHIFT",
        true
      );

      return data;
    },

    async UPDATE_SHIFT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_SHIFT",
        true
      );

      return data;
    },

    async CREATE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create_machine(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_MACHINE",
        true
      );

      return data;
    },
    async DELETE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete_machine(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_MACHINE",
        true
      );

      return data;
    },

    async UPDATE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update_machine(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_MACHINE",
        true
      );

      return data;
    },

    async USER_COUNT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.user_count(payload);
          return data;
        },
        commit,
        dispatch,
        "USER_COUNT",
        true
      );

      return data;
    },

    async USER_SHIFT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.user_shift(payload);
          return data;
        },
        commit,
        dispatch,
        "USER_SHIFT",
        true
      );

      return data;
    },
  },
};
