<template>
  <v-row>
    <v-col cols="12">
      <div class="font-14 line-h-17 font-weight--semibold text-black-100">
        {{ element.title }}
        <span class="red--text required" v-if="element.is_required === 1">*</span>
      </div>
    </v-col>
    <v-col cols="12" v-if="element.description">
      <div
        class="font-13 line-h-16 text-black-300 font-weight--semibold"
        v-html="element.description"
      ></div>
    </v-col>
    <v-col cols="12"> </v-col>
  </v-row>
</template>
<script>
export default {
  name: "form-card",
  props: ["element"],
  data: () => ({
    answer_id: null,
  }),
  computed: {},
};
</script>
<style lang="scss" scoped></style>
