<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-btn
          :elevation="0"
          :ripple="false"
          text
          class="font-13 line-h-16 font-weight--semibold text-black-300 text-capitalize mb-5 letter-s-0"
          @click="$router.push({ path: '/customers' })"
        >
          <v-icon size="15" color="black">mdi-arrow-left</v-icon>
          Back to Contacts
        </v-btn>
        <v-row class="mt-6" v-if="loading">
          <v-col cols="12" md="12">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
        <template v-if="!loading">
          <v-card flat class="pa-7">
            <div class="d-flex mb-4">
              <span
                class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                v-if="contact && contact.name"
              >
                {{ contact.name }}
              </span>
              <span
                class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                v-if="contact && contact.name === null"
              >
                <img
                  :src="`/images/${lowerCase(contact.source_name)}.svg`"
                  class="mr-2"
                  v-if="contact.source_name !== null"
                />
                {{ contact.social_handle }}
              </span>
              <template v-for="(val, index) in statusTags">
                <span
                  class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                  :style="
                    val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                  "
                  :key="index"
                  v-if="contact.status === val.id"
                  >{{ val.name }}</span
                >
              </template>
            </div>
            <div class="d-flex">
              <span
                class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                :class="contact.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                >{{ contact.user_type_name }}</span
              >
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.branch_name"
              >
                <span class="mr-1">
                  <inline-svg src="/images/icons/map.svg" />
                </span>
                {{ contact.branch_name }}
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.email"
              >
                <span class="mr-1">
                  <inline-svg src="/images/icons/at.svg" />
                </span>
                {{ contact.email }}
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.contact_no"
              >
                <span class="mr-1">
                  <inline-svg src="/images/icons/mobile.svg" />
                </span>
                {{ contact.contact_no }}
                <v-tooltip
                  content-class="copy-tooltip"
                  :open-on-hover="false"
                  :open-on-click="true"
                  color="#000"
                  text-color="#fff"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyContent(contact.contact_no)"
                      v-on="on"
                      size="15"
                      class="ml-2"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copied to clipboard</span>
                </v-tooltip>
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.social_handle !== null"
              >
                <img
                  :src="`/images/${lowerCase(contact.source_name)}.svg`"
                  height="15"
                  class="mr-1"
                  v-if="contact.source_name !== null"
                />
                {{ contact.social_handle }}
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.date_of_birth"
              >
                <span class="mr-1">
                  <v-icon size="16" color="black">mdil-calendar</v-icon>
                </span>
                {{ contact.date_of_birth | moment("DD/MM/YY") }}
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                v-if="contact.gender"
              >
                <span class="mr-1">
                  <v-icon size="16" color="black">mdil-account-outline</v-icon>
                </span>
                {{ contact.gender }}
              </span>
            </div>
            <div class="d-flex mt-4">
              <div class="widget--chip">
                <p
                  class="mb-2 font-21 line-h-25 font-weight--bold text-black-400"
                  v-if="contact.points_earned"
                >
                  {{ contact.points_earned.points_spent }}
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">
                  Total Spent
                </p>
              </div>
              <div class="widget--chip">
                <p class="mb-2 font-21 line-h-25 font-weight--bold text-black-400">
                  {{ contact.referrals }}
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">Referrals</p>
              </div>
              <div class="widget--chip">
                <p
                  class="mb-2 font-21 line-h-25 font-weight--bold text-black-400"
                  v-if="contact.points_earned"
                >
                  {{ contact.points_earned.total_points }}
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">Points</p>
              </div>
              <div class="widget--chip">
                <p class="mb-2 font-21 line-h-25 font-weight--bold text-black-400">0</p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">Deals</p>
              </div>
              <div class="widget--chip">
                <p class="mb-2 font-21 line-h-25 font-weight--bold text-black-400">
                  <span v-if="contact.next_appointment_date !== null">
                    {{ contact.next_appointment_date | moment("DD/MM/YY") }}
                  </span>
                  <span v-else>-</span>
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">
                  Next Appt. Date
                </p>
              </div>
              <div class="widget--chip">
                <p class="mb-2 font-21 line-h-25 font-weight--bold text-black-400">
                  {{ contact.live_opportunity_count }}
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">
                  Live Opportunities
                </p>
              </div>
              <div class="widget--chip">
                <p class="mb-2 font-21 line-h-25 font-weight--bold text-black-400">
                  {{ contact.live_complaints_count }}
                </p>
                <p class="mb-0 font-11 line-h-13 font-weight--semibold text-black-300">
                  Live Complaints
                </p>
              </div>
            </div>
          </v-card>
          <v-row class="mt-3">
            <v-col cols="12" md="12">
              <WidgetOpportunities />
              <WidgetComplaints />
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12" md="6">
              <WidgetCallbackNotes />
              <v-card class="mt-6 pb-5">
                <v-card-title
                  class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6"
                >
                  Details
                </v-card-title>
                <v-divider class="my-5"></v-divider>
                <v-card-text class="px-7 py-0">
                  <div class="border rounded-6 px-6 py-5">
                    <p class="font-14 line-h-17 font-weight--bold text-black-200">Address</p>
                    <p class="font-16 line-h-22 text-black-200 mb-0">{{ contact.address_one }}</p>
                    <p class="font-16 line-h-22 text-black-200 mb-0">{{ contact.address_two }}</p>
                    <p class="font-16 line-h-22 text-black-200 mb-0">{{ contact.city }}</p>
                    <p class="font-16 line-h-22 text-black-200 mb-0">{{ contact.postcode }}</p>
                  </div>
                  <div class="border rounded-6 px-6 py-5 mt-5">
                    <p class="font-14 line-h-17 font-weight--bold text-black-200 mb-3">
                      Preferred Branch
                    </p>
                    <p class="font-16 line-h-22 text-black-200">
                      {{ contact.branch_name }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between align-center">
                    <p class="font-15 line-h-18 fw-semibold text-black-600 mb-0">Receive SMS</p>
                    <v-switch color="secondary" v-model="contact.is_receive_sms" inset></v-switch>
                  </div>
                </v-card-text>
              </v-card>
              <v-card class="mt-6 pb-5">
                <v-card-title
                  class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6"
                >
                  Loyalty Points History
                </v-card-title>
                <v-divider class="my-5"></v-divider>
                <v-card-text class="px-7 py-0">
                  <template v-if="points.length > 0">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left font-14 line-h-17 font-weight--bold text-black-200"
                            >
                              Date
                            </th>
                            <th
                              class="text-left font-14 line-h-17 font-weight--bold text-black-200"
                            >
                              Points
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="item in points">
                            <tr>
                              <td>
                                <div class="d-flex flex-column">
                                  <p
                                    class="mb-0 font-14 line-h-17 font-weight--regular text-black-300"
                                  >
                                    {{ item.last_added | moment("DD/MM/YY") }}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex flex-column">
                                  <p
                                    class="font-16 line-h-21 font-weight--regular text-black-100 mb-1"
                                  >
                                    £{{ item.points }}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                  <template v-else>
                    <p>No records found!</p>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <WidgetAppointments />
              <WidgetActivity />
            </v-col>
          </v-row>
        </template>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import status from "../../../mixins/status";
import globalFn from "../../../mixins/globalFn";
import WidgetAppointments from "../../../components/contacts/WidgetAppointments.vue";
import WidgetCallbackNotes from "../../../components/contacts/WidgetCallbackNotes";
import WidgetActivity from "../../../components/contacts/WidgetActivity";
import WidgetPointsHistory from "../../../components/contacts/WidgetPointsHistory";
import WidgetOpportunities from "../../../components/contacts/WidgetOpportunities";
import WidgetComplaints from "../../../components/contacts/WidgetComplaints";
export default {
  name: "ViewContact",
  mixins: [status, globalFn],
  components: {
    WidgetAppointments,
    WidgetCallbackNotes,
    WidgetActivity,
    WidgetPointsHistory,
    WidgetOpportunities,
    WidgetComplaints,
  },
  data() {
    return {
      receiveSms: false,
      loading: false,
      points: [],
    };
  },
  computed: {
    contact() {
      return this.$store.state.contacts.item;
    },
  },
  mounted() {
    this.refreshScreen();
    this.$root.$on("refreshContact", this.refreshScreen);
    this.getPoints();
  },
  methods: {
    async refreshScreen() {
      this.loading = true;
      await this.$store.dispatch("contacts/CONTACT_BY_ID", {
        user_id: this.$router.currentRoute.params.id,
      });
      this.loading = false;
    },

    async getPoints() {
      this.loading = true;
      await this.$store
        .dispatch("contacts/LOYALTY_POINTS", { user_id: this.$router.currentRoute.params.id })
        .then((res) => {
          this.points = res.data;
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="css" scoped></style>
