<template>
  <v-row>
    <v-col cols="12">
      <div class="font-14 line-h-17 font-weight--semibold text-black-100">
        {{ element.title }}
        <span class="red--text required" v-if="element.is_required === 1">*</span>
      </div>
    </v-col>
    <v-col cols="12" v-if="element.description">
      <div
        class="font-13 line-h-16 text-black-300 font-weight--semibold"
        v-html="element.description"
      ></div>
    </v-col>
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="answer"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            flat
            hide-details="auto"
            solo
            class="solo-cust"
            v-model="answer"
            label="Please enter your input"
            append-icon="mdil-clock"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          :allowed-minutes="allowedStep"
          no-title
          ampm-in-title
          format="24hr"
          v-if="menu"
          v-model="answer"
          full-width
          @click:minute="$refs.menu.save(answer)"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "form-card",
  props: ["element"],
  data: () => ({
    answer: null,
    menu: false,
  }),
  watch: {
    answer: _.debounce(function () {
      this.submit();
    }, 1000),
  },
  methods: {
    allowedStep: (m) => m % 15 === 0,
    async submit() {
      this.$emit("updateAnswer", { element_id: this.element.id, answer: this.answer });
    },
  },
};
</script>
<style lang="scss" scoped></style>
