<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Add Appointment</div>

        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#client">Client Details</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside py-0 right-pane">
        <v-tabs-items v-model="tab">
          <div class="d-flex my-4">
            <span
              class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
              v-if="appointment.user && appointment.user.name"
            >
              {{ appointment.user.name }}
            </span>
            <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
              <img
                :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                class="mr-2"
                v-if="appointment.user.source_name !== null"
              />
              {{ appointment.user.social_handle }}
            </span>
          </div>
          <div class="d-flex">
            <span
              class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
              :class="appointment.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
              >{{ appointment.user.user_type_name }}</span
            >
            <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
              <span class="mr-1">
                <inline-svg src="/images/icons/map.svg" />
              </span>
              {{ appointment.user.branch_name }}
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.email"
            >
              <span class="mr-1">
                <inline-svg src="/images/icons/at.svg" />
              </span>
              {{ appointment.user.email }}
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.contact_no"
            >
              <span class="mr-1">
                <inline-svg src="/images/icons/mobile.svg" />
              </span>
              {{ appointment.user.contact_no }}
              <v-tooltip
                content-class="copy-tooltip"
                :open-on-hover="false"
                :open-on-click="true"
                color="#000"
                text-color="#fff"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyContent(appointment.user.contact_no)"
                    v-on="on"
                    size="15"
                    class="ml-2"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copied to clipboard</span>
              </v-tooltip>
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.social_handle !== null"
            >
              <img
                :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                height="15"
                class="mr-2"
                v-if="appointment.user.source_name !== null"
              />
              {{ appointment.user.social_handle }}
            </span>
          </div>
          <v-tab-item value="client">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <template v-if="!flag">
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt"> Find client: </span>
                      </label>
                      <div class="mt-2 mb-4" v-if="selectedAppointment">
                        <v-chip class="este-chip tag-chip px-3 mb-2">
                          <span v-if="selectedAppointment.name"
                            >{{ selectedAppointment.name }}
                          </span>
                          <span v-if="selectedAppointment.name === null"
                            >{{ selectedAppointment.social_handle }}
                          </span>
                          <span v-if="selectedAppointment.contact_no"
                            >/ {{ selectedAppointment.contact_no }}
                          </span>
                          <span v-if="selectedAppointment.email"
                            >/ {{ selectedAppointment.email }}
                          </span>
                          <v-icon
                            color="primary"
                            class="ml-2"
                            small
                            @click.stop="removeAppointmentedUser(item)"
                          >
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </div>
                      <v-text-field
                        flat
                        rounded
                        prepend-inner-icon="mdi-magnify"
                        :rules="rules.user_id"
                        autocomplete="off"
                        label="Search for a client"
                        class="solo-cust"
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        solo
                        hide-details="auto"
                        @input="isTyping = true"
                        v-model="search"
                        :loading="loading"
                      >
                      </v-text-field>
                      <ul
                        class="contact--list"
                        v-if="search.length > 3"
                        :class="appointments.length > 20 ? 'height-300' : ''"
                      >
                        <li v-for="(item, index) in appointments" :key="index">
                          <a
                            @click="selectContact(item)"
                            class="d-flex align-center flex-row cursor"
                          >
                            <span
                              class="font-12 line-h-15 font-weight--regular text-black-100 ml-3"
                              v-if="item"
                            >
                              <span v-if="item.name">{{ item.name }} </span>
                              <span v-if="item.name === null">{{ item.social_handle }} </span>
                              <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                              <span v-if="item.email">/ {{ item.email }} </span>
                            </span>
                            <span
                              class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                              :class="
                                item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                              "
                            >
                              {{ item.user_type_name === "Lead" ? "L" : "C" }}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatment / Service: </span>
                    </label>
                    <v-autocomplete
                      v-model="form.treatment_id"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Date: </span>
                      <v-menu
                        v-model="appointmentDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            autocomplete="off"
                            v-model="appointmentDateUkFormat"
                            solo
                            outlined
                            v-on="on"
                            flat
                            hide-details="auto"
                            class="solo-cust"
                            append-icon="mdil-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.appointment_date"
                          no-title
                          @input="appointmentDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt">Appointment Time:</span>
                          <v-menu
                            ref="appointmentFromTimeMenu"
                            v-model="appointmentFromTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_from_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust"
                                v-model="form.appointment_from_time"
                                label="From"
                                append-icon="mdil-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentFromTimeMenu"
                              v-model="form.appointment_from_time"
                              full-width
                              @click:minute="
                                $refs.appointmentFromTimeMenu.save(form.appointment_from_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                          <v-menu
                            ref="appointmentToTimeMenu"
                            v-model="appointmentToTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_to_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust mt-5"
                                v-model="form.appointment_to_time"
                                label="To"
                                append-icon="mdil-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentToTimeMenu"
                              v-model="form.appointment_to_time"
                              full-width
                              @click:minute="
                                $refs.appointmentToTimeMenu.save(form.appointment_to_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        :rules="rules.branch_id"
                        v-model="form.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                        @change="staffByBranch"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Select Consultant </span>
                      <v-select
                        autocomplete="off"
                        :items="staff"
                        v-model="form.consultant_id"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Machines </span>
                    </label>

                    <v-select
                      autocomplete="off"
                      item-value="id"
                      :items="machines"
                      v-model="form.machine"
                      item-text="name"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <template v-if="!flag">
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt"> Appointment Type </span>
                      </label>
                      <v-select
                        autocomplete="off"
                        :items="appointmentType"
                        v-model="form.appointment_type"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="
                        SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('appointment');
                          })
                        )
                      "
                      v-model="form.status"
                      :rules="rules.status"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="disabled"
          rounded
          x-large
          @click="addAppointment"
        >
          Add Appointment
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
import add from "./add.js";
export default {
  mixins: [globalFn, status, add],
  data: () => ({
    flag: null,
  }),
  computed: {
    appointment() {
      if (this.$router.currentRoute.meta.name === "Appointments") {
        if (this.$store.state.appointments.item) {
          return this.$store.state.appointments.item;
        }
      }
      if (this.$store.state.opportunities.item) {
        return this.$store.state.opportunities.item;
      }
    },
  },
  created() {
    if (this.$router.currentRoute.meta.name === "Opportunities") {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },
};
</script>
