<template>
  <v-menu offset-y :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        flat
        :elevation="0"
        color="#FFF6CC"
        tonal
        height="80%"
        rounded="sm"
        class="holiday-slot"
        v-bind="attrs"
        v-on="on"
      >
        <v-card-title class="text-subtitle-1 font-weight-medium text-black">Holiday</v-card-title>
      </v-card>
    </template>

    <Update
      @close="menu = false"
      @expandForm="expandForm"
      :rota="rota"
      :consultant="consultant"
      :date="date"
    />
  </v-menu>
</template>

<script>
import Update from "./update.vue";
// import modalFn from "../../../mixins/modalFn";
export default {
  // mixins: [modalFn],
  props: ["consultant", "date", "rota"],
  components: { Update },
  data: () => ({
    menu: false,
  }),
  methods: {
    // async expandForm() {
    //   this.$store.dispatch("rota/SHOW", {
    //     id: this.rota.id,
    //     type: this.consultant.hasOwnProperty("user_type") ? "user" : "machine",
    //   });
    //   this.openModal("edit", 1100, "Scheduler/update");
    // },
  },
};
</script>

<style lang="scss" scoped>
.holiday-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffe14c !important;
  border-left-width: 5px solid #ffe14c !important;
}
</style>
