<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card class="widget-card">
          <v-row>
            <template v-for="(item, index) in widgets">
              <v-col cols="12" md="3" sm="6">
                <div
                  class="widget"
                  :class="item.active ? 'selected' : ''"
                  @click="widgetFilter(item)"
                >
                  <span class="active" v-if="item.active">
                    <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                  </span>
                  <div class="content">
                    <div class="text">{{ item.name }}</div>
                    <div class="number">{{ item.count }}</div>
                  </div>
                  <span class="bottom-arrow" v-if="item.active"></span>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.user="{ item }">
                <div class="name-column" v-if="item.user !== null">
                  <div class="user-name" v-if="item.user.name">
                    {{ item.user.name }}
                  </div>
                  <div class="d-flex user-name" v-if="!item.user.name && item.user.social_handle">
                    <img
                      :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                      class="mr-2"
                      v-if="item.user.source_name !== null"
                    />
                    <span> @{{ item.user.social_handle }} </span>
                  </div>
                  <p class="user-location">
                    {{ item.user.branch_name }}
                  </p>
                  <p class="user-location mt-2">
                    <v-chip
                      small
                      class="este-chip status-chip"
                      :text-color="item.user.user_type_name === 'Lead' ? 'white' : 'black'"
                      :color="
                        item.user.user_type_name === 'Lead'
                          ? 'rgba(0, 0, 0, 0.4)'
                          : 'rgba(223, 180, 71, 0.4)'
                      "
                    >
                      {{ item.user.user_type_name | capitalize }}
                    </v-chip>
                  </p>
                </div>
              </template>
              <template v-slot:item.phone="{ item }">
                <p
                  class="mt-4 ml-n1"
                  v-if="
                    item.user !== null &&
                    item.user.contact_no !== null &&
                    item.user.contact_no !== ''
                  "
                >
                  <span class="contact">
                    <a :href="`tel:${item.user.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.user.contact_no }}</a
                    >
                  </span>
                </p>
                <p
                  class="mt-4 ml-n1 mb-4"
                  v-if="item.user !== null && item.user.email !== null && item.user.email !== ''"
                >
                  <span class="contact">
                    <a :href="`mailto:${item.user.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.user.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.appointment_date="{ item }">
                <p class="date-sec date-mute" v-if="item.enquiry_date !== null">
                  <v-btn class="badge badge-grey" icon>
                    <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                  </v-btn>
                  {{ item.enquiry_date | moment("DD/MM/YY") }}
                </p>
                <p class="date-sec vertical-bar" v-if="item.enquiry_date !== null">|</p>
                <template v-if="item.appointment_date !== null">
                  <p class="date-sec">
                    <v-btn class="badge badge-grey text" icon>
                      <v-icon size="20" color="black" class="mr-2">mdil-calendar</v-icon>
                      <span>{{ item.appointment_date | moment("DD/MM/YY") }}</span>
                      <span v-if="item.appointment_from_time !== null"
                        >:{{ item.appointment_from_time }}</span
                      >
                      <span v-if="item.appointment_to_time !== null">
                        - {{ item.appointment_to_time }}</span
                      >
                    </v-btn>
                  </p>
                </template>
                <template v-else>
                  <p class="date-sec">
                    <v-btn @click="editItem(item)" class="badge badge-grey action" icon>
                      <v-icon size="20" color="black" class="mr-2">mdil-calendar</v-icon>
                      Book Now
                    </v-btn>
                  </p>
                </template>
              </template>
              <template v-slot:item.treatments="{ item }">
                <div class="mb-2" v-if="item.appointment_type !== null">
                  <v-chip
                    small
                    class="este-chip status-chip"
                    :text-color="item.appointment_type === 'Consultation' ? 'white' : 'black'"
                    :color="
                      item.appointment_type === 'Consultation'
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(223, 180, 71, 0.4)'
                    "
                  >
                    {{ item.appointment_type | capitalize }}
                  </v-chip>
                </div>
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0, 2)">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </template>
                <span class="badge-label" v-if="item.treatments.length > 2"
                  >+{{ item.treatments.length - 2 }}</span
                >
              </template>
              <template v-slot:item.responsible="{ item }">
                <div class="profile-icon" alt="avatar" v-if="item.consultant !== null">
                  <p>{{ getInitials(item.consultant.name) }}</p>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('appointment');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    :outlined="stat.outline"
                    :text-color="stat.text ? stat.color : '#221F20'"
                    :color="stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Appointment</a></li>
                      <li><a @click="SmsReminder(item)">Send Reminder</a></li>
                      <li><a @click="deal(item)">Create Deal</a></li>
                      <li><a @click="convert(item)">Convert to Client</a></li>
                      <li>
                        <a class="text-mute" @click="archiveItem(item)" v-if="item.status !== 7"
                          >Archive Appointment</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import eventBus from "../../../eventBus.js";
import AppointmentWidgets from "../../../mixins/AppointmentWidgets";
import moment from "moment";
export default {
  name: "Treatments",
  mixins: [globalFn, sliderFn, status, AppointmentWidgets],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      showCalendar: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "user", align: "left", sortable: false },
        { text: "Contact Details", value: "phone", align: "left", sortable: false },
        { text: "Type / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        {
          text: "Enquiry / Booking Date",
          value: "appointment_date",
          sortable: false,
          align: "left",
        },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      AppointmentParams: {
        attributes: "user,treatments,consultations,consultant,branch",
        search: "",
        type: "treatment",
        filter: {
          enquiry_date_from: "",
          enquiry_date_to: "",
          appointment_date_from: "",
          appointment_date_to: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
      },
      showAppointmentParams: "?attributes=user,treatments,consultations,consultant,branch",
      message: "",
      action: "",
      actionText: "",
      calendarData: [],
      start: "",
      end: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("treatmentsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchAppointments", this.getSearchAppointments);
  },
  created() {
    window.Echo.channel("new-appointment").listen(".appointment-created", (e) => {
      this.options.page = 1;
      let res = [];
      res.type = "success";
      res.treatments = [];
      res.treatments = e.data.treatments;
      let name = e.data.name ? e.data.name : `@ ${e.data.social_handle}`;
      res.message =
        e.data.treatments.length > 0
          ? `New Appointment Added: ${name} for`
          : `New Appointment Added: ${name}`;
      if (this.user.user_type_name === "Admin") {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      } else if (
        this.user.permission_branches.some((el) => el.id === e.data.branch_id) ||
        this.user.branch_id === e.data.branch_id
      ) {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      }
    });
  },
  methods: {
    async getSearchAppointments(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.AppointmentParams.widget_filter = "";
      }
      this.AppointmentParams.search = data.search;
      this.AppointmentParams.filter.enquiry_date_from = data.enquiry_date_from;
      this.AppointmentParams.filter.enquiry_date_to = data.enquiry_date_to;
      this.AppointmentParams.filter.appointment_date_from = data.appointment_date_from;
      this.AppointmentParams.filter.appointment_date_to = data.appointment_date_to;
      this.AppointmentParams.filter.status = data.status;
      this.AppointmentParams.filter.branch = data.branch;
      this.AppointmentParams.filter.source = data.source;
      this.AppointmentParams.filter.assigned = data.assigned;
      this.AppointmentParams.filter.assigned_to = data.assigned_to;
      this.AppointmentParams.filter.source_blank = data.source_blank;
      await this.loadAppointmentCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      this.options.page = 1;
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.AppointmentParams.widget_filter = item.active ? item.slug : "";
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("appointments/GET_APPOINTMENT", {
          data: this.AppointmentParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      let result = [];
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: this.showAppointmentParams,
        })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "appointments/editPane");
          } else {
            this.openDrawer("add", "750", "appointments/editTab");
          }
          result = res.data;
        });
    },
    async deal(item) {
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: this.showAppointmentParams,
        })
        .then((res) => {
          this.openDrawer("add", "900", "deals/add");
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async SmsReminder(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to send SMS reminder this to client?";
      this.actionText = "Confirm";
      this.action = "SendReminder";
    },
    async SendReminder() {
      await this.$store
        .dispatch("sms/SEND_SMS", { id: this.selectedItem.id, model: "appointment" })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            res.type = "success";
            this.$store.dispatch("snackbar/SHOW", res);
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async convert(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = " Please confirm you want to convert this to client?";
      this.actionText = "Confirm";
      this.action = "convertToClient";
    },
    async convertToClient() {
      await this.$store
        .dispatch("appointments/CONVERT_TO_CLIENT", { appointment_id: this.selectedItem.id })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this appointment?";
      this.actionText = "Archive";
      this.action = "archiveAppointment";
    },
    async archiveAppointment() {
      this.selectedItem.status = 7;
      await this.$store
        .dispatch("appointments/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
