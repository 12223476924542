<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card class="widget-card">
          <v-row>
            <template
              v-for="(item, index) in widgets.filter(function (el) {
                return el.module.includes('lead');
              })"
            >
              <v-col cols="12" md="2" lg="2" xl="2" sm="6">
                <div
                  class="widget"
                  :class="item.active ? 'selected' : ''"
                  @click="widgetFilter(item)"
                >
                  <span class="active" v-if="item.active">
                    <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                  </span>
                  <div class="content">
                    <div class="text">{{ item.name }}</div>
                    <div class="number">{{ item.count }}</div>
                  </div>
                  <span class="bottom-arrow" v-if="item.active"></span>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-skeleton-loader
              class="widget-card"
              v-show="loading"
              :loading="loading"
              type="table-row-divider@6"
            ></v-skeleton-loader>
            <v-data-table
              v-show="!loading"
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <div class="user-name" v-if="item.name">
                  {{ item.name }}
                </div>
                <div class="d-flex user-name" v-if="!item.name && item.social_handle">
                  <img
                    :src="`/images/${item.source_name.toLowerCase()}.svg`"
                    class="mr-2"
                    v-if="item.source_name !== null"
                  />
                  <span> @{{ item.social_handle }} </span>
                </div>
                <p class="user-location">
                  {{ item.branch_name }}
                </p>
              </template>
              <template v-slot:item.contact_no="{ item }">
                <p class="ml-n1" v-if="item.contact_no !== null && item.contact_no !== ''">
                  <span class="contact">
                    <a :href="`tel:${item.contact_no}`">
                      <img src="/images/phone.svg" height="15" /> {{ item.contact_no }}</a
                    >
                  </span>
                </p>
                <p class="mt-4 ml-n1 mb-4" v-if="item.email !== null && item.email !== ''">
                  <span class="contact">
                    <a :href="`mailto:${item.email}`">
                      <img src="/images/email.svg" height="15" /> {{ item.email }}</a
                    >
                  </span>
                </p>
              </template>
              <template v-slot:item.lead_details="{ item }">
                <template v-if="item.lead_details !== null">
                  <div class="date-column" v-if="item.lead_details.enquiry_date !== null">
                    <p class="date-sec date-mute">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn>
                      {{ item.lead_details.enquiry_date | moment("DD/MM/YY") }}
                    </p>
                    <template v-if="item.lead_details.follow_up_date !== null">
                      <p
                        class="date-sec vertical-bar"
                        v-if="item.lead_details.follow_up_date !== null"
                      >
                        |
                      </p>
                      <p class="date-sec">
                        <v-btn class="badge badge-grey text" icon>
                          <v-icon size="20" color="black" class="mr-2">mdil-calendar</v-icon>
                          <span>{{ item.lead_details.follow_up_date | moment("DD/MM/YY") }}</span>
                          <span v-if="item.lead_details.follow_up_time !== null"
                            >@{{ item.lead_details.follow_up_time }}</span
                          >
                        </v-btn>
                      </p>
                    </template>
                  </div>
                </template>
              </template>
              <template v-slot:item.responsible="{ item }">
                <template v-if="item.lead_details !== null">
                  <template v-if="item.lead_details.responsible !== null">
                    <div class="profile-icon" alt="avatar">
                      <p>{{ getInitials(item.lead_details.responsible.name) }}</p>
                    </div>
                  </template>
                </template>
              </template>
              <template v-slot:item.source_name="{ item }">
                <v-chip small class="este-chip tag-chip" text-color="black" v-if="item.source_name">
                  {{ item.source_name | capitalize }}
                </v-chip>
                <div class="mt-2">
                  <template v-if="item.campaigns.length > 0">
                    <template v-for="campaign in item.campaigns">
                      <v-chip
                        small
                        class="este-chip text-uppercase"
                        text-color="white"
                        :color="campaign.color"
                      >
                        {{ campaign.chip | capitalize }}
                      </v-chip>
                    </template>
                  </template>
                </div>
                <div class="mt-2" v-if="item.source_id === 3 && item.referred_by !== null">
                  <v-chip small class="este-chip text-uppercase" text-color="white" color="black">
                    App Referral
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <span class="date-sec date-mute">{{ item.updated_at | moment("DD/MM/YY") }}</span>
                <v-chip class="ml-1 count-chip" v-if="item.activity_count">
                  <img src="/images/bolt-grey.svg" class="mr-1" />
                  {{ item.activity_count }}
                </v-chip>
              </template>
              <template v-slot:item.treatments="{ item }">
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0, 3)">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </template>
                <span class="badge-label" v-if="item.treatments.length > 3"
                  >+{{ item.treatments.length - 3 }}</span
                >
              </template>
              <template v-slot:item.status="{ item }">
                <template
                  v-for="stat in SortAtoZ(
                    statusTags.filter(function (el) {
                      return el.slug.includes('lead');
                    })
                  )"
                  v-if="stat.id === item.status"
                >
                  <v-chip
                    small
                    class="este-chip mb-2"
                    :class="stat.outline ? 'tag-chip' : 'status-chip'"
                    :outlined="stat.outline"
                    :text-color="stat.text ? stat.color : '#221F20'"
                    :color="stat.color"
                  >
                    {{ stat.name | capitalize }}
                  </v-chip>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit Lead</a></li>
                      <li><a @click="convert(item)">Convert to Client</a></li>
                      <li><a @click="request(item)">Request Appointment</a></li>
                      <li><a @click="requestCallback(item)">Request Call Back</a></li>
                      <li>
                        <a class="text-mute" @click="archive(item)" v-if="item.status !== 7"
                          >Archive Lead</a
                        >
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import Widgets from "../../../mixins/Widgets";
import eventBus from "../../../eventBus.js";
export default {
  name: "Leads",
  mixins: [globalFn, sliderFn, status, Widgets],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      showSkeleton: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Contact Details", value: "contact_no", align: "left", sortable: false },
        { text: "Source", value: "source_name", align: "left", sortable: false },
        { text: "Services / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Responsible", value: "responsible", align: "left", sortable: false },
        { text: "Enquiry Dates", value: "lead_details", sortable: false, align: "left" },
        { text: "Last Updated", value: "updated_at", sortable: false, align: "left" },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      leadParams: {
        search: "",
        filter: {
          enquiry_from: "",
          enquiry_to: "",
          followup_from: "",
          followup_from_time: "",
          followup_to: "",
          followup_to_time: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
        },
        widget_filter: "",
      },
      showLeadParams:
        "?attributes=staff,branch,leadDetails,reasons,symptoms,treatments,source,callbacks,appointments",
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("leadsTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchLeads", this.getSearchLeads);
    this.$root.$on("AppointmentEvent", this.changeLeadStatus);
    this.$root.$on("CallbackEvent", this.changeLeadStatus);
  },
  created() {
    window.Echo.channel("new-lead").listen(".lead-created", (e) => {
      this.options.page = 1;
      let res = [];
      res.type = "success";
      res.treatments = [];
      res.treatments = e.data.treatments;
      let name = e.data.name ? e.data.name : `@ ${e.data.social_handle}`;
      res.message =
        e.data.treatments.length > 0 ? `New Lead Added: ${name} for` : `New Lead Added: ${name}`;
      if (this.user.user_type_name === "Admin") {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      } else if (
        this.user.permission_branches.some((el) => el.id === e.data.branch_id) ||
        this.user.branch_id === e.data.branch_id
      ) {
        this.$store.dispatch("snackbar/SHOW", res);
        this.readTheDataFromApi();
      }
    });
  },
  methods: {
    async getSearchLeads(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.leadParams.widget_filter = "";
      }
      this.leadParams.search = data.search;
      this.leadParams.filter.enquiry_from = data.enquiry_from;
      this.leadParams.filter.enquiry_to = data.enquiry_to;
      this.leadParams.filter.followup_from = data.followup_from;
      this.leadParams.filter.followup_from_time = data.followup_from_time;
      this.leadParams.filter.followup_to = data.followup_to;
      this.leadParams.filter.followup_to_time = data.followup_to_time;
      this.leadParams.filter.status = data.status;
      this.leadParams.filter.branch = data.branch;
      this.leadParams.filter.source = data.source;
      this.leadParams.filter.assigned = data.assigned;
      this.leadParams.filter.assigned_to = data.assigned_to;
      this.leadParams.filter.source_blank = data.source_blank;

      await this.loadCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.leadParams.widget_filter = item.active ? item.slug : "";
      this.options.page = 1;
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("leads/GET_LEAD", {
          data: this.leadParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      await this.$store
        .dispatch("leads/SHOW_LEAD", { id: item.id, param: this.showLeadParams })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "leads/editPane");
          } else {
            this.openDrawer("add", "750", "leads/editTab");
          }
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async convert(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = " Please confirm you want to convert this lead to client?";
      this.actionText = "Convert to client";
      this.action = "convertLeadToClient";
    },
    async convertLeadToClient() {
      await this.$store
        .dispatch("leads/CONEVRT_LEAD_TO_CLIENT", { lead_id: this.selectedItem.id })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.selectedItem = null;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async archive(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to archive this lead?";
      this.actionText = "Archive";
      this.action = "archiveLead";
    },
    async archiveLead() {
      this.selectedItem.status = 7;
      await this.archiveEntry();
    },
    async changeLeadStatus(data) {
      debugger;
      this.selectedItem.status = data;
      await this.archiveEntry();
    },
    async archiveEntry() {
      await this.$store
        .dispatch("leads/ARCHIVE_LEAD", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    request(item) {
      this.selectedItem = item;
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "appointments/addPane");
      } else {
        this.openDrawer("add", "750", "appointments/addTab");
      }
      localStorage.setItem("appointmentClientId", item.id);
      localStorage.setItem("appointmentUserType", "Lead");
    },
    requestCallback(item) {
      this.selectedItem = item;
      if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
        this.openDrawer("add", "1100", "callback/addPane");
      } else {
        this.openDrawer("add", "750", "callback/addTab");
      }
      localStorage.setItem("callbackUserId", item.id);
    },
  },
};
</script>
