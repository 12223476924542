import collection from "../../api/collections/Financials";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    item: {},
    packageItem: {},
    list: [],
    package: [],
  },
  mutations: {
    SET_PACKAGE(state, data) {
      state.package = data;
    },
    SET_SHOW_PACKAGE(state, data) {
      state.packageItem = data;
    },
    SET_SALES(state, data) {
      state.list = data;
    },
    SET_SALE_ITEM(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET_FINANCIAL_STATUS_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getStatusCount(payload);
          return data;
        },
        commit,
        dispatch,
        "GET_FINANCIAL_STATUS_COUNT",
        true
      );
    },
    async GET_FINANCIAL_BY_TYPE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getFinancialByType(payload);
          commit("SET_SALES", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_FINANCIAL_BY_TYPE",
        true
      );
    },
    async GET_PAYMENT_SUMMARY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getPaymentSummary(payload);
          return data;
        },
        commit,
        dispatch,
        "GET_PAYMENT_SUMMARY",
        true
      );
    },
    async GET_DEAL_SUMMARY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getDealSummary(payload);
          return data;
        },
        commit,
        dispatch,
        "GET_DEAL_SUMMARY",
        true
      );
    },
  },
};
