<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="shiftList.data"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="shiftList.total"
            >
              <template v-slot:item.actions="{ item }">
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item.id)">Edit</a></li>
                      <li><a @click="openDeleteModal(item.id)">Delete</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Delete' ? 'red' : 'secondary'"
              depressed
              @click="deleteItem"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import globalFn from "../../../../mixins/globalFn";
import sliderFn from "../../../../mixins/sliderFn";
import status from "../../../../mixins/status";
export default {
  name: "Shift Template",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Start Time", align: "start", value: "start_time", align: "left", sortable: false },
        { text: "End Time", value: "end_time", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      itemsPerPage: 10,
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      // Modal
      modalDialog: false,
      selectedId: null,
      message: "",
      action: "",
      actionText: "",
    };
  },
  async mounted() {
    this.fetchShifts();
  },
  computed: {
    shiftList() {
      return this.$store.state.shiftTemplate.list;
    },
  },
  methods: {
    async fetchShifts() {
      await this.$store.dispatch("shiftTemplate/GET");
    },
    async editItem(id) {
      await this.$store.dispatch("shiftTemplate/SHOW", id).then((res) => {
        this.openDrawer("add", "750", "settings/shiftTemplate/edit");
      });
    },
    async openDeleteModal(id) {
      this.selectedId = id;
      this.modalDialog = true;
      this.message = " Please confirm you want to delete?";
      this.actionText = "Delete";
      this.action = "deleteMachine";
    },
    async deleteItem() {
      this.$store.dispatch("shiftTemplate/DELETE", this.selectedId).then((res) => {
        this.modalDialog = false;
        if (res.result === "success") this.fetchShifts();
      });
    },
  },
};
</script>
