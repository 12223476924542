import collection from "../../api/collections/Points";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    item: {},
    list: [],
  },
  mutations: {
    SET_POINTS(state, data) {
      state.item = data;
    },
    SET_POINTS_HISTORY(state, data) {
      state.list = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_POINTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async UPDATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );
    },

    async POINTS_HISTORY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.pointHistoryByUser(payload);
          commit("SET_POINTS_HISTORY", data.data);
          return data;
        },
        commit,
        dispatch,
        "POINTS_HISTORY",
        true
      );
    },
  },
};
