export default {
  data: () => ({
    loading: false,
    contactChange: false,
    showDealForm: false,
    showPaymentsTab: true,
    showAppointmentsTab: false,
    dealLoading: false,
    deals: [],
    activeIndex: null,
    enquiryMenu: false,
    followUpMenu: false,
    followUpTimeMenu: false,
    followupDateChange: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    dealTab: null,
    addedCustomerId: 0,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    rules: {
      name: [(v) => !!v || "Please enter name"],
      branch_id: [(v) => !!v || "Please select branch"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      deal_info: [(v) => !!v || "Please enter deal information"],
      total: [(v) => !!v || "Please enter total"],
      comment: [(v) => !!v || "Please enter comment"],
      sessions: [(v) => !!v || "Please select sessions"],
    },
    dealForm: {
      user_id: 0,
      deal_info: "",
      treatments: [],
      total: "",
      soldby_id: "",
      no_of_sessions: 0,
    },
    satisfaction: [
      {
        name: "Sad",
        slug: "sad",
        image: "images/sad.svg",
      },
      {
        name: "Neutral",
        slug: "neutral",
        image: "images/neutral.svg",
      },
      {
        name: "Happy",
        slug: "happy",
        image: "images/happy.svg",
      },
      {
        name: "VIP",
        slug: "vip",
        image: "images/vip.svg",
      },
    ],
    suggestedTreatments: [],
    staffParams: {
      branch: "",
      type: "",
    },
    navigationTab: null,
    navigation: [
      {
        name: "Personal Details",
        icon: "mdil-account",
        slug: "personal",
        form: true,
      },
      {
        name: "Client Details",
        icon: "mdil-star",
        slug: "client",
        form: true,
      },
      {
        name: "Appointments",
        icon: "mdil-calendar",
        slug: "appointment",
        module: "appointment",
        form: true,
      },
      {
        name: "Callbacks",
        icon: "mdil-phone",
        slug: "callback",
        module: "callback",
        form: true,
      },
      {
        name: "Deals",
        icon: "mdil-book",
        slug: "deal",
        form: false,
      },
      {
        name: "Insights",
        icon: "mdil-chart-areaspline",
        slug: "insight",
        form: false,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        form: false,
      },
      {
        name: "Points History",
        icon: "mdi-history",
        slug: "points",
        form: false,
      },
    ],
  }),
  mounted() {
    this.loadDeals();
    this.$root.$on("showPaymentHistoryTab", this.loadPaymentHistory);
    this.symptomChange();
  },
  computed: {
    client() {
      if (this.$store.state.clients.item) {
        if (!this.$store.state.clients.item.client_details) {
          this.$store.state.clients.item.client_details = {};
        }
        return this.$store.state.clients.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" });
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("campaign/LIST", "");
  },
  watch: {
    "client.social_handle": _.debounce(function () {
      if (this.client.social_handle) {
        this.autoSelectSocialIcon();
      }
    }, 1000),
    "client.contact_no"(val) {
      this.contactChange = true;
    },
    "client.client_details.follow_up_date": function (newVal, oldVal) {
      this.IsFollowUpDateChanged(newVal, oldVal);
    },
  },
  methods: {
    refreshPoints(slug) {
      if (slug === "points") {
        this.$root.$emit("refreshPoints");
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async autoSelectSocialIcon() {
      let url = new URL(this.client.social_handle);
      this.client.source_id = this.getSourceId(url.origin);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async loadDeals() {
      let dealParams = {
        client_id: this.client.id,
        attributes: "client,treatments,soldBy,payments,appointments",
      };
      await this.$store
        .dispatch("deals/GET_DEAL_LIST", { data: dealParams, params: "" })
        .then((res) => {
          this.deals = res.data;
        });
      this.dealForm.treatments = this.client.treatments;
      this.dealForm.soldby_id = this.user.id;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      if (this.$router.currentRoute.meta.slug === "pabau") {
        this.$root.$emit("salesTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "customer") {
        this.$root.$emit("customerTableUpdate", "");
      } else {
        this.$root.$emit("clientsTableUpdate", "");
      }

      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async removeService(item) {
      const index = this.client.treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.client.treatments.splice(index, 1);
      } else {
        const i = this.client.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.client.treatments.splice(i, 1);
      }
    },
    async removeCampaign(item) {
      const index = this.client.campaigns.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.client.campaigns.splice(index, 1);
      } else {
        const i = this.client.campaigns.findIndex((x) => x === item.id);
        if (i >= 0) this.client.campaigns.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.client.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.client.symptoms.splice(index, 1);
      } else {
        const i = this.client.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.client.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeTreatment(item) {
      const index = this.dealForm.treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.dealForm.treatments.splice(index, 1);
      } else {
        const i = this.dealForm.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.dealForm.treatments.splice(i, 1);
      }
    },
    IsFollowUpDateChanged(newVal, oldVal) {
      var d1 = new Date(oldVal);
      var d2 = new Date(newVal);
      if (d1.getTime() !== d2.getTime() && this.client.status !== 26 && this.client.status !== 19) {
        this.client.status = 15;
      }
    },
    clearFollowUpDate() {
      this.lead.lead_details.follow_up_date = null;
    },
    clearFollowUpTime() {
      this.lead.lead_details.follow_up_time = null;
    },

    removeResponsible(item) {
      this.client.client_details.responsible_id = null;
    },
    async symptomChange() {
      const ids = [];
      this.client.symptoms.forEach((value) => {
        if (value.id) {
          ids.push({ id: value.id });
        } else {
          ids.push(value);
        }
      });
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", { symptoms: ids })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async updateClient() {
      let formData = {};
      formData.id = this.client.id;
      formData.title = this.client.title;
      formData.name = this.client.name;
      formData.address_one = this.client.address_one;
      formData.address_two = this.client.address_two;
      formData.city = this.client.city;
      formData.postcode = this.client.postcode;
      formData.branch_id = this.client.branch_id;
      formData.social_handle = this.client.social_handle;
      formData.responsible_id = this.client.client_details.responsible_id;

      if (this.contactChange) {
        formData.contact_no = this.client.contact_no;
      }

      if (this.client.email) {
        formData.email = this.client.email;
      }
      formData.client_satisfaction = this.client.client_satisfaction;
      formData.status = this.client.status;
      formData.is_receive_sms = this.client.is_receive_sms;

      formData.source_id = this.client.source_id;

      formData.follow_up_date = this.client.client_details.follow_up_date;
      formData.follow_up_time = this.client.client_details.follow_up_time;

      formData.service_recommended = [];
      this.client.treatments.forEach((value) => {
        if (value.id) {
          formData.service_recommended.push(value.id);
        } else {
          formData.service_recommended.push(value);
        }
      });

      formData.symptoms = [];
      this.client.symptoms.forEach((value) => {
        if (value.id) {
          formData.symptoms.push(value.id);
        } else {
          formData.symptoms.push(value);
        }
      });

      formData.campaign_ids = [];
      this.client.campaigns.forEach((value) => {
        if (value.id) {
          formData.campaign_ids.push(value.id);
        } else {
          formData.campaign_ids.push(value);
        }
      });

      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("clients/UPDATE_CLIENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async suggTreat(item) {
      let exists = this.client.treatments.some((el) => el.id === item.id)
        ? true
        : this.client.treatments.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.client.treatments.push(item.id);
      } else {
        const index = this.client.treatments.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.client.treatments.splice(index, 1);
        } else {
          const i = this.client.treatments.findIndex((x) => x === item.id);
          if (i >= 0) this.client.treatments.splice(i, 1);
        }
      }
    },
    async loadPaymentHistory(data) {
      let payment = "showPayment" + data;
      let appointment = "showAppointment" + data;
      let history = "paymentHistory" + data;
      let add = "addPayment" + data;
      document.getElementById(payment).style.display = "block";
      document.getElementById(appointment).style.display = "none";
      document.getElementById(history).style.display = "flex";
      document.getElementById(add).style.display = "none";
      await this.loadDeals();
    },
    showPayments($event, deal) {
      $event.target.classList.add("active");
      document.getElementById("showPayment" + deal.id).style.display = "block";
      document.getElementById("showAppointment" + deal.id).style.display = "none";
      document.getElementById("addPayment" + deal.id).style.display = "none";
      document.getElementById("paymentHistory" + deal.id).style.display = "flex";
    },
    showAppointments($event, deal) {
      $event.target.classList.add("active");
      document.getElementById("showPayment" + deal.id).style.display = "none";
      document.getElementById("showAppointment" + deal.id).style.display = "block";
      document.getElementById("appointmentHistory" + deal.id).style.display = "flex";
      document.getElementById("addAppointment" + deal.id).style.display = "none";
    },
    showAdd(deal) {
      document.getElementById("addPayment" + deal.id).style.display = "flex";
      document.getElementById("paymentHistory" + deal.id).style.display = "none";
    },
    request(deal) {
      document.getElementById("appointmentHistory" + deal.id).style.display = "none";
      document.getElementById("addAppointment" + deal.id).style.display = "flex";
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.client.client_satisfaction = item.name.toLowerCase();
    },
    async AddDeal() {
      let formData = {};
      formData.user_id = this.client.id;
      formData.deal_info = this.dealForm.deal_info;
      formData.total = this.dealForm.total;
      formData.soldby_id = this.dealForm.soldby_id;
      formData.no_of_sessions = this.dealForm.no_of_sessions;
      formData.treatments = [];
      this.dealForm.treatments.forEach((value) => {
        if (value.id) {
          formData.treatments.push(value.id);
        } else {
          formData.treatments.push(value);
        }
      });
      if (formData.treatments.length > 1) {
        let response = [];
        response.type = "error";
        response.message = "Please choose only one treatment per Deal";
        this.$store.dispatch("snackbar/SHOW", response);
      } else {
        if (this.$refs.dealForm.validate()) {
          await this.$store
            .dispatch("deals/CREATE_DEAL", formData)
            .then((response) => {
              if (response.result === "success") {
                this.loading = false;
                this.loadDeals();
                this.$refs.dealForm.reset();
              } else if (response.result === "fail") {
                response.type = "error";
                this.loading = false;
                this.$store.dispatch("snackbar/SHOW", response);
              }
            })
            .catch((err) => {
              this.loading = false;
              err.type = "error";
              this.$store.dispatch("snackbar/SHOW", err);
            });
        }
      }
    },
  },
};
