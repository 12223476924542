import { render, staticRenderFns } from "./WidgetOpportunities.vue?vue&type=template&id=fb7900da&scoped=true"
import script from "./WidgetOpportunities.vue?vue&type=script&lang=js"
export * from "./WidgetOpportunities.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb7900da",
  null
  
)

export default component.exports