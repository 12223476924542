<template>
  <div class="fill-height slider-cont">
    <div class="pb-0 tab-brd">
      <v-tabs v-model="activeTab" class="cust-tabs" @change="loadAppointments">
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <template v-for="item in tabs" :key="item.slug">
          <v-tab :href="`#${item.slug}`">
            {{ item.name }}
            ({{ getStatusCount(item.slug) }})
          </v-tab>
        </template>
      </v-tabs>
    </div>
    <div class="px-6 right-pane">
      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabs" :key="item" :eager="true" :value="tab.slug">
          <div v-if="loading" class="pt-8 text-center">
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </div>
          <div v-else-if="!loading && !appointments.length" class="pt-8 text-center">
            <div class="text-subtitle-1">No data available</div>
          </div>
          <div v-else>
            <div
              class="cell-item cursor"
              v-for="item in appointments"
              @click="item.showNotes = true"
            >
              <v-row dense class="e-row">
                <v-col cols="6" md="6">
                  <template v-for="tag in item.treatments">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </v-col>
                <v-col cols="6" md="6">
                  <span class="cell-date"
                    >Appointment Date:
                    <span>{{ item.appointment_date | moment("DD/MM/YY") }}</span>
                    <span v-if="item.appointment_from_time !== null"
                      >:{{ item.appointment_from_time }}</span
                    >
                    <span v-if="item.appointment_to_time !== null">
                      - {{ item.appointment_to_time }}</span
                    >
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="e-row">
                <v-col cols="6" md="9">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      Appointment Type :
                      <v-chip
                        small
                        class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                        :text-color="item.appointment_type === 'Consultation' ? 'black' : 'black'"
                        :color="
                          item.appointment_type === 'Consultation'
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(223, 180, 71, 0.4)'
                        "
                      >
                        {{ item.appointment_type | capitalize }}
                      </v-chip>
                    </div>
                    <div v-if="">
                      Status:
                      <template
                        v-for="stat in SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('appointment');
                          })
                        )"
                        v-if="stat.id === item.status"
                      >
                        <v-chip
                          small
                          class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                          :class="stat.outline ? 'tag-chip' : 'status-chip'"
                          :outlined="stat.outline"
                          :text-color="stat.text ? stat.color : '#221F20'"
                          :color="stat.color"
                        >
                          {{ stat.name | capitalize }}
                        </v-chip>
                      </template>
                    </div>
                    <div class="d-flex align-center">
                      Responsible:
                      <v-tooltip
                        content-class="ml-2 este-tooltip"
                        color="#000"
                        text-color="#fff"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2 badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                            v-if="item.consultant_name"
                          >
                            {{ getInitials(item.consultant_name) }}
                          </span>
                        </template>
                        <span v-if="item.consultant_name !== null">{{ item.consultant_name }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" md="3">
                  <v-menu
                    max-width="200"
                    :nudge-width="200"
                    offset-y
                    left
                    content-class="user-action"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                        <v-icon large>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <div class="logout-block">
                      <ul>
                        <li v-if="item.status === 46">
                          <a @click="bookApp(item)">Book Appointment</a>
                        </li>
                        <li v-if="item.status === 46">
                          <a @click="viewNotes(item)">View Notes</a>
                        </li>
                        <li v-else>
                          <a @click="editItem(item)">Edit</a>
                        </li>
                      </ul>
                    </div>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense class="e-row" v-if="item.showNotes">
                <v-col cols="12" md="12">
                  <template v-if="item.recentComment">
                    <p class="pa-4 bg-surface-100 font-12 text-black-300 rounded-lg">
                      {{ item.recentComment }}
                    </p>
                  </template>
                  <template v-if="!item.showNotesExpand">
                    <v-btn
                      block
                      color="secondary"
                      class="mt-3 font-12 text-black-300 text-capitalize"
                      depressed
                      rounded
                      @click="item.showNotesExpand = true"
                      small
                    >
                      <v-icon size="16" class="mr-2">mdi-plus</v-icon>
                      Add Notes</v-btn
                    >
                  </template>
                </v-col>
              </v-row>
              <v-row dense class="e-row" v-if="item.showNotesExpand">
                <v-col cols="12" md="12">
                  <v-form ref="form">
                    <v-textarea
                      v-model="item.comment"
                      placeholder="Add notes here"
                      rows="5"
                      row-height="25"
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="solo-cust"
                    ></v-textarea>
                    <div class="py-4">
                      <v-btn
                        class="font-12 text-black-300 text-capitalize"
                        depressed
                        rounded
                        outlined
                        @click="close"
                        small
                        @click="item.showNotesExpand = false"
                        >Cancel</v-btn
                      >

                      <v-btn
                        color="secondary"
                        class="font-12 text-black-300 text-capitalize"
                        depressed
                        rounded
                        small
                        @click="saveNotes(item)"
                        >Save</v-btn
                      >
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import sliderFn from "../../mixins/sliderFn.js";
import status from "../../mixins/status.js";
import EditAppointment from "../../templates/forms/leads/EditAppointment";
export default {
  mixins: [globalFn, status, sliderFn],
  props: {
    contact: {
      required: true,
    },
  },
  components: {
    EditAppointment,
  },
  data() {
    return {
      drawer: false,
      appointments: [],
      rules: {
        notes: [(v) => !!v || "Please enter comment"],
      },
      status: [],
      tabs: [
        {
          name: "All Bookings",
          slug: "all",
        },
        {
          name: "Upcoming",
          slug: "upcoming",
        },
        {
          name: "Cancelled",
          slug: "cancelled",
        },
        {
          name: "No Show",
          slug: "no_show",
        },
      ],
      activeTab: "all",
      loading: false,
    };
  },
  mounted() {
    this.$root.$on("toggleApppointmentDrawer", this.toggleApppointmentDrawer);
    this.loadAppointments();
    this.loadStatusCount();
  },
  methods: {
    async editItem(item) {
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: "?attributes=user,treatments,consultations,consultant,branch",
        })
        .then((res) => {
          this.openDrawer("add", "750", "calendar/editAppointment");
        });
    },
    async viewNotes(item) {
      await this.$store
        .dispatch("appointments/SHOW_APPOINTMENT", {
          id: item.id,
          param: "?attributes=user,treatments,consultations,consultant,branch",
        })
        .then((res) => {
          this.openDrawer("add", "750", "calendar/notes");
        });
    },
    async loadStatusCount() {
      await this.$store
        .dispatch("appointments/GET_APPOINTMENT_STATUS_COUNT", {
          id: this.contact.id,
        })
        .then((res) => {
          this.status = res.data;
        });
    },
    async loadAppointments() {
      this.appointments = [];
      this.loading = true;
      await this.$store
        .dispatch("appointments/GET_APPOINTMENT_BY_TYPE", {
          data: {
            id: this.contact.id,
            type: this.activeTab,
            sort_order: "ASC",
            sort_by: "id",
          },
          params: "?attributes=user,treatments,notes",
        })
        .then((res) => {
          this.appointments = res.data.map(function (el) {
            let item = Object.assign({}, el);
            item.recentComment = el.notes.length > 0 ? el.notes?.[0].note : null;
            item.comment = null;
            item.showNotesExpand = false;
            item.showNotes = false;
            return item;
          });
        })
        .finally(() => (this.loading = false));
    },
    async saveNotes(item) {
      await this.$store
        .dispatch("appointmentNotes/CREATE_NOTES", { appointment_id: item.id, note: item.comment })
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.loadAppointments();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async toggleApppointmentDrawer() {
      this.drawer = false;
      await this.loadAppointments();
    },
    getStatusCount(slug) {
      const status = this.status.find((item) => item.group === slug);
      return status?.count || 0;
    },
    bookApp(item) {
      localStorage.setItem("toBeScheduleAppointment", JSON.stringify(item));
      const routeData = this.$router.resolve({ path: `/calendar/appointments` });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
