import collection from "../../../api/collections/Categories";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_CATEGORY(state, data) {
      state.list = data;
    },
    SET_ALL_CATEGORY(state, data) {
      state.allList = data;
    },
    SET_CATEGORY_ITEM(state, data) {
      state.item = data;
    },
    RESET_CATEGORY(state, data) {
      state.item = {};
    },
    SET_CATEGORY_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_CATEGORY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_CATEGORY", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CATEGORY",
        true
      );
    },
    async GET_ALL_CATEGORY({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_CATEGORY", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_CATEGORY",
        true
      );
    },
    async CREATE_CATEGORY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_CATEGORY",
        true
      );
      return data;
    },
    async SHOW_CATEGORY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_CATEGORY_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_CATEGORY",
        false
      );
      return data;
    },
    async UPDATE_CATEGORY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_CATEGORY",
        true
      );

      return data;
    },
    async DELETE_CATEGORY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_CATEGORY",
        true
      );
      return data;
    },
  },
};
