export default {
  data: () => ({
    loading: false,
    dropdown: true,
    selectedUser: null,
    disabled: false,
    clientSearch: null,
    appointmentDateMenu: false,
    appointmentFromTimeMenu: false,
    appointmentToTimeMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    search: "",
    isTyping: false,
    appointments: [],
    selectedAppointment: "",
    form: {
      user_id: 0,
      treatment_id: [],
      appointment_type: "Consultation",
      appointment_date: "",
      appointment_from_time: "",
      appointment_to_time: "",
      branch_id: "",
      machine: "",
      appointment_notes: "",
      consultant_id: "",
      opportunity_id: "",
      status: 11,
    },
    items: [],
    rules: {
      user_id: [
        (v) => !!v || "Please select client",
        (v) => (v && v.length > 3) || "Please enter min 3 characters",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      machine: [(v) => !!v || "Please select machine"],
      status: [(v) => !!v || "Please select status"],
    },
    clientParams: "?sort_by=name&sort_order=ASC",
    leadParams: {
      attributes: "staff,branch,leadDetails,reasons,symptoms,treatments",
      search: "",
      filter: {
        date_from: "",
        date_to: "",
        status: [],
        branch: [],
      },
    },
    staff: [],
    staffParams: {
      branch: "",
      type: "appointment",
    },
    updateLeadStatus: "",
    updateClientStatus: "",
    SelectedUserType: "",
  }),
  computed: {
    appointmentDateUkFormat() {
      if (!this.form.appointment_date) return null;
      const [year, month, day] = this.form.appointment_date.split("-");
      return `${day}/${month}/${year}`;
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    machines() {
      if (this.$store.state.machines.all) {
        return this.$store.state.machines.all;
      }
    },
  },
  mounted() {
    this.selectClient();
    this.form.appointment_type =
      this.$router.currentRoute.meta.slug === "app-treatments" ? "Treatment" : "Consultation";
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("machines/GET_MACHINE_LIST", "");
  },
  watch: {
    "form.appointment_date": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "form.appointment_from_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    "form.appointment_to_time": _.debounce(function () {
      this.updateStatus();
    }, 1000),
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.search.length > 3) {
        this.filterUserSearchAPI();
      }
    },
  },
  methods: {
    async selectClient() {
      if (this.$router.currentRoute.meta.slug === "customer") {
        this.dropdown = false;
        this.form.user_id = parseInt(localStorage.getItem("appointmentClientId"));
        await this.setUserId();
      } else if (this.$router.currentRoute.meta.slug === "opportunities") {
        this.dropdown = false;
        this.form.user_id = parseInt(localStorage.getItem("appointmentClientId"));
        this.form.opportunity_id = parseInt(localStorage.getItem("opportunity_id"));
      } else {
        this.dropdown = true;
        let LeadArr = await this.getLeads();
        let ClientArr = await this.getClients();
        this.items = [...LeadArr, ...ClientArr];
      }
    },
    async filterUserSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.search;
      this.loading = true;
      await this.$store.dispatch("appointments/FILTERED_APPOINTMENTS", data).then((res) => {
        this.appointments = res.data;
        this.loading = false;
      });
    },
    async selectContact(item) {
      this.selectedAppointment = item;
      this.form.user_id = item.id;
    },
    async removeAppointmentedUser(item) {
      this.selectedAppointment = "";
      this.form.user_id = null;
    },
    async getLeads() {
      let data = [];
      await this.$store.dispatch("leads/GET_LEADS_LIST").then((res) => {
        data = res.data;
      });
      return data;
    },
    async getClients() {
      let data = [];
      await this.$store
        .dispatch("clients/GET_CLIENT_LIST", `?sort_by=name&sort_order=ASC`)
        .then((res) => {
          data = res.data;
        });
      return data;
    },
    async clientChange() {
      await this.$store.dispatch("user/GET_USER", `?user_id=${this.form.user_id}`).then((res) => {
        this.SelectedUserType = res.data.user_type_name;
      });
      await this.setUserId();
    },
    async setUserId() {
      await this.$store.dispatch("user/GET_USER", `?user_id=${this.form.user_id}`).then((res) => {
        this.selectedUser = res.data;
        this.form.branch_id = res.data.branch_id;
        this.staffParams.branch = res.data.branch_id;
        this.form.treatment_id = [];
        res.data.treatments.forEach((value) => {
          this.form.treatment_id.push(value.id);
        });

        if (
          res.data.lead_details &&
          res.data.lead_details.responsible &&
          res.data.lead_details.responsible.role_type === 4
        ) {
          this.form.consultant_id = res.data.lead_details.responsible.id;
        }
      });

      await this.getStaffList();
    },
    async staffByBranch() {
      this.staffParams.branch = this.form.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    DisableItems(item) {
      if (this.items) {
        let res = this.items.find((x) => x.id !== item.id);
        return res ? true : false;
      }
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      localStorage.removeItem("appointmentClientId");
      localStorage.removeItem("opportunity_id");
      localStorage.removeItem("userType");
    },
    updateStatus() {
      if (
        this.form.appointment_date &&
        this.form.appointment_from_time &&
        this.form.appointment_to_time
      ) {
        this.form.status = 5;
      } else if (
        this.form.appointment_date &&
        !this.form.appointment_from_time &&
        !this.form.appointment_to_time
      ) {
        this.form.status = 11;
      }
    },
    loadData() {
      localStorage.removeItem("appointmentClientId");
      localStorage.removeItem("opportunity_id");
      localStorage.removeItem("userType");
      this.$store.commit("rightSlider/RESET_SLIDER");
      if (this.$router.currentRoute.meta.slug === "opportunities") {
        this.$root.$emit("opportunitiesTable", "");
      } else if (this.$router.currentRoute.meta.slug === "clients") {
        this.$root.$emit("clientsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "call-back") {
        this.$root.$emit("callbacksTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "app-treatments") {
        this.$root.$emit("treatmentsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "customer") {
        this.$root.$emit("customerTableUpdate", "");
      } else {
        this.$root.$emit("appointmentsTableUpdate", "");
      }
    },
    async addAppointment() {
      this.updateLeadStatus = 19;
      this.updateClientStatus = 19;
      if (this.$router.currentRoute.meta.slug === "opportunities") {
        this.form.user_id = parseInt(localStorage.getItem("appointmentClientId"));
        this.form.opportunity_id = parseInt(localStorage.getItem("opportunity_id"));
      }
      if (this.$refs.form.validate()) {
        this.disabled = true;
        await this.$store
          .dispatch("appointments/CREATE_APPOINTMENT", this.form)
          .then((response) => {
            if (response.result === "success") {
              this.loadData();
            } else if (response.result === "fail") {
              console.log(response.result);
              response.type = "error";
              this.disabled = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            console.log(err);
            this.disabled = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async archiveEntry() {
      let action =
        this.SelectedUserType === "Lead" ? "leads/ARCHIVE_LEAD" : "clients/ARCHIVE_CLIENT";
      let status =
        this.SelectedUserType === "Lead" ? this.updateLeadStatus : this.updateClientStatus;
      await this.$store
        .dispatch(action, { id: this.form.user_id, status: status })
        .then((res) => {
          if (res.result === "success") {
            this.disabled = false;
            if (this.SelectedUserType === "Lead") {
              this.removeFollowupDateForLead();
            } else {
              this.loadData();
            }
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async removeFollowupDateForLead() {
      let formData = {};
      formData.id = this.form.user_id;
      formData.follow_up_date = null;
      formData.follow_up_time = null;
      await this.$store
        .dispatch("leads/UPDATE_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
