<template>
  <v-dialog v-model="logCallbackNote" max-width="550" class="rounded-6">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="badge--chip d-flex align-center justify-center cursor"
        :class="showPlus ? 'bg-gold-100' : 'bg-gray-100'"
        @mouseover="showPlus = true"
        @mouseleave="showPlus = false"
      >
        <img
          src="/images/icons/phone.svg"
          class="text-black-100 ma-0 mr-1"
          width="13"
          height="13"
        />
        <span class="font-weight--semibold text-black-300 font-11 line-h-13">
          {{ data.callback_note_count }}
        </span>
        <inline-svg class="ml-1" src="/images/plus.svg" v-if="showPlus" />
      </div>
    </template>
    <v-card class="rounded-6 advance-sec">
      <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold">
        Log Call
      </v-card-title>
      <v-divider class="my-5"></v-divider>
      <v-form ref="form">
        <v-card-text class="pt-0">
          <p class="font-13 line-h-16 font-weight--semibold text-black-300 mb-2">
            Please describe the callback
          </p>
          <v-text-field
            autocomplete="off"
            solo
            outlined
            flat
            v-model="comment"
            hide-details="auto"
            class="mb-2 solo-cust"
          ></v-text-field>
          <div class="d-flex align-center mb-8">
            <template v-for="(item, index) in options">
              <div
                :class="[
                  'font-11 line-h-13 font-weight--medium height-29 py-2 px-3 border rounded-6 mr-1 cursor',
                  { 'bg-gray-100': suggestedIndex === index },
                ]"
                @click="updateSuggestions(item, index)"
              >
                {{ item }}
              </div>
            </template>
          </div>
          <v-row class="e-row" v-if="data.support_ticket_type === 'complaint'">
            <v-col cols="12" md="6" class="e-col">
              <label>
                <span class="font-13 line-h-16 font-weight--semibold text-black-300">
                  Next Action Date
                </span>
              </label>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :full-width="true"
                    height="35"
                    @click:clear="clearNextActionDate"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    autocomplete="off"
                    :value="dateUkFormat(data.next_action_date)"
                    solo
                    outlined
                    v-on="on"
                    flat
                    hide-details="auto"
                    class="advance-input no-width"
                    prepend-inner-icon="mdil-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.next_action_date"
                  no-title
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-5"></v-divider>
        <v-card-actions class="d-flex justify-space-between align-center px-4 pb-4">
          <div class="d-flex align-center">
            <p class="mb-0 font-13 line-h-16 font-weight--semibold mr-2">Set Status</p>
            <v-select
              v-model="status"
              autocomplete="off"
              :items="callbackNoteStatusTags"
              item-text="name"
              item-value="id"
              solo
              outlined
              flat
              class="advance-input font-12 line-h-15 font-weight--regular"
              hide-details="auto"
            >
              <template slot="append">
                <v-icon>mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </div>
          <div class="d-flex align-center">
            <v-btn
              class="height-35 border rounded-6 text-capitalize px-3 py-2 letter-s-0 mr-2 font-12 line-h-15 font-weight--regular"
              depressed
              outlined
              x-large
              @click="logCallbackNote = false"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="addComment"
              color="secondary"
              class="height-35 text-capitalize rounded-6 letter-s-0 bg-gold-100 px-3 py-2 font-12 line-h-15 font-weight--bold"
              depressed
              x-large
            >
              Save
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import status from "../../../mixins/status";
import globalFn from "../../../mixins/globalFn";
export default {
  name: "CallbackNote",
  mixins: [status, globalFn],
  props: {
    data: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dateMenu: false,
      logCallbackNote: false,
      showPlus: false,
      suggestedIndex: null,
      comment: "",
      status: null,
      options: ["Did not answer", `Wasn't interested`, `Left Voicemail`, `Sent WhatsApp`],
    };
  },
  computed: {
    callbackNoteStatusTags() {
      let items = [];
      if (this.data.support_ticket_type === "complaint") {
        items = this.SortAtoZ(
          this.statusTags.filter(function (el) {
            return el.slug.includes("complaint");
          })
        );
      } else {
        items = this.SortAtoZ(
          this.statusTags.filter(function (el) {
            return el.slug.includes("callback");
          })
        );
      }
      return items;
    },
  },
  methods: {
    updateSuggestions(item, index) {
      this.suggestedIndex = index;
      this.comment = item;
    },

    clearNextActionDate() {
      this.data.next_action_date = null;
    },

    async addComment() {
      let formData = {};
      formData.complaint_id = this.data.id;
      formData.comment = this.comment;
      formData.category = "callback_note";
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch(`complaints/ADD_COMMENT`, formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.markCall(res.data);
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },

    async markCall(item) {
      let form = {};
      form.comment_id = item.id;
      form.is_marked = 1;
      await this.$store
        .dispatch("customers/MARK_AS_CALL", form)
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.updateStatus();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async updateStatus() {
      let formData = {};
      formData.id = this.data.id;
      formData.user_id = this.data.user.id;
      formData.next_action_date = this.data.next_action_date;
      formData.status = this.status;
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch(`complaints/UPDATE_COMPLAINT`, formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.logCallbackNote = false;
              this.$root.$emit("SupportTicketsTableUpdate", "");
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
<style lang="css" scoped></style>
