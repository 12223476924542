import collection from "../../../api/collections/Staff";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    consultants: [],
    shifts: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_STAFF(state, data) {
      state.list = data;
    },
    SET_STAFF_LIST(state, data) {
      state.all = data;
    },
    SET_CONSULTANTS(state, data) {
      state.consultants = data;
    },
    SET_CONSULTANT_SHIFTS(state, data) {
      state.shifts = data;
    },
    SET_STAFF_ITEM(state, data) {
      state.item = data;
    },
    RESET_STAFF(state, data) {
      state.item = {};
    },
    SET_STAFF_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_STAFF({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload.data, payload.params);
          commit("SET_STAFF", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_STAFF",
        true
      );
    },
    async GET_STAFF_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload.data, payload.params);
          commit("SET_STAFF_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_STAFF_LIST",
        true
      );
    },

    async GET_CONSULTANTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get_consultants(payload);
          commit("SET_CONSULTANTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CONSULTANTS",
        true
      );
    },

    async GET_CONSULTANT_SHIFTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getConsultantShifts(payload);
          commit("SET_CONSULTANT_SHIFTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CONSULTANT_SHIFTS",
        true
      );
    },

    async CREATE_STAFF({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_STAFF",
        true
      );
      return data;
    },
    async SHOW_STAFF({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_STAFF_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_STAFF",
        false
      );
      return data;
    },
    async UPDATE_STAFF({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_STAFF",
        true
      );

      return data;
    },
    async UPDATE_STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.archive(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_STATUS",
        true
      );

      return data;
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.upload(payload);
          return data;
        },
        commit,
        dispatch,
        "UPLOAD_DOCS",
        true
      );
      return data;
    },

    async UPLOAD_IMAGE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.upload_image(payload);
          return data;
        },
        commit,
        dispatch,
        "UPLOAD_IMAGE",
        true
      );
      return data;
    },

    async UPDATE_PLAYERID({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.playerid(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_PLAYERID",
        true
      );
      return data;
    },
    async UPDATE_COLOR({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update_color(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_COLOR",
        true
      );

      return data;
    },
  },
};
