<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">View Appointment</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#appointment">Appointment Details</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside py-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="appointment">
            <v-form ref="appointmentForm">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <div class="client-info">
                      <p class="name" v-if="pabauData.customer_name !== null">
                        {{ pabauData.customer_name | capitalize }}
                      </p>
                      <p class="mt-4">
                        <span class="contact" v-if="pabauData.Mobile !== null">
                          <a :href="`tel:${pabauData.Mobile}`">
                            <img src="/images/phone.svg" height="15" /> {{ pabauData.Mobile }}</a
                          >
                        </span>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Branch: </span>
                    </label>
                    <span class="contact" v-if="pabauData.branch_name !== null">
                      {{ pabauData.branch_name | capitalize }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatment / Service: </span>
                    </label>
                    <span class="contact" v-if="pabauData.service !== null">
                      {{ pabauData.service }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Date: </span>
                    </label>
                    {{ pabauData.start_date | moment("DD/MM/YY") }}
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Time: </span>
                    </label>
                    {{ pabauData.start_time }} - {{ pabauData.end_time }}
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :loading="loading"
          rounded
          x-large
          @click="update"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus.js";
export default {
  data: () => ({
    pabauData: [],
  }),
  mounted() {
    this.tab = "appointment";
    eventBus.$on("loadPabauDetails", this.loadPabauDetails);
  },
  methods: {
    async loadPabauDetails(data) {
      this.pabauData = data;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
  },
};
</script>
