<template>
  <v-card text :ripple="false" class="my-4 consent-card">
    <v-card-text>
      <v-row>
        <v-col cols="7">
          <v-text-field
            autocomplete="off"
            solo
            outlined
            flat
            hide-details="auto"
            class="solo-cust"
            placeholder="Title"
            v-model="formDetails.title"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            autocomplete="off"
            solo
            outlined
            flat
            hide-details="auto"
            class="solo-cust text-capitalize"
            :items="typeList"
            v-model="formDetails.type"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <vue-editor v-if="formDetails.type === 'information'" v-model="formDetails.description" />
          <v-text-field
            v-else
            autocomplete="off"
            solo
            outlined
            flat
            hide-details="auto"
            class="solo-cust"
            placeholder="Description"
            v-model="formDetails.description"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <FormElementType
            :type="formDetails.type"
            :description="formDetails.description"
            :answers.sync="answers"
            @addOption="addOption"
            @deleteOption="deleteOption"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn class="mr-2" icon @click="$emit('deleteForm', formDetails.id)">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-switch
        :elevation="0"
        label="required"
        color="secondary"
        class="mr-4"
        v-model="formDetails.is_required"
      ></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormElementType from "./FormElementType.vue";
import { VueEditor } from "vue2-editor";

const initialValue = {
  medical_form_element_id: null,
  title: "",
  order: 1,
};

export default {
  name: "form-card",
  props: ["formDetails", "formId"],
  components: {
    FormElementType,
    VueEditor,
  },
  data: () => ({
    typeList: [
      {
        text: "Short Answer",
        value: "short_answer",
      },
      {
        text: "Long Answer",
        value: "long_answer",
      },
      {
        text: "Radio",
        value: "radio",
      },
      {
        text: "Checkbox",
        value: "checkbox",
      },
      {
        text: "Dropdown",
        value: "dropdown",
      },
      {
        text: "Dropdown Multi Select",
        value: "dropdown_multi_select",
      },
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Time",
        value: "time",
      },
      {
        text: "Date Time",
        value: "date_time",
      },
      {
        text: "Information",
        value: "information",
      },
    ],
    answers: [initialValue],
    formElementId: null,
  }),
  computed: {
    type() {
      return this.formDetails.type;
    },
  },
  watch: {
    type(newVal) {
      this.answers = [{ id: 1, ...initialValue }];
    },
    async formId(newVal) {
      this.formDetails.medical_form_id = newVal;

      await this.$store
        .dispatch("formElement/CREATE_FORM_ELEMENT", this.formDetails)
        .then((res) => {
          this.answers.forEach((item) => {
            this.$store.dispatch("formAnswer/CREATE_FORM_ANSWER", {
              ...item,
              medical_form_element_id: res.data.id,
            });
          });

          this.$emit("close");

          this.$store.dispatch("form/GET_FORM", {
            params: "?attributes=medicalFormType",
          });
        });
    },
  },
  methods: {
    addOption() {
      let order = this.answers[this.answers.length - 1]?.order;

      const body = order
        ? {
            ...initialValue,
            id: Math.random(),
            order: ++order,
          }
        : {
            id: Math.random(),
            ...initialValue,
          };

      this.answers.push(body);
    },
    deleteOption(id) {
      const newArr = this.answers
        .filter((item) => item.id !== id)
        .map((item, index) => ({
          ...item,
          order: ++index,
        }));

      this.answers = newArr;

      if (this.answers.length < 1) this.addOption();
    },
  },
};
</script>

<style lang="scss" scoped></style>
