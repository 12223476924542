import collection from "../../../api/collections/Machines";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    shifts: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_MACHINE(state, data) {
      state.list = data;
    },
    SET_MACHINE_LIST(state, data) {
      state.all = data;
    },
    SET_MACHINE_SHIFTS(state, data) {
      state.shifts = data;
    },
    SET_MACHINE_ITEM(state, data) {
      state.item = data;
    },
    RESET_MACHINE(state, data) {
      state.item = {};
    },
    SET_MACHINE_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_MACHINE({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_MACHINE", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_MACHINE",
        true
      );
    },
    async GET_MACHINE_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.list(payload);
          commit("SET_MACHINE_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_MACHINE_LIST",
        true
      );
    },

    async GET_MACHINE_SHIFTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getShifts(payload);
          commit("SET_MACHINE_SHIFTS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_MACHINE_SHIFTS",
        true
      );
    },
    async CREATE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_MACHINE",
        true
      );
      return data;
    },
    async SHOW_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_MACHINE_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_MACHINE",
        false
      );
      return data;
    },
    async UPDATE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_MACHINE",
        true
      );

      return data;
    },
    async DELETE_MACHINE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_MACHINE",
        true
      );
      return data;
    },
    async UPDATE_COLOR({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update_color(payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_COLOR",
        true
      );

      return data;
    },
  },
};
