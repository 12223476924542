import http from "../../http/common";

const resource = "/lead";

export default {
  create: (payload) => http.post(resource, payload),

  get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

  list: (payload) => http.get(`${resource}/get-all`, payload),

  show: (id, params) => http.get(`${resource}/${id}${params}`),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  search: (params) => http.get(`${resource}/search${params}`),

  convertToClient: (payload) => http.post(`${resource}/convert-lead-to-client`, payload),

  archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

  comment: (payload) => http.post(`${resource}/add-comment`, payload),

  upload: (payload) => http.post(`${resource}/upload-docs`, payload),

  quickLead: (payload) => http.post(`${resource}/add-quick-lead`, payload),

  validateLead: (payload) => http.post(`${resource}/validate-lead`, payload),

  calendar: (payload, params) => http.get(`${resource}/get-by-week${params}`, payload),

  leadCount: (payload) => http.post(`${resource}/lead-count`, payload),

  assignedTo: (payload) => http.get(`${resource}/staff-list`, payload),
};
