<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table class="results-table" :headers="headers" :items="points">
              <template v-slot:item.actions="{ item }">
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import sliderFn from "../../../../mixins/sliderFn";
export default {
  name: "Points",
  mixins: [sliderFn],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Points per £ spent",
          align: "start",
          value: "points_amount_per",
          align: "left",
          sortable: false,
        },
        {
          text: "Points per referral £ spent",
          align: "start",
          value: "referred_points_amount_per",
          align: "left",
          sortable: false,
        },
        {
          text: "Points used",
          align: "start",
          value: "points_spend",
          align: "left",
          sortable: false,
        },
        {
          text: "Per £ Spent",
          align: "start",
          value: "amount_for_points",
          align: "left",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      selectedItem: "",
      items: [
        {
          points_amount_per: null,
          referred_points_amount_per: null,
          points_spend: null,
          amount_for_points: null,
        },
      ],
    };
  },
  computed: {
    points() {
      if (this.$store.state.points.item) {
        return [this.$store.state.points.item];
      }
    },
  },
  created() {
    this.$store.dispatch("points/GET", "");
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("points/GET", "").then((res) => {
        this.openDrawer("add", "750", "settings/points/edit");
      });
    },
  },
};
</script>
