import collection from "../../../api/collections/Treatments";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_TREATMENT(state, data) {
      state.list = data;
    },
    SET_ALL_TREATMENT(state, data) {
      state.allList = data;
    },
    SET_TREATMENT_ITEM(state, data) {
      state.item = data;
    },
    RESET_TREATMENT(state, data) {
      state.item = {};
    },
    SET_TREATMENT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_TREATMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_TREATMENT", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_TREATMENT",
        true
      );
    },
    async GET_ALL_TREATMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_TREATMENT", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_TREATMENT",
        true
      );
    },
    async CREATE_TREATMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_TREATMENT",
        true
      );
      return data;
    },
    async SHOW_TREATMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_TREATMENT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_TREATMENT",
        false
      );
      return data;
    },
    async UPDATE_TREATMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_TREATMENT",
        true
      );

      return data;
    },
    async DELETE_TREATMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_TREATMENT",
        true
      );
      return data;
    },
    async SELL_TREATMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.sell(payload);
          return data;
        },
        commit,
        dispatch,
        "SELL_TREATMENT",
        true
      );
      return data;
    },
  },
};
