<template>
  <div class="pa-5">
    <v-row>
      <v-col cols="12" md="12" v-if="consentFormData && consentFormData.length > 0">
        <v-timeline align-top dense class="pt-0 ml-n10">
          <v-timeline-item
            class="doc-timeline"
            v-for="(item, index) in consentFormData"
            :key="index"
            color="secondary"
            x-small
            fill-dot
          >
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col doc-item">
                <div class="date">
                  {{ item.patient_signed_at | moment("MMMM Do YYYY") }} at
                  {{ item.patient_signed_at | moment("h:mm:ss a") }}
                </div>
                <div class="file-section">
                  <div class="caption mb-0">
                    <span class="float-right download-text" @click="printPreview(item)">
                      <v-icon large>mdi-printer</v-icon>
                    </span>
                  </div>
                  <p>{{ item.medical_form.title }}</p>
                </div>
                <div class="added-user">
                  <span class="float-left user"> By {{ item.submitted_by_user.name }}</span>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col cols="12" md="12" v-else>
        <p class="d-flex align-center justify-center">No data avilable</p>
      </v-col>
    </v-row>
    <v-navigation-drawer
      class="este-drawer"
      v-model="drawer"
      stateless
      app
      right
      fixed
      temporary
      width="750"
    >
      <consent-form></consent-form>
    </v-navigation-drawer>
  </div>
</template>
<script>
import ConsentForm from "../../templates/forms/consent/consent-form.vue";
import sliderFn from "../../mixins/sliderFn";
export default {
  mixins: [sliderFn],
  data: () => ({
    consentFormData: [],
    drawer: false,
    loading: false,
  }),
  props: {
    contact: {
      required: true,
    },
  },
  components: {
    ConsentForm,
  },
  mounted() {
    this.loadForms();
    this.$root.$on("togglePDFDrawer", this.togglePDFDrawer);
    this.$root.$on("refreshForms", this.loadForms);
  },

  methods: {
    async loadForms() {
      this.consentFormData = [];
      await this.$store
        .dispatch("formPatientCrm/GET_PATIENT_FORM", `?id=${this.contact.id}`)
        .then((res) => {
          this.consentFormData = res.data;
        });
    },
    async printPreview(item) {
      this.loading = true;
      await this.$store.dispatch("formPatientCrm/GENERATE_PDF", { id: item.id }).then((res) => {
        this.loading = false;
        const pdfUrl = res.data;

        var link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank";

        var event = new MouseEvent("click");
        link.dispatchEvent(event);
      });
    },
    async togglePDFDrawer() {
      this.drawer = false;
    },
  },
};
</script>
