<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Create New Deal</div>
        <div class="tab-content-wrapper">
          <v-form ref="form">
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div class="client-info">
                  <div class="name">
                    <span v-html="sale.package_name"></span>
                    <small>- {{ sale.package_total | currency("£") }}</small>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div>
                  <label>
                    <span class="label-txt"> Deal Info </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    :rules="rules.deal_info"
                    v-model="form.deal_info"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                    @keyup="remaincharCount()"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div>
                  <label>
                    <span class="label-txt"> Deal Notes </span>
                  </label>
                  <v-textarea
                    autocomplete="off"
                    solo
                    outlined
                    v-model="form.notes"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
            <v-row class="e-row" v-for="item in form.treatments" :key="item.id">
              <v-col cols="12" md="6" class="e-col">
                <label>
                  <span class="label-txt"> Treatment </span>
                </label>
                <v-autocomplete
                  :rules="rules.treatment_id"
                  v-model="item.treatment_id"
                  :items="treatmentsList"
                  item-text="name"
                  item-value="id"
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      class="este-chip tag-chip px-3"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <span class="pr-2">
                        {{ data.item.name }}
                      </span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" class="e-col">
                <div>
                  <label>
                    <span class="label-txt"> Total (£) </span>
                  </label>
                  <v-text-field
                    placeholder="£1999"
                    :rules="rules.cost"
                    autocomplete="off"
                    solo
                    outlined
                    v-model="item.cost"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                    @change="getDealTotal"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="e-col">
                <div>
                  <label>
                    <span class="label-txt"> No of Sessions </span>
                  </label>
                  <v-text-field
                    :rules="numberRule"
                    autocomplete="off"
                    solo
                    outlined
                    v-model="item.no_of_sessions"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="1" class="d-flex align-center justify-center">
                <v-btn icon color="green" @click="addTreatment" v-if="item.plus">
                  <v-icon size="50">mdil-plus-circle</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="deleteTreatment(item.id)" v-if="!item.plus">
                  <v-icon size="50">mdil-minus-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="6" class="e-col"> </v-col>
              <v-col cols="12" md="2" class="e-col">
                <div class="text-center">
                  <label>
                    <span class="label-txt">
                      {{ dealTotal | currency("£") }}
                    </span>
                  </label>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="e-col"> </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
export default {
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    form: {
      deal_info: null,
      notes: null,
      user_id: null,
      treatments: [
        {
          id: "index0",
          treatment_id: null,
          no_of_sessions: null,
          cost: null,
          plus: true,
        },
      ],
    },
    dealTotal: 0,
    counter: 0,
    numberRule: [
      (v) => !!v || "This field is required",
      (v) => (v && v <= 50) || "No of sessions has to be between 0 to 50",
      (v) => (v && v > 0) || "Please enter a positive number which is greate than 0",
    ],
    rules: {
      deal_info: [(v) => !!v || "Please enter deal information"],
      cost: [(v) => !!v || "Please enter cost value"],
      no_of_sessions: [(v) => !!v || "Please select no of sessions"],
      treatment_id: [(v) => !!v || "Please select treatment"],
      soldby_id: [(v) => !!v || "Please select Sold by"],
      sessions: [(v) => !!v || "Please select sessions"],
    },
    maxcharacter: 150,
  }),
  mixins: [globalFn],
  computed: {
    sale() {
      if (this.$store.state.sales.item) {
        return this.$store.state.sales.item;
      }
    },
    packages() {
      if (this.$store.state.sales.package) {
        return this.$store.state.sales.package;
      }
    },
    package() {
      if (this.$store.state.sales.packageItem) {
        return this.$store.state.sales.packageItem;
      }
    },
    treatmentsList() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
  },
  created() {
    this.$store.dispatch("sales/GET_PACKAGE");
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
  },
  mounted() {
    this.loadCreateDetails();
  },
  methods: {
    htmlDecode(input) {
      const doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    },
    getDealTotal() {
      this.dealTotal = this.form.treatments.reduce(function (accumulator, item) {
        let val = item.cost !== null ? parseInt(item.cost) : 0;
        return accumulator + val;
      }, 0);
    },
    addTreatment() {
      this.form.treatments.push({
        id: `index${++this.counter}`,
        treatment_id: null,
        cost: null,
        no_of_sessions: null,
        plus: false,
      });
    },
    deleteTreatment(id) {
      if (Object.keys(this.form.treatments).length > 1) {
        const index = this.form.treatments.findIndex((x) => x.id === id);
        if (index >= 0) {
          this.form.treatments.splice(index, 1);
        }
      }
      this.getDealTotal();
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("salesTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadCreateDetails() {
      this.form.deal_info = this.htmlDecode(this.sale.package_name);
      this.form.user_id = this.sale.user_id;
    },
    remaincharCount() {
      if (this.form.deal_info.length > this.maxcharacter) {
        this.rules.deal_info = [(v) => `Exceeded  ${this.maxcharacter} characters limit.`];
      } else {
        const remainCharacters = this.maxcharacter - this.form.deal_info.length;
        this.rules.deal_info = [];
      }
    },
    async create() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("appointments/CREATE_DEAL", this.form)
          .then((response) => {
            if (response.result === "success") {
              if (this.sale.amount_paid !== "0.00" && this.sale.amount_paid !== null) {
                this.connectPayment(response.data.id);
              } else {
                this.markAsSynced();
              }
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },

    async connectPayment(dealId) {
      let formData = {};
      formData.deal_id = dealId;
      formData.method =
        this.sale.payment_method === "card"
          ? 1 // if
          : this.sale.payment_method === "cash"
          ? 2 // else if
          : 6; // else
      formData.total = this.sale.amount_paid;
      formData.date = this.sale.payment_date;
      await this.$store
        .dispatch("payments/CREATE_PAYMENT", formData)
        .then((response) => {
          if (response.result === "success") {
            this.markAsSynced();
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async markAsSynced() {
      await this.$store
        .dispatch("sales/UPDATE_STATUS", { id: this.sale.id, status: 36 })
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.$refs.form.reset();
            this.loadData();
          } else if (res.result === "fail") {
            this.loading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
