export default {
  data: () => ({
    loading: false,
    dateRaisedMenu: false,
    nextActionDateMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    dropdown: true,
    selectedUser: null,
    readMore: false,
    form: {
      user_id: 0,
      deal_id: 0,
      date_raised: "",
      category_id: 0,
      description: "",
      next_action: 0,
      next_action_date: "",
      responsible_id: 0,
      urgency: 0,
      status: 9,
      complimentary_sessions: null,
      escalation_level: null,
      products_sold: null,
      machine_used: null,
      pabau_id: null,
      medical_condition: null,
    },
    rules: {
      user_id: [(v) => !!v || "Please select client"],
      responsible_id: [(v) => !!v || "Please select responsible person"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    clientParams: "?sort_by=name&sort_order=ASC",
  }),
  mounted() {
    this.selectClient();
  },
  computed: {
    dateRaisedUkFormat() {
      if (!this.form.date_raised) return null;
      const [year, month, day] = this.form.date_raised.split("-");
      return `${day}/${month}/${year}`;
    },
    nextActionUkFormat() {
      if (!this.form.next_action_date) return null;
      const [year, month, day] = this.form.next_action_date.split("-");
      return `${day}/${month}/${year}`;
    },
    nextActions() {
      if (this.$store.state.complaints.action) {
        return this.$store.state.complaints.action;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
    category() {
      if (this.$store.state.complaints.category) {
        return this.$store.state.complaints.category;
      }
    },
    escalation() {
      if (this.$store.state.complaints.escalation) {
        return this.$store.state.complaints.escalation;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
    clients() {
      if (this.$store.state.clients.all) {
        return this.$store.state.clients.all;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },

    dealsbyclient() {
      if (this.$store.state.deals.all) {
        return this.$store.state.deals.all;
      }
    },
  },
  created() {
    this.$store.dispatch("complaints/GET_NEXT_ACTION");
    this.$store.dispatch("complaints/GET_PRIORITY");
    this.$store.dispatch("complaints/GET_CATEGORY");
    this.$store.dispatch("complaints/GET_ESCALATION");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" });
    this.$store.dispatch("clients/GET_CLIENT_LIST", this.clientParams);
    this.$store.dispatch("source/GET_SOURCE", "");
  },
  methods: {
    async selectClient() {
      if (this.$router.currentRoute.meta.slug === "customer") {
        this.dropdown = false;
        this.form.user_id = parseInt(localStorage.getItem("complaintClientID"));
        await this.setUserId();
      } else {
        this.dropdown = true;
      }
    },

    async setUserId() {
      await this.$store.dispatch("user/GET_USER", `?user_id=${this.form.user_id}`).then((res) => {
        this.selectedUser = res.data;
      });

      await this.loadDealsByClient();
    },

    async loadDealsByClient() {
      await this.$store.dispatch("deals/GET_DEAL_LIST", {
        data: { client_id: this.form.user_id },
        params: "",
      });
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      localStorage.removeItem("complaintClientID");
    },
    loadData() {
      this.$root.$emit("complaintsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
      localStorage.removeItem("complaintClientID");
    },
    async addComplaint() {
      this.form.date_raised = this.changeDateFormat(this.dateRaisedUkFormat);
      this.form.follow_up_date = this.changeDateFormat(this.nextActionUkFormat);
      if (this.$refs.leadForm.validate()) {
        this.tab = "complaint";
        this.showTab("complaint");
        if (this.$refs.complaintForm.validate()) {
          this.loading = true;
          await this.$store
            .dispatch("complaints/CREATE_COMPLAINT", this.form)
            .then((response) => {
              if (response.result === "success") {
                this.loading = false;
                this.loadData();
              } else if (response.result === "fail") {
                response.type = "error";
                this.loading = false;
                this.$store.dispatch("snackbar/SHOW", response);
              }
            })
            .catch((err) => {
              this.loading = false;
              err.type = "error";
              this.$store.dispatch("snackbar/SHOW", err);
            });
        }
      }
    },
  },
};
