import http from "../http/common";

const resource = "/offers";

export default {
  get: (payload) => http.post(`${resource}/get`, payload),
  create: (payload) => http.post(`${resource}/create`, payload),
  update: (payload) => http.post(`${resource}/update`, payload),
  delete: (payload) => http.post(`${resource}/delete`, payload),
  byUser: (payload) => http.post(`${resource}/get-byuser`, payload),
  byId: (payload) => http.post(`${resource}/by-id`, payload),
};
