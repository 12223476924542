export default {
  data: () => ({
    loading: false,
    showEditContactTrue: false,
    conatctTyping: false,
    isContactSelected: false,
    existing: false,
    contactLoading: false,
    contacts: [],
    results: [],
    selectedContact: null,
    contactSearch: "",
    disabled: false,
    IsDuplicate: false,
    ExistUserId: 0,
    existUser: null,
    isHovering: false,
    callbackDisabled: false,
    comment: "",
    enquiryMenu: false,
    followUpTimeMenu: false,
    followUpMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    reasonSearch: null,
    sourceSearch: null,
    leadForm: {
      user_id: null,
      contact_type: "",
      title: "",
      name: "",
      address_one: "",
      address_two: "",
      city: "",
      postcode: "",
      contact_no: "",
      branch_id: "",
      opp_branch_id: "",
      email: "",
      reasons: [],
      symptoms: [],
      service_recommended: [],
      status: 3,
      enquiry_date: new Date().toISOString().slice(0, 10),
      follow_up_date: new Date().toISOString().slice(0, 10),
      follow_up_time: "",
      responsible_id: "",
      opp_responsible_id: "",
      opp_source_id: "",
      source_id: "",
      social_handle: "",
      is_receive_sms: 0,
      campaign_ids: [],
    },
    suggestedTreatments: [],
    rules: {
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      user_id: [(v) => !!v || "Please select contact"],
      opp_branch_id: [(v) => !!v || "Please select branch"],
      comment: [(v) => !!v || "Please enter comment"],
      source: [(v) => !!v || "Please select source"],
    },
    staff: [],
    staffParams: {
      branch: "",
      type: "lead",
    },
    navigationTab: null,
    navigation: [
      {
        name: "Contact Details",
        icon: "mdil-account",
        slug: "personal",
        module: "personal",
        form: true,
      },
      {
        name: "opportunity Details",
        icon: "mdil-star",
        slug: "lead",
        module: "lead",
        form: true,
      },
    ],
    emailError: "",
    contactError: "",
    socialError: "",
    emailErrorMessage: "",
    contactErrorMessage: "",
    socialErrorMessage: "",
  }),
  watch: {
    "leadForm.status"(val) {
      this.rules.status = [];
      this.loading = false;
    },
    "leadForm.email": _.debounce(function () {
      this.isTyping = false;
      if (this.leadForm.email) {
        this.checkForDuplicate();
      }
    }, 3000),
    "leadForm.contact_no": _.debounce(function () {
      this.isTyping = false;
      if (this.leadForm.contact_no) {
        this.checkForDuplicate();
      }
    }, 3000),
    "leadForm.social_handle": _.debounce(function () {
      this.isTyping = false;
      if (this.leadForm.social_handle) {
        this.checkForDuplicate();
      }
    }, 3000),
    "leadForm.source_id": _.debounce(function () {
      this.isTyping = false;
      if (this.leadForm.social_handle) {
        this.checkForDuplicate();
      }
    }, 3000),

    contactSearch: _.debounce(function () {
      this.conatctTyping = false;
    }, 1000),
    conatctTyping: function (value) {
      if (!value) {
        this.globalSearchAPI();
      }
    },
  },
  computed: {
    enquiryUKformat() {
      if (!this.leadForm.enquiry_date) return null;
      const [year, month, day] = this.leadForm.enquiry_date.split("-");
      return `${day}/${month}/${year}`;
    },
    followupUKformat() {
      if (!this.leadForm.follow_up_date) return null;
      const [year, month, day] = this.leadForm.follow_up_date.split("-");
      return `${day}/${month}/${year}`;
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },

    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
    formatContact() {
      if (this.selectedContact) {
        const socilaHandle = this.selectedContact.social_handle
          ? `@${this.selectedContact.social_handle}`
          : null;
        const fullname = [
          this.selectedContact.name,
          socilaHandle,
          this.selectedContact.email,
          this.selectedContact.contact_no,
        ];
        const trimFullName = fullname.filter(function (el) {
          return el != null;
        });
        return trimFullName.join("/");
      } else {
        return "";
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("campaign/LIST", "");
  },
  mounted() {
    this.staffParams.branch = this.user.branch_id;
    this.getStaffList();
  },
  methods: {
    async globalSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.contactSearch;
      this.loading = true;
      await this.$store.dispatch("user/GLOBAL_SEARCH", data).then((res) => {
        this.results = res.data.data;
        this.loading = false;
      });
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    checkForDuplicate() {
      this.CheckEmailDuplicate();
      this.CheckContactDuplicate();
      this.CheckSocialDuplicate();
    },
    async CheckEmailDuplicate() {
      if (this.leadForm.email) {
        let formData = {};
        formData.email = this.leadForm.email;
        await this.validate(formData, "email");
        this.emailError = this.IsDuplicate ? this.emailErrorMessage : "";
      }
    },
    async CheckContactDuplicate() {
      if (this.leadForm.contact_no) {
        let formData = {};
        formData.contact_no = this.leadForm.contact_no;
        await this.validate(formData, "contact");
        this.contactError = this.IsDuplicate ? this.contactErrorMessage : "";
      }
    },
    async CheckSocialDuplicate() {
      if (this.leadForm.social_handle) {
        let formData = {};
        let isValid = this.isValidUrl(this.leadForm.social_handle);
        if (isValid) {
          let url = new URL(this.leadForm.social_handle);
          this.leadForm.source_id = this.getSourceId(url.origin);
        }
        formData.source_id = this.leadForm.source_id;
        formData.social_handle = this.leadForm.social_handle;
        await this.validate(formData, "social");
        this.socialError = this.IsDuplicate ? this.socialErrorMessage : "";
      }
    },
    async validate(formData, type) {
      this.IsDuplicate = false;
      this.emailErrorMessage = "";
      this.contactErrorMessage = "";
      this.socialErrorMessage = "";
      this.ExistUserId = 0;
      this.existUser = null;
      this.loading = true;
      await this.$store
        .dispatch("leads/VALIDATE_LEAD", formData)
        .then((response) => {
          this.loading = false;
          if (response.result === "success" && response.data.status) {
            this.IsDuplicate = response.data.status;
            this.ExistUserId = response.data.user_id;
            this.existUser = response.data.user;
            if (type === "email") {
              this.emailErrorMessage = response.message;
            }
            if (type === "contact") {
              this.contactErrorMessage = response.message;
            }
            if (type === "social") {
              this.socialErrorMessage = response.message;
            }
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async autoSelectSocialIcon() {
      let isValid = this.isValidUrl(this.leadForm.social_handle);
      if (isValid) {
        let url = new URL(this.leadForm.social_handle);
        this.leadForm.source_id = this.getSourceId(url.origin);
      }
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async findContact(item) {
      this.isContactSelected = true;
      this.showEditContactTrue = true;
      this.selectedContact = item;
      this.leadForm.user_id = item.id;
      this.leadForm.responsible_id = item.responsible_id;
      this.leadForm.opp_branch_id = item.branch_id;
    },

    async selectContact() {
      this.isContactSelected = true;
      this.showEditContactTrue = true;
      this.selectedContact = this.existUser;
      this.existing = true;
      this.leadForm.user_id = this.existUser.user_id;
      this.leadForm.responsible_id = this.existUser.responsible_id;
      this.leadForm.opp_branch_id = this.existUser.branch_id;
    },
    loadData() {
      this.$root.$emit("opportunitiesTable", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async removeService(item) {
      const index = this.leadForm.service_recommended.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.leadForm.service_recommended.splice(index, 1);
      } else {
        const i = this.leadForm.service_recommended.findIndex((x) => x === item.id);
        if (i >= 0) this.leadForm.service_recommended.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.leadForm.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.leadForm.symptoms.splice(index, 1);
      } else {
        const i = this.leadForm.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.leadForm.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeReason(item) {
      if (item.id) {
        const index = this.leadForm.reasons.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.leadForm.reasons.splice(index, 1);
        } else {
          const i = this.leadForm.reasons.findIndex((x) => x === item.id);
          if (i >= 0) this.leadForm.reasons.splice(i, 1);
        }
      } else {
        const i = this.leadForm.reasons.findIndex((x) => x === item);
        if (i >= 0) this.leadForm.reasons.splice(i, 1);
      }
    },
    async symptomChange() {
      const ids = this.leadForm.symptoms;
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", { symptoms: ids })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async AddLead() {
      if (!this.existing && this.leadForm.contact_no === "" && this.leadForm.email === "") {
        let response = [];
        response.type = "error";
        response.message = "Please enter either email address/contact number";
        this.$store.dispatch("snackbar/SHOW", response);
        this.navigationTab = "personal";
        this.$refs.personalForm.validate();
      } else if (this.existing && this.leadForm.user_id === "") {
        this.navigationTab = "personal";
        this.$refs.personalForm.validate();
      } else {
        let formData = {};
        formData.user_id = this.leadForm.user_id;

        if (this.existing) {
          formData.user_type = this.selectedContact.user_type;
          formData.title = this.selectedContact.title;
          formData.name = this.selectedContact.name;
          formData.address_one = this.selectedContact.address_one;
          formData.address_two = this.selectedContact.address_two;
          formData.city = this.selectedContact.city;
          formData.postcode = this.selectedContact.postcode;
          formData.branch_id = this.selectedContact.branch_id;
          formData.social_handle = this.selectedContact.social_handle;
          formData.is_receive_sms = this.selectedContact.is_receive_sms;
          if (this.selectedContact.email) {
            formData.email = this.selectedContact.email;
          }
          if (this.selectedContact.contact_no) {
            formData.contact_no = this.selectedContact.contact_no;
          }
          if (this.selectedContact.source_id) {
            formData.source_id = this.selectedContact.source_id;
          }

          formData.status = this.selectedContact.status;
          formData.responsible_id = this.selectedContact.responsible_id;
        } else {
          formData.contact_type = this.leadForm.contact_type;
          formData.title = this.leadForm.title;
          formData.name = this.leadForm.name;
          formData.address_one = this.leadForm.address_one;
          formData.address_two = this.leadForm.address_two;
          formData.city = this.leadForm.city;
          formData.postcode = this.leadForm.postcode;
          formData.branch_id = this.leadForm.branch_id;
          formData.social_handle = this.leadForm.social_handle;
          formData.is_receive_sms = this.leadForm.is_receive_sms;
          if (this.leadForm.email) {
            formData.email = this.leadForm.email;
          }
          if (this.leadForm.contact_no) {
            formData.contact_no = this.leadForm.contact_no;
          }
          if (this.leadForm.source_id) {
            formData.source_id = this.leadForm.source_id;
          }

          formData.status = this.leadForm.status;
          formData.responsible_id = this.leadForm.responsible_id;
        }

        formData.opp_branch_id = this.leadForm.opp_branch_id;
        formData.opp_responsible_id = this.leadForm.opp_responsible_id;
        formData.opp_source_id = this.leadForm.opp_source_id;

        formData.reasons = [];
        this.leadForm.reasons.forEach((value) => {
          if (value.id) {
            formData.reasons.push({ id: value.id });
          } else {
            formData.reasons.push(value);
          }
        });

        formData.service_recommended = [];
        this.leadForm.service_recommended.forEach((value) => {
          if (value.id) {
            formData.service_recommended.push(value.id);
          } else {
            formData.service_recommended.push(value);
          }
        });

        formData.symptoms = [];
        this.leadForm.symptoms.forEach((value) => {
          if (value.id) {
            formData.symptoms.push(value.id);
          } else {
            formData.symptoms.push(value);
          }
        });

        formData.enquiry_date = this.changeDateFormat(this.enquiryUKformat);
        formData.follow_up_date = this.changeDateFormat(this.followupUKformat);
        formData.follow_up_time = this.leadForm.follow_up_time;
        if (this.leadForm.status === "") {
          this.rules.status = [(v) => !!v || "Please select status"];
        }
        this.navigationTab = "personal";
        if (this.$refs.personalForm.validate()) {
          this.navigationTab = "lead";
          if (this.$refs.leadForm.validate()) {
            this.disabled = true;
            await this.$store
              .dispatch("opportunities/CREATE", formData)
              .then((response) => {
                if (response.result === "success") {
                  this.disabled = false;
                  this.loadData();
                } else if (response.result === "fail") {
                  response.type = "error";
                  this.disabled = false;
                  this.$store.dispatch("snackbar/SHOW", response);
                }
              })
              .catch((err) => {
                this.disabled = false;
                err.type = "error";
                this.$store.dispatch("snackbar/SHOW", err);
              });
          }
        }
      }
    },
    async suggTreat(item) {
      let exists = this.leadForm.service_recommended.some((el) => el.id === item.id)
        ? true
        : this.leadForm.service_recommended.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.leadForm.service_recommended.push(item.id);
      } else {
        const index = this.leadForm.service_recommended.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.leadForm.service_recommended.splice(index, 1);
        } else {
          const i = this.leadForm.service_recommended.findIndex((x) => x === item.id);
          if (i >= 0) this.leadForm.service_recommended.splice(i, 1);
        }
      }
    },
    async staffByBranch() {
      this.staffParams.branch = this.leadForm.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async addComment() {
      if (this.$refs.commentForm.validate()) {
        await this.$store
          .dispatch("leads/ADD_COMMENT", { lead_id: this.lead.id, comment: this.comment })
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadAudit();
              this.$refs.commentForm.reset();
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async markToChase() {
      let formData = {};
      formData.contact_no = this.leadForm.contact_no;
      formData.comment = this.leadForm.comment;
      if (this.leadForm.email) {
        formData.email = this.leadForm.email;
      }
      formData.source_id = this.leadForm.source_id;
      formData.social_handle = this.leadForm.social_handle;
      formData.branch_id = this.leadForm.branch_id;
      formData.treatment_ids = this.leadForm.treatment_ids;
      this.loading = true;
      await this.$store
        .dispatch("leads/QUICK_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async UpdateChase() {
      await this.markToChase();
      await this.loadData();
    },

    async callback() {
      await this.markToChase();
      this.callbackDisabled = true;
      let formData = {};
      formData.user_id = this.ExistUserId;
      formData.comment = this.leadForm.comment;
      await this.$store
        .dispatch("callback/CREATE_CALLBACK_USER", formData)
        .then((response) => {
          if (response.result === "success") {
            this.callbackDisabled = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.callbackDisabled = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.callbackDisabled = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    disableStatusOption(item) {
      return [21, 28].includes(item.id);
    },
  },
};
