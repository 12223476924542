<template>
  <v-list-item>
    <v-list-item-title>
      <div class="d-flex align-center">
        <div class="text-capitalize text-body-2 font-weight-bold">
          {{ treatment.name }}
        </div>

        <v-spacer></v-spacer>
        <v-btn icon text color="red" @click="$emit('removeTreatment', treatment.id)"
          ><v-icon>mdi-delete-outline</v-icon></v-btn
        >
      </div>
      <div>
        <div class="d-flex align-center">
          <p class="font-12 lh-14 text-gray-300 font-weight--regular">Number of Sessions:</p>
          <v-spacer></v-spacer>
          <div style="width: 40%">
            <v-text-field
              type="number"
              flat
              solo
              dense
              hide-details="auto"
              class="solo-cust"
              :rules="rules.session"
              v-model="treatment.no_of_sessions"
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex align-center my-2">
          <p class="font-12 lh-14 text-gray-300 font-weight--regular">Duration:</p>
          <v-spacer></v-spacer>

          <div style="width: 40%">
            <v-text-field
              type="number"
              flat
              solo
              dense
              hide-details="auto"
              class="solo-cust"
              :rules="rules.duration"
              v-model="treatment.duration"
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex align-center">
          <p class="font-12 lh-14 text-gray-300 font-weight--regular">Total:</p>

          <v-spacer></v-spacer>
          <div style="width: 40%">
            <v-text-field
              type="number"
              flat
              solo
              dense
              hide-details="auto"
              class="solo-cust"
              :rules="rules.total"
              v-model="treatment.cost"
            >
            </v-text-field>
          </div>
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: ["treatment"],
  data: () => ({
    rules: {
      session: [
        (v) => !!v || "Please enter no of session",
        (v) => (v !== 0 && v !== undefined && v > 0) || "Invalid no of session",
      ],
      duration: [
        (v) => !!v || "Please enter duration",
        (v) => (v !== 0 && v !== undefined && v > 0) || "Invalid duration",
      ],
      total: [
        (v) => !!v || "Please enter total",
        (v) => (v !== 0 && v !== undefined && v > 0) || "Invalid total",
      ],
    },
  }),
};
</script>
