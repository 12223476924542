export default {
  props: {},
  data: () => ({
    statusTags: [
      {
        id: 3,
        name: "New",
        active: true,
        outline: true,
        dropdown: true,
        text: true,
        slug: ["lead", "opportunitie", "client"],
        state: "Start",
        color: "#e6b325",
        sort: "first",
        hidden: false,
      },
      {
        id: 15,
        name: "Follow up",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "client"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 41,
        name: "Callback",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["opportunitie"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 23,
        name: "Recall",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "callback", "support-ticket"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 25,
        name: "Appointment Booked",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "client", "support-ticket"],
        state: "End+",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 12,
        name: "Deal Made",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["appointment", "opportunitie"],
        state: "End+",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 21,
        name: "Invalid",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "appointment", "callback", "support-ticket"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 22,
        name: "Not Interested",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 28,
        name: "Duplicate",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "appointment", "callback", "support-ticket"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 13,
        name: "Dead",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["lead", "opportunitie", "appointment", "support-ticket"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 7,
        name: "Archived",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: [
          "lead",
          "opportunitie",
          "client",
          "appointment",
          "callback",
          "complaint",
          "insight",
          "support-ticket",
          "offer",
          "notification",
        ],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 29,
        name: "No Deal",
        active: false,
        outline: false,
        dropdown: false,
        text: false,
        slug: ["appointment"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 34,
        name: "Matched",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["pabau"],
        state: "Middle",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "second",
        hidden: false,
      },
      {
        id: 35,
        name: "Unmatched",
        active: false,
        outline: true,
        dropdown: false,
        text: false,
        slug: ["pabau"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 36,
        name: "Synced",
        active: false,
        outline: true,
        dropdown: false,
        text: true,
        slug: ["pabau"],
        state: "Middle",
        color: "#EB5757",
        sort: "second",
        hidden: false,
      },
      {
        id: 16,
        name: "Cancelled",
        active: false,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 0,
        name: "Inactive",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["customer"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 9,
        name: "Open",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["insight", "support-ticket"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 37,
        name: "Awaiting Evidence",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["complaint", "support-ticket"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 38,
        name: "Awaiting Consultant Feedback",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["complaint", "support-ticket"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 39,
        name: "Management Decision",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["complaint", "support-ticket"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 40,
        name: "Awaiting Client",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["complaint", "support-ticket"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 10,
        name: "Resolved",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["complaint", "callback", "support-ticket"],
        state: "End+",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 1,
        name: "Active",
        active: false,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["client", "customer", "offer", "notification"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 11,
        name: "Todo",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment", "callback", "support-ticket", "complaint"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 46,
        name: "To be Scheduled",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 49,
        name: "Waiting",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 47,
        name: "Arrived",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "End+",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 48,
        name: "Running Late",
        active: true,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
      {
        id: 5,
        name: "Booked",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["client", "appointment"],
        state: "Middle",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "second",
        hidden: false,
      },
      {
        id: 18,
        name: "Completed",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: [],
        state: "End+",
        color: "rgba(74, 206, 40, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 20,
        name: "Client is Chasing",
        active: false,
        outline: true,
        dropdown: true,
        text: true,
        slug: ["lead", "client"],
        state: "Middle",
        color: "#EB5757",
        sort: "second",
        hidden: false,
      },
      {
        id: 19,
        name: "Appointment Requested",
        active: false,
        outline: true,
        dropdown: false,
        text: false,
        slug: ["lead", "client"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 26,
        name: "Callback Requested",
        active: false,
        outline: true,
        dropdown: false,
        text: false,
        slug: ["lead", "client"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 27,
        name: "No Show",
        active: false,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["appointment"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 42,
        name: "Draft",
        active: false,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["offer", "notification"],
        state: "Middle",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "second",
        hidden: false,
      },
      {
        id: 43,
        name: "Expired",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["offer"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 45,
        name: "Failed",
        active: false,
        outline: false,
        dropdown: true,
        text: false,
        slug: ["notification"],
        state: "End-",
        color: "rgba(211, 65, 65, 0.2)",
        sort: "third",
        hidden: true,
      },
      {
        id: 44,
        name: "Sent",
        active: false,
        outline: true,
        dropdown: true,
        text: false,
        slug: ["notification"],
        state: "Start",
        color: "rgba(34, 31, 32, 0.1)",
        sort: "first",
        hidden: false,
      },
    ],
    statsColors: [
      "#f7c436",
      "#74b744",
      "#2d9bd1",
      "#490faa",
      "#9017b4",
      "#ac2457",
      "#f8382b",
      "#f7622a",
      "#f7a431",
      "#c0c0c0",
      "#5d5d5d",
    ],
  }),
  methods: {
    getSourceName(id) {
      if (this.source) {
        let res = this.source.find((el) => el.id === id);
        return res.name.toLowerCase();
      }
    },
    SortAtoZ(arr) {
      return arr.sort((a, b) => {
        if (a.sort === b.sort) {
          return a.name < b.name ? -1 : 1;
        } else {
          return a.sort < b.sort ? -1 : 1;
        }
      });
      // return arr.sort((a, b) => (b.sort - a.sort || b.name.localeCompare(a.name)));
    },
    getPaymentStatusColor(val) {
      const statuses = {
        "partially-paid": "#DFB447",
        "not-paid": "rgba(211, 65, 65, 0.2)",
        "fully-paid": "rgba(74, 206, 40, 0.2)",
      };
      return statuses[val] || "";
    },
    priorityColor(value) {
      switch (value) {
        case "Low":
        case 1:
          return "rgba(223, 180, 71, 0.2)";
          break;
        case "Medium":
        case 2:
          return "#DFB447";
          break;
        case "High":
        case 3:
          return "rgba(211, 65, 65, 0.2)";
          break;
        default:
          return "";
          break;
      }
    },
    getStatusName(status) {
      let obj = this.statusTags.find((el) => el.id === status);
      if (obj) {
        return obj.name;
      } else {
        return "";
      }
    },
    getColorByStatusName(status) {
      let statusName = status === "Follow-up" ? "Follow up" : status;
      let obj = this.statusTags.find((el) => el.name === statusName);
      if (obj) {
        return obj.color;
      } else {
        return "";
      }
    },
    getColor(status) {
      let obj = this.statusTags.find((el) => el.id === status);
      if (obj) {
        return obj.color;
      } else {
        return "";
      }
    },
    getStatus(string) {
      const statuses = {
        0: "Inactive",
        1: "Active",
        2: "Deleted",
        3: "New",
        4: "Contacted",
        5: "Booked",
        6: "FSO",
        7: "Archived",
        8: "Client",
        9: "Open",
        10: "Resolved",
        11: "Todo",
        12: "Deal",
        13: "Dead",
        14: "Appointment",
        15: "Follow-up",
        16: "Cancelled",
        17: "Rescheduled",
        18: "Completed",
        19: "Requested Appointment",
        20: "Client is Chasing",
        21: "Invalid",
        22: "Not Interested",
        23: "Recall",
        24: "Booked-Call Back",
        25: "Booked - Appointmen",
        26: "Requested Callback",
        27: "No Show",
        28: "Duplicate",
        29: "No Deal",
      };

      return statuses[string] || "";
    },
    statusDisable(item) {
      if (this.statusTags) {
        let res = this.statusTags.find((el) => el.id === item.id);
        return !res.dropdown ? true : false;
      }
    },
  },
};
