<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Create Deal</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#deal">Deal</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="tab">
          <div class="d-flex my-4">
            <span
              class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
              v-if="appointment.user && appointment.user.name"
            >
              {{ appointment.user.name }}
            </span>
            <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
              <img
                :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                class="mr-2"
                v-if="appointment.user.source_name !== null"
              />
              {{ appointment.user.social_handle }}
            </span>
          </div>
          <div class="d-flex">
            <span
              class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
              :class="appointment.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
              >{{ appointment.user.user_type_name }}</span
            >
            <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
              <span class="mr-1">
                <inline-svg src="/images/icons/map.svg" />
              </span>
              {{ appointment.user.branch_name }}
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.email"
            >
              <span class="mr-1">
                <inline-svg src="/images/icons/at.svg" />
              </span>
              {{ appointment.user.email }}
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.contact_no"
            >
              <span class="mr-1">
                <inline-svg src="/images/icons/mobile.svg" />
              </span>
              {{ appointment.user.contact_no }}
              <v-tooltip
                content-class="copy-tooltip"
                :open-on-hover="false"
                :open-on-click="true"
                color="#000"
                text-color="#fff"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyContent(appointment.user.contact_no)"
                    v-on="on"
                    size="15"
                    class="ml-2"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copied to clipboard</span>
              </v-tooltip>
            </span>
            <span
              class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
              v-if="appointment.user.social_handle !== null"
            >
              <img
                :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                height="15"
                class="mr-2"
                v-if="appointment.user.source_name !== null"
              />
              {{ appointment.user.social_handle }}
            </span>
          </div>

          <v-tab-item value="deal" eager>
            <tab-deal
              :userId="appointment.user_id"
              :keyId="appointment.id"
              :itemData="appointment"
            ></tab-deal>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn
          class="cancel-btn btn-gap px-15"
          depressed
          rounded
          outlined
          x-large
          @click="loadData"
        >
          Close
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import sliderFn from "../../../mixins/sliderFn.js";
import TabDeal from "../../../components/TabItems/TabDeal";
export default {
  mixins: [globalFn, status, sliderFn],
  components: {
    TabDeal,
  },
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
  }),
  computed: {
    appointment() {
      if (this.$router.currentRoute.meta.name === "Appointments") {
        if (this.$store.state.appointments.item) {
          return this.$store.state.appointments.item;
        }
      }
      if (this.$store.state.opportunities.item) {
        return this.$store.state.opportunities.item;
      }
    },
  },
  methods: {
    loadData() {
      this.$root.$emit("appointmentsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
  },
};
</script>
