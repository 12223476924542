<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Preview Form</div>
        <v-tabs v-model="activeTab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :href="`#${item.slug}`">
              {{ item.name | capitalize }}
            </v-tab>
          </template>
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="activeTab">
          <v-tab-item value="preview" :eager="true">
            <v-form ref="form">
              <div id="forms-wrapper" class="tab-content-wrapper">
                <v-row>
                  <v-col cols="12">
                    <div class="form-title text-center">
                      {{ formDetails.title | capitalize }}
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="formDetails.description">
                    <div
                      class="font-14 line-h-17 font-weight--bold text-black-100"
                      v-html="formDetails.description"
                    ></div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="px-4">
                    <FormElement
                      v-for="(item, index) in formDetails.elements"
                      :key="index"
                      :element="item"
                    />
                  </v-col>
                  <v-col cols="12">
                    <label>
                      <span class="label-txt">Consultant Signature:</span>
                    </label>
                    <div class="mt-2">
                      <signature-pad />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <label>
                      <span class="label-txt">Patient Signature:</span>
                    </label>
                    <div class="mt-2">
                      <signature-pad />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="permissions" :eager="true">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Find client: </span>
                    </label>
                    <div class="mt-2 mb-4" v-if="selectedAppointment">
                      <v-chip class="este-chip tag-chip px-3 mb-2">
                        <span v-if="selectedAppointment.name">{{ selectedAppointment.name }} </span>
                        <span v-if="selectedAppointment.name === null"
                          >{{ selectedAppointment.social_handle }}
                        </span>
                        <span v-if="selectedAppointment.contact_no"
                          >/ {{ selectedAppointment.contact_no }}
                        </span>
                        <span v-if="selectedAppointment.email"
                          >/ {{ selectedAppointment.email }}
                        </span>
                        <v-icon
                          color="primary"
                          class="ml-2"
                          small
                          @click.stop="removeAppointmentedUser(item)"
                        >
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </div>
                    <v-text-field
                      flat
                      rounded
                      prepend-inner-icon="mdi-magnify"
                      :rules="rules.user_id"
                      autocomplete="off"
                      label="Search for a client"
                      class="solo-cust"
                      clearable
                      clear-icon="mdi-close-circle-outline"
                      solo
                      hide-details="auto"
                      @input="isTyping = true"
                      v-model="search"
                      :loading="loading"
                    >
                    </v-text-field>
                    <ul
                      class="contact--list"
                      v-if="search.length > 3"
                      :class="appointments.length > 20 ? 'height-300' : ''"
                    >
                      <li v-for="(item, index) in appointments" :key="index">
                        <a @click="selectContact(item)" class="d-flex align-center flex-row cursor">
                          <span
                            class="font-12 line-h-15 font-weight--regular text-black-100 ml-3"
                            v-if="item"
                          >
                            <span v-if="item.name">{{ item.name }} </span>
                            <span v-if="item.name === null">{{ item.social_handle }} </span>
                            <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                            <span v-if="item.email">/ {{ item.email }} </span>
                          </span>
                          <span
                            class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                            :class="item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                          >
                            {{ item.user_type_name === "Lead" ? "L" : "C" }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="AddLead"
        >
          Update
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
import FormElement from "../../../../components/FormPreview/FormElement.vue";
import submitForm from "../../../../components/FormPreview/SubmitForm.vue";
import signaturePad from "../../../../components/SignaturePad.vue";

export default {
  mixins: [status, globalFn],
  components: {
    FormElement,
    signaturePad,
    submitForm,
  },
  data: () => ({
    tabs: [
      {
        name: "Preview",
        slug: "preview",
      },
      {
        name: "Permissions",
        slug: "permissions",
      },
    ],
    isSubmitable: false,
    isTyping: false,
    appointments: [],
    loading: false,
    activeTab: "preview",
    search: "",
    selectedAppointment: "",
    user_id: 0,
    rules: {
      user_id: [
        (v) => !!v || "Please select client",
        (v) => (v && v.length > 3) || "Please enter min 3 characters",
      ],
    },
  }),
  computed: {
    formDetails() {
      return this.$store.state.form.formDetails;
    },
  },

  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.search.length > 3) {
        this.filterUserSearchAPI();
      }
    },
  },
  created() {
    if (this.$router.currentRoute.meta.name === "Appointments") {
      this.isSubmitable = true;
    } else {
      this.isSubmitable = false;
    }
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    formatStr(str) {
      return str.split("_").join(" ");
    },
    async filterUserSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.search;
      this.loading = true;
      await this.$store.dispatch("appointments/FILTERED_APPOINTMENTS", data).then((res) => {
        this.appointments = res.data;
        this.loading = false;
      });
    },
    async selectContact(item) {
      this.selectedAppointment = item;
      this.user_id = item.id;
    },
    async removeAppointmentedUser(item) {
      this.selectedAppointment = "";
      this.user_id = null;
    },
    async addForm() {
      await this.$store.dispatch("formPatientCrm/ADD_FORM", {
        id: this.user_id,
        medical_form_id: this.formDetails.id,
      });
    },
    async submitForm() {
      console.log("submited");
    },
  },
};
</script>
<style lang="scss">
.forms-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 1rem;
}

.forms-wrapper::-webkit-scrollbar {
  display: none;
}

.forms-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
