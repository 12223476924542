<template>
  <div>
    <v-text-field
      flat
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      label="Search for a lead/client…"
      solo
      hide-details
      class="search-input"
      @input="isTyping = true"
      v-model="search"
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="reset"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" md="3" sm="6" lg="3" xl="3">
                <span class="heading">Date Updated:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="dateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.start_date)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.start_date"
                      no-title
                      @input="dateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="dateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.end_date)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.end_date"
                      no-title
                      @input="dateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="3" sm="6" lg="3" xl="3">
                <span class="heading">Type:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-select
                    placeholder="Type"
                    autocomplete="off"
                    :items="types"
                    v-model="filter.type"
                    solo
                    outlined
                    flat
                    class="advance-input keyword"
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </div>
                <div>
                  <span class="heading">Keyword:</span>
                  <div class="d-flex mt-2 mb-4">
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      v-model="filter.keyword"
                      flat
                      hide-details="auto"
                      class="advance-input keyword"
                      placeholder="keyword"
                      prepend-inner-icon="mdi-magnify"
                      :full-width="true"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="3" sm="6" lg="3" xl="3">
                <span class="heading"
                  >Branch:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="BranchToggle"
                    @click="handleFunction('BranchCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="branch in BrachDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="branch.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="branchToggle(branch)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="3" sm="6" lg="3" xl="3">
                <span class="heading">Staff:</span>
                <div class="mt-2">
                  <template v-for="item in userStaff">
                    <v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="tag-chip mb-2 staff-chip"
                          :class="item.active === true ? 'selected' : ''"
                          text-color="black"
                          @click="userStaffToggle(item)"
                        >
                          {{ getInitials(item.name) }}
                        </v-chip>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import advancedSearch from "../../mixins/advancedSearch";
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status";
import _ from "lodash";
export default {
  name: "ComplaintSearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dateFromMenu: false,
    dateToMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    filter: {
      start_date: new Date(new Date().setDate(new Date().getDate() - 2))
        .toISOString()
        .substr(0, 10),
      end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10),
      user_id: [],
      branch: [],
      search: "",
      staff: [],
      type: "",
      keyword: "",
    },
    users: [],
    search: "",
    isTyping: false,
    types: ["status", "comment"],
  }),
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.filter.search = this.search;
        this.checkAll();
        this.searchKey();
      }
    },
  },
  mounted() {
    this.loadDefaults();
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: "", params: "" });
  },
  computed: {
    userStaff() {
      this.$store.state.staff.all = this.$store.state.staff.all.map(function (el) {
        let item = Object.assign({}, el);
        item.active = false;
        return item;
      });
      return this.$store.state.staff.all;
    },
  },
  methods: {
    async searchKey() {
      console.log(this.filter);
      this.$root.$emit("searchLeadActivityTable", this.filter);
    },
    async userStaffToggle(item) {
      item.active = !item.active;
      this.filter.staff = this.userStaff
        .filter(function (el) {
          return el.active === true;
        })
        .map(function (el) {
          return el.id;
        });
    },

    async reset() {
      await this.resetFilter();
      await this.loadDefaults();
    },
    async resetFilter() {
      this.filter.date = new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .substr(0, 10);
      this.filter.user_id = [];
      this.filter.branch_id = [];
      this.filter.staff = [];
      this.filter.search = "";
      this.filter.keyword = "";
      this.filter.type = "";
    },
  },
};
</script>
