import http from "../../http/common";

const resource = "/shift-template";

export default {
  get: () => http.get(`${resource}?sort_order=ASC&sort_by=id`),

  show: (id) => http.get(`${resource}/${id}`),

  create: (payload) => http.post(resource, payload),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),
};
