import collection from "../../../api/collections/Deals";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    item: {},
    widget: {},
    pagination: {},
  },
  mutations: {
    SET_DEAL(state, data) {
      state.list = data;
    },
    SET_DEAL_LIST(state, data) {
      state.all = data;
    },
    SET_DEAL_ITEM(state, data) {
      state.item = data;
    },
    RESET_DEAL(state, data) {
      state.item = {};
    },
    SET_DEAL_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {};
    },
  },
  actions: {
    async GET_DEAL({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload.data, payload.params);
          commit("SET_DEAL", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_DEAL",
        true
      );
    },
    async GET_ALL_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.all(payload.data, payload.params);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_DEAL",
        true
      );
      return data;
    },
    async GET_DEAL_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.list(payload.data, payload.params);
          commit("SET_DEAL_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_DEAL_LIST",
        true
      );
    },
    async CREATE_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_DEAL",
        true
      );
      return data;
    },
    async SHOW_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.params) {
            payload.params = "";
          }
          const { data } = await collection.show(payload.id, payload.params);
          commit("SET_DEAL_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_DEAL",
        false
      );
      return data;
    },
    async UPDATE_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_DEAL",
        true
      );

      return data;
    },
    async DELETE_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_DEAL",
        true
      );
      return data;
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.dealCount(payload);
          commit("SET_WIDGET_COUNT", data.data);
          return data;
        },
        commit,
        dispatch,
        "WIDGET_COUNT",
        true
      );
    },
  },
};
