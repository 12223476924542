<template>
  <div class="fill-height slider-cont">
    <v-card class="vertical-slide fill-height">
      <v-card-text class="pa-0">
        <v-row dense class="e-row">
          <v-col cols="6" md="3" class="e-col pa-0 ma-0">
            <v-toolbar class="left-toolbar" flat> </v-toolbar>
            <v-tabs v-model="navigationTab" vertical class="left-nav mt-0">
              <template v-for="(item, index) in navigation">
                <v-tab :href="`#${item.slug}`" v-if="hasPermission(item)">
                  <v-icon left>
                    {{ item.icon }}
                  </v-icon>
                  {{ item.name }}
                  <v-chip class="ml-1 count-chip" v-if="item.name === 'Activity'">
                    <img src="/images/bolt-grey.svg" class="mr-1" />
                    <!-- {{commentCount.comment}} -->
                    {{ lead.activity_count }}
                  </v-chip>
                </v-tab>
              </template>
            </v-tabs>
          </v-col>
          <v-col cols="6" md="9" class="e-col pa-0 ma-0">
            <v-toolbar class="right-toolbar" flat>
              <div class="form-title">Edit Lead</div>
            </v-toolbar>
            <div class="slide-right">
              <div class="side-pane">
                <div class="form-wrap px-10">
                  <v-tabs-items v-model="navigationTab">
                    <v-tab-item value="personal" :eager="true">
                      <v-form ref="form">
                        <div class="tab-content-wrapper">
                          <v-row dense class="e-row">
                            <v-col cols="6" md="2" class="e-col">
                              <div>
                                <label>
                                  <span class="label-txt">Title</span>
                                  <v-select
                                    autocomplete="off"
                                    :items="titles"
                                    v-model="lead.title"
                                    solo
                                    outlined
                                    flat
                                    class="solo-cust"
                                    hide-details="auto"
                                  >
                                    <template slot="append">
                                      <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                  </v-select>
                                </label>
                              </div>
                            </v-col>
                            <v-col cols="6" md="10" class="e-col">
                              <div>
                                <label>
                                  <span class="label-txt"> Name </span>
                                  <v-text-field
                                    autocomplete="off"
                                    solo
                                    outlined
                                    v-model="lead.name"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                  ></v-text-field>
                                </label>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Social Handle </span>
                              </label>
                              <v-text-field
                                autocomplete="off"
                                solo
                                outlined
                                v-model="lead.social_handle"
                                flat
                                hide-details="auto"
                                class="solo-cust social-handle-input"
                              >
                                <template slot="append-outer">
                                  <v-select
                                    autocomplete="off"
                                    v-model="lead.source_id"
                                    :items="socialMedia"
                                    item-text="name"
                                    item-value="id"
                                    solo
                                    outlined
                                    flat
                                    class="este-autocomplete-input"
                                    hide-details="auto"
                                  >
                                    <template v-slot:selection="data">
                                      <img
                                        :src="`images/${trimLowerCase(data.item.name)}.svg`"
                                        height="20px"
                                      />
                                    </template>
                                    <template v-slot:item="data">
                                      <img
                                        :src="`images/${trimLowerCase(data.item.name)}.svg`"
                                        height="20px"
                                      />
                                    </template>
                                    <template slot="append">
                                      <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                  </v-select>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Phone No. </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  v-model="lead.contact_no"
                                  flat
                                  hide-details="auto"
                                  class="solo-cust"
                                  append-icon="mdil-phone"
                                ></v-text-field>
                              </label>
                            </v-col>
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Email Address </span>
                                <v-text-field
                                  autocomplete="off"
                                  v-model="lead.email"
                                  solo
                                  outlined
                                  flat
                                  hide-details="auto"
                                  class="solo-cust"
                                  append-icon="mdil-email"
                                ></v-text-field>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Address </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="lead.address_one"
                                  hide-details="auto"
                                  class="solo-cust mb-2"
                                ></v-text-field>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="lead.address_two"
                                  hide-details="auto"
                                  class="solo-cust mar-8"
                                ></v-text-field>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Town / City </span>
                                <v-text-field
                                  autocomplete="off"
                                  solo
                                  outlined
                                  flat
                                  v-model="lead.city"
                                  hide-details="auto"
                                  class="solo-cust"
                                ></v-text-field>
                              </label>
                            </v-col>
                            <v-col cols="12" md="6" class="e-col">
                              <v-row no-gutters>
                                <v-col cols="6">
                                  <label>
                                    <span class="label-txt"> Post Code </span>
                                    <v-text-field
                                      autocomplete="off"
                                      solo
                                      outlined
                                      flat
                                      v-model="lead.postcode"
                                      hide-details="auto"
                                      class="solo-cust"
                                    ></v-text-field>
                                  </label>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Preferred Branch: </span>
                                <v-select
                                  autocomplete="off"
                                  :items="branches"
                                  :rules="rules.branch_id"
                                  item-value="id"
                                  item-text="branch"
                                  v-model="lead.branch_id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <p class="sub-title mb-4">Contact Preferences</p>
                              <label>
                                <span class="label-txt"> Receive SMS </span>
                              </label>
                              <v-radio-group
                                class="mt-2"
                                hide-details="auto"
                                mandatory
                                v-model="lead.is_receive_sms"
                                row
                              >
                                <v-radio
                                  :ripple="false"
                                  off-icon="mdi-checkbox-blank-circle-outline"
                                  on-icon="mdi-check-circle"
                                  hide-details="auto"
                                  label="Yes"
                                  :value="1"
                                ></v-radio>
                                <v-radio
                                  :ripple="false"
                                  off-icon="mdi-checkbox-blank-circle-outline"
                                  on-icon="mdi-check-circle"
                                  hide-details="auto"
                                  label="No"
                                  :value="0"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="lead" :eager="true">
                      <v-form ref="leadForm">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Reason for Treatment: </span>
                              </label>
                              <v-combobox
                                v-model="lead.reasons"
                                :items="reasons"
                                item-text="name"
                                item-value="id"
                                multiple
                                :search-input.sync="reasonSearch"
                                solo
                                outlined
                                flat
                                chips
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        No results matching "<strong>{{ reasonSearch }}</strong
                                        >". Press <kbd>enter</kbd> to create a new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2" v-if="data.item.name">
                                      {{ data.item.name }}
                                    </span>
                                    <span class="pr-2" v-else>
                                      {{ data.item }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeReason(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Symptoms / Condition: </span>
                              </label>
                              <v-autocomplete
                                v-model="lead.symptoms"
                                :items="symptoms"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                                @change="symptomChange"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeSymptoms(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Suggested Treatments: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip
                                  small
                                  class="este-chip status-chip mb-2"
                                  text-color="black"
                                  @click="suggTreat(treat)"
                                >
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Service Recommended: </span>
                              </label>
                              <v-autocomplete
                                v-model="lead.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeService(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Enquiry Date </span>
                                <v-menu
                                  v-model="enquiryMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="dateUkFormat(lead.lead_details.enquiry_date)"
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="lead.lead_details.enquiry_date"
                                    no-title
                                    @input="enquiryMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Follow Up Date </span>
                                <v-menu
                                  v-model="followUpMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      @click:clear="clearFollowUpDate"
                                      clearable
                                      clear-icon="mdi-close-circle-outline"
                                      autocomplete="off"
                                      :value="dateUkFormat(lead.lead_details.follow_up_date)"
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      prepend-inner-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="lead.lead_details.follow_up_date"
                                    no-title
                                    @input="followUpMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">Follow Up Time:</span>
                                <v-menu
                                  ref="followUpTimeMenu"
                                  v-model="followUpTimeMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="lead.lead_details.follow_up_time"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      @click:clear="clearFollowUpTime"
                                      clearable
                                      clear-icon="mdi-close-circle-outline"
                                      outlined
                                      flat
                                      hide-details="auto"
                                      solo
                                      class="solo-cust"
                                      v-model="lead.lead_details.follow_up_time"
                                      prepend-inner-icon="mdil-clock"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    :allowed-minutes="allowedStep"
                                    no-title
                                    ampm-in-title
                                    format="24hr"
                                    v-if="followUpTimeMenu"
                                    v-model="lead.lead_details.follow_up_time"
                                    full-width
                                    @click:minute="
                                      $refs.followUpTimeMenu.save(lead.lead_details.follow_up_time)
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Campaigns: </span>
                              </label>
                              <v-autocomplete
                                v-model="lead.campaigns"
                                :items="campaigns"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeCampaign(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Source: </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :rules="rules.source"
                                :items="source"
                                v-model="lead.source_id"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Responsible </span>
                              </label>
                              <v-autocomplete
                                :items="staff"
                                v-model="lead.lead_details.responsible_id"
                                item-text="name"
                                item-value="id"
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeResponsible(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Lead Status </span>
                                <v-select
                                  autocomplete="off"
                                  :item-disabled="statusDisable"
                                  :items="
                                    SortAtoZ(
                                      statusTags.filter(function (el) {
                                        return el.slug.includes('lead');
                                      })
                                    )
                                  "
                                  item-value="id"
                                  item-text="name"
                                  v-model="lead.status"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="appointment" :eager="true">
                      <tab-appointment
                        :itemData="lead"
                        :userId="lead.id"
                        :keyId="lead.id"
                      ></tab-appointment>
                    </v-tab-item>
                    <v-tab-item value="callback" :eager="true">
                      <tab-callback
                        :itemData="lead"
                        :userId="lead.id"
                        :keyId="lead.id"
                      ></tab-callback>
                    </v-tab-item>
                    <v-tab-item value="insight" :eager="true">
                      <tab-insight
                        :itemData="lead"
                        :userId="lead.id"
                        :keyId="lead.id"
                      ></tab-insight>
                    </v-tab-item>
                    <v-tab-item value="activity" :eager="true">
                      <tab-activity
                        :itemData="lead"
                        :userId="lead.id"
                        :keyId="lead.id"
                      ></tab-activity>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="b-blue"
                      depressed
                      @click="updateLead"
                      :loading="loading"
                      rounded
                      x-large
                    >
                      Save Changes
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import TabActivity from "../../../components/TabItems/TabActivity";
import TabInsight from "../../../components/TabItems/TabInsight";
import TabCallback from "../../../components/TabItems/TabCallback";
import TabAppointment from "../../../components/TabItems/TabAppointment";
import editLead from "./editLead.js";
export default {
  mixins: [globalFn, status, editLead, sidePaneMenu],
  components: {
    TabActivity,
    TabInsight,
    TabCallback,
    TabAppointment,
  },
  data: () => ({}),
  methods: {},
  mounted() {
    this.paneTab = "lead";
    this.leadUnit = true;
  },
};
</script>
