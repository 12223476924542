<template>
  <div>
    <div class="content-wrapper">
      <v-layout>
        <v-flex>
          <v-row class="mt-6" v-if="loading">
            <v-col cols="12" md="12">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <template v-if="!loading">
            <v-row class="mt-6">
              <v-col cols="12" md="3">
                <Profile :contact="contact" />
              </v-col>
              <v-col cols="12" md="9">
                <v-card flat class="pa-6">
                  <v-row>
                    <v-col cols="12" md="3">
                      <!-- <v-list flat v-model="currentTabComponent">
                        <template v-for="(tab, index) in tabs" :key="index">
                          <v-list-item v-if="!tab.submenu" :ripple="false" :value="tab.component">
                            <div class="text-center">
                              <v-list-item-title class="text-body-2 mt-2">{{
                                tab.title
                              }}</v-list-item-title>
                            </div>
                          </v-list-item>

                          <v-list-group
                            sub-group
                            v-else-if="tab.submenu"
                            :ripple="false"
                            color="secondary"
                          >
                            <template v-slot:activator>
                              <v-list-item-title>{{ tab.title }}</v-list-item-title>
                            </template>

                            <v-list-item
                              v-for="child in tab.submenu"
                              :key="child.title"
                              :value="child.component"
                            >
                              <div class="text-center">
                                <v-list-item-title class="text-body-2 mt-2">{{
                                  child.title
                                }}</v-list-item-title>
                              </div>
                            </v-list-item>
                          </v-list-group>
                        </template>
                      </v-list> -->
                      <TabMenu :contact="contact" :currentTabComponent="currentTabComponent" />
                    </v-col>
                    <v-col cols="12" md="9">
                      <component :is="currentTabComponent" :contact="contact"></component>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import status from "../../../mixins/status";
import globalFn from "../../../mixins/globalFn";
import Profile from "../../../components/ContactViewMolecules/Profile/index.vue";
import TabMenu from "../../../components/ContactViewMolecules/TabMenu.vue";
import Appointments from "../../../components/ContactViewMolecules/Appointments.vue";
import Financials from "../../../components/ContactViewMolecules/Financials.vue";
import Forms from "../../../components/ContactViewMolecules/Forms.vue";
import Photos from "../../../components/ContactViewMolecules/Photos/index.vue";
import Documents from "../../../components/ContactViewMolecules/Documents.vue";
export default {
  name: "ViewContact",
  mixins: [status, globalFn],
  components: {
    Profile,
    TabMenu,
    Appointments,
    Forms,
    Documents,
    Financials,
    Photos,
  },
  data() {
    return {
      loading: false,
      currentTabComponent: Appointments,
      tabs: [
        {
          title: "Appointments",
          active: true,
          component: "Appointments",
        },
        {
          title: "Charts",
          submenu: [
            { title: "Treament Notes", component: "TreatmentNotes" },
            { title: "Forms", component: "Forms" },
            { title: "Photos", component: "Photos" },
            { title: "Documents", component: "Documents" },
          ],
        },
        {
          title: "Financials",
          component: "Financials",
        },
      ],
    };
  },
  computed: {
    contact() {
      return this.$store.state.contacts.item;
    },
  },
  mounted() {
    this.refreshScreen();
    this.$root.$on("monitorTabEvent", this.monitorTabEvent);
  },
  methods: {
    async refreshScreen() {
      this.loading = true;
      await this.$store.dispatch("contacts/CONTACT_BY_ID", {
        user_id: this.$router.currentRoute.params.id,
      });
      this.loading = false;
    },
    monitorTabEvent(item) {
      this.currentTabComponent = item.component;
    },
  },
};
</script>
<style lang="css" scoped></style>
