<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0 pt-3">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <template v-if="item.user">
                  <div class="d-flex align-center flex-row position-relative mr-4">
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                      v-if="item.user.name"
                    >
                      {{ item.user.name }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border"
                      v-if="!item.user.name && !item.user.social_handle"
                      >Not Supplied</span
                    >
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2 d-flex align-center justify-center"
                      v-if="item.user.name === null"
                    >
                      <img
                        :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                        class="mr-1"
                        v-if="item.user.source_name !== null"
                      />
                      {{ item.user.social_handle }}
                    </span>
                    <span
                      class="position-absolute small mt-n5 ml-n1 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                      :class="item.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                    >
                      {{ item.user.user_type_name === "Lead" ? "L" : "C" }}
                    </span>
                  </div>
                  <div class="d-flex align-center ml-2">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.user.contact_no }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                      >{{ item.branch_name }}</span
                    >
                  </div>
                  <div
                    class="d-flex align-center ml-2"
                    v-if="item.user.email !== null && item.user.email !== ''"
                  >
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.user.email }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex align-center flex-row position-relative mr-4 mb-2">
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                      v-if="item.client_name"
                    >
                      {{ item.client_name | capitalize }}
                    </span>
                  </div>
                  <div class="d-flex align-center mt-1 ml-2">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.mobile }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                      >{{ item.branch_name }}</span
                    >
                  </div>
                  <div
                    class="d-flex align-center mt-1 ml-2"
                    v-if="item.email !== null && item.email !== ''"
                  >
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.email }}
                    </span>
                  </div>
                </template>
              </template>
              <template v-slot:item.package_name="{ item }">
                <span
                  v-html="item.package_name"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                >
                </span>
                <p class="total">
                  <span class="font-10 line-h-12 text-black-300 font-weight--semibold"
                    >RRP Price: {{ item.package_price | currency("£") }}</span
                  >
                </p>
                <p class="total mb-2">
                  <span class="font-10 line-h-12 text-black-300 font-weight--semibold"
                    >Total: {{ item.package_total | currency("£") }}</span
                  >
                </p>
              </template>
              <template v-slot:item.amount="{ item }">
                <p class="total">
                  <span class="font-10 line-h-12 font-weight--semibold green--text"
                    >Paid: {{ item.amount_paid | currency("£") }}</span
                  >
                </p>
                <p class="total mb-2">
                  <span class="font-10 line-h-12 font-weight--semibold red--text"
                    >Outstanding: {{ item.amount_outstanding | currency("£") }}</span
                  >
                </p>
              </template>
              <template v-slot:item.payment="{ item }">
                <p class="date-sec date-mute" v-if="item.payment_method !== ''">
                  <span
                    class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                  >
                    {{ item.payment_method | capitalize }}
                  </span>
                </p>
                <p class="date-sec vertical-bar" v-if="item.payment_date !== null">|</p>
                <p class="date-sec date-mute" v-if="item.payment_date !== null">
                  <v-btn class="badge badge-grey" icon>
                    <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                  </v-btn>
                  {{ item.payment_date | moment("DD/MM/YY") }}
                </p>
              </template>
              <template v-slot:item.responsible="{ item }">
                <v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.employee) }}
                    </span>
                  </template>
                  <span>{{ item.employee }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on" v-if="item.status !== 36">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li>
                        <a @click="editItem(item)" v-if="item.status !== 36 && item.status === 35"
                          >Assign User</a
                        >
                      </li>
                      <li>
                        <a @click="editItem(item)" v-if="item.status !== 36 && item.status === 34"
                          >Edit Assigned User</a
                        >
                      </li>
                      <li>
                        <a @click="payment(item)" v-if="item.status !== 36">Connect Payment</a>
                      </li>
                      <li><a @click="deal(item)" v-if="item.status !== 36">Create Deal</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "Complaints",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Package Details", value: "package_name", align: "left", sortable: false },
        { text: "Amount Details", value: "amount", align: "left", sortable: false },
        { text: "Sold By", value: "responsible", align: "left", sortable: false },
        { text: "Payment", value: "payment", sortable: false, align: "left" },
        { text: "Status", value: "status", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      payload: {
        attributes: "user",
        search: "",
        filter: {
          sale_date_from: "",
          sale_date_to: "",
          payment_date_from: "",
          payment_date_to: "",
          status: [],
          branch: [],
        },
      },
      showParams: "?attributes=user",
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        console.log("here1");
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
        }
        this.itemsPerPage = $event.itemsPerPage;
        this.page = $event.page;
        this.readTheDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("salesTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchSales", this.getSearchSales);
  },
  methods: {
    async getSearchSales(data) {
      console.log("here2");
      this.options.page = 1;
      this.items = [];
      this.payload.search = data.search;
      this.payload.filter.sale_date_from = data.sale_date_from;
      this.payload.filter.sale_date_to = data.sale_date_to;
      this.payload.filter.payment_date_from = data.payment_date_from;
      this.payload.filter.payment_date_to = data.payment_date_to;
      this.payload.filter.status = data.status;
      this.payload.filter.branch = data.branch;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      console.log("here3");
      this.loading = true;
      await this.$store
        .dispatch("sales/GET", {
          data: this.payload,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },

    async editItem(item) {
      await this.$store
        .dispatch("sales/SHOW", { id: item.id, param: this.showParams })
        .then((res) => {
          this.openDrawer("add", "750", "sales/edit");
        });
    },

    async editAssignedItem(item) {
      await this.$store
        .dispatch("clients/SHOW_CLIENT", {
          id: item.user_id,
          param:
            "?attributes=staff,branch,clientDetails,reasons,symptoms,treatments,documents,comments,source,callbacks,appointments",
        })
        .then((res) => {
          if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
            this.openDrawer("add", "1100", "clients/editPane");
          } else {
            this.openDrawer("add", "750", "clients/editTab");
          }
        });
    },

    async deal(item) {
      if (item.user_id) {
        await this.$store
          .dispatch("sales/SHOW", { id: item.id, param: this.showParams })
          .then((res) => {
            this.openDrawer("add", "750", "sales/deal");
          });
      } else {
        let res = [];
        res.type = "error";
        res.message = "Please assign user first then try creating a deal";
        this.$store.dispatch("snackbar/SHOW", res);
      }
    },

    async payment(item) {
      if (item.user_id) {
        await this.$store
          .dispatch("sales/SHOW", { id: item.id, param: this.showParams })
          .then((res) => {
            this.openDrawer("add", "750", "sales/payment");
          });
      } else {
        let res = [];
        res.type = "error";
        res.message = "Please assign user first then try connecting payment";
        this.$store.dispatch("snackbar/SHOW", res);
      }
    },
  },
};
</script>
