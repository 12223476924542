<template>
  <v-card flat>
    <v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
      <v-toolbar-title>
        <div class="form-title">Edit Support Ticket</div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="navigationTab"
      :vertical="vertical"
      class="este__tabs"
      :class="responsiveClass"
    >
      <v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
      <template v-for="(item, index) in navigation">
        <v-tab :href="`#${item.slug}`">
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
    <div class="tab__content__wrapper">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="ticket" :eager="true">
          <div class="content__text">
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div class="d-flex mb-4">
                  <span
                    class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                    v-if="complaint.user && complaint.user.name"
                  >
                    {{ complaint.user.name }}
                  </span>
                  <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                    {{ complaint.user.social_handle }}
                  </span>
                  <template v-for="(val, index) in statusTags">
                    <span
                      class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                      :style="
                        val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                      "
                      :key="index"
                      v-if="complaint.user.status === val.id"
                      >{{ val.name }}</span
                    >
                  </template>
                </div>
                <div class="d-flex">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                    :class="
                      complaint.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                    "
                    >{{ complaint.user.user_type_name }}</span
                  >
                  <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
                    <span class="mr-1">
                      <inline-svg src="/images/icons/map.svg" />
                    </span>
                    {{ complaint.user.branch_name }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="complaint.user.email"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/at.svg" />
                    </span>
                    {{ complaint.user.email }}
                  </span>
                  <span
                    class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                    v-if="complaint.user.contact_no"
                  >
                    <span class="mr-1">
                      <inline-svg src="/images/icons/mobile.svg" />
                    </span>
                    {{ complaint.user.contact_no }}
                    <v-tooltip
                      content-class="copy-tooltip"
                      :open-on-hover="false"
                      :open-on-click="true"
                      color="#000"
                      text-color="#fff"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyContent(complaint.user.contact_no)" v-on="on" size="15"
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      <span>Copied to clipboard</span>
                    </v-tooltip>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div>
                  <label>
                    <span class="label-txt">Support Ticket Type:</span>
                  </label>
                </div>
                <v-select
                  autocomplete="off"
                  :items="ticketTypes"
                  v-model="complaint.support_ticket_type"
                  item-text="label"
                  item-value="id"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <div class="callback--wrap">
              <v-form ref="complaintForm">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatment / Service: </span>
                    </label>
                    <v-autocomplete
                      v-model="complaint.treatments"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Call Back Date: </span>
                      <v-menu
                        v-model="callbackDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            autocomplete="off"
                            :value="dateUkFormat(complaint.callback_date)"
                            solo
                            outlined
                            v-on="on"
                            flat
                            hide-details="auto"
                            class="solo-cust"
                            append-icon="mdil-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="complaint.callback_date"
                          no-title
                          @input="callbackDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Call Back Time:</span>
                      <v-menu
                        ref="callbackTimeMenu"
                        v-model="callbackTimeMenu"
                        :close-on-content-click="false"
                        :return-value.sync="complaint.callback_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            flat
                            hide-details="auto"
                            solo
                            class="solo-cust"
                            v-model="complaint.callback_time"
                            append-icon="mdil-clock"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          :allowed-minutes="allowedStep"
                          no-title
                          ampm-in-title
                          format="24hr"
                          v-if="callbackTimeMenu"
                          v-model="complaint.callback_time"
                          full-width
                          @click:minute="$refs.callbackTimeMenu.save(complaint.callback_time)"
                        ></v-time-picker>
                      </v-menu>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        :rules="rules.branch_id"
                        v-model="complaint.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                        @change="staffByBranch"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Urgency </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="priority"
                      v-model="complaint.urgency"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Select Contact </span>
                      <v-select
                        autocomplete="off"
                        :items="staff"
                        v-model="complaint.responsible_id"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="
                        SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('callback');
                          })
                        )
                      "
                      v-model="complaint.status"
                      :rules="rules.status"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="activity" :eager="true">
          <div class="content__text">
            <tab-activity-history
              screenType="support-ticket"
              :itemData="complaint"
              :userId="complaint.user_id"
              :keyId="complaint.id"
            ></tab-activity-history>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed rounded x-large @click="update">
          Save Changes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import TabActivityHistory from "../../../components/TabItems/TabActivityHistory";
export default {
  mixins: [globalFn, status],
  components: {
    TabActivityHistory,
  },
  data: () => ({
    contactLoading: false,
    staff: [],
    navigationTab: null,
    ticketIndex: null,
    actionName: null,
    dateRaisedMenu: false,
    nextActionDateMenu: false,
    callbackDateMenu: false,
    callbackTimeMenu: false,
    navigation: [
      {
        name: "Ticket Details",
        icon: "mdil-account",
        slug: "ticket",
        module: "ticket",
        form: true,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        module: "activity",
        form: false,
      },
    ],

    ticketType: null,
    user_id: 0,
    rules: {
      branch_id: [(v) => !!v || "Please select branch"],
      status: [(v) => !!v || "Please select status"],
      responsible_id: [(v) => !!v || "Please select responsible person"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
  }),
  computed: {
    responsiveClass() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? "lg"
        : "md";
    },
    vertical() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? true
        : false;
    },
    complaint() {
      if (this.$store.state.complaints.item) {
        return this.$store.state.complaints.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
  },
  created() {
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("complaints/GET_PRIORITY");
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
  },
  mounted() {
    this.staffByBranch();
  },
  methods: {
    async staffByBranch() {
      this.staffParams.branch = this.complaint.user.branch_id;
      await this.getStaffList();
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    updateTicketType(item, index) {
      this.ticketIndex = index;
      this.ticketType = item.label.toLowerCase();
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async loadDealsByClient() {
      await this.$store.dispatch("deals/GET_DEAL_LIST", {
        data: { client_id: this.user_id },
        params: "",
      });
    },

    loadData() {
      if (this.$router.currentRoute.meta.slug === "support-tickets") {
        this.$root.$emit("SupportTicketsTableUpdate", "");
      } else if (this.$router.currentRoute.meta.slug === "view-customer") {
        this.$root.$emit("refreshTicketsWidget", "");
      }

      this.$store.commit("rightSlider/RESET_SLIDER");
    },

    async update() {
      let formdata = {};
      formdata.id = this.complaint.id;
      formdata.callback_date = this.complaint.callback_date;
      formdata.callback_time = this.complaint.callback_time;
      formdata.branch_id = this.complaint.branch_id;
      formdata.user_id = this.complaint.user_id;
      formdata.status = this.complaint.status;
      formdata.responsible_id = this.complaint.responsible_id;
      formdata.urgency = this.complaint.urgency;
      formdata.support_ticket_type = this.complaint.support_ticket_type;
      formdata.treatment_id = [];
      this.complaint.treatments.forEach((value) => {
        if (value.id) {
          formdata.treatment_id.push(value.id);
        } else {
          formdata.treatment_id.push(value);
        }
      });
      if (this.$refs.complaintForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("complaints/UPDATE_COMPLAINT", formdata)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
