<template>
  <div class="nav-bar" v-if="!globalSearch">
    <v-app-bar prominent absolute app color="white" light class="cust-header" flat v-if="header">
      <v-app-bar-nav-icon
        @click.stop="toggleDrawer"
        v-if="$vuetify.breakpoint.mdAndDown"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">
        <template v-if="slug === 'settings'">
          <div class="d-flex justify-center align-center">
            <v-btn icon color="#221F20" :ripple="false">
              <v-icon size="30">mdil-settings</v-icon>
            </v-btn>
            <v-btn class="ml-n3 mr-n2" icon color="rgba(32 ,32, 32,0.5)" :ripple="false">
              <v-icon size="15">mdi-chevron-right</v-icon>
            </v-btn>
            <h1 class="headerContent">{{ title | capitalize }}</h1>
          </div>
        </template>
        <template v-else>
          <h1 class="headerContent">{{ title | capitalize }}</h1>
        </template>
        <v-btn
          v-if="!$vuetify.breakpoint.xsOnly"
          @click="enableGlobalSearch"
          color="#221F20"
          class="b-btn b-blue font-family--head letter-s-0 text-capitalize white--text ml-4"
          depressed
        >
          <v-icon class="mr-2">mdi-magnify</v-icon>
          Global Search
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="main-title">
        <TheSearch :screen="screen" :slug="slug" v-if="!$vuetify.breakpoint.xsOnly"></TheSearch>
        <TheHeaderButtons
          :screen="screen"
          :slug="slug"
          v-if="!$vuetify.breakpoint.smAndDown"
        ></TheHeaderButtons>
      </div>
      <TheHeaderButtonsMobile
        :screen="screen"
        :slug="slug"
        v-if="$vuetify.breakpoint.smAndDown"
      ></TheHeaderButtonsMobile>
    </v-app-bar>
    <div class="mobile-search" v-if="$vuetify.breakpoint.xsOnly">
      <TheSearch :screen="screen" :slug="slug"></TheSearch>
      <v-btn
        block
        @click="enableGlobalSearch"
        color="#221F20"
        class="mt-4 font-family--head letter-s-0 text-capitalize white--text py-2 px-6 font-18 line-h-23 width-191 height-48"
        rounded
        depressed
      >
        <v-icon class="mr-2">mdi-magnify</v-icon>
        Global Search
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER, LOGOUT } from "../store/action-types.js";
import globalFn from "../mixins/globalFn";
import TheSearch from "./TheSearch";
import TheHeaderButtons from "./TheHeaderButtons";
import TheHeaderButtonsMobile from "./TheHeaderButtonsMobile";
export default {
  name: "topnavbar",
  mixins: [globalFn],
  components: {
    TheSearch,
    TheHeaderButtons,
    TheHeaderButtonsMobile,
  },
  props: {
    globalSearch: {
      type: Boolean,
    },
  },
  data: () => ({
    title: "",
    screen: "",
    slug: "",
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    header: true,
  }),
  watch: {
    $route: function (val) {
      this.screen = val.meta.name;
      if (val.meta.name === "Calendar") {
        this.title = this.screenName(val.params.screen);
      } else {
        this.title = val.meta.name;
      }
      this.slug = val.meta.slug;
    },
  },
  mounted() {
    this.loadTitle();
    this.$root.$on("showHeader", this.showHeader);
  },
  methods: {
    ...mapActions({
      LOGOUT,
    }),
    loadTitle() {
      this.screen = this.$router.currentRoute.meta.name;
      if (this.$router.currentRoute.meta.name === "Calendar") {
        this.title = this.screenName(this.$router.currentRoute.params.screen);
      } else {
        this.title = this.$router.currentRoute.meta.name;
      }
      this.slug = this.$router.currentRoute.meta.slug;
    },
    screenName(string) {
      const screens = {
        leads: "follow up calendar",
        "call-back": "Call Back calendar",
        appointments: "Appointments",
        "app-treatments": "Appointments",
      };
      return screens[string] || "";
    },
    toggleDrawer() {
      this.$root.$emit("toggle-drawer-side-bar", true);
    },
    clickLogout() {
      this.LOGOUT().then((res) => {
        window.location.href = "/";
      });
    },
    enableGlobalSearch() {
      this.header = false;
      this.$root.$emit("showGlobalSearch", "");
    },
    showHeader() {
      this.header = true;
    },
  },
};
</script>
<style scoped></style>
