<template>
  <v-row>
    <v-col cols="3" v-for="(product, index) in filteredProducts" :key="product.id">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :ripple="false"
            :elevation="hover ? 10 : 4"
            class="cursor-pointer overflow-hidden rounded-md transition-swing"
            :class="{ 'active-card': getBgColor(product.id) }"
            @click="addProducts(product, index)"
          >
            <div
              style="height: 150px; background: rgb(236, 237, 240)"
              class="d-flex justify-center align-center"
            >
              <img src="../../../../../images/image-placeholder.svg" height="40" width="40" />
            </div>
            <v-card-text class="text-center">
              <p class="text-body-2 font-weight-bold mb-2">
                {{ product.name }}
              </p>
              <p class="text-subtitle-1 font-weight-bold">
                {{ addPoundSymbol(product.selling_price) }}
              </p>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import globalFn from "../../../../mixins/globalFn";
export default {
  mixins: [globalFn],
  props: ["formObj", "search"],
  computed: {
    products() {
      return this.$store.state.products.list;
    },
    filteredProducts() {
      return this.products.filter((item) => item.name.toLowerCase().includes(this.search));
    },
  },
  methods: {
    getBgColor(id) {
      return this.formObj.products.find((item) => item.id === id) ? true : false;
    },
    addProducts(product, index) {
      const productExist = this.formObj.products.find((item) => item.id === product.id);

      if (productExist) {
        const index = this.formObj.products.findIndex((item) => item.id === product.id);

        this.formObj.products.splice(index, 1);
      } else {
        this.formObj.products.push(product);
      }
    },
  },
};
</script>
