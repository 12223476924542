<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Deal</div>
        <v-divider class="py-5"></v-divider>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
            </v-overlay>
            <v-form ref="form">
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Deal Info </span>
                    </label>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      :rules="rules.deal_info"
                      v-model="dealForm.deal_info"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                      @keyup="remaincharCount()"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Deal Notes </span>
                    </label>
                    <v-textarea
                      autocomplete="off"
                      solo
                      outlined
                      v-model="dealForm.notes"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                    ></v-textarea>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row" v-for="item in treatmentInputs" :key="item.id">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Treatment </span>
                  </label>
                  <v-autocomplete
                    :rules="rules.treatment_id"
                    v-model="item.treatment_id"
                    :items="treatments"
                    item-text="name"
                    item-value="id"
                    chips
                    autocomplete="off"
                    solo
                    outlined
                    flat
                    hide-details="auto"
                    class="este-autocomplete-input"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        class="este-chip tag-chip px-3"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                      >
                        <span class="pr-2">
                          {{ data.item.name }}
                        </span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Total (£) </span>
                    </label>
                    <v-text-field
                      placeholder="£1999"
                      :rules="rules.cost"
                      autocomplete="off"
                      solo
                      outlined
                      v-model="item.cost"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                      @change="getDealTotal"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="3" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> No of Sessions </span>
                    </label>
                    <v-text-field
                      :rules="numberRule"
                      autocomplete="off"
                      solo
                      outlined
                      v-model="item.no_of_sessions"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="1" class="d-flex align-center justify-center">
                  <v-btn icon color="green" @click="addTreatment" v-if="item.plus">
                    <v-icon size="50">mdil-plus-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteTreatment(item.id)" v-if="!item.plus">
                    <v-icon size="50">mdil-minus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col"> </v-col>
                <v-col cols="12" md="2" class="e-col">
                  <div class="text-center">
                    <label>
                      <span class="label-txt">
                        {{ dealTotal | currency("£") }}
                      </span>
                    </label>
                  </div>
                </v-col>
                <v-col cols="12" md="3" class="e-col"> </v-col>
                <v-col cols="12" md="1"> </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="e-row mt-4" v-for="item in productInputs" :key="item.id">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Product </span>
                  </label>
                  <v-autocomplete
                    :rules="rules.product_id"
                    v-model="item.product_id"
                    :items="productList"
                    item-text="name"
                    item-value="id"
                    chips
                    autocomplete="off"
                    solo
                    outlined
                    flat
                    hide-details="auto"
                    class="este-autocomplete-input"
                  >
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        class="este-chip tag-chip px-3"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                      >
                        <span class="pr-2">
                          {{ data.item.name }}
                        </span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Quantity </span>
                    </label>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      v-model="item.quantity"
                      flat
                      hide-details="auto"
                      class="solo-cust"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="1" class="d-flex align-center justify-center">
                  <v-btn icon color="green" @click="addProduct" v-if="item.plus">
                    <v-icon size="50">mdil-plus-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteProduct(item.id)" v-if="!item.plus">
                    <v-icon size="50">mdil-minus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense class="e-row mt-5">
                <v-col cols="6" md="6" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Sold by: </span>
                    </label>
                    <v-autocomplete
                      :readonly="user.role_type_name === 'Consultant' ? true : false"
                      :rules="rules.soldby_id"
                      v-model="dealForm.soldby_id"
                      :items="staff"
                      item-text="name"
                      item-value="id"
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="createDeal"
        >
          Add Deal
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import sliderFn from "../../../mixins/sliderFn.js";
import status from "../../../mixins/status.js";
export default {
  mixins: [globalFn, status, sliderFn],
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
    itemData: {
      required: true,
    },
  },
  data: () => ({
    dealTotal: 0,
    numberRule: [
      (v) => !!v || "This field is required",
      (v) => (v && v <= 50) || "No of sessions has to be between 0 to 50",
      (v) => (v && v > 0) || "Please enter a positive number which is greate than 0",
    ],
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    dealForm: {
      user_id: 0,
      deal_info: "",
      notes: "",
      soldby_id: "",
      appointment_id: null,
      opportunity_id: null,
    },

    treatmentInputs: [
      {
        id: "index0",
        treatment_id: null,
        no_of_sessions: null,
        cost: null,
        plus: true,
      },
    ],
    productInputs: [
      {
        id: "index00",
        product_id: null,
        quantity: null,
        plus: true,
      },
    ],
    counter: 0,
    rules: {
      deal_info: [(v) => !!v || "Please enter deal information"],
      cost: [(v) => !!v || "Please enter cost value"],
      no_of_sessions: [(v) => !!v || "Please select no of sessions"],
      treatment_id: [(v) => !!v || "Please select treatment"],
      product_id: [(v) => !!v || "Please select product"],
      soldby_id: [(v) => !!v || "Please select Sold by"],
      sessions: [(v) => !!v || "Please select sessions"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    deals: [],
    branchId: null,
    maxcharacter: 150,
    remaincharactersText: "Remaining 150 characters.",
  }),
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    productList() {
      if (this.$store.state.products.list) {
        return this.$store.state.products.list;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        const arrayOfObjects = this.$store.state.staff.all;
        return arrayOfObjects.map((obj) => ({
          ...obj,
          name: this.capitalizeFirstLetterEachWord(obj.name),
        }));
      }
    },
  },
  mounted() {
    this.$root.$on("RefreshDeals", this.loadDeals);
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", {
      data: {
        branch: this.user.user_type_name === "Admin" ? "" : this.user.branch_id,
        type: "",
      },
      params: "",
    });
    this.$store.dispatch("products/GET_PRODUCT", "");
  },
  methods: {
    remaincharCount() {
      if (this.dealForm.deal_info.length > this.maxcharacter) {
        this.rules.deal_info = [(v) => `Exceeded  ${this.maxcharacter} characters limit.`];
      } else {
        const remainCharacters = this.maxcharacter - this.dealForm.deal_info.length;
        this.rules.deal_info = [];
      }
    },
    getDealTotal() {
      this.dealTotal = this.treatmentInputs.reduce(function (accumulator, item) {
        let val = item.cost !== null ? parseInt(item.cost) : 0;
        return accumulator + val;
      }, 0);
    },
    addTreatment() {
      this.treatmentInputs.push({
        id: `index${++this.counter}`,
        treatment_id: null,
        cost: null,
        no_of_sessions: null,
        plus: false,
      });
    },
    addProduct() {
      this.productInputs.push({
        id: `index${++this.counter}`,
        product_id: null,
        plus: false,
      });
    },
    deleteTreatment(id) {
      if (Object.keys(this.treatmentInputs).length > 1) {
        const index = this.treatmentInputs.findIndex((x) => x.id === id);
        if (index >= 0) {
          this.treatmentInputs.splice(index, 1);
        }
      }
      this.getDealTotal();
    },
    deleteProduct(id) {
      if (Object.keys(this.productInputs).length > 1) {
        const index = this.productInputs.findIndex((x) => x.id === id);
        if (index >= 0) {
          this.productInputs.splice(index, 1);
        }
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      localStorage.removeItem("opportunity_id");
      this.$root.$emit("monitorTabEvent", { component: "Financials" });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.createDeal(deal);
      }
    },
    async createDeal() {
      this.dealForm.user_id = this.$router.currentRoute.params.id;
      this.dealForm.treatments = this.treatmentInputs;
      this.dealForm.products = this.productInputs;
      if (this.$refs.form.validate()) {
        this.dealLoading = true;
        await this.$store
          .dispatch("deals/CREATE_DEAL", this.dealForm)
          .then((response) => {
            if (response.result === "success") {
              this.dealLoading = false;
              this.$refs.form.reset();
              this.close();
            } else if (response.result === "fail") {
              response.type = "error";
              this.dealLoading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.dealLoading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
