<template>
  <v-card class="delete-alert">
    <v-card-title>
      <div>
        <v-icon class="mr-2">mdi-camera-outline</v-icon>
        Take photo
      </div>
      <a class="float-right pa-6" @click="close"><img src="/images/close.svg" alt="Close" /></a>
    </v-card-title>

    <v-card-text>
      <div>
        <video v-if="!photo" ref="ideo" width="400" height="300" autoplay></video>
        <img :src="photo" v-if="photo" width="400" height="300" />
      </div>

      <div>
        <v-btn depressed @click="takePhoto" v-if="!photo">Take Photo</v-btn>
        <v-btn depressed @click="retakePhoto" v-if="photo">Retake Photo</v-btn>
      </div>
    </v-card-text>

    <v-card-actions class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-btn
            block
            depressed
            rounded
            outlined
            @click="close"
            color="dark"
            x-large
            class="text-capitalize"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="success"
            block
            depressed
            @click="uploadPhoto"
            rounded
            x-large
            :loading="uploading"
            :disabled="!photo"
            class="text-capitalize"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    photo: null,
    stream: null,
    uploading: false,
  }),
  mounted() {
    setTimeout(() => {
      this.startCamera();
    }, 1000);
  },
  methods: {
    close() {
      this.$store.commit("popupModal/RESET_MODAL");
    },
    async uploadPhoto() {
      this.uploading = true;
      const formData = new FormData();

      formData.append("user_id", this.$route.params.id);
      formData.append("photo[]", this.photo);

      await this.$store
        .dispatch("photos/UPLOAD", formData)
        .then((res) => {
          if (res.result === "success") {
            this.$store.dispatch("photos/GET", { user_id: this.$route.params.id });
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            res.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        })
        .finally(() => {
          this.uploading = false;
          this.close();
        });
    },
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = this.stream;
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    },
    takePhoto() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.video.videoWidth;
      canvas.height = this.$refs.video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
      this.photo = canvas.toDataURL("image/png");
      this.stopCamera();
    },
    retakePhoto() {
      this.photo = null;
      this.startCamera();
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
  },
  beforeDestroy() {
    this.stopCamera();
  },
};
</script>

<style lang="scss" scoped></style>
