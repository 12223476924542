<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="dashboard-card">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <module-stats></module-stats>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ChannelStats></ChannelStats>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <OppsStatsByEndDate></OppsStatsByEndDate>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ChannelStatsByEnd></ChannelStatsByEnd>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ModuleStats from "../../../components/Graphics/ModuleStats.vue";
import ChannelStats from "../../../components/Graphics/ChannelStats.vue";
import OppsStatsByEndDate from "../../../components/Graphics/OppsStatsByEndDate.vue";
import ChannelStatsByEnd from "../../../components/Graphics/ChannelStatsByEnd.vue";
import globalFn from "../../../mixins/globalFn";
export default {
  components: {
    ModuleStats,
    ChannelStats,
    OppsStatsByEndDate,
    ChannelStatsByEnd,
  },
  mixins: [globalFn],
  data: () => ({
    start: "",
    end: "",
  }),
  mounted() {
    this.$root.$on("SearchDashboard", this.SearchDashboard);
  },
  methods: {
    async SearchDashboard(data) {
      this.start = data.created_at_from;
      this.end = data.created_at_to;
    },
  },
};
</script>
