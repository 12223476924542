<template>
  <div class="fill-height slider-cont">
    <div class="modal-inside pb-0 tab-brd">
      <div class="form-title mb-24">Submit Form</div>
      <v-divider class="py-5"></v-divider>
      <v-form ref="form">
        <div id="forms-wrapper" class="tab-content-wrapper">
          <v-row>
            <v-col cols="12">
              <div class="form-title text-center">
                {{ formDetails.title | capitalize }}
              </div>
            </v-col>
            <v-col cols="12" v-if="formDetails.description">
              <div
                class="font-14 line-h-17 font-weight--bold text-black-100"
                v-html="formDetails.description"
              ></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-4">
              <template v-for="(item, index) in formDetails.elements">
                <FormElement :element="item" @userAnswer="refreshAnswer" :key="index" />
              </template>
            </v-col>
            <v-col cols="12">
              <label>
                <span class="label-txt">Consultant Signature:</span>
              </label>
              <div class="mt-2">
                <signature-pad @signed="consultantSign" />
              </div>
            </v-col>
            <v-col cols="12">
              <label>
                <span class="label-txt">Patient Signature:</span>
              </label>
              <div class="mt-2">
                <signature-pad @signed="patientSign" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="submitForm"
        >
          Submit Form
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import FormElement from "./formMolecules/formElement.vue";
import signaturePad from "../../../components/SignaturePad.vue";

export default {
  mixins: [status, globalFn],
  components: {
    FormElement,
    signaturePad,
  },
  data: () => ({
    tabs: [
      {
        name: "Preview",
        slug: "preview",
      },
      {
        name: "Permissions",
        slug: "permissions",
      },
    ],
    isSubmitable: false,
    isTyping: false,
    appointments: [],
    loading: false,
    activeTab: "preview",
    search: "",
    selectedAppointment: "",
    user_id: 0,
    consultantSignature: null,
    patientSignature: null,
    rules: {
      user_id: [
        (v) => !!v || "Please select client",
        (v) => (v && v.length > 3) || "Please enter min 3 characters",
      ],
    },
    answers: [],
    addedIdToClient: 0,
  }),
  computed: {
    formDetails() {
      return this.$store.state.form.formDetails;
    },
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.search.length > 3) {
        this.filterUserSearchAPI();
      }
    },
  },
  created() {
    if (this.$router.currentRoute.meta.name === "Appointments") {
      this.isSubmitable = true;
    } else {
      this.isSubmitable = false;
    }
  },
  methods: {
    refreshAnswer(data) {
      console.log(data);
      this.answers.push(data);
    },

    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$root.$emit("refreshForms", "");
      this.$root.$emit("monitorTabEvent", { component: "Forms" });
    },

    formatStr(str) {
      return str.split("_").join(" ");
    },
    consultantSign(data) {
      this.consultantSignature = data.data;
    },
    patientSign(data) {
      this.patientSignature = data.data;
    },
    async filterUserSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.search;
      this.loading = true;
      await this.$store.dispatch("appointments/FILTERED_APPOINTMENTS", data).then((res) => {
        this.appointments = res.data;
        this.loading = false;
      });
    },
    async selectContact(item) {
      this.selectedAppointment = item;
      this.user_id = item.id;
    },
    async removeAppointmentedUser(item) {
      this.selectedAppointment = "";
      this.user_id = null;
    },
    async addForm() {
      await this.$store
        .dispatch("formPatientCrm/ADD_FORM", {
          id: this.$router.currentRoute.params.id,
          medical_form_id: this.formDetails.id,
        })
        .then((res) => {
          this.addedIdToClient = res.data.id;
          this.submitAnswers();
        });
    },
    async submitForm() {
      await this.addForm();
    },

    async submitAnswers() {
      let data = {};
      data.id = this.addedIdToClient;
      data.patient_sign = this.patientSignature;
      data.consultant_sign = this.consultantSignature;
      data.answers = this.answers;

      console.log(data);

      await this.$store.dispatch("formPatientCrm/SUBMIT_FORM", data).then((res) => {
        this.close();
      });
    },
  },
};
</script>
<style lang="scss">
.forms-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 1rem;
}

.forms-wrapper::-webkit-scrollbar {
  display: none;
}

.forms-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
