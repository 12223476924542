import http from "../http/common";

const resource = "/financial";

export default {
  getStatusCount: (payload) => http.post(`${resource}/get-status-count`, payload),

  getFinancialByType: (payload) => http.post(`${resource}/get-financial-by-type`, payload),

  getPaymentSummary: (payload) => http.post(`payment/get-payment-summary`, payload),

  getDealSummary: (payload) => http.post(`deal/get-deal-summary`, payload),
};
