<template>
  <v-row>
    <v-col cols="12">
      <div class="font-14 line-h-17 font-weight--semibold text-black-100">
        {{ element.title }}
        <span class="red--text required" v-if="element.is_required === 1">*</span>
      </div>
    </v-col>
    <v-col cols="12" v-if="element.description">
      <div
        class="font-13 line-h-16 text-black-300 font-weight--semibold"
        v-html="element.description"
      ></div>
    </v-col>
    <v-col cols="12">
      <v-select
        autocomplete="off"
        :items="element.answers"
        item-value="id"
        item-text="title"
        v-model="answer_id"
        solo
        outlined
        flat
        class="solo-cust"
        hide-details="auto"
      >
        <template slot="append">
          <v-icon>mdi-chevron-down</v-icon>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "form-card",
  props: ["element"],
  data: () => ({
    answer_id: null,
  }),
  computed: {},
  watch: {
    answer_id: _.debounce(function () {
      this.submit();
    }, 1000),
  },
  methods: {
    async submit() {
      this.$emit("updateAnswer", { element_id: this.element.id, answer_id: [this.answer_id] });
    },
  },
};
</script>
<style lang="scss" scoped></style>
