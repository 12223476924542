import collection from "../../../api/collections/Forms/patientCrm";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    // formData: [],
    formDetails: {},
    submittedDetails: {},
    formConsentDetails: {},
  },
  mutations: {
    // SET_FORM(state, data) {
    //     state.forms = data;
    // },
    SET_FORM_DETAILS(state, data) {
      state.formDetails = data;
    },
    SET_SUBMIT_FORM(state, data) {
      state.submittedDetails = data;
    },
    SET_FORM_CONSENT(state, data) {
      state.formConsentDetails = data;
    },
  },
  actions: {
    async ADD_FORM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          console.log(data.data);
          if (data.data.result) {
            dispatch("GET_FORM");
          }
          return data;
        },
        commit,
        dispatch,
        "ADD_FORM",
        true
      );
      return data;
    },
    async GET_PATIENT_FORM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          return data;
        },
        commit,
        dispatch,
        "GET_PATIENT_FORM",
        true
      );
      return data;
    },
    async GENERATE_PDF({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.generate(payload);
          commit("SET_FORM_DETAILS", data.data);
          return data;
        },
        commit,
        dispatch,
        "GENERATE_PDF",
        true
      );
    },
    async SUBMIT_FORM({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.submit(payload);
          commit("SET_SUBMIT_FORM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SUBMIT_FORM",
        true
      );
    },
    async SHOW_FORM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.show(payload);
          commit("SET_FORM_CONSENT", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_FORM",
        true
      );
      return data;
    },
  },
};
