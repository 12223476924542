<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Add Client</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#personal">Personal Details</v-tab>
          <v-tab href="#client">Client Details</v-tab>
          <v-tab href="#deal">Deal</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="personal" :eager="true">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="6" md="2" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt">Title</span>
                        <v-select
                          autocomplete="off"
                          :items="titles"
                          v-model="form.title"
                          solo
                          outlined
                          flat
                          class="solo-cust"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </label>
                    </div>
                  </v-col>
                  <v-col cols="6" md="10" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Name </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          :rules="rules.name"
                          v-model="form.name"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Social Handle </span>
                    </label>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      v-model="form.social_handle"
                      flat
                      hide-details="auto"
                      class="solo-cust social-handle-input"
                    >
                      <template slot="append-outer">
                        <v-select
                          autocomplete="off"
                          v-model="form.source_id"
                          :items="socialMedia"
                          item-text="name"
                          item-value="id"
                          solo
                          outlined
                          flat
                          class="este-autocomplete-input"
                          hide-details="auto"
                        >
                          <template v-slot:selection="data">
                            <img
                              :src="`images/${trimLowerCase(data.item.name)}.svg`"
                              height="20px"
                            />
                          </template>
                          <template v-slot:item="data">
                            <img
                              :src="`images/${trimLowerCase(data.item.name)}.svg`"
                              height="20px"
                            />
                          </template>
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Phone No. </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        v-model="form.contact_no"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-phone"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Email Address </span>
                      <v-text-field
                        autocomplete="off"
                        v-model="form.email"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-email"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Address </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.address_one"
                        hide-details="auto"
                        class="solo-cust mb-2"
                      ></v-text-field>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.address_two"
                        hide-details="auto"
                        class="solo-cust mar-8"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Town / City </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="form.city"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt"> Post Code </span>
                          <v-text-field
                            autocomplete="off"
                            solo
                            outlined
                            flat
                            v-model="form.postcode"
                            hide-details="auto"
                            class="solo-cust"
                          ></v-text-field>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :rules="rules.branch_id"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        v-model="form.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="">
                    <label>
                      <span class="label-txt"> Client Satisfaction: </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="12" class="e-col">
                    <template v-for="(item, index) in satisfaction">
                      <div
                        :class="[
                          'emoji-wrap',
                          `${lowerCase(item.name)}-emoji`,
                          { active: activeIndex === index },
                        ]"
                        @click="setActive(item, index)"
                        :key="item.name"
                      >
                        <div class="icon">
                          <img :src="`${item.image}`" />
                        </div>
                        <div class="text">{{ item.name }}</div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <p class="sub-title mb-4">Contact Preferences</p>
                    <label>
                      <span class="label-txt"> Receive SMS </span>
                    </label>
                    <v-radio-group
                      class="mt-2"
                      hide-details="auto"
                      mandatory
                      v-model="form.is_receive_sms"
                      row
                    >
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="Yes"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="No"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="client" :eager="true">
            <v-form ref="leadForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Symptoms / Condition: </span>
                    </label>
                    <v-autocomplete
                      v-model="form.symptoms"
                      :items="symptoms"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                      @change="symptomChange"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          class="este-chip tag-chip px-3"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          <span class="pr-2">
                            {{ data.item.name }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Suggested Treatments: </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="12" class="e-col">
                    <template v-for="treat in suggestedTreatments">
                      <v-chip
                        small
                        class="este-chip status-chip mb-2"
                        text-color="black"
                        @click="suggTreat(treat)"
                      >
                        {{ treat.name | capitalize }}
                      </v-chip>
                    </template>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Service Recommended: </span>
                    </label>
                    <v-autocomplete
                      v-model="form.service_recommended"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          class="este-chip tag-chip px-3"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          <span class="pr-2">
                            {{ data.item.name }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeService(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                      <v-select
                        autocomplete="off"
                        :item-disabled="statusDisable"
                        :items="
                          SortAtoZ(
                            statusTags.filter(function (el) {
                              return el.slug.includes('client');
                            })
                          )
                        "
                        item-value="id"
                        item-text="name"
                        :rules="rules.status"
                        v-model="form.status"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Responsible </span>
                      <v-select
                        autocomplete="off"
                        :items="staff"
                        v-model="form.responsible_id"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Source: </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="source"
                      v-model="form.source_id"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="deal" :eager="true">
            <v-form ref="dealForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Deal Info </span>
                      </label>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        :rules="rules.deal_info"
                        v-model="dealForm.deal_info"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatments </span>
                    </label>
                    <v-autocomplete
                      v-model="dealForm.treatments"
                      :rules="rules.treatments"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          class="este-chip tag-chip px-3"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          <span class="pr-2">
                            {{ data.item.name }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeTreatment(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="4" md="4" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Total </span>
                      </label>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        :rules="rules.total"
                        v-model="dealForm.total"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> No of Sessions </span>
                      </label>
                      <v-select
                        autocomplete="off"
                        :items="numbers"
                        :rules="rules.sessions"
                        v-model="dealForm.no_of_sessions"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Sold by: </span>
                      </label>
                      <v-autocomplete
                        v-model="dealForm.soldby_id"
                        :items="staff"
                        item-text="name"
                        item-value="id"
                        chips
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="este-autocomplete-input"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="validate"
        >
          Add Client
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
import add from "./add.js";
export default {
  mixins: [globalFn, status, add],
};
</script>
