<template>
  <div class="pt-4">
    <v-form ref="paymentDealForm">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="3" class="e-col">
          <label>
            <span class="label-txt"> Total </span>
          </label>
          <v-text-field
            :rules="rules.total"
            v-model="paymentForm.total"
            autocomplete="off"
            solo
            outlined
            flat
            hide-details="auto"
            class="solo-cust"
            placeholder="£1999"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="e-col">
          <label>
            <span class="label-txt"> Taken By </span>
          </label>
          <v-select
            autocomplete="off"
            v-model="paymentForm.take_by"
            :items="staff"
            item-text="name"
            item-value="id"
            solo
            outlined
            flat
            class="solo-cust"
            hide-details="auto"
          >
            <template slot="append">
              <v-icon>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="3" class="e-col">
          <label>
            <span class="label-txt"> Method </span>
          </label>
          <v-select
            autocomplete="off"
            :rules="rules.method"
            v-model="paymentForm.method"
            :items="paymentMethods"
            item-value="id"
            item-text="name"
            solo
            outlined
            flat
            class="solo-cust"
            hide-details="auto"
          >
            <template slot="append">
              <v-icon>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="3" class="e-col">
          <label>
            <span class="label-txt"> Date </span>
          </label>
          <v-menu
            v-model="paymentDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                autocomplete="off"
                v-model="paymentDate"
                solo
                outlined
                v-on="on"
                flat
                hide-details="auto"
                class="solo-cust"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="paymentForm.date"
              no-title
              @input="paymentDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-btn
        class="b-comment add-pay"
        depressed
        rounded
        x-large
        :loading="loading"
        @click="confirmPayment"
      >
        Confirm
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn.js";
import status from "../../mixins/status.js";
export default {
  props: {
    dealId: {
      required: true,
    },
    dealTreatments: {
      required: true,
    },
  },
  mixins: [globalFn, status],
  data: () => ({
    paymentDateMenu: false,
    loading: false,
    payments: [],
    paymentForm: {
      deal_treatment_id: "",
      method: "",
      total: "",
      date: new Date().toISOString().substr(0, 10),
      take_by: null,
    },
    rules: {
      deal_treatment_id: [(v) => !!v || "Please select treatment"],
      method: [(v) => !!v || "Please select method"],
      total: [(v) => !!v || "Please enter total value"],
    },
  }),
  computed: {
    paymentDate: {
      get() {
        if (this.paymentForm.date.length) {
          const a = this.formatDate(this.paymentForm.date);
          return a;
        }
      },
      set(value) {
        this.value = new Date().toISOString().substr(0, 10);
      },
    },

    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  created() {
    this.$store.dispatch("staff/GET_STAFF_LIST", {
      data: this.staffParams,
      params: "",
    });
  },
  mounted() {},
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async confirmPayment() {
      let formData = {};
      formData.deal_id = this.dealId;
      formData.method = this.paymentForm.method;
      formData.total = this.paymentForm.total;
      formData.take_by = this.paymentForm.take_by;
      formData.date = this.changeDateFormat(this.paymentDate);
      if (this.$refs.paymentDealForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("payments/CREATE_PAYMENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.$root.$emit("RefreshDeals");
              this.$root.$emit("readTheDataFromApi");
              this.$refs.paymentDealForm.reset();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
              this.$refs.paymentDealForm.reset();
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
            this.$refs.paymentDealForm.reset();
          });
      }
    },
  },
};
</script>
