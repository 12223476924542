<template>
  <div>
    <div class="caption" v-if="data.comment_calls.length === 0">
      <div
        class="read-less"
        v-if="!readMore"
        :class="category === 'comment' ? 'd-flex align-center justify-space-between' : ''"
      >
        <div class="d-flex flex-column">
          <span v-if="typeof comment === 'string'">
            <span v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))">{{
              moduleName
            }}</span>
            <span v-if="comment.includes('Additional enquiry:')"> Additional enquiry </span>
            <span v-else>
              {{ capitalize(category.replace(/_/g, " ")) }} {{ event }} to
              {{ comment.slice(0, 100) }}
            </span>
          </span>
          <span v-else-if="typeof comment !== 'string'">
            <span v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))">{{
              moduleName
            }}</span>
            <span v-if="comment.includes('Additional enquiry:')"> Additional enquiry </span>
            <span v-else>
              {{ capitalize(category.replace(/_/g, " ")) }} changed to {{ comment }}
            </span>
          </span>
          <span
            class="read-text mt-1 font-14 line-h-17 font-weight--bold text-black-100"
            v-if="!readMore && comment.length > 100"
            @click="readMoreAction(comment)"
          >
            Read more</span
          >
        </div>
        <div class="d-flex align-center justify-end" v-if="category === 'comment'">
          <v-divider class="ml-1 mr-2" vertical></v-divider>
          <img src="/images/phone.svg" height="15" />
          <v-checkbox
            v-model="data.isMarked"
            :ripple="false"
            hide-details="auto"
            class="mt-0 pt-0"
            @click="markCall(data)"
          >
          </v-checkbox>
        </div>
      </div>
      <div
        class="read-more"
        v-if="readMore"
        :class="category === 'comment' ? 'd-flex align-center justify-space-between' : ''"
      >
        <div class="d-flex flex-column w-100">
          <span v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))">{{
            moduleName
          }}</span>
          <span v-if="comment.includes('Additional enquiry:')"> Additional enquiry </span>
          <span v-else>
            {{ capitalize(category.replace(/_/g, " ")) }} changed to {{ comment }}
          </span>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold"
                      >Field</span
                    >
                  </th>
                  <th class="text-left">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                      Value
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in JSON.parse(comment.replace('Additional enquiry:', ''))">
                  <template v-if="value && value.length > 0">
                    <template v-if="key === 'reasons'">
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ capitalize(key.replace(/_/g, " ")) }}
                        </span>
                      </td>
                      <td>
                        <template v-for="item in value">
                          <span
                            class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border mr-2"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </td>
                    </template>
                    <template v-else-if="key === 'conditions'">
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ capitalize(key.replace(/_/g, " ")) }}
                        </span>
                      </td>
                      <td>
                        <template v-for="item in symptoms">
                          <span
                            class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border mr-2"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </td>
                    </template>
                    <template v-else-if="key === 'symptoms'">
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ capitalize(key.replace(/_/g, " ")) }}
                        </span>
                      </td>
                      <td>
                        <template v-for="item in symptoms">
                          <span
                            class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border mr-2"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </td>
                    </template>
                    <template v-else-if="key === 'service_recommended'">
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ capitalize(key.replace(/_/g, " ")) }}
                        </span>
                      </td>
                      <td>
                        <template v-for="item in treatments">
                          <span
                            class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border mr-2"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </td>
                    </template>
                    <template v-else>
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ capitalize(key.replace(/_/g, " ")) }}
                        </span>
                      </td>
                      <td>
                        <span class="font-13 line-h-16 text-black-300 font-weight--semibold">
                          {{ value }}</span
                        >
                      </td>
                    </template>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span
            class="read-text mt-2 font-14 line-h-17 font-weight--bold text-black-100"
            v-if="readMore"
            @click="readMore = !readMore"
          >
            Read less</span
          >
        </div>
        <div class="d-flex align-center justify-end" v-if="category === 'comment'">
          <v-divider class="ml-1 mr-2" vertical></v-divider>
          <img src="/images/phone.svg" height="15" />
          <v-checkbox
            v-model="data.isMarked"
            hide-details="auto"
            class="mt-0 pt-0"
            :ripple="false"
            @click="markCall(data)"
          >
          </v-checkbox>
        </div>
      </div>
    </div>
    <v-expansion-panels
      class="ma-0 comment-expansion"
      multiple
      v-if="data.comment_calls.length > 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <div class="caption">
            <div
              class="read-less"
              v-if="!readMore"
              :class="category === 'comment' ? 'd-flex align-center justify-space-between' : ''"
            >
              <div class="d-flex flex-column">
                <span v-if="typeof comment === 'string'">
                  <span
                    v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))"
                    >{{ moduleName }}</span
                  >
                  {{ capitalize(category.replace(/_/g, " ")) }} {{ event }} to
                  {{ comment.slice(0, 100) }}
                </span>
                <span v-else-if="typeof comment !== 'string'">
                  <span
                    v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))"
                    >{{ moduleName }}</span
                  >
                  {{ capitalize(category.replace(/_/g, " ")) }} {{ event }} to {{ comment }}
                </span>
                <span
                  class="read-text mt-1"
                  v-if="!readMore && comment.length > 100"
                  @click="readMore = !readMore"
                >
                  Read more</span
                >
              </div>
              <div class="d-flex align-center justify-end" v-if="category === 'comment'">
                <v-divider class="ml-1 mr-2" vertical></v-divider>
                <img src="/images/phone.svg" height="15" />
                <v-checkbox
                  v-model="data.isMarked"
                  :input-value="true"
                  :ripple="false"
                  hide-details="auto"
                  class="mt-0 pt-0"
                  @click="markCall(data)"
                >
                </v-checkbox>
              </div>
            </div>
            <div
              class="read-more"
              v-if="readMore"
              :class="category === 'comment' ? 'd-flex align-center justify-space-between' : ''"
            >
              <div class="d-flex flex-column">
                <span
                  v-if="moduleName !== 'Comment' && !category.includes(lowerCase(moduleName))"
                  >{{ moduleName }}</span
                >
                {{ capitalize(category.replace(/_/g, " ")) }} changed to {{ comment }}
                <span class="read-text mt-1" v-if="readMore" @click="readMore = !readMore">
                  Read less</span
                >
              </div>
              <div class="d-flex align-center justify-end" v-if="category === 'comment'">
                <v-divider class="ml-1 mr-2" vertical></v-divider>
                <img src="/images/phone.svg" height="15" />
                <v-checkbox
                  v-model="data.isMarked"
                  :input-value="true"
                  hide-details="auto"
                  class="mt-0 pt-0"
                  :ripple="false"
                  @click="markCall(data)"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="group-panel-content">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Marked By</th>
                  <th class="text-left">Descritpion</th>
                  <th class="text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in data.comment_calls">
                  <td>{{ value.owner_name }}</td>
                  <td>{{ value.description }}</td>
                  <td>
                    {{ value.created_at | moment("MMMM Do YYYY") }} at
                    {{ value.created_at | moment("h:mm:ss a") }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn";
export default {
  mixins: [globalFn],
  props: {
    data: {
      required: true,
    },
    event: {
      required: true,
    },
    category: {
      required: true,
    },
    comment: {
      required: true,
    },
    moduleName: {
      required: true,
    },
  },
  data: () => ({
    readMore: false,
    call: false,
    reasons: [],
    symptoms: [],
    treatments: [],
  }),
  methods: {
    async markCall(item) {
      let form = {};
      form.comment_id = item.auditable_id;
      form.is_marked = item.isMarked;
      await this.$store
        .dispatch("customers/MARK_AS_CALL", form)
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.$emit("refreshAudit");
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async readMoreAction(comment) {
      this.readMore = !this.readMore;
      if (comment && comment.includes("Additional enquiry:")) {
        let enquiry = JSON.parse(comment.replace("Additional enquiry:", ""));

        if (enquiry.service_recommended) {
          await this.filterTreatments(enquiry.service_recommended);
        }
        if (enquiry.symptoms) {
          await this.filterSymptoms(enquiry.symptoms);
        }

        if (enquiry.conditions) {
          const arrOfStr = enquiry.conditions.split(",");
          const arrOfNum = arrOfStr.map((str) => {
            return Number(str);
          });
          console.log(arrOfNum);
          await this.filterSymptoms(arrOfNum);
        }
      }
    },

    async filterSymptoms(value) {
      await this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "").then((response) => {
        this.symptoms = response.data.filter((ele) => value.includes(ele.id));
      });
    },

    async filterTreatments(value) {
      await this.$store.dispatch("treatments/GET_ALL_TREATMENT", "").then((response) => {
        this.treatments = response.data.filter((ele) => value.includes(ele.id));
      });
    },
  },
};
</script>
