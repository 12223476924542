<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0 pt-3">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
              :options.sync="options"
            >
              <template v-slot:item.name="{ item }">
                <template v-if="item.user">
                  <div class="d-flex align-center flex-row position-relative mr-4">
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                      v-if="item.user.name"
                    >
                      {{ item.user.name }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border"
                      v-if="!item.user.name && !item.user.social_handle"
                      >Not Supplied</span
                    >
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2 d-flex align-center justify-center"
                      v-if="item.user.name === null"
                    >
                      <img
                        :src="`/images/${item.user.source_name.toLowerCase()}.svg`"
                        class="mr-1"
                        v-if="item.user.source_name !== null"
                      />
                      {{ item.user.social_handle }}
                    </span>
                    <span
                      class="position-absolute small mt-n5 ml-n1 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                      :class="item.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                    >
                      {{ item.user.user_type_name === "Lead" ? "L" : "C" }}
                    </span>
                  </div>
                  <div class="d-flex align-center ml-2">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.user.contact_no }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                      >{{ item.branch_name }}</span
                    >
                  </div>
                  <div
                    class="d-flex align-center ml-2"
                    v-if="item.user.email !== null && item.user.email !== ''"
                  >
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.user.email }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex align-center flex-row position-relative mr-4">
                    <span
                      class="font-14 line-h-17 font-weight--bold text-black-100 ml-2"
                      v-if="item.customer_name"
                    >
                      {{ item.customer_name }}
                    </span>
                  </div>
                  <div class="d-flex align-center ml-2">
                    <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                      {{ item.mobile }}
                    </span>
                    <span
                      class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                      >{{ item.branch_name }}</span
                    >
                  </div>
                </template>
              </template>
              <template v-slot:item.responsible="{ item }">
                <v-tooltip
                  content-class="este-tooltip"
                  color="#000"
                  text-color="#fff"
                  top
                  v-if="item.staff_name !== null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.staff_name) }}
                    </span>
                  </template>
                  <span v-if="item.staff_name !== null">{{ item.staff_name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.appointment_status="{ item }">
                <span
                  v-if="item.appointment_status"
                  class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 border"
                  >{{ item.appointment_status }}</span
                >
              </template>
              <template v-slot:item.treatments="{ item }">
                <span
                  class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                  v-if="item.service !== null && item.service !== ''"
                  >{{ item.service | capitalize }}</span
                >
              </template>
              <template v-slot:item.appointment_date="{ item }">
                <div class="d-flex flex-column">
                  <span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
                    {{ item.start_date | moment("DD/MM/YY") }}
                  </span>
                  <span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
                    <span
                      class="font-13 line-h-16 font-weight--regular text-black-300 mb-1"
                      v-if="item.start_time !== null"
                      >{{ item.start_time }}</span
                    >
                    <span
                      class="font-13 line-h-16 font-weight--regular text-black-300 mb-1"
                      v-if="item.end_time !== null"
                      >- {{ item.end_time }}</span
                    >
                  </span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="item.status === 1">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 border"
                    >Unmatched</span
                  >
                </template>
                <template v-else>
                  <template v-for="(val, index) in statusTags">
                    <span
                      class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                      :style="
                        val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                      "
                      :key="index"
                      v-if="item.status === val.id"
                      >{{ val.name }}</span
                    >
                  </template>
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li v-if="item.status !== 34"><a @click="editItem(item)">Assign User</a></li>
                      <li v-else><a @click="editItem(item)">Edit Assigned User</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "LeadActivityReport",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", align: "start", value: "name", sortable: false },
        { text: "App Status", value: "appointment_status", align: "left", sortable: false },
        // { text: 'Contact Details', value: 'contact_no', align: 'left', sortable: false },
        { text: "Services / Treatments", value: "treatments", align: "left", sortable: false },
        { text: "Responsible", align: "left", value: "responsible", sortable: false },
        { text: "Booking Date", value: "appointment_date", sortable: false, align: "left" },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      payload: {
        per_page: 10,
        page: 1,
        search: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        branch: [],
      },
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("pabauAppointmentsTable", this.readTheDataFromApi);
    this.$root.$on("searchPabauAppointmentsTable", this.searchPabauAppointments);
  },
  created() {},
  methods: {
    async searchPabauAppointments(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.payload.search = data.search;
        this.payload.start_date = data.start_date;
        this.payload.end_date = data.end_date;
        this.payload.start_time = data.start_time;
        this.payload.end_time = data.end_time;
        this.payload.branch = data.branch;
        await this.readTheDataFromApi();
      } else {
        this.payload.search = "";
        this.payload.start_date = "";
        this.payload.end_date = "";
        this.payload.start_time = "";
        this.payload.end_time = "";
        this.payload.branch = [];
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("appointments/GET_PABAU_APPOINTMENTS", this.payload)
        .then((res) => {
          console.log(res);
          this.items = res.data.data;
          this.totalCount = res.data.total;
          this.loading = false;
        });
    },

    async editItem(item) {
      await this.$store
        .dispatch("appointments/PABAU_APPOINTMENT_SHOW", { id: item.id })
        .then((res) => {
          this.openDrawer("add", "750", "appointments/syncUser");
        });
    },
  },
};
</script>
