<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Stock for {{ product.name }}</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Amount </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  :rules="rules.name"
                  v-model="form.quantity"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Movement </span>
                </label>
                <v-select
                  autocomplete="off"
                  :items="['In', 'Out']"
                  :rules="rules.movement"
                  v-model="form.movement"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
        >
          Add Product
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    form: {
      quantity: null,
      movement: null,
    },
    rules: {
      quantity: [(v) => !!v || "Please enter amount"],
      movement: [(v) => !!v || "Please select movement"],
    },
  }),
  computed: {
    product() {
      if (this.$store.state.products.item) {
        return this.$store.state.products.item;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("productsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async create() {
      let formData = {};
      formData.quantity = this.form.quantity;
      formData.movement = this.form.movement === "In" ? 1 : 0;
      formData.product_id = this.product.id;

      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("stock/CREATE", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
