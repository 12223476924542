<template>
  <div>
    <v-dialog
      v-model="currentContent.item"
      :width="currentContent.width"
      :fullscreen="currentContent.width === '100%'"
    >
      <component v-if="loader" v-bind:is="loader"></component>
    </v-dialog>
  </div>
</template>
<script>
import modalFn from "../../mixins/modalFn";
export default {
  name: "PopupModal",
  data() {
    return {
      componentName: "popupModal",
      loading: false,
      component: null,
      options: {},
      headers: [],
    };
  },
  computed: {
    loader() {
      if (!this.currentContent.item) {
        return null;
      }
      return require(`../../templates/forms/${this.currentContent.componentName}`).default;
    },
    currentContent() {
      return this.$store.state.popupModal;
    },
  },
  mounted() {},
  mixins: [modalFn],
  // methods: {
  //   closeSlider() {
  //     this.$store.commit("popupModal/RESET_MODAL");
  //   },
  // },
};
</script>
<style scoped></style>
