import http from "../../http/common";

const resource = "/album";

export default {
  get: (params) => http.get(`${resource}${params}`),

  getById: (id, params) => http.get(`${resource}/${id}${params}`),

  create: (payload) => http.post(`${resource}`, payload),

  update: (id, payload) => http.put(`${resource}/${id}`, payload),

  delete: (id) => http.delete(`${resource}/${id}`),

  addPhotos: (payload) => http.post(`${resource}/add-photos`, payload),

  removePhotos: (payload) => http.post(`${resource}/remove-photos`, payload),
};
