import collection from "../../api/collections/AppointmentNotes";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_NOTES(state, data) {
      state.list = data;
    },
    SET_ALL_NOTES(state, data) {
      state.allList = data;
    },
  },
  actions: {
    async GET_NOTES({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_ROOM", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_NOTES",
        true
      );
    },
    async GET_ALL_NOTES({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_NOTES", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_NOTES",
        true
      );
    },
    async CREATE_NOTES({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_NOTES",
        true
      );
      return data;
    },
  },
};
