<template>
  <div>
    <div class="fill-height slider-cont">
      <v-card class="vertical-slide fill-height">
        <v-card-text class="pa-0">
          <v-row dense class="e-row">
            <v-col cols="6" md="3" class="e-col pa-0 ma-0">
              <v-toolbar class="left-toolbar" flat> </v-toolbar>

              <v-tabs v-model="navigationTab" vertical class="left-nav mt-0">
                <template v-for="item in navigation" :key="item.slug">
                  <v-tab :href="`#${item.slug}`" v-if="shouldRenderTab(item)">
                    <v-icon left>{{ item.icon }}</v-icon>
                    {{ item.name }}
                  </v-tab>
                </template>
              </v-tabs>
            </v-col>

            <v-col cols="6" md="9" class="e-col pa-0 ma-0">
              <v-toolbar class="right-toolbar" flat>
                <div class="form-title">View Appointment</div>
              </v-toolbar>
              <div class="slide-right">
                <div class="form-wrap px-10">
                  <v-tabs-items v-model="navigationTab">
                    <div class="d-flex my-4">
                      <span
                        class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                        v-if="appointment.user && appointment.user.name"
                      >
                        {{ appointment.user.name }}
                      </span>
                      <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                        <img
                          :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                          class="mr-2"
                          v-if="appointment.user.source_name !== null"
                        />
                        {{ appointment.user.social_handle }}
                      </span>
                    </div>
                    <div class="d-flex">
                      <span
                        class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                        :class="
                          appointment.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                        "
                        >{{ appointment.user.user_type_name }}</span
                      >
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/map.svg" />
                        </span>
                        {{ appointment.user.branch_name }}
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="appointment.user.email"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/at.svg" />
                        </span>
                        {{ appointment.user.email }}
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="appointment.user.contact_no"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/mobile.svg" />
                        </span>
                        {{ appointment.user.contact_no }}
                        <v-tooltip
                          content-class="copy-tooltip"
                          :open-on-hover="false"
                          :open-on-click="true"
                          color="#000"
                          text-color="#fff"
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="copyContent(appointment.user.contact_no)"
                              v-on="on"
                              size="15"
                              class="ml-2"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copied to clipboard</span>
                        </v-tooltip>
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="appointment.user.social_handle !== null"
                      >
                        <img
                          :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`"
                          height="15"
                          class="mr-2"
                          v-if="appointment.user.source_name !== null"
                        />
                        {{ appointment.user.social_handle }}
                      </span>
                    </div>
                    <v-tab-item value="personal" :eager="true">
                      <tab-personal
                        :itemData="appointment.user"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-personal>
                      <!-- <v-form ref="form">
                                                <div
                                                    class="tab-content-wrapper"
                                                >
                                                    <v-row dense class="e-row">
                                                        <v-col
                                                            cols="6"
                                                            md="2"
                                                            class="e-col"
                                                        >
                                                            <div>
                                                                <label>
                                                                    <span
                                                                        class="label-txt"
                                                                        >Title</span
                                                                    >
                                                                    <v-select
                                                                        autocomplete="off"
                                                                        :items="
                                                                            titles
                                                                        "
                                                                        v-model="
                                                                            appointment
                                                                                .user
                                                                                .title
                                                                        "
                                                                        solo
                                                                        outlined
                                                                        flat
                                                                        class="solo-cust"
                                                                        hide-details="auto"
                                                                    >
                                                                        <template
                                                                            slot="append"
                                                                        >
                                                                            <v-icon
                                                                                >mdi-chevron-down</v-icon
                                                                            >
                                                                        </template>
                                                                    </v-select>
                                                                </label>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="6"
                                                            md="10"
                                                            class="e-col"
                                                        >
                                                            <div>
                                                                <label>
                                                                    <span
                                                                        class="label-txt"
                                                                    >
                                                                        Name
                                                                    </span>
                                                                    <v-text-field
                                                                        autocomplete="off"
                                                                        solo
                                                                        outlined
                                                                        v-model="
                                                                            appointment
                                                                                .user
                                                                                .name
                                                                        "
                                                                        flat
                                                                        hide-details="auto"
                                                                        class="solo-cust"
                                                                    ></v-text-field>
                                                                </label>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="e-row">
                                                        <v-col
                                                            cols="12"
                                                            md="12"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Social
                                                                    Handle
                                                                </span>
                                                            </label>
                                                            <v-text-field
                                                                autocomplete="off"
                                                                solo
                                                                outlined
                                                                v-model="
                                                                    appointment
                                                                        .user
                                                                        .social_handle
                                                                "
                                                                flat
                                                                hide-details="auto"
                                                                class="solo-cust social-handle-input"
                                                            >
                                                                <template
                                                                    slot="append-outer"
                                                                >
                                                                    <v-select
                                                                        autocomplete="off"
                                                                        v-model="
                                                                            appointment
                                                                                .user
                                                                                .source_id
                                                                        "
                                                                        :items="
                                                                            socialMedia
                                                                        "
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        solo
                                                                        outlined
                                                                        flat
                                                                        class="este-autocomplete-input"
                                                                        hide-details="auto"
                                                                    >
                                                                        <template
                                                                            v-slot:selection="data"
                                                                        >
                                                                            <img
                                                                                :src="`images/${trimLowerCase(
                                                                                    data
                                                                                        .item
                                                                                        .name
                                                                                )}.svg`"
                                                                                height="20px"
                                                                            />
                                                                        </template>
                                                                        <template
                                                                            v-slot:item="data"
                                                                        >
                                                                            <img
                                                                                :src="`images/${trimLowerCase(
                                                                                    data
                                                                                        .item
                                                                                        .name
                                                                                )}.svg`"
                                                                                height="20px"
                                                                            />
                                                                        </template>
                                                                        <template
                                                                            slot="append"
                                                                        >
                                                                            <v-icon
                                                                                >mdi-chevron-down</v-icon
                                                                            >
                                                                        </template>
                                                                    </v-select>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="e-row">
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Phone No.
                                                                </span>
                                                                <v-text-field
                                                                    autocomplete="off"
                                                                    solo
                                                                    outlined
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .contact_no
                                                                    "
                                                                    flat
                                                                    hide-details="auto"
                                                                    class="solo-cust"
                                                                    append-icon="mdil-phone"
                                                                ></v-text-field>
                                                            </label>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Email
                                                                    Address
                                                                </span>
                                                                <v-text-field
                                                                    autocomplete="off"
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .email
                                                                    "
                                                                    solo
                                                                    outlined
                                                                    flat
                                                                    hide-details="auto"
                                                                    class="solo-cust"
                                                                    append-icon="mdil-email"
                                                                ></v-text-field>
                                                            </label>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="e-row">
                                                        <v-col
                                                            cols="12"
                                                            md="12"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Address
                                                                </span>
                                                                <v-text-field
                                                                    autocomplete="off"
                                                                    solo
                                                                    outlined
                                                                    flat
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .address_one
                                                                    "
                                                                    hide-details="auto"
                                                                    class="solo-cust mb-2"
                                                                ></v-text-field>
                                                                <v-text-field
                                                                    autocomplete="off"
                                                                    solo
                                                                    outlined
                                                                    flat
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .address_two
                                                                    "
                                                                    hide-details="auto"
                                                                    class="solo-cust mar-8"
                                                                ></v-text-field>
                                                            </label>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="e-row">
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Town / City
                                                                </span>
                                                                <v-text-field
                                                                    autocomplete="off"
                                                                    solo
                                                                    outlined
                                                                    flat
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .city
                                                                    "
                                                                    hide-details="auto"
                                                                    class="solo-cust"
                                                                ></v-text-field>
                                                            </label>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                            class="e-col"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col cols="6">
                                                                    <label>
                                                                        <span
                                                                            class="label-txt"
                                                                        >
                                                                            Post
                                                                            Code
                                                                        </span>
                                                                        <v-text-field
                                                                            autocomplete="off"
                                                                            solo
                                                                            outlined
                                                                            flat
                                                                            v-model="
                                                                                appointment
                                                                                    .user
                                                                                    .postcode
                                                                            "
                                                                            hide-details="auto"
                                                                            class="solo-cust"
                                                                        ></v-text-field>
                                                                    </label>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="e-row">
                                                        <v-col
                                                            cols="12"
                                                            md="12"
                                                            class="e-col"
                                                        >
                                                            <label>
                                                                <span
                                                                    class="label-txt"
                                                                >
                                                                    Preferred
                                                                    Branch:
                                                                </span>
                                                                <v-select
                                                                    autocomplete="off"
                                                                    :items="
                                                                        branches
                                                                    "
                                                                    :rules="
                                                                        rules.branch_id
                                                                    "
                                                                    item-value="id"
                                                                    item-text="branch"
                                                                    v-model="
                                                                        appointment
                                                                            .user
                                                                            .branch_id
                                                                    "
                                                                    solo
                                                                    outlined
                                                                    flat
                                                                    class="solo-cust"
                                                                    hide-details="auto"
                                                                >
                                                                    <template
                                                                        slot="append"
                                                                    >
                                                                        <v-icon
                                                                            >mdi-chevron-down</v-icon
                                                                        >
                                                                    </template>
                                                                </v-select>
                                                            </label>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-form> -->
                    </v-tab-item>
                    <v-tab-item value="lead" :eager="true">
                      <v-form ref="leadForm">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Reason for Treatment: </span>
                              </label>
                              <v-combobox
                                v-model="appointment.user.user_details.reasons"
                                :items="reasons"
                                item-text="name"
                                item-value="id"
                                multiple
                                :search-input.sync="reasonSearch"
                                solo
                                outlined
                                flat
                                chips
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        No results matching "<strong>{{ reasonSearch }}</strong
                                        >". Press
                                        <kbd>enter</kbd>
                                        to create a new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2" v-if="data.item.name">
                                      {{ data.item.name }}
                                    </span>
                                    <span class="pr-2" v-else>
                                      {{ data.item }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeReason(data.item)"
                                    >
                                      close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Symptoms / Condition: </span>
                              </label>
                              <v-autocomplete
                                v-model="appointment.user.user_details.symptoms"
                                :items="symptoms"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                                @change="symptomChange"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeSymptoms(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Suggested Treatments: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip
                                  small
                                  class="este-chip status-chip mb-2"
                                  text-color="black"
                                  @click="suggTreat(treat)"
                                >
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Service Recommended: </span>
                              </label>
                              <v-autocomplete
                                v-model="appointment.user.user_details.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeService(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Enquiry Date </span>
                                <v-menu
                                  v-model="enquiryMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="
                                        dateUkFormat(appointment.user.other_details.enquiry_date)
                                      "
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="appointment.user.other_details.enquiry_date"
                                    no-title
                                    @input="enquiryMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt"> Follow Up Date </span>
                                <v-menu
                                  v-model="followUpMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="
                                        dateUkFormat(appointment.user.other_details.follow_up_date)
                                      "
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="appointment.user.other_details.follow_up_date"
                                    no-title
                                    @input="followUpMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">Follow Up Time:</span>
                                <v-menu
                                  ref="followUpTimeMenu"
                                  v-model="followUpTimeMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="appointment.user.other_details.follow_up_time"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      outlined
                                      flat
                                      hide-details="auto"
                                      solo
                                      class="solo-cust"
                                      v-model="appointment.user.other_details.follow_up_time"
                                      append-icon="mdil-clock"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    :allowed-minutes="allowedStep"
                                    no-title
                                    ampm-in-title
                                    format="24hr"
                                    v-if="followUpTimeMenu"
                                    v-model="appointment.user.other_details.follow_up_time"
                                    full-width
                                    @click:minute="
                                      $refs.followUpTimeMenu.save(
                                        appointment.user.other_details.follow_up_time
                                      )
                                    "
                                  ></v-time-picker>
                                </v-menu>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Source: </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="source"
                                v-model="appointment.user.source_id"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Responsible </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="appointment.user.other_details.responsible_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Lead Status </span>
                                <v-select
                                  autocomplete="off"
                                  :item-disabled="statusDisable"
                                  :items="
                                    SortAtoZ(
                                      statusTags.filter(function (el) {
                                        return el.slug.includes('lead');
                                      })
                                    )
                                  "
                                  item-value="id"
                                  item-text="name"
                                  v-model="appointment.user.status"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="client" :eager="true">
                      <v-form ref="form">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Symptoms / Condition: </span>
                              </label>
                              <v-autocomplete
                                v-model="appointment.user.user_details.symptoms"
                                :items="symptoms"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                                @change="symptomChange"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    color=""
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeSymptoms(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Suggested Treatments: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip
                                  small
                                  class="este-chip status-chip mb-2"
                                  text-color="black"
                                  @click="suggTreat(treat)"
                                >
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Service Recommended: </span>
                              </label>
                              <v-autocomplete
                                v-model="appointment.user.user_details.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip
                                    class="este-chip tag-chip px-3"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                  >
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon
                                      color="primary"
                                      small
                                      @click.stop="removeService(data.item)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Responsible </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="appointment.user.other_details.responsible_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Status </span>
                                <v-select
                                  autocomplete="off"
                                  :item-disabled="statusDisable"
                                  :items="
                                    SortAtoZ(
                                      statusTags.filter(function (el) {
                                        return el.slug.includes('client');
                                      })
                                    )
                                  "
                                  item-value="id"
                                  item-text="name"
                                  v-model="appointment.user.status"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="">
                              <label>
                                <span class="label-txt"> Client Satisfaction: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="(item, index) in satisfaction">
                                <div
                                  v-model="appointment.user.client_satisfaction"
                                  :class="[
                                    'emoji-wrap',
                                    `${lowerCase(item.name)}-emoji`,
                                    {
                                      active: activeIndex === index,
                                    },
                                    {
                                      active: appointment.user.client_satisfaction === item.slug,
                                    },
                                  ]"
                                  @click="setActive(item, index)"
                                  :key="item.name"
                                >
                                  <div class="icon">
                                    <img :src="`${item.image}`" />
                                  </div>
                                  <div class="text">
                                    {{ item.name }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="edit-appointment" :eager="true">
                      <v-form ref="appointmentForm">
                        <div class="tab-content-wrapper">
                          <v-row dense class="e-row">
                            <v-col cols="12" md="5" class="e-col">
                              <v-btn
                                class="b-small text-capitalize"
                                depressed
                                rounded
                                color="secondary"
                                :loading="reminderLoading"
                                @click="SendReminder(appointment)"
                              >
                                <v-icon class="mr-2" color="black"
                                  >mdi-message-text-clock-outline</v-icon
                                >
                                Send Reminder
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Treatment / Service: </span>
                              </label>
                              {{ appointment.appointment_type }}
                              <v-autocomplete
                                v-model="appointment.treatments"
                                :items="treatments"
                                item-text="name"
                                item-value="id"
                                multiple
                                chips
                                autocomplete="off"
                                solo
                                outlined
                                flat
                                hide-details="auto"
                                class="este-autocomplete-input"
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item.name }}</span>
                                  </v-chip>
                                  <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ appointment.treatments.length - 1 }}
                                    others)
                                  </span>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt"> Appointment Date: </span>
                                <v-menu
                                  v-model="appointmentDateMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      autocomplete="off"
                                      :value="dateUkFormat(appointment.appointment_date)"
                                      solo
                                      outlined
                                      v-on="on"
                                      flat
                                      hide-details="auto"
                                      class="solo-cust"
                                      append-icon="mdil-calendar"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="appointment.appointment_date"
                                    no-title
                                    @input="
                                      appointmentDateMenu = false;
                                      updateStatus();
                                    "
                                  ></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="6" md="6" class="e-col">
                              <v-row>
                                <v-col cols="6">
                                  <label>
                                    <span class="label-txt">Appointment Time:</span>
                                    <v-menu
                                      ref="appointmentFromTimeMenu"
                                      v-model="appointmentFromTimeMenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          outlined
                                          flat
                                          hide-details="auto"
                                          solo
                                          class="solo-cust"
                                          v-model="appointment.appointment_from_time"
                                          label="From"
                                          append-icon="mdil-clock"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-time-picker
                                        :allowed-minutes="allowedStep"
                                        no-title
                                        ampm-in-title
                                        format="24hr"
                                        v-if="appointmentFromTimeMenu"
                                        v-model="appointment.appointment_from_time"
                                        full-width
                                        @click:minute="
                                          $refs.appointmentFromTimeMenu.save(
                                            appointment.appointment_from_time
                                          )
                                        "
                                      ></v-time-picker>
                                    </v-menu>
                                  </label>
                                </v-col>
                                <v-col cols="6">
                                  <label>
                                    <v-menu
                                      ref="appointmentToTimeMenu"
                                      v-model="appointmentToTimeMenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          outlined
                                          flat
                                          hide-details="auto"
                                          solo
                                          class="solo-cust mt-5"
                                          v-model="appointment.appointment_to_time"
                                          label="To"
                                          append-icon="mdil-clock"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-time-picker
                                        :allowed-minutes="allowedStep"
                                        no-title
                                        ampm-in-title
                                        format="24hr"
                                        v-if="appointmentToTimeMenu"
                                        v-model="appointment.appointment_to_time"
                                        full-width
                                        @click:minute="
                                          $refs.appointmentToTimeMenu.save(
                                            appointment.appointment_to_time
                                          )
                                        "
                                      ></v-time-picker>
                                    </v-menu>
                                  </label>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Preferred Branch: </span>
                                <v-select
                                  autocomplete="off"
                                  :items="branches"
                                  item-value="id"
                                  item-text="branch"
                                  :rules="rules.branch_id"
                                  v-model="appointment.branch_id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                  @change="staffByBranch"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Select Consultant </span>
                                <v-select
                                  autocomplete="off"
                                  :items="staff"
                                  v-model="appointment.consultant_id"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  outlined
                                  flat
                                  class="solo-cust"
                                  hide-details="auto"
                                >
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Appointment Type </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="appointmentType"
                                v-model="appointment.appointment_type"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt"> Status </span>
                              </label>
                              <v-select
                                autocomplete="off"
                                :items="
                                  SortAtoZ(
                                    statusTags.filter(function (el) {
                                      return el.slug.includes('appointment');
                                    })
                                  )
                                "
                                v-model="appointment.status"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row" v-if="appointment.user.user_type_name === 'Client'">
                            <v-col cols="12" md="12" class="">
                              <label>
                                <span class="label-txt"> Client Satisfaction: </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="(item, index) in satisfaction">
                                <div
                                  v-model="appointment.client_satisfaction"
                                  :class="[
                                    'emoji-wrap',
                                    `${lowerCase(item.name)}-emoji`,
                                    {
                                      active: activeIndex === index,
                                    },
                                    {
                                      active: appointment.client_satisfaction === item.slug,
                                    },
                                  ]"
                                  @click="setActive(item, index)"
                                  :key="item.name"
                                >
                                  <div class="icon">
                                    <img :src="`${item.image}`" />
                                  </div>
                                  <div class="text">
                                    {{ item.name }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="deal" :eager="true">
                      <tab-deal
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-deal>
                    </v-tab-item>
                    <v-tab-item value="insight" :eager="true">
                      <tab-insight
                        :itemData="appointment.user"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-insight>
                    </v-tab-item>
                    <v-tab-item value="activity" :eager="true">
                      <tab-activity
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-activity>
                    </v-tab-item>
                    <v-tab-item value="appointments" :eager="true">
                      <tab-appointment
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-appointment>
                    </v-tab-item>

                    <v-tab-item value="consent" :eager="true">
                      <tab-consent
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-consent>
                    </v-tab-item>
                    <v-tab-item value="notes" :eager="true">
                      <tab-appointment-notes
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-appointment-notes>
                    </v-tab-item>
                    <v-tab-item value="upload-document" :eager="true">
                      <tab-document-upload
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-document-upload>
                    </v-tab-item>
                    <v-tab-item value="financials" :eager="true">
                      <tab-financials
                        :itemData="appointment"
                        :userId="appointment.user_id"
                        :keyId="appointment.id"
                      ></tab-financials>
                    </v-tab-item>

                    <!-- <v-tab-item
                                            value="financials"
                                            :eager="true"
                                        >
                                            <tab-appointment
                                                :itemData="appointment"
                                                :userId="appointment.user_id"
                                                :keyId="appointment.id"
                                            ></tab-appointment>
                                        </v-tab-item>

                                        <v-tab-item
                                            value="packages"
                                            :eager="true"
                                        >
                                        </v-tab-item>

                                        <v-tab-item
                                            value="communication"
                                            :eager="true"
                                        >
                                        </v-tab-item>
                                        <v-tab-item value="emr" :eager="true">
                                        </v-tab-item>
                                        <v-tab-item
                                            value="gift-voucher"
                                            :eager="true"
                                        >
                                        </v-tab-item>
                                        <v-tab-item
                                            value="loyalty"
                                            :eager="true"
                                        >
                                        </v-tab-item>
                                        <v-tab-item
                                            value="task-recalls"
                                            :eager="true"
                                        >
                                        </v-tab-item> -->
                  </v-tabs-items>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      <template v-if="navigationTab !== 'deal'">Cancel</template>
                      <template v-if="navigationTab === 'deal'">Close</template>
                    </v-btn>

                    <v-btn
                      v-if="navigationTab !== 'deal'"
                      color="secondary"
                      class="b-blue"
                      depressed
                      @click="update"
                      :loading="loading"
                      rounded
                      x-large
                    >
                      Save Changes
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import edit from "./edit.js";
import TabActivity from "../../../components/TabItems/TabActivity";
import TabInsight from "../../../components/TabItems/TabInsight";
import TabDeal from "../../../components/TabItems/TabDeal";
import TabAppointment from "../../../components/TabItems/TabAppointment";
import TabPersonal from "../../../components/TabItems/TabPersonal";
import TabConsent from "../../../components/TabItems/TabConsent";
import TabAppointmentNotes from "../../../components/TabItems/AppointmentsNotes";
import TabDocumentUpload from "../../../components/TabItems/DocumentUpload.vue";
import TabFinancials from "../../../components/TabItems/Financials.vue";

export default {
  components: {
    TabActivity,
    TabInsight,
    TabDeal,
    TabAppointment,
    TabPersonal,
    TabConsent,
    TabAppointmentNotes,
    TabDocumentUpload,
    TabFinancials,
  },

  mixins: [globalFn, status, edit, sidePaneMenu],
};
</script>
