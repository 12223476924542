<template>
  <div>
    <v-text-field
      flat
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      label="Search for a sale…"
      solo
      hide-details
      class="search-input"
      @input="isTyping = true"
      v-model="search"
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left content-class="">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="reset"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" sm="6" md="4">
                <span class="heading">Sale Date:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="saleDateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.sale_date_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.sale_date_from"
                      no-title
                      @input="saleDateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="saleDateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.sale_date_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.sale_date_to"
                      no-title
                      @input="saleDateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <span class="heading">Payment Date:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="paymentdateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.payment_date_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.payment_date_from"
                      no-title
                      @input="paymentdateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="paymentdateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.payment_date_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.payment_date_to"
                      no-title
                      @input="paymentdateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="heading"
                  >Status:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="StatusToggle"
                    @click="handleFunction('StatusCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="stat in StatusDefault">
                    <v-chip
                      small
                      class="este-chip status-chip mb-2"
                      :outlined="stat.outline"
                      :text-color="stat.text ? stat.color : '#221F20'"
                      :color="stat.color"
                      @click="statusToggle(stat)"
                    >
                      {{ stat.name | capitalize }}
                      <v-icon right color="black" v-if="stat.active" size="15">mdil-check</v-icon>
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="heading"
                  >Branch:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="BranchToggle"
                    @click="handleFunction('BranchCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="branch in BrachDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="branch.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="branchToggle(branch)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import advancedSearch from "../../mixins/advancedSearch";
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status";
import _ from "lodash";
export default {
  name: "ComplaintSearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    saleDateFromMenu: false,
    saleDateToMenu: false,
    paymentdateFromMenu: false,
    paymentdateToMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    params: "?&sort_order=DESC&sort_by=name",
    filter: {
      search: "",
      sale_date_from: "",
      sale_date_to: "",
      payment_date_from: "",
      payment_date_to: "",
      status: [],
      branch: [],
    },
    search: "",
    isTyping: false,
  }),
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.filter.search = this.search;

        this.checkAll();
        this.searchKey();
      }
    },
  },
  mounted() {
    this.loadDefaults();
  },
  methods: {
    async searchKey() {
      this.$root.$emit("searchSales", this.filter);
    },
    async reset() {
      await this.resetFilter();
      await this.loadDefaults();
    },
    async resetFilter() {
      this.filter.search = "";
      this.filter.status = [];
      this.filter.sale_date_from = "";
      this.filter.sale_date_to = "";
      this.filter.payment_date_from = "";
      this.filter.payment_date_to = "";
    },
  },
};
</script>
