<template>
  <div>
    <div class="pa-0 album" @click="$emit('viewAlbum')" style="cursor: pointer">
      <div
        class="album-item border"
        v-for="i in 4"
        :key="i"
        :style="{ backgroundImage: photos[i - 1] ? `url(${photos[i - 1].url})` : '' }"
      ></div>
    </div>

    <div class="d-flex align-center justify-space-between">
      <p class="mt-2 text-subtitle-1 text-capitalize">{{ name }} ({{ photos.length }})</p>

      <v-menu max-width="200" offset-y left content-class="user-action" v-if="showOptions">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="updateAlbum">
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon> Update
          </v-list-item>
          <v-list-item class="text-red" @click="deleteAlbum">
            <v-icon class="mr-2" color="red" small>mdi-delete-outline</v-icon> Delete
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import modalFn from "../../../../mixins/modalFn";
export default {
  mixins: [modalFn],
  props: ["album", "photos", "name", "showOptions"],
  methods: {
    async deleteAlbum() {
      await this.$store.dispatch("albums/DELETE", this.album.id).then((res) => {
        if (res.result === "success") {
          this.$emit("refresh");
        }
      });
    },
    updateAlbum() {
      this.$store.dispatch("albums/GET_BY_ID", { id: this.album.id, params: "?attributes=photos" });
      this.openModal("edit", 400, "Photos/update");
    },
  },
};
</script>

<style>
.album {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  /* width: 190px; */
  width: 90%;

  border-radius: 8px;
}

.album-item {
  height: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.album-item:nth-child(1) {
  border-top-left-radius: 8px;
}

.album-item:nth-child(2) {
  border-top-right-radius: 8px;
}

.album-item:nth-child(3) {
  border-bottom-left-radius: 8px;
}

.album-item:nth-child(4) {
  border-bottom-right-radius: 8px;
}
</style>
