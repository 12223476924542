<template>
  <img
    :src="mini ? configuration.brand_mini_logo_url : configuration.brand_logo_url"
    alt="logo"
    class="site--logo"
  />
</template>
<script>
export default {
  name: "TheLogo",
  props: {
    mini: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: false,
    },
  },
  data() {
    return {
      screen: null,
      defaults: {
        brand_logo_url: "/images/logo-reverse.svg",
        brand_mini_logo_url: "/images/mini-logo.svg",
      },
    };
  },
  computed: {
    configuration() {
      if (this.$store.state.configurations.item) {
        let arr = this.$store.state.configurations.item;
        if (!arr.brand_logo) {
          arr.brand_logo_url = this.defaults.brand_logo_url;
        }
        if (!arr.brand_mini_logo) {
          arr.brand_mini_logo_url = this.defaults.brand_mini_logo_url;
        }
        return arr;
      } else {
        return this.defaults;
      }
    },
  },
  created() {
    this.$store.dispatch("configurations/GET_LOGO", "");
  },
  watch: {
    $route: function (val) {
      this.screen = val.meta.name;
    },
  },
  mounted() {
    this.loadTitle();
  },
  methods: {
    loadTitle() {
      this.screen = this.$router.currentRoute.meta.name;
    },
  },
};
</script>
<style lang="css" scoped></style>
