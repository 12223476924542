<template>
  <div>
    <div class="date-range">
      <v-btn fab text small class="prev" color="prev grey darken-2" @click="prev">
        <v-icon large> mdi-chevron-left </v-icon>
      </v-btn>
      <div class="filter-wrap">
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              flat
              :value="dateUkFormat(filter.date)"
              prepend-inner-icon="mdil-calendar"
              autocomplete="off"
              solo
              hide-details
              class="search-input"
            >
              <template v-slot:append> </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filter.date" no-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                dateMenu = false;
                loadCalendarData();
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu :close-on-content-click="false" offset-y left max-width="800">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance bg-white-100" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="clear"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="two-layout-grid">
              <v-col class="item">
                <span class="heading">Branch:</span>
                <div class="mt-2">
                  <template v-for="(branch, index) in branches">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="{ selected: branchIndex === index }"
                      text-color="black"
                      @click="branchToggle(branch, index)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col class="item">
                <span class="heading">Treatments:</span>
                <div class="mt-2">
                  <v-autocomplete
                    autocomplete="off"
                    multiple
                    placeholder="Filter Treatments"
                    :items="treatments"
                    item-value="id"
                    item-text="name"
                    v-model="filter.treatments"
                    solo
                    outlined
                    flat
                    class="advance-input full-width"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip class="este-chip select-chip" v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.treatments.length - 1 }} others)
                      </span>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="heading">Consultants:</span>
                <div class="mt-2">
                  <v-autocomplete
                    autocomplete="off"
                    multiple
                    placeholder="Filter Consultants"
                    :items="consultants"
                    item-value="id"
                    item-text="name"
                    v-model="filter.consultants"
                    solo
                    outlined
                    flat
                    class="advance-input full-width"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip class="este-chip select-chip" v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.consultants.length - 1 }} others)
                      </span>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="heading">Machines:</span>
                <div class="mt-2">
                  <v-autocomplete
                    autocomplete="off"
                    multiple
                    placeholder="Filter Machines"
                    :items="machines"
                    item-value="id"
                    item-text="name"
                    v-model="filter.machines"
                    solo
                    outlined
                    flat
                    class="advance-input full-width"
                    hide-details="auto"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip class="este-chip select-chip" v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.machines.length - 1 }} others)
                      </span>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </div>
      <v-btn fab text small class="next" color=" grey darken-2 mt-1 ml-1" @click="next">
        <v-icon large> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status";
import eventBus from "../../eventBus.js";
import _ from "lodash";
export default {
  name: "CalendarSearch",
  mixins: [globalFn, status],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    routeName: "",
    user: JSON.parse(localStorage.getItem("user")),
    date: [],
    dateMenu: false,
    BranchToggle: false,
    type: "category",
    labels: {
      month: "Month",
      week: "Week",
      day: "Day",
      category: "Day",
    },
    BrachDefault: [],
    filter: {
      branches: [],
      consultants: [],
      treatments: [],
      machines: [],
      date: new Date().toISOString().substr(0, 10),
    },
    filterMenu: false,
    branchIndex: "",
  }),
  computed: {
    machines() {
      if (this.$store.state.machines.all) {
        return this.$store.state.machines.all;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    consultants() {
      if (this.$store.state.staff.all) {
        this.$store.state.staff.all = this.$store.state.staff.all.filter(function (el) {
          return el.role_type_name === "Consultant";
        });
        return this.$store.state.staff.all;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: "", params: "" });
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.routeName = this.$router.currentRoute.params.screen;
  },
  mounted() {
    eventBus.$on("setBranchId", this.setBranch);
    eventBus.$on("updateDaterange", this.updateDaterange);
  },
  methods: {
    ...mapActions({}),

    setBranch(data) {
      console.log("tttt");
      this.filter.branches = data;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async loadCalendarData() {
      eventBus.$emit("setDateForCalendar", this.filter);
    },
    prev() {
      eventBus.$emit("PrevAction", true);
    },
    next() {
      eventBus.$emit("NextAction", true);
    },
    updateDaterange(data) {
      this.filter.date = data;
    },
    async branchToggle(item, index) {
      this.filter.branches = [];
      this.branchIndex = index;
      this.filter.branches.push(parseInt(item.id));
    },
    async searchKey() {
      this.filterMenu = true;
      eventBus.$emit("searchCalendarAppointments", this.filter);
    },
    async clear() {
      this.filter.branches = [];
      this.filter.date = new Date().toISOString().substr(0, 10);
      this.filter.consultants = [];
      this.filter.machines = [];
      this.filter.treatments = [];
      this.branches.forEach((value) => {
        value.active = false;
      });
      await this.searchKey();
    },
  },
};
</script>
