<template>
  <div>
    <v-navigation-drawer left v-model="drawer" app class="nav-drawer" :width="260">
      <div class="menu-wrap">
        <div :class="mini ? 'brand-mini-logo ' : 'brand-logo'">
          <TheLogo :mini="mini" />
          <img src="/images/arrow_double.svg" width="24" height="24" />
        </div>
        <div class="nav-lists">
          <v-list>
            <template v-for="(m, index) in menu">
              <v-list-group
                :prepend-icon="m.icon"
                class="custom-list-group"
                v-if="m.submenu && subMenuPermission(m.submenu)"
                :key="index"
                v-model="m.active"
              >
                <template v-slot:activator>
                  <v-list-item-title class="menu-txt">{{ m.name }}</v-list-item-title>
                </template>
                <template v-for="(ms, index) in m.submenu" v-if="hasPermission(ms)" :key="index">
                  <v-list-item :to="ms.to" :exact="false" class="menu">
                    <v-list-item-action class="menu-icon">
                      <v-icon>{{ ms.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="menu-txt pt-1 pb-1">
                      <v-list-item-title>
                        {{ ms.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                :to="m.to"
                :exact="false"
                class="menu"
                :key="index"
                v-if="!m.submenu && hasPermission(m)"
              >
                <v-list-item-action class="menu-icon">
                  <v-badge
                    v-if="menuCount.hasOwnProperty(m.stat) && mini"
                    overlap
                    color="secondary"
                    :content="menuCount[m.stat]"
                    :value="menuCount[m.stat]"
                    offset-x="10"
                    offset-y="10"
                    class="menu-badge"
                  >
                    <v-icon>{{ m.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else>{{ m.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="menu-txt">
                  <v-list-item-title>
                    {{ m.name }}
                    <v-chip
                      class="ml-1 count-chip"
                      v-if="menuCount.hasOwnProperty(m.stat) && !mini"
                    >
                      <img src="/images/bolt-grey.svg" class="mr-1" />
                      {{ menuCount[m.stat] }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
        <div class="nav-lists settings-menu">
          <v-menu
            max-width="200"
            :nudge-width="200"
            offset-y
            top
            content-class="setting-action"
            v-if="settingsMenuPermission(settings)"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-on="on">
                <v-list>
                  <v-list-item
                    :exact="false"
                    class="menu"
                    :class="routeName === 'Settings' ? 'v-list-item--active' : ''"
                  >
                    <v-list-item-action class="menu-icon">
                      <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="menu-txt">
                      <v-list-item-title> Settings </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </a>
            </template>
            <div class="logout-block">
              <ul>
                <li v-for="item in settings">
                  <a
                    v-if="hasPermission(item)"
                    class="text-decoration-none"
                    :href="`${item.path}`"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </v-menu>
          <v-list class="py-0">
            <v-list-item :exact="false" class="menu" @click="clickLogout">
              <v-list-item-action class="menu-icon">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content class="menu-txt">
                <v-list-item-title> Log Out </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER, LOGOUT } from "../store/action-types";
import sidePaneMenu from "../mixins/sidePaneMenu.js";
import eventBus from "../eventBus.js";
import TheLogo from "./TheLogo";
export default {
  mixins: [sidePaneMenu],
  components: {
    TheLogo,
  },
  name: "sidebar",
  data: () => ({
    drawer: null,
    mini: false,
    routeName: "",
    user: JSON.parse(localStorage.getItem("user")),
    menu: [
      {
        name: "Dashboard",
        icon: "mdi-view-dashboard-outline",
        to: "/dashboard",
        module: "dashboard",
      },
      {
        name: "Pulse Dashboard",
        icon: "mdi-clipboard-pulse-outline",
        to: "/pulse-dashboard",
        module: "pulse_dashboard",
      },
      {
        name: "Opportunities",
        icon: "mdi-rocket-launch-outline",
        to: "/opportunities",
        module: "opportunities",
        stat: "live",
      },
      {
        name: "Contacts",
        icon: "mdi-account-box-outline",
        to: "/customers",
        module: "customer",
      },
      {
        name: "Bookings",
        icon: "mdi-calendar",
        to: "/appointments",
        module: "appointment",
        submenu: [
          {
            name: "Schedule Manager",
            icon: "mdi-calendar",
            to: "/schedular",
            module: "schedular",
          },
          {
            name: "Calendar",
            icon: "mdi-calendar",
            to: "/calendar/appointments",
            module: "appointment",
          },
          {
            name: "Appointments",
            icon: "mdi-calendar",
            to: "/appointments",
            module: "appointment",
          },
        ],
      },
      {
        name: "Sales",
        icon: "mdi-credit-card-outline",
        to: "/deals",
        module: "deal",
        submenu: [
          {
            name: "Deals",
            icon: "mdi-trophy-award",
            to: "/deals",
            module: "deal",
          },
        ],
      },

      {
        name: "Support Tickets",
        icon: "mdi-help-circle-outline",
        to: "/support-tickets",
        module: "support-tickets",
      },
      {
        name: "Pabau Sync",
        icon: "mdi-sync",
        to: "/pabau-appointments",
        module: "report",
        submenu: [
          {
            name: "Pabau Appointments",
            icon: "mdil-calendar",
            to: "/pabau-appointments",
            module: "appointment",
            stat: "pabau_appointments",
          },
          {
            name: "Pabau Sales",
            icon: "mdil-currency-gbp",
            to: "/pabau-sales",
            module: "pabau_sales",
          },
        ],
      },

      {
        name: "Reports",
        icon: "mdi-file-document-outline",
        to: "/reports",
        module: "report",
        submenu: [
          {
            name: "Download Reports",
            icon: "mdi-file-download-outline",
            to: "/reports",
            module: "report",
          },
          {
            name: "Opportunity Activity Report",
            icon: "mdi-account-details-outline",
            to: "/lead-activity-report",
            module: "lead_activity_report",
          },
          {
            name: "Phone Activity Report",
            icon: "mdi-phone-log-outline",
            to: "/phone-activity-report",
            module: "phone_activity_report",
          },
        ],
      },
      {
        name: "Expenses",
        icon: "mdi-credit-card-outline",
        submenu: [
          {
            name: "Invoices",
            to: "/purchase-invoices",
            module: "purchase_invoice",
            icon: "mdi-file-document-outline",
          },
          {
            name: "Payments",
            to: "/cash-payments",
            module: "cash_payment",
            icon: "mdi-credit-card-chip-outline",
          },
        ],
      },
      {
        name: "Marketing",
        icon: "mdi-bullhorn-outline",
        submenu: [
          {
            name: "Offers",
            icon: "mdi-label-percent-outline",
            to: "/offers",
            module: "offer",
          },
          {
            name: "Notifications",
            icon: "mdi-bell-outline",
            to: "/notifications",
            module: "notifications",
          },
        ],
      },
    ],
    staffParams: "?attributes=permissions",
    settings: [
      {
        name: "Branches",
        path: "/settings/branches",
        module: "branches",
      },
      {
        name: "Campaigns",
        path: "/settings/campaigns",
        module: "campaigns",
      },
      {
        name: "Shift Template",
        path: "/settings/shift-template",
        module: "shift_template",
      },
      {
        name: "Staff",
        path: "/settings/staff",
        module: "staff",
      },
      {
        name: "Rooms",
        path: "/settings/rooms",
        module: "rooms",
      },
      {
        name: "Forms",
        path: "/settings/forms",
        module: "forms",
      },
      {
        name: "Categories",
        path: "/settings/categories",
        module: "categories",
      },
      {
        name: "Symptoms",
        path: "/settings/symptoms",
        module: "symptoms",
      },
      {
        name: "Treatments",
        path: "/settings/treatments",
        module: "treatments",
      },
      {
        name: "Machines",
        path: "/settings/machines",
        module: "machines",
      },
      {
        name: "Warehouse",
        path: "/settings/warehouse",
        module: "warehouse",
      },
      {
        name: "Products",
        path: "/settings/products",
        module: "products",
      },
      {
        name: "Points",
        path: "/settings/points",
        module: "points",
      },
      {
        name: "Brand Configuration",
        path: "/settings/configurations",
        module: "configurations",
      },
    ],
  }),
  watch: {
    $route: function (val) {
      this.routeName = val.meta.slug;
    },
  },
  computed: {
    staff() {
      if (this.$store.state.staff.item) {
        return this.$store.state.staff.item;
      }
    },
    menuCount() {
      if (this.$store.state.statistics.item) {
        return this.$store.state.statistics.item;
      }
    },
  },
  created() {
    this.USER();
    this.$store.dispatch("statistics/GET_COUNT", "");
    window.Echo.channel("new-lead").listen(".lead-created", (e) => {
      this.$store.dispatch("statistics/GET_COUNT", "");
    });
    window.Echo.channel("new-appointment").listen(".appointment-created", (e) => {
      this.$store.dispatch("statistics/GET_COUNT", "");
    });
    window.Echo.channel("new-callback").listen(".callback-created", (e) => {
      this.$store.dispatch("statistics/GET_COUNT", "");
    });
  },
  mounted() {
    this.loadMenu();
    this.$root.$on("toggle-drawer-side-bar", this.toggleSideLeftBar);
    this.loadRole();
    eventBus.$on("StatsRefresh", this.StatsRefresh);
  },
  methods: {
    ...mapActions({
      LOGOUT,
      USER,
    }),
    loadMenu() {
      this.routeName = this.$router.currentRoute.meta.slug;
    },
    StatsRefresh() {
      this.$store.dispatch("statistics/GET_COUNT", "");
    },
    async loadRole() {
      this.routeName = this.$router.currentRoute.meta.slug;
      if (this.user.user_type_name === "Staff") {
        this.loadStaff();
      }
    },
    async loadStaff() {
      await this.$store.dispatch("staff/SHOW_STAFF", {
        id: this.user.id,
        param: this.staffParams,
      });
    },
    toggleSideLeftBar() {
      this.drawer = !this.drawer;
    },
    clickLogout() {
      this.LOGOUT().then((res) => {
        window.location.href = "/";
      });
    },
  },
};
</script>
