<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
import { USER, SHOW_STAFF } from "../../store/action-types";
export default {
  name: "Home",
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    staffParams: "?attributes=permissions",
  }),
  created() {
    this.redirect();
  },
  computed: {
    staff() {
      if (this.$store.state.staff.item) {
        return this.$store.state.staff.item;
      }
    },
  },
  methods: {
    ...mapActions({
      USER,
      SHOW_STAFF,
    }),
    async redirect() {
      let hostname = location.hostname;
      if (this.user.user_type_name === "Admin") {
        window.location.href = "/opportunities";
      } else {
        let routes = [
          { module: "lead", route: "opportunities" },
          { module: "client", route: "customers" },
          { module: "opportunities", route: "opportunities" },
          { module: "support-tickets", route: "support-tickets" },
          { module: "customer", route: "customers" },
          { module: "complaint", route: "support-tickets" },
          { module: "appointment", route: "opportunities" },
          { module: "commission_report", route: "reports" },
          { module: "invoice_report", route: "reports" },
          { module: "cash_payment_report", route: "reports" },
          { module: "insight", route: "insights" },
          { module: "deal", route: "deals" },
          { module: "purchase_invoice", route: "purchase-invoices" },
          { module: "cash_payment", route: "cash-payments" },
          { module: "callback", route: "support-tickets" },
          { module: "dashboard", route: "dashboard" },
          { module: "pulse_dashboard", route: "pulse-dashboard" },
          { module: "social_media_report", route: "reports" },
          { module: "lead_source_report", route: "reports" },
          { module: "email_report", route: "reports" },
          { module: "social_media_chased_report", route: "reports" },
          { module: "appointment_calendar_dashboard", route: "appointment-calendar-dashboard" },
          { module: "pabau_sales", route: "pabau-sales" },
          { module: "phone_activity_report", route: "lead-activity-report" },
          { module: "lead_activity_report", route: "phone-activity-report" },
        ];
        let self = this;
        let routeName = "";
        let found = routes.some(function (el) {
          if (el.module === self.user.permission_modules[0].module) {
            routeName = el.route;
            return el.route;
          }
        });
        window.location.href = found ? "/" + routeName : this.error();
      }
    },
    error() {
      let res = [];
      res.type = "error";
      res.message = `You don't have a permission for any screen yet. Please contact admin`;
      this.$store.dispatch("snackbar/SHOW", res);
    },
  },
};
</script>
<style></style>
