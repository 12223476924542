<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Edit Campaign</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Name </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  :rules="rules.name"
                  v-model="campaign.name"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Choose Badge Color: </span>
                </label>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      small
                      class="este-chip text-uppercase"
                      text-color="white"
                      :color="campaign.color"
                    >
                      <span v-if="campaign.name">{{ campaign.name | capitalize }}</span>
                      <span v-else>Campaign Name</span>
                    </v-chip>
                  </template>
                  <v-color-picker
                    mode="hexa"
                    flat
                    v-model="campaign.color"
                    class="mx-auto"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="update"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    rules: {
      name: [(v) => !!v || "Please enter name"],
    },
  }),
  computed: {
    campaign() {
      if (this.$store.state.campaign.item) {
        return this.$store.state.campaign.item;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("campaignTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("campaign/UPDATE", this.campaign)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
              this.$refs.form.reset();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
