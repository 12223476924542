import eventBus from "../../../eventBus.js";
export default {
  data: () => ({
    loading: false,
    followUpTimeMenu: false,
    enquiryMenu: false,
    followUpMenu: false,
    followupDateChange: false,
    user: JSON.parse(localStorage.getItem("user")),
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    reasonSearch: null,
    sourceSearch: null,
    suggestedTreatments: [],
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      status: [],
      source: [(v) => !!v || "Please select source"],
    },
    staff: [],
    staffParams: {
      branch: "",
      type: "lead",
    },
    contactChange: false,
    emailChange: false,
    socailHandle: false,

    navigationTab: null,
    navigation: [
      {
        name: "Personal Details",
        icon: "mdil-account",
        slug: "personal",
        module: "personal",
        form: true,
      },
      {
        name: "Lead Details",
        icon: "mdil-star",
        slug: "lead",
        module: "lead",
        form: true,
      },
      {
        name: "Appointments",
        icon: "mdil-calendar",
        slug: "appointment",
        module: "appointment",
        form: true,
      },
      {
        name: "Callbacks",
        icon: "mdil-phone",
        slug: "callback",
        module: "callback",
        form: true,
      },
      {
        name: "Insights",
        icon: "mdil-chart-areaspline",
        slug: "insight",
        module: "insight",
        form: false,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        module: "activity",
        form: false,
      },
    ],
  }),
  watch: {
    "lead.social_handle": _.debounce(function () {
      if (this.lead.social_handle) {
        this.autoSelectSocialIcon();
      }
      this.socailHandle = true;
    }, 1000),
    "lead.contact_no"(val) {
      this.contactChange = true;
    },
    "lead.email"(val) {
      this.emailChange = true;
    },
    "lead.social_handle"(val) {
      this.socailHandle = true;
    },
    "lead.lead_details.follow_up_date": function (newVal, oldVal) {
      this.IsFollowUpDateChanged(newVal, oldVal);
    },
  },
  computed: {
    lead() {
      if (this.$store.state.leads.item) {
        this.$store.state.leads.item.source_id = this.$store.state.leads.item.source_id;
        if (!this.$store.state.leads.item.lead_details) {
          this.$store.state.leads.item.lead_details = {};
        }
        return this.$store.state.leads.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
    commentCount() {
      if (this.$store.state.statistics.comments) {
        return this.$store.state.statistics.comments;
      }
    },
    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all;
      }
    },
  },
  mounted() {
    this.symptomChange();
    this.staffByBranch();
    eventBus.$on(`loadCommentsCount${this.lead.id}`, this.loadCommentStats);
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("campaign/LIST", "");
  },
  methods: {
    IsFollowUpDateChanged(newVal, oldVal) {
      if (this.lead.status !== 23 && newVal) {
        var d1 = new Date(oldVal);
        var d2 = new Date(newVal);
        if (d1.getTime() !== d2.getTime() && this.lead.status !== 26 && this.lead.status !== 19) {
          this.lead.status = 15;
        }
      }
    },
    clearFollowUpDate() {
      this.lead.lead_details.follow_up_date = null;
    },
    clearFollowUpTime() {
      this.lead.lead_details.follow_up_time = null;
    },
    async loadCommentStats() {
      await this.$store.dispatch("statistics/GET_COMMENT_COUNT", `?type=lead&id=${this.lead.id}`);
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async autoSelectSocialIcon() {
      let isValid = this.isValidUrl(this.lead.social_handle);
      if (isValid) {
        let url = new URL(this.lead.social_handle);
        this.lead.source = this.getSourceId(url.origin);
      }
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    loadData() {
      eventBus.$emit("StatsRefresh");
      this.$store.commit("rightSlider/RESET_SLIDER");

      if (this.$router.currentRoute.meta.slug === "customer") {
        this.$root.$emit("customerTableUpdate", "");
      } else {
        this.$root.$emit("leadsTableUpdate", "");
      }
    },
    async removeService(item) {
      const index = this.lead.treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.lead.treatments.splice(index, 1);
      } else {
        const i = this.lead.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.lead.treatments.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.lead.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.lead.symptoms.splice(index, 1);
      } else {
        const i = this.lead.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.lead.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeCampaign(item) {
      const index = this.lead.campaigns.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.lead.campaigns.splice(index, 1);
      } else {
        const i = this.lead.campaigns.findIndex((x) => x === item.id);
        if (i >= 0) this.lead.campaigns.splice(i, 1);
      }
    },
    async removeReason(item) {
      if (item.id) {
        const index = this.lead.reasons.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.lead.reasons.splice(index, 1);
        } else {
          const i = this.lead.reasons.findIndex((x) => x === item.id);
          if (i >= 0) this.lead.reasons.splice(i, 1);
        }
      } else {
        const index = this.lead.reasons.findIndex((x) => x.id === item);
        if (index >= 0) {
          this.lead.reasons.splice(index, 1);
        } else {
          const i = this.lead.reasons.findIndex((x) => x === item);
          if (i >= 0) this.lead.reasons.splice(i, 1);
        }
      }
    },

    removeResponsible(item) {
      this.lead.lead_details.responsible_id = null;
    },
    async symptomChange() {
      const ids = [];
      this.lead.symptoms.forEach((value) => {
        if (value.id) {
          ids.push({ id: value.id });
        } else {
          ids.push(value);
        }
      });
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", { symptoms: ids })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async suggTreat(item) {
      let exists = this.lead.treatments.some((el) => el.id === item.id)
        ? true
        : this.lead.treatments.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.lead.treatments.push(item.id);
      } else {
        const index = this.lead.treatments.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.lead.treatments.splice(index, 1);
        } else {
          const i = this.lead.treatments.findIndex((x) => x === item.id);
          if (i >= 0) this.lead.treatments.splice(i, 1);
        }
      }
    },
    async staffByBranch() {
      this.staff = [];
      this.staffParams.branch = this.lead.branch_id;
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async updateLead() {
      let formData = {};
      formData.id = this.lead.id;
      formData.title = this.lead.title;
      formData.name = this.lead.name;
      formData.address_one = this.lead.address_one;
      formData.address_two = this.lead.address_two;
      formData.city = this.lead.city;
      formData.postcode = this.lead.postcode;
      if (this.contactChange) {
        formData.contact_no = this.lead.contact_no;
      }
      formData.branch_id = this.lead.branch_id;
      if (this.socailHandle) {
        formData.social_handle = this.lead.social_handle;
      }
      if (this.lead.email && this.emailChange) {
        formData.email = this.lead.email;
      }
      formData.status = this.lead.status;
      formData.responsible_id = this.lead.lead_details.responsible_id;

      formData.source_id = this.lead.source_id;

      formData.is_receive_sms = this.lead.is_receive_sms;
      formData.reasons = [];
      this.lead.reasons.forEach((value) => {
        if (value.id) {
          formData.reasons.push({ id: value.id });
        } else {
          formData.reasons.push(value);
        }
      });

      formData.service_recommended = [];
      this.lead.treatments.forEach((value) => {
        if (value.id) {
          formData.service_recommended.push(value.id);
        } else {
          formData.service_recommended.push(value);
        }
      });

      formData.symptoms = [];
      this.lead.symptoms.forEach((value) => {
        if (value.id) {
          formData.symptoms.push(value.id);
        } else {
          formData.symptoms.push(value);
        }
      });

      formData.campaign_ids = [];
      this.lead.campaigns.forEach((value) => {
        if (value.id) {
          formData.campaign_ids.push(value.id);
        } else {
          formData.campaign_ids.push(value);
        }
      });

      formData.enquiry_date = this.lead.lead_details.enquiry_date;
      formData.follow_up_date = this.lead.lead_details.follow_up_date;
      formData.follow_up_time = this.lead.lead_details.follow_up_time;
      this.loading = true;
      await this.$store
        .dispatch("leads/UPDATE_LEAD", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            this.loading = false;
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
