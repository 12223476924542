import collection from "../../../api/collections/Branches";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    allList: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_BRANCH(state, data) {
      state.list = data;
    },
    SET_ALL_BRANCH(state, data) {
      state.allList = data;
    },
    SET_BRANCH_ITEM(state, data) {
      state.item = data;
    },
    RESET_BRANCH(state, data) {
      state.item = {};
    },
    SET_BRANCH_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_BRANCH({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_BRANCH", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_BRANCH",
        true
      );
    },
    async GET_ALL_BRANCH({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_ALL_BRANCH", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_ALL_BRANCH",
        true
      );
    },
    async CREATE_BRANCH({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_BRANCH",
        true
      );
      return data;
    },
    async SHOW_BRANCH({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_BRANCH_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_BRANCH",
        false
      );
      return data;
    },
    async UPDATE_BRANCH({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_BRANCH",
        true
      );

      return data;
    },
    async DELETE_BRANCH({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_BRANCH",
        true
      );
      return data;
    },
  },
};
