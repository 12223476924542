<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0 pt-3">
            <v-data-table
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center flex-row" v-if="item.name">
                  <span class="font-14 line-h-17 font-weight--bold text-black-100">
                    {{ item.name | capitalize }}
                  </span>
                </div>
                <div class="d-flex align-center mt-2">
                  <span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2">
                    {{ item.role_type_name }}
                  </span>
                </div>
              </template>
              <template v-slot:item.contact_no="{ item }">
                <span
                  class="font-14 line-h-17 font-weight--bold text-black-100"
                  v-if="item.contact_no !== null"
                >
                  {{ item.contact_no }}
                </span>
                <div
                  class="font-13 line-h-16 font-weight--semibold text-black-300"
                  v-if="item.email !== null"
                >
                  {{ item.email }}
                </div>
              </template>
              <template v-slot:item.branch_name="{ item }">
                <span
                  class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                  >{{ item.branch_name }}</span
                >
              </template>
              <template v-slot:item.permission_treatments="{ item }">
                <template v-if="item.permission_treatments.length > 0">
                  <template v-for="(val, index) in item.permission_treatments.slice(0, 1)">
                    <span
                      :key="index"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                    >
                      {{ val.name }}
                    </span>
                  </template>
                  <span
                    class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                    v-if="item.permission_treatments.length > 1"
                    >+{{ item.permission_treatments.length - 1 }}</span
                  >
                </template>
              </template>
              <template v-slot:item.permission_consultations="{ item }">
                <template v-if="item.permission_consultations.length > 0">
                  <template v-for="(val, index) in item.permission_consultations.slice(0, 1)">
                    <span
                      :key="index"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                    >
                      {{ val.name }}
                    </span>
                  </template>
                  <span
                    class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                    v-if="item.permission_consultations.length > 1"
                    >+{{ item.permission_consultations.length - 1 }}</span
                  >
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>
                <v-menu
                  max-width="200"
                  :nudge-width="200"
                  offset-y
                  left
                  content-class="user-action"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">View</a></li>
                      <li><a @click="archiveItem(item)" v-if="item.status !== 7">Archive</a></li>
                      <li><a @click="archiveItem(item)" v-if="item.status === 7">Unarchive</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import globalFn from "../../../../mixins/globalFn";
import sliderFn from "../../../../mixins/sliderFn";
import status from "../../../../mixins/status";
export default {
  name: "Clients",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        { text: "Name", align: "start", value: "name", align: "left", sortable: false },
        { text: "Contact Details", value: "contact_no", align: "left", sortable: false },
        { text: "Branch", value: "branch_name", align: "left", sortable: false },
        // { text: 'Treatments', value: 'permission_treatments', align: 'left', sortable: false },
        // { text: 'Consultations', value: 'permission_consultations', sortable: false, align: 'left' },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      staffParams: {
        // attributes: "permissionTreatments,permissionConsultations",
        attributes: "",
        search: "",
      },
      selectedItem: "",
      params:
        "?attributes=branch,permissionTreatments,permissionConsultations,permissionBranches,permissionModules,permissionWarehouses",
      message: "",
      action: "",
      actionText: "",
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    this.$root.$on("staffTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchStaff", this.getSearchStaff);
  },
  created() {},
  methods: {
    async getSearchStaff(data) {
      this.options.page = 1;
      this.items = [];
      if (data) {
        this.staffParams.search = data.search;
        await this.readTheDataFromApi();
      } else {
        this.staffParams.search = "";
        await this.readTheDataFromApi();
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store
        .dispatch("staff/GET_STAFF", {
          data: this.staffParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      this.loading = false;
    },
    async editItem(item) {
      await this.$store
        .dispatch("staff/SHOW_STAFF", { id: item.id, param: this.params })
        .then((res) => {
          this.openDrawer("add", "750", "settings/staff/edit");
        });
    },
    handleFunction(funName) {
      this[funName]();
    },
    async archiveItem(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.actionText = item.status === 7 ? "Unarchive" : "Archive";
      this.message = `Please confirm you want to ${this.actionText} this staff?`;
      this.action = "archiveStaff";
    },
    async archiveStaff() {
      this.selectedItem.status = this.selectedItem.status === 7 ? 1 : 7;
      await this.$store
        .dispatch("staff/UPDATE_STATUS", {
          id: this.selectedItem.id,
          status: this.selectedItem.status,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
