<template>
  <v-card flat>
    <v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
      <v-toolbar-title>
        <div class="form-title">Edit Contact</div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="navigationTab"
      :vertical="vertical"
      class="este__tabs"
      :class="responsiveClass"
    >
      <v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
      <template v-for="(item, index) in navigation">
        <v-tab :href="`#${item.slug}`">
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
    <div class="tab__content__wrapper">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="personal" :eager="true">
          <div class="content__text">
            <v-form ref="personalForm">
              <v-row dense class="e-row">
                <v-col cols="6" md="2" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt">Title</span>
                      <v-select
                        autocomplete="off"
                        :items="titles"
                        v-model="contact.title"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </div>
                </v-col>
                <v-col cols="6" md="10" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt"> Name </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        :rules="rules.name"
                        v-model="contact.name"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Social Handle </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    v-model="contact.social_handle"
                    flat
                    hide-details="auto"
                    class="solo-cust social-handle-input"
                  >
                    <template slot="append-outer">
                      <v-select
                        autocomplete="off"
                        v-model="contact.source_id"
                        :items="socialMedia"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="este-autocomplete-input"
                        hide-details="auto"
                      >
                        <template v-slot:selection="data">
                          <img
                            :src="`/images/${trimLowerCase(data.item.name)}.svg`"
                            height="20px"
                          />
                        </template>
                        <template v-slot:item="data">
                          <img
                            :src="`/images/${trimLowerCase(data.item.name)}.svg`"
                            height="20px"
                          />
                        </template>
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Date of birth </span>
                    <v-menu
                      v-model="date_of_birth"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          :value="dateUkFormat(contact.date_of_birth)"
                          solo
                          outlined
                          v-on="on"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                          append-icon="mdil-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="contact.date_of_birth"
                        no-title
                        @input="date_of_birth = false"
                      ></v-date-picker>
                    </v-menu>
                  </label>
                </v-col>
                <v-col cols="6" md="6" class="e-col">
                  <v-row>
                    <v-col cols="12">
                      <div>
                        <label>
                          <span class="label-txt">Gender</span>
                          <v-select
                            autocomplete="off"
                            :items="genders"
                            v-model="contact.gender"
                            solo
                            outlined
                            flat
                            class="solo-cust"
                            hide-details="auto"
                          >
                            <template slot="append">
                              <v-icon>mdi-chevron-down</v-icon>
                            </template>
                          </v-select>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Phone No. </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    v-model="contact.contact_no"
                    flat
                    hide-details="auto"
                    class="solo-cust"
                    append-icon="mdil-phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Email Address </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    v-model="contact.email"
                    solo
                    outlined
                    flat
                    hide-details="auto"
                    class="solo-cust"
                    append-icon="mdil-email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Address </span>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      v-model="contact.address_one"
                      hide-details="auto"
                      class="solo-cust mb-2"
                    ></v-text-field>
                    <v-text-field
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      v-model="contact.address_two"
                      hide-details="auto"
                      class="solo-cust mar-8"
                    ></v-text-field>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Town / City </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    flat
                    v-model="contact.city"
                    hide-details="auto"
                    class="solo-cust"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Post Code </span>
                  </label>
                  <v-text-field
                    autocomplete="off"
                    solo
                    outlined
                    flat
                    v-model="contact.postcode"
                    hide-details="auto"
                    class="solo-cust"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Preferred Branch: </span>
                    <v-select
                      autocomplete="off"
                      :items="branches"
                      :rules="rules.branch_id"
                      item-value="id"
                      item-text="branch"
                      v-model="contact.branch_id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                      @change="getStaffList"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt"> Responsible </span>
                    <v-select
                      autocomplete="off"
                      :items="staff"
                      v-model="contact.responsible_id"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Status </span>
                    <v-select
                      autocomplete="off"
                      :items="
                        SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('customer');
                          })
                        )
                      "
                      item-value="id"
                      item-text="name"
                      :rules="rules.status"
                      v-model="contact.status"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt"> Contact Type </span>
                  </label>
                  <v-radio-group
                    class="mt-2"
                    hide-details="auto"
                    mandatory
                    v-model="contact.user_type"
                    row
                    @change="
                      contact.user_type === 4
                        ? (isContactTypeClient = true)
                        : (isContactTypeClient = false)
                    "
                  >
                    <v-radio
                      :ripple="false"
                      off-icon="mdi-checkbox-blank-circle-outline"
                      on-icon="mdi-check-circle"
                      hide-details="auto"
                      label="Lead"
                      :value="3"
                    ></v-radio>
                    <v-radio
                      :ripple="false"
                      off-icon="mdi-checkbox-blank-circle-outline"
                      on-icon="mdi-check-circle"
                      hide-details="auto"
                      label="Client"
                      :value="4"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <template v-if="isContactTypeClient">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="">
                    <label>
                      <span class="label-txt"> Client Satisfaction: </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="12" class="e-col">
                    {{ satisfaction }}
                    <template v-for="(item, index) in satisfaction">
                      <div
                        :class="[
                          'emoji-wrap',
                          `${lowerCase(item.name)}-emoji`,
                          { active: activeIndex === index },
                        ]"
                        @click="setActive(item, index)"
                        :key="item.name"
                      >
                        <div class="icon">
                          <img :src="`${item.image}`" />
                        </div>
                        <div class="text">{{ item.name }}</div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <p class="sub-title mb-4">Contact Preferences</p>
                    <label>
                      <span class="label-txt"> Receive SMS </span>
                    </label>
                    <v-radio-group
                      class="mt-2"
                      hide-details="auto"
                      mandatory
                      v-model="contact.is_receive_sms"
                      row
                    >
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="Yes"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="No"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="disabled"
          @click="update"
          rounded
          x-large
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status";
export default {
  mixins: [globalFn, status],
  data: () => ({
    navigationTab: null,
    disabled: false,
    isContactTypeClient: false,
    navigation: [
      {
        name: "Contact Details",
        icon: "mdil-account",
        slug: "personal",
        module: "personal",
        form: true,
      },
    ],
    activeIndex: null,
    rules: {
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      status: [],
      comment: [(v) => !!v || "Please enter comment"],
      source: [(v) => !!v || "Please select source"],
    },
    genders: ["Male", "Female", "Others"],
    staff: [],
  }),
  computed: {
    responsiveClass() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? "lg"
        : "md";
    },
    vertical() {
      return this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl"
        ? true
        : false;
    },
    contact() {
      return this.$store.state.contacts.item;
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
  },
  created() {
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("source/GET_SOURCE", "");
  },
  mounted() {
    this.getStaffList();
  },
  methods: {
    async update() {
      this.disabled = true;

      await this.$store
        .dispatch("contacts/UPDATE", this.contact)
        .then((response) => {
          if (response.result === "success") {
            this.disabled = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.callbackDisabled = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.callbackDisabled = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    loadData() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$root.$emit("refreshContact");
    },

    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: { branch: this.contact.branch_id }, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    setActive(item, index) {
      this.activeIndex = index;
      this.form.client_satisfaction = item.name.toLowerCase();
    },
  },
};
</script>
