import collection from "../../../api/collections/Forms/answers";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    answers: [],
    formDetails: null,
  },
  mutations: {
    SET_FORM_ANSWER(state, data) {
      state.types = data;
    },
    // SET_FORM_ANSWER_DETAILS(state, data) {
    //     state.formDetails = data;
    // },
  },
  actions: {
    async GET_FORM_ANSWER({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get();
          commit("SET_FORM_ANSWER", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_FORM_ANSWER",
        true
      );
    },
    // async SHOW_FORM_ANSWER({ commit, dispatch }, payload) {
    //     return await asyncMiddleware(
    //         async function () {
    //             const { data } = await collection.show(payload.id, payload);
    //             commit("SET_FORM_ANSWER_DETAILS", data.data);
    //             return data;
    //         },
    //         commit,
    //         dispatch,
    //         "SHOW_FORM_ANSWER",
    //         true
    //     );
    // },
    async CREATE_FORM_ANSWER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);

          return data;
        },
        commit,
        dispatch,
        "CREATE_FORM_ANSWER",
        true
      );
      return data;
    },
    async UPDATE_FORM_ANSWER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          if (data.result) {
            dispatch("GET_FORM_ANSWER");
          }
          return data;
        },
        commit,
        dispatch,
        "UPDATE_FORM_ANSWER",
        true
      );

      return data;
    },
    async DELETE_FORM_ANSWER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload.id);

          if (data.result) {
            dispatch("GET_FORM_ANSWER");
          }
          return data;
        },
        commit,
        dispatch,
        "DELETE_FORM_ANSWER",
        true
      );
      return data;
    },
  },
};
