import eventBus from "../../../eventBus.js";
export default {
  data: () => ({
    loading: false,
    enquiryMenu: false,
    followUpMenu: false,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    activeIndex: null,
    callbackDateMenu: false,
    callbackTimeMenu: false,
    followUpTimeMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    rules: {
      user_id: [(v) => !!v || "Please select client"],
      branch_id: [(v) => !!v || "Please select branch"],
    },
    clientParams: "?sort_by=name&sort_order=ASC",
    details: [],
    staff: [],
    reasonSearch: null,
    sourceSearch: null,
    suggestedTreatments: [],
    staffParams: {
      branch: "",
      type: "callback",
    },
    navigationTab: null,
    navigation: [
      {
        name: "Personal Details",
        icon: "mdil-account",
        slug: "personal",
        form: true,
      },
      {
        name: "Lead Details",
        icon: "mdil-star",
        slug: "lead",
        form: true,
      },
      {
        name: "Client Details",
        icon: "mdil-star",
        slug: "client",
        form: true,
      },
      {
        name: "Appointments",
        icon: "mdil-calendar",
        slug: "appointment",
        form: true,
      },
      {
        name: "Insights",
        icon: "mdil-chart-areaspline",
        slug: "insight",
        form: false,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        form: false,
      },
    ],
    contactChange: false,
    emailChange: false,
    socailHandle: false,
  }),
  watch: {
    "callback.user.social_handle": _.debounce(function () {
      if (this.lead.social_handle) {
        this.autoSelectSocialIcon();
      }
      this.socailHandle = true;
    }, 1000),
    "callback.user.contact_no"(val) {
      this.contactChange = true;
    },
    "callback.user.email"(val) {
      this.emailChange = true;
    },
    "callback.user.social_handle"(val) {
      this.socailHandle = true;
    },
  },
  mounted() {
    this.navigationTab = "callback";
    this.staffByBranch();
  },
  computed: {
    callback() {
      if (this.$store.state.callback.item) {
        if (!this.$store.state.callback.item.user.other_details) {
          this.$store.state.callback.item.user.other_details = {};
        }
        return this.$store.state.callback.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList;
      }
    },
    clients() {
      if (this.$store.state.clients.all) {
        return this.$store.state.clients.all;
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter((item) =>
          ["Facebook", "Instagram", "TikTok", "Snapchat"].includes(item.name)
        );
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("symptoms/GET_ALL_SYMPTOM", "");
    this.$store.dispatch("clients/GET_CLIENT_LIST", this.clientParams);
    this.$store.dispatch("source/GET_SOURCE", "");
    this.$store.dispatch("reasons/GET_ALL_REASON", "");
    this.$store.dispatch("complaints/GET_PRIORITY");
  },
  methods: {
    async autoSelectSocialIcon() {
      let isValid = this.isValidUrl(this.callback.user.social_handle);
      if (isValid) {
        let url = new URL(this.callback.user.social_handle);
        this.callback.user.source_id = this.getSourceId(url.origin);
      }
    },
    isValidUrl(_string) {
      const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      return matchPattern.test(_string);
    },
    getSourceId(url) {
      let id = 0;
      let self = this;
      this.socialMedia.some(function (el) {
        if (url.indexOf(el.name.toLowerCase()) != -1) {
          id = el.id;
        }
      });
      return id;
    },
    async removeService(item) {
      const index = this.callback.user.user_details.treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.callback.user.user_details.treatments.splice(index, 1);
      } else {
        const i = this.callback.user.user_details.treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.callback.user.user_details.treatments.splice(i, 1);
      }
    },
    async removeSymptoms(item) {
      const index = this.callback.user.user_details.symptoms.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.callback.user.user_details.symptoms.splice(index, 1);
      } else {
        const i = this.callback.user.user_details.symptoms.findIndex((x) => x === item.id);
        if (i >= 0) this.callback.user.user_details.symptoms.splice(i, 1);
      }
      await this.symptomChange();
    },
    async removeReason(item) {
      if (item.id) {
        const index = this.callback.user.user_details.reasons.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.callback.user.user_details.reasons.splice(index, 1);
        } else {
          const i = this.callback.user.user_details.reasons.findIndex((x) => x === item.id);
          if (i >= 0) this.callback.user.user_details.reasons.splice(i, 1);
        }
      } else {
        const index = this.callback.user.user_details.reasons.findIndex((x) => x.id === item);
        if (index >= 0) {
          this.callback.user.user_details.reasons.splice(index, 1);
        } else {
          const i = this.callback.user.user_details.reasons.findIndex((x) => x === item);
          if (i >= 0) this.callback.user.user_details.reasons.splice(i, 1);
        }
      }
    },
    async symptomChange() {
      const ids = [];
      this.callback.user.user_details.symptoms.forEach((value) => {
        if (value.id) {
          ids.push({ id: value.id });
        } else {
          ids.push(value);
        }
      });
      await this.$store
        .dispatch("symptoms/GET_SUGGESTED_TREATMENTS", { symptoms: ids })
        .then((response) => {
          if (response.result === "success") {
            this.suggestedTreatments = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    async suggTreat(item) {
      let exists = this.callback.user.user_details.treatments.some((el) => el.id === item.id)
        ? true
        : this.callback.user.user_details.treatments.some((el) => el === item.id)
        ? true
        : false;
      if (!exists) {
        this.callback.user.user_details.treatments.push(item.id);
      } else {
        const index = this.callback.user.user_details.treatments.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          this.callback.user.user_details.treatments.splice(index, 1);
        } else {
          const i = this.callback.user.user_details.treatments.findIndex((x) => x === item.id);
          if (i >= 0) this.callback.user.user_details.treatments.splice(i, 1);
        }
      }
    },
    async staffByBranch() {
      this.staff = [];
      this.staffParams.branch = this.callback.branch_id;
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      eventBus.$emit("StatsRefresh");
      this.$root.$emit("updateGlobalSearch", "");
      this.$root.$emit("callbacksTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    setActive(item, index) {
      this.activeIndex = index;
      this.callback.client_satisfaction = item.name.toLowerCase();
    },
    async update() {
      let formData = {};
      formData.id = this.callback.id;
      formData.user_id = this.callback.user_id;
      formData.callback_date = this.callback.callback_date;
      formData.callback_time = this.callback.callback_time;
      formData.branch_id = this.callback.branch_id;
      formData.consultant_id = this.callback.consultant_id;
      formData.status = this.callback.status;
      formData.urgency = this.callback.urgency;
      formData.treatment_id = [];
      this.callback.treatments.forEach((value) => {
        if (value.id) {
          formData.treatment_id.push(value.id);
        } else {
          formData.treatment_id.push(value);
        }
      });
      if (this.$refs.callbackForm.validate()) {
        this.loading = true;
        await this.updatePersonal();
        await this.$store
          .dispatch("callback/UPDATE_CALLBACK", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async updatePersonal() {
      let formData = {};
      formData.id = this.callback.user.id;
      formData.title = this.callback.user.title;
      formData.name = this.callback.user.name;
      formData.address_one = this.callback.user.address_one;
      formData.address_two = this.callback.user.address_two;
      formData.city = this.callback.user.city;
      formData.postcode = this.callback.user.postcode;
      formData.branch_id = this.callback.user.branch_id;
      if (this.contactChange) {
        formData.contact_no = this.callback.user.contact_no;
      }
      if (this.socailHandle) {
        formData.social_handle = this.callback.user.social_handle;
      }
      if (this.callback.user.email && this.emailChange) {
        formData.email = this.callback.user.email;
      }

      formData.status = this.callback.user.status;
      formData.responsible_id = this.callback.user.other_details.responsible_id;
      formData.source_id = this.callback.user.source_id;

      formData.service_recommended = [];
      this.callback.user.user_details.treatments.forEach((value) => {
        if (value.id) {
          formData.service_recommended.push(value.id);
        } else {
          formData.service_recommended.push(value);
        }
      });

      formData.symptoms = [];
      this.callback.user.user_details.symptoms.forEach((value) => {
        if (value.id) {
          formData.symptoms.push(value.id);
        } else {
          formData.symptoms.push(value);
        }
      });

      if (this.callback.user.user_type_name === "Lead") {
        formData.reasons = [];
        this.callback.user.user_details.reasons.forEach((value) => {
          if (value.id) {
            formData.reasons.push({ id: value.id });
          } else {
            formData.reasons.push(value);
          }
        });
        formData.enquiry_date = this.callback.user.other_details.enquiry_date;
        formData.follow_up_date = this.callback.user.other_details.follow_up_date;
        formData.follow_up_time = this.callback.user.other_details.follow_up_time;
      }

      if (this.callback.user.user_type_name === "Client") {
        formData.client_satisfaction = this.callback.user.client_satisfaction;
      }

      let action =
        this.callback.user.user_type_name === "Lead"
          ? "leads/UPDATE_LEAD"
          : "clients/UPDATE_CLIENT";
      this.loading = true;
      await this.$store
        .dispatch(action, formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
          } else if (response.result === "fail") {
            this.loading = false;
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
