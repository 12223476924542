import collection from "../../../api/collections/Audit";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {},
  mutations: {
    SET_AUDIT(state, data) {
      state.list = data;
    },
  },
  actions: {
    async GET_AUDIT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload) {
            payload = "/?";
          }
          const { data } = await collection.get(payload);
          return data;
        },
        commit,
        dispatch,
        "GET_AUDIT",
        true
      );
      return data;
    },

    async AUDIT_HISTORY({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.auditHistory(payload);
          return data;
        },
        commit,
        dispatch,
        "AUDIT_HISTORY",
        true
      );
      return data;
    },
  },
};
