export default {
  data: () => ({
    widgets: [
      {
        name: "Urgent",
        active: false,
        slug: "urgent",
        svg: "/images/icons/duotune/general/mail-open.svg",
      },
      {
        name: "Complaints",
        active: false,
        slug: "complaint",
        svg: "/images/icons/duotune/general/user.svg",
        module: "complaint",
      },
      {
        name: "Advice & Info",
        active: false,
        slug: "advice_info",
        svg: "/images/icons/duotune/general/user.svg",
        module: "callback",
      },
      {
        name: "Overdue",
        active: false,
        slug: "overdue",
        svg: "/images/icons/duotune/general/chat.svg",
      },
    ],
  }),
  mounted() {
    this.loadCount();
  },
  methods: {
    async loadCount() {
      let params = {};
      params.callback_date_from = this.payload.filter.callback_date_from;
      params.callback_date_to = this.payload.filter.callback_date_to;
      params.callback_time_from = this.payload.filter.callback_time_from;
      params.callback_time_to = this.payload.filter.callback_time_to;
      params.date_raised_from = this.payload.filter.date_raised_from;
      params.date_raised_to = this.payload.filter.date_raised_to;
      params.next_action_date_from = this.payload.filter.next_action_date_from;
      params.next_action_date_to = this.payload.filter.next_action_date_to;
      params.status = this.payload.filter.status;
      params.branch = this.payload.filter.branch;
      params.source = this.payload.filter.source;
      params.source_blank = this.payload.filter.source_blank;
      params.assigned = this.payload.filter.assigned;
      params.assigned_to = this.payload.filter.assigned_to;
      let counts = {};
      await this.$store.dispatch("complaints/WIDGET_COUNT", params).then((res) => {
        counts = res.data;
      });
      this.widgets = this.widgets.map(function (el) {
        let item = Object.assign({}, el);
        item.count = counts.hasOwnProperty(el.slug) ? counts[el.slug] : 0;
        return item;
      });
    },
  },
};
