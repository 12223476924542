<template>
  <v-app>
    <router-view></router-view>
    <Notification></Notification>
    <RightSlider></RightSlider>
    <PopupModal></PopupModal>
  </v-app>
</template>
<script>
import Notification from "./Notification";
import RightSlider from "./RightSlider";
import PopupModal from "./PopupModal";
export default {
  name: "App",
  components: {
    Notification,
    RightSlider,
    PopupModal,
  },
};
</script>
<style></style>
