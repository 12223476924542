<template>
  <div class="d-flex align-center">
    <div class="mr-3">
      <v-icon color="grey-lighten-2" size="50">mdi-account-circle</v-icon>
    </div>
    <div>
      <div class="text-body-2 font-weight-semibold text-capitalize">{{ data.name }}</div>
      <div class="text-caption mt-1">
        <v-icon small>mdi-clock-outline</v-icon>
        {{ data.total_hour }}
      </div>
      <div class="text-caption mt-1">
        <v-icon small>mdi-clock-outline</v-icon>
        {{ data.total_shift }} Shifts
      </div>

      <v-menu offset-y v-model="menu" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="paint-btn" v-on="on" v-bind="attrs"
            ><v-icon>mdi-format-color-fill</v-icon></v-btn
          >
        </template>

        <v-color-picker
          hide-canvas
          hide-inputs
          hide-sliders
          hide-mode-switch
          mode="hex"
          show-swatches
          @input="updateColor"
        ></v-color-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "type"],

  data: () => ({
    menu: false,
    initialSet: 0, // v-color-picker initially runs the update event to update a default color
  }),
  methods: {
    updateColor(event) {
      // Staff and Machine not found error
      if (this.initialSet) {
        const dispatchName = this.type === "staff" ? "staff/UPDATE_COLOR" : "machines/UPDATE_COLOR";

        this.$store.dispatch(dispatchName, { id: this.data.id, color: event.hex }).then((res) => {
          if (res.result === "success") {
            this.$root.$emit("fetchRota");
            this.$store.dispatch("snackbar/SHOW", res);
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        });

        this.menu = false;
      } else this.initialSet = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.paint-btn {
  position: absolute;
  bottom: 5%;
  right: 5%;
}
</style>
