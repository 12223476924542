import collection from "../../../api/collections/ShiftTemplate";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
  },
  mutations: {
    SET_TEMPLATES(state, data) {
      state.list = data;
    },
    SET_TEMPLATES_ITEM(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);

          commit("SET_TEMPLATES", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET",
        true
      );
    },
    async SHOW({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.show(payload);

          commit("SET_TEMPLATES_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW",
        false
      );
      return data;
    },
    async CREATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);

          return data;
        },
        commit,
        dispatch,
        "CREATE",
        true
      );

      return data;
    },
    async UPDATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload.body);

          return data;
        },
        commit,
        dispatch,
        "UPDATE",
        true
      );

      return data;
    },
    async DELETE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);

          return data;
        },
        commit,
        dispatch,
        "DELETE",
        true
      );

      return data;
    },
  },
};
