<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Medical/Consent Forms</div>
        <v-divider class="py-5"></v-divider>
        <v-text-field
          flat
          prepend-inner-icon="mdi-magnify"
          autocomplete="off"
          solo
          hide-details
          class="search-input"
          @input="isTyping = true"
          v-model="search"
          placeholder="Search Form"
        >
        </v-text-field>
        <div class="tab-content-wrapper">
          <v-data-table
            class="results-table"
            item-key="id"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            sort-by="updated_at"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
            :server-items-length="totalCount"
          >
            <template v-slot:item.type="{ item }">
              <span
                class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                v-if="item.medical_form_type"
              >
                {{ item.medical_form_type.name | capitalize }}</span
              >
            </template>
            <template v-slot:item.description="{ item }">
              <div
                class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor width-400"
              >
                <span class="font-14 line-h-20 font-weight--regular text-black-100 ml-2 py-3">
                  {{ item.description }}
                </span>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <div class="logout-block">
                  <ul>
                    <li><a @click="previewItem(item)">Sign</a></li>
                  </ul>
                </div>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Close
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
export default {
  name: "Clients",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [
        {
          text: "Name",
          value: "title",
          align: "left",
          sortable: false,
        },
        {
          text: "Type",
          value: "type",
          align: "left",
          sortable: false,
        },
        { text: "Created", value: "created_at", sortable: false },
        {
          text: "actions",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      formParams: {
        // attributes: "permissionTreatments,permissionConsultations",
        attributes: "",
        search: "",
      },
      selectedItem: "",
      params: "?attributes=medicalFormType",
      message: "",
      action: "",
      actionText: "",
    };
  },
  computed: {
    formList() {
      return this.$store.state.form.forms;
    },
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.getSearchForm();
      }
    },
    formList(newVal) {
      this.items = newVal.data;
      this.totalCount = newVal.total;
    },
  },
  mounted() {
    this.readTheDataFromApi();
  },
  created() {},
  methods: {
    async getSearchForm() {
      this.options.page = 1;
      this.items = [];
      this.formParams.search = this.search;
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("form/GET_FORM", {
        params: `${this.params}&page=${this.page}&per_page=${this.itemsPerPage}&search=${this.formParams.search}`,
      });
      this.loading = false;
    },
    async previewItem(item) {
      await this.$store
        .dispatch("form/SHOW_FORM", {
          id: item.id,
          params: "?attributes=medicalFormType,elements.answers",
        })
        .then((res) => {
          this.openDrawer("add", "750", "consent/submitForm");
        });
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
  },
};
</script>
