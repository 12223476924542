<template>
  <div>
    <div class="fill-height slider-cont">
      <v-card class="vertical-slide fill-height">
        <v-card-text class="pa-0">
          <v-row dense class="e-row">
            <v-col cols="6" md="3" class="e-col pa-0 ma-0">
              <v-toolbar class="left-toolbar" flat> </v-toolbar>
              <div class="nav-lists tab-drawer fill-height ma-0">
                <v-list dense>
                  <template v-for="(m, index) in tabMenu">
                    <v-list-item
                      class="menu"
                      :class="paneTab === m.slug ? 'active' : ''"
                      :key="index"
                      @click="showTab(m.slug)"
                    >
                      <v-list-item-action class="menu-icon">
                        <v-icon>{{ m.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="menu-txt">
                        <v-list-item-title>
                          {{ m.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </v-col>
            <v-col cols="6" md="9" class="e-col pa-0 ma-0">
              <v-toolbar class="right-toolbar" flat>
                <div class="form-title">Add Appointment</div>
              </v-toolbar>
              <div class="slide-right">
                <div class="form-wrap px-10">
                  <div class="side-pane" v-show="clientUnit">
                    <v-form ref="form">
                      <div class="tab-content-wrapper">
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Find client: </span>
                            </label>
                            <div class="mt-2 mb-4" v-if="selectedAppointment">
                              <v-chip class="este-chip tag-chip px-3 mb-2">
                                <span v-if="selectedAppointment.name"
                                  >{{ selectedAppointment.name }}
                                </span>
                                <span v-if="selectedAppointment.name === null"
                                  >{{ selectedAppointment.social_handle }}
                                </span>
                                <span v-if="selectedAppointment.contact_no"
                                  >/ {{ selectedAppointment.contact_no }}
                                </span>
                                <span v-if="selectedAppointment.email"
                                  >/ {{ selectedAppointment.email }}
                                </span>
                                <v-icon
                                  color="primary"
                                  class="ml-2"
                                  small
                                  @click.stop="removeAppointmentedUser(item)"
                                >
                                  mdi-close
                                </v-icon>
                              </v-chip>
                            </div>
                            <v-text-field
                              flat
                              rounded
                              prepend-inner-icon="mdi-magnify"
                              :rules="rules.user_id"
                              autocomplete="off"
                              label="Search for a client"
                              class="solo-cust"
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              solo
                              hide-details="auto"
                              @input="isTyping = true"
                              v-model="search"
                              :loading="loading"
                            >
                            </v-text-field>
                            <ul
                              class="contact--list"
                              v-if="search.length > 3"
                              :class="appointments.length > 20 ? 'height-300' : ''"
                            >
                              <li v-for="(item, index) in appointments" :key="index">
                                <a
                                  @click="selectContact(item)"
                                  class="d-flex align-center flex-row cursor"
                                >
                                  <span
                                    class="font-12 line-h-15 font-weight--regular text-black-100 ml-3"
                                    v-if="item"
                                  >
                                    <span v-if="item.name">{{ item.name }} </span>
                                    <span v-if="item.name === null">{{ item.social_handle }} </span>
                                    <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                                    <span v-if="item.email">/ {{ item.email }} </span>
                                  </span>
                                  <span
                                    class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                                    :class="
                                      item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                                    "
                                  >
                                    {{ item.user_type_name === "Lead" ? "L" : "C" }}
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </v-col>
                        </v-row>

                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Treatment / Service: </span>
                            </label>
                            <v-autocomplete
                              v-model="form.treatment_id"
                              :items="treatments"
                              item-text="name"
                              item-value="id"
                              multiple
                              chips
                              autocomplete="off"
                              solo
                              outlined
                              flat
                              hide-details="auto"
                              class="este-autocomplete-input"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="6" class="e-col">
                            <label>
                              <span class="label-txt"> Appointment Date: </span>
                              <v-menu
                                v-model="appointmentDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    autocomplete="off"
                                    :value="dateUkFormat(form.appointment_date)"
                                    solo
                                    outlined
                                    v-on="on"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                    append-icon="mdil-calendar"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="form.appointment_date"
                                  no-title
                                  @input="appointmentDateMenu = false"
                                ></v-date-picker>
                              </v-menu>
                            </label>
                          </v-col>
                          <v-col cols="6" md="6" class="e-col">
                            <v-row>
                              <v-col cols="6">
                                <label>
                                  <span class="label-txt">Appointment Time:</span>
                                  <v-menu
                                    ref="appointmentFromTimeMenu"
                                    v-model="appointmentFromTimeMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="form.appointment_from_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        outlined
                                        flat
                                        hide-details="auto"
                                        solo
                                        class="solo-cust"
                                        v-model="form.appointment_from_time"
                                        label="From"
                                        append-icon="mdil-clock"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-time-picker
                                      :allowed-minutes="allowedStep"
                                      no-title
                                      ampm-in-title
                                      format="24hr"
                                      v-if="appointmentFromTimeMenu"
                                      v-model="form.appointment_from_time"
                                      full-width
                                      @click:minute="
                                        $refs.appointmentFromTimeMenu.save(
                                          form.appointment_from_time
                                        )
                                      "
                                    ></v-time-picker>
                                  </v-menu>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label>
                                  <v-menu
                                    ref="appointmentToTimeMenu"
                                    v-model="appointmentToTimeMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="form.appointment_to_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        outlined
                                        flat
                                        hide-details="auto"
                                        solo
                                        class="solo-cust mt-5"
                                        v-model="form.appointment_to_time"
                                        label="To"
                                        append-icon="mdil-clock"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-time-picker
                                      :allowed-minutes="allowedStep"
                                      no-title
                                      ampm-in-title
                                      format="24hr"
                                      v-if="appointmentToTimeMenu"
                                      v-model="form.appointment_to_time"
                                      full-width
                                      @click:minute="
                                        $refs.appointmentToTimeMenu.save(form.appointment_to_time)
                                      "
                                    ></v-time-picker>
                                  </v-menu>
                                </label>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Preferred Branch: </span>
                              <v-select
                                autocomplete="off"
                                :items="branches"
                                item-value="id"
                                item-text="branch"
                                :rules="rules.branch_id"
                                v-model="form.branch_id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                                @change="staffByBranch"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </label>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Select Consultant </span>
                              <v-select
                                autocomplete="off"
                                :items="staff"
                                v-model="form.consultant_id"
                                item-text="name"
                                item-value="id"
                                solo
                                outlined
                                flat
                                class="solo-cust"
                                hide-details="auto"
                              >
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </label>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Machines </span>
                            </label>

                            <v-select
                              autocomplete="off"
                              item-value="id"
                              :items="machines"
                              v-model="form.machine"
                              item-text="name"
                              solo
                              outlined
                              flat
                              class="solo-cust"
                              hide-details="auto"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Appointment Type </span>
                            </label>
                            <v-select
                              autocomplete="off"
                              :items="appointmentType"
                              v-model="form.appointment_type"
                              solo
                              outlined
                              flat
                              class="solo-cust"
                              hide-details="auto"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row class="e-row">
                          <v-col cols="12" md="12" class="e-col">
                            <label>
                              <span class="label-txt"> Status </span>
                            </label>
                            <v-select
                              autocomplete="off"
                              :items="
                                SortAtoZ(
                                  statusTags.filter(function (el) {
                                    return el.slug.includes('appointment');
                                  })
                                )
                              "
                              v-model="form.status"
                              :rules="rules.status"
                              item-text="name"
                              item-value="id"
                              solo
                              outlined
                              flat
                              class="solo-cust"
                              hide-details="auto"
                            >
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </div>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn
                      class="cancel-btn btn-gap px-15"
                      depressed
                      rounded
                      outlined
                      @click="close"
                      x-large
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="b-blue"
                      depressed
                      @click="addAppointment"
                      :disabled="disabled"
                      rounded
                      x-large
                    >
                      Add Appointment
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import status from "../../../mixins/status.js";
import sidePaneMenu from "../../../mixins/sidePaneMenu.js";
import add from "./add.js";
export default {
  mixins: [globalFn, status, add, sidePaneMenu],
  data: () => ({
    tabMenu: [
      {
        name: "Client Details",
        icon: "mdil-account",
        slug: "client",
      },
    ],
  }),
  mounted() {
    this.paneTab = "client";
    this.clientUnit = true;
  },
};
</script>
