<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Edit Staff</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#personal">Personal Details</v-tab>
          <!-- <v-tab href="#treatments">Treatments</v-tab> -->
          <v-tab href="#permissions">Permissions</v-tab>
          <!-- <v-tab href="#activity" @change="handleFunction('loadAudit')">Activity</v-tab> -->
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="personal" eager>
            <v-form ref="personalForm">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="6" md="2" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt">Title</span>
                        <v-select
                          autocomplete="off"
                          :items="titles"
                          :rules="rules.title"
                          v-model="staff.title"
                          solo
                          outlined
                          flat
                          class="solo-cust"
                          hide-details="auto"
                        >
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </label>
                    </div>
                  </v-col>
                  <v-col cols="6" md="10" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Name </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          v-model="staff.name"
                          :rules="rules.name"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Phone No. </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        v-model="staff.contact_no"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-phone"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Email Address </span>
                      <v-text-field
                        autocomplete="off"
                        v-model="staff.email"
                        :rules="rules.email"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-email"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Address </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="staff.address_one"
                        hide-details="auto"
                        class="solo-cust mb-2"
                      ></v-text-field>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="staff.address_two"
                        hide-details="auto"
                        class="solo-cust mar-8"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Town / City </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="staff.city"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt"> Post Code </span>
                          <v-text-field
                            autocomplete="off"
                            solo
                            outlined
                            flat
                            v-model="staff.postcode"
                            hide-details="auto"
                            class="solo-cust"
                          ></v-text-field>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        :rules="rules.branch_id"
                        item-value="id"
                        item-text="branch"
                        v-model="staff.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Role: </span>
                      <v-select
                        autocomplete="off"
                        :items="roles"
                        :rules="rules.role_type"
                        item-value="id"
                        item-text="name"
                        v-model="staff.role_type"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                  <v-col cols="6" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Commission % </span>
                      <v-text-field
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        v-model="staff.commission"
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" md="6" class="e-col">
                    <div>
                      <label>
                        <span class="label-txt"> Pabau ID </span>
                        <v-text-field
                          autocomplete="off"
                          solo
                          outlined
                          v-model="staff.pabau_id"
                          flat
                          hide-details="auto"
                          class="solo-cust"
                        ></v-text-field>
                      </label>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="treatments" eager>
            <v-form ref="treatmentsForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatments </span>
                    </label>
                    <v-autocomplete
                      v-model="staff.skill_treatments"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="treatmentsSelectToggle">
                          <v-list-item-action>
                            <v-checkbox v-model="treatmentSelect"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ staff.skill_treatments.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Consultations </span>
                    </label>
                    <v-autocomplete
                      v-model="staff.skill_consultations"
                      :items="consultations"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="consultationsSelectToggle">
                          <v-list-item-action>
                            <v-checkbox v-model="consultationsSelect"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ staff.skill_consultations.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="permissions" eager>
            <v-form ref="permissionsForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatments </span>
                    </label>
                    <v-autocomplete
                      v-model="staff.permission_treatments"
                      :items="perTreatments"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="perTreatmentsSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="perTreatmentsSelect"
                              @input="perTreatmentsSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ staff.permission_treatments.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Consultations </span>
                    </label>
                    <v-autocomplete
                      v-model="staff.permission_consultations"
                      :items="perConsultations"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="perConsultationsSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="perConsultationsSelect"
                              @input="perConsultationsSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ staff.permission_consultations.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Branches </span>
                    </label>
                    <v-autocomplete
                      :disabled="noPermission"
                      v-model="staff.permission_branches"
                      :items="branches"
                      item-text="branch"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="branchChange">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="brancAllSelect"
                              @input="branchChange"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.branch }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ staff.permission_branches.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Warehouse </span>
                    </label>
                    <v-autocomplete
                      :disabled="noPermission"
                      v-model="staff.permission_warehouses"
                      :items="warehouses"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="warehouseChange">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="warehouseAllSelect"
                              @input="warehouseChange"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                          (+{{ form.permission_warehouses.length - 1 }} others)
                        </span>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Can see All Branch Data </span>
                    </label>
                    <v-radio-group hide-details="auto" mandatory v-model="staff.no_branch_data" row>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="Yes"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        off-icon="mdi-checkbox-blank-circle-outline"
                        on-icon="mdi-check-circle"
                        hide-details="auto"
                        label="No"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Modules </span>
                    </label>
                    <v-autocomplete
                      :disabled="noPermission"
                      v-model="staff.permissionModules"
                      :items="modules"
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="moduleSelectToggle">
                          <v-list-item-action>
                            <v-simple-checkbox
                              :ripple="false"
                              :value="moduleSelect"
                              @input="moduleSelectToggle"
                            >
                            </v-simple-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="activity">
            <div class="tab-content-wrapper">
              <v-form ref="commentForm">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="">
                    <label>
                      <span class="label-txt"> Leave Comment </span>
                    </label>
                    <v-text-field
                      :rules="rules.comment"
                      v-model="comment"
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="solo-cust"
                      placeholder="Add a comment"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-row dense class="e-row mt-5">
                <v-col cols="6" md="6" class="e-col"> </v-col>
                <v-col cols="6" md="6" class="e-col">
                  <v-btn class="b-act-comment" depressed rounded x-large @click="addComment">
                    Add Comment
                  </v-btn>
                  <v-btn
                    class="b-act-upload mr-2"
                    depressed
                    rounded
                    outlined
                    x-large
                    @click="openFilePicker"
                  >
                    Upload File
                  </v-btn>
                  <input
                    type="file"
                    multiple
                    id="filePicker"
                    @change="onFileChange($event.target.name, $event.target.files)"
                    style="display: none"
                  />
                </v-col>
              </v-row>
              <v-row dense class="e-row">
                <v-col cols="12" md="12" class="e-col" v-if="audit.length > 0">
                  <label>
                    <span class="label-txt pb-5"> Latest Updates </span>
                  </label>
                </v-col>
                <v-col cols="12" md="12" class="e-col">
                  <template v-if="auditLoading">
                    <v-skeleton-loader class="px-10" type="article"></v-skeleton-loader>
                  </template>
                  <template v-else>
                    <v-timeline align-top dense class="pt-0 ml-n10">
                      <v-timeline-item
                        class="doc-timeline"
                        v-for="(doc, index) in docs"
                        :key="index"
                        color="secondary"
                        x-small
                        fill-dot
                      >
                        <v-row dense class="e-row">
                          <v-col cols="12" md="12" class="e-col doc-item">
                            <div class="date">
                              {{ doc.created_at | moment("MMMM Do YYYY") }} at
                              {{ doc.created_at | moment("h:mm:ss a") }}
                            </div>
                            <div class="file-section">
                              <div class="caption mb-0">
                                <span class="float-right download-text"
                                  >Download files ({{ doc.filesize }} in total)</span
                                >
                              </div>
                              <v-avatar class="doc-img">
                                <v-img
                                  src="/images/pdf.png"
                                  class="img"
                                  v-if="doc.filetype === 'pdf'"
                                  @click="previewDoc(doc)"
                                >
                                  <div class="content-overlay">
                                    <img src="/images/zoom-in.svg" />
                                  </div>
                                </v-img>
                                <v-img
                                  :src="`${doc.url}`"
                                  class="img"
                                  v-else
                                  @click="previewDoc(doc)"
                                >
                                  <div class="content-overlay">
                                    <img src="/images/zoom-in.svg" />
                                  </div>
                                </v-img>
                              </v-avatar>
                            </div>
                            <div class="added-user">
                              <span class="float-left user"> By {{ doc.uploaded_by_name }}</span>
                              <span class="float-right user">
                                <v-icon color="rgba(0, 0, 0, 0.6)" size="16" class="mt-n1"
                                  >mdil-delete</v-icon
                                >
                                <span class="delete">Delete Files</span>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                    <v-timeline align-top dense class="pt-0 ml-n10">
                      <template v-for="(item, index) in audit">
                        <template v-if="item.new_values">
                          <template v-for="(value, key) in item.new_values">
                            <v-timeline-item
                              class="doc-timeline"
                              v-if="value !== null"
                              :key="index.key"
                              color="secondary"
                              x-small
                              fill-dot
                            >
                              <v-row dense class="e-row">
                                <v-col cols="12" md="12" class="e-col doc-item">
                                  <div class="date">
                                    {{ item.created_at | moment("MMMM Do YYYY") }} at
                                    {{ item.created_at | moment("h:mm:ss a") }}
                                  </div>
                                  <div
                                    class="file-section"
                                    :class="key === 'status' ? 'status' : ''"
                                  >
                                    <div class="caption">
                                      <span class="float-left">
                                        <p class="type">
                                          {{ key.replace(/_/g, " ") | capitalize }} changed to
                                          {{ value | capitalize }}
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="added-user">
                                    <span class="float-left user"> By {{ item.owner_name }}</span>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-timeline-item>
                          </template>
                        </template>
                      </template>
                    </v-timeline>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="update"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="showPreviewDialog" persistent max-width="900">
      <v-card class="preview-image">
        <v-card-text class="pa-0">
          <ul class="action">
            <li>
              <a @click.stop="deleteDocs(selectedPreview)">
                <img src="/images/delete.svg" alt="Delete" />
                <div class="text">Delete</div>
              </a>
            </li>
            <li>
              <a @click="showPreviewDialog = false">
                <img src="/images/prev-close.svg" alt="Close" />
                <div class="text">Close</div>
              </a>
            </li>
          </ul>
          <template v-if="selectedPreview.filetype === 'pdf'">
            <embed
              :src="`${selectedPreview.url}#toolbar=0&navpanes=0`"
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </template>
          <template v-else>
            <div class="outer">
              <div class="inner">
                <img class="preview" :src="`${selectedPreview.url}`" alt="" />
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    comment: "",
    docs: [],
    loading: false,
    noPermission: false,
    auditLoading: false,
    brancAllSelect: false,
    warehouseAllSelect: false,
    selectedPreview: "",
    showPreviewDialog: false,
    moduleSelect: false,
    perTreatmentsSelect: false,
    perConsultationsSelect: false,
    treatmentSelect: false,
    consultationsSelect: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    titles: ["Mr", "Mrs", "Dr", "Miss"],
    form: {
      title: "",
      name: "",
      address_one: "",
      address_two: "",
      city: "",
      postcode: "",
      contact_no: "",
      branch_id: "",
      email: "",
      skill_consultations: [],
      permission_consultations: [],
      skill_treatments: [],
      permission_treatments: [],
      permission_branches: [],
      permission_modules: [],
      status: "",
      commission: "",
      role_type: "",
      job_title: "",
    },
    rules: {
      title: [(v) => !!v || "Please select title"],
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      role_type: [(v) => !!v || "Please select role"],
    },
    audit: [],
  }),
  computed: {
    staff() {
      if (this.$store.state.staff.item) {
        return this.$store.state.staff.item;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    perTreatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    consultations() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    perConsultations() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    warehouses() {
      if (this.$store.state.warehouse.all) {
        return this.$store.state.warehouse.all;
      }
    },
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("warehouse/LIST", "");
  },
  mounted() {
    this.permissionCheck();
  },
  methods: {
    permissionCheck() {
      if (this.user.user_type_name === "Admin") {
        this.noPermission = false;
      } else {
        this.noPermission = ["Branch Manager", "Team Member", "Consultant"].includes(
          this.user.role_type_name
        );
      }
    },
    handleFunction(funName) {
      this[funName]();
    },
    async loadAudit() {
      this.auditLoading = true;
      await this.$store
        .dispatch("audit/GET_AUDIT", `?filters=[{"user_id":[{"equ":${this.staff.id}}]}]`)
        .then((res) => {
          this.audit = res.data.data;
          this.auditLoading = false;
        });
      this.loadDocs();
    },
    branchChange() {
      this.brancAllSelect = !this.brancAllSelect;
      if (this.brancAllSelect === true) {
        this.staff.permission_branches = [];
        this.branches.forEach((value) => {
          this.staff.permission_branches.push(parseInt(value.id));
        });
      } else {
        this.staff.permission_branches = [];
      }
    },
    warehouseChange() {
      this.warehouseAllSelect = !this.warehouseAllSelect;
      if (this.warehouseAllSelect === true) {
        this.staff.permission_warehouses = [];
        this.warehouses.forEach((value) => {
          this.staff.permission_warehouses.push(parseInt(value.id));
        });
      } else {
        this.staff.permission_warehouses = [];
      }
    },
    moduleSelectToggle() {
      this.moduleSelect = !this.moduleSelect;
      if (this.moduleSelect === true) {
        this.staff.permissionModules = [];
        this.modules.forEach((value) => {
          this.staff.permissionModules.push(value.id);
        });
      } else {
        this.staff.permissionModules = [];
      }
    },
    perTreatmentsSelectToggle() {
      this.perTreatmentsSelect = !this.perTreatmentsSelect;
      if (this.perTreatmentsSelect === true) {
        this.staff.permission_treatments = [];
        this.perTreatments.forEach((value) => {
          this.staff.permission_treatments.push(parseInt(value.id));
        });
      } else {
        this.staff.permission_treatments = [];
      }
    },
    perConsultationsSelectToggle() {
      this.perConsultationsSelect = !this.perConsultationsSelect;
      if (this.perConsultationsSelect === true) {
        this.staff.permission_consultations = [];
        this.perConsultations.forEach((value) => {
          this.staff.permission_consultations.push(parseInt(value.id));
        });
      } else {
        this.staff.permission_consultations = [];
      }
    },
    treatmentsSelectToggle() {
      this.treatmentSelect = !this.treatmentSelect;
      if (this.treatmentSelect === true) {
        this.staff.skill_treatments = [];
        this.treatments.forEach((value) => {
          this.staff.skill_treatments.push(parseInt(value.id));
        });
      } else {
        this.staff.skill_treatments = [];
      }
    },
    consultationsSelectToggle() {
      this.consultationsSelect = !this.consultationsSelect;
      if (this.consultationsSelect === true) {
        this.staff.skill_consultations = [];
        this.consultations.forEach((value) => {
          this.staff.skill_consultations.push(parseInt(value.id));
        });
      } else {
        this.staff.skill_consultations = [];
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("staffTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async update() {
      let formData = {};
      formData.id = this.staff.id;
      formData.title = this.staff.title;
      formData.name = this.staff.name;
      formData.address_one = this.staff.address_one;
      formData.address_two = this.staff.address_two;
      formData.city = this.staff.city;
      formData.postcode = this.staff.postcode;
      formData.contact_no = this.staff.contact_no;
      formData.branch_id = this.staff.branch_id;
      formData.email = this.staff.email;
      formData.status = this.staff.status;
      formData.commission = this.staff.commission;
      formData.role_type = this.staff.role_type;
      formData.pabau_id = this.staff.pabau_id;
      formData.no_branch_data = this.staff.no_branch_data;

      // formData.skill_treatments = []
      // this.staff.skill_treatments.forEach(value => {
      //   if (value.id) {
      //     formData.skill_treatments.push(value.id);
      //   } else {
      //     formData.skill_treatments.push(value);
      //   }
      // });

      // formData.skill_consultations = []
      // this.staff.skill_consultations.forEach(value => {
      //   if (value.id) {
      //     formData.skill_consultations.push(value.id);
      //   } else {
      //     formData.skill_consultations.push(value);
      //   }
      // });

      formData.permission_consultations = [];
      this.staff.permission_consultations.forEach((value) => {
        if (value.id) {
          formData.permission_consultations.push(value.id);
        } else {
          formData.permission_consultations.push(value);
        }
      });

      formData.permission_treatments = [];
      this.staff.permission_treatments.forEach((value) => {
        if (value.id) {
          formData.permission_treatments.push(value.id);
        } else {
          formData.permission_treatments.push(value);
        }
      });

      formData.permission_branches = [];
      this.staff.permission_branches.forEach((value) => {
        if (value.id) {
          formData.permission_branches.push(value.id);
        } else {
          formData.permission_branches.push(value);
        }
      });

      formData.permission_warehouses = [];
      this.staff.permission_warehouses.forEach((value) => {
        if (value.id) {
          formData.permission_warehouses.push(value.id);
        } else {
          formData.permission_warehouses.push(value);
        }
      });

      formData.permission_modules = [];
      this.staff.permissionModules.forEach((value) => {
        if (value) {
          formData.permission_modules.push(value);
        }
      });
      if (this.$refs.personalForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("staff/UPDATE_STAFF", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    async addComment() {
      if (this.$refs.commentForm.validate()) {
        await this.$store
          .dispatch("leads/ADD_COMMENT", { lead_id: this.staff.id, comment: this.comment })
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadAudit();
              this.$refs.commentForm.reset();
            } else if (res.result === "fail") {
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
    openFilePicker() {
      document.getElementById("filePicker").click();
    },
    async onFileChange(fieldName, files) {
      let formData = new FormData();
      formData.append("staff_id", this.staff.id);

      for (let file of files) {
        formData.append("docs[]", file, file.name);
      }
      try {
        await this.$store
          .dispatch("staff/UPLOAD_DOCS", formData)
          .then((res) => {
            if (res.result === "success") {
              this.loading = false;
              this.loadDocs();
            } else if (res.result === "fail") {
              this.loading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } catch (e) {
        console.log("error: ", e);
        this.loading = false;
        this.cancel();
      }
    },
    async loadDocs() {
      this.auditLoading = true;
      try {
        await this.$store
          .dispatch("docs/GET_DOCS", `?id=${this.staff.id}&type=staff&sort_by=id&sort_order=desc`)
          .then((res) => {
            if (res.result === "success") {
              this.auditLoading = false;
              this.docs = res.data;
            } else if (res.result === "fail") {
              this.auditLoading = false;
              res.type = "error";
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            this.auditLoading = false;
            console.log(err);
          });
      } catch (e) {
        console.log("error: ", e);
        this.auditLoading = false;
        this.cancel();
      }
    },
    async previewDoc(doc) {
      this.selectedPreview = doc;
      this.showPreviewDialog = true;
    },
    async deleteDocs(item) {
      this.loading = true;
      let ids = [];
      ids.push(item.id);
      await this.$store
        .dispatch("docs/DELETE_DOCS", { documents: ids })
        .then((res) => {
          if (res.result === "success") {
            this.loading = false;
            this.showPreviewDialog = false;
            this.loadDocs();
          } else if (res.result === "fail") {
            this.loading = false;
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
