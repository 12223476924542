<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Edit Treatment</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Treatment Name </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  :rules="rules.name"
                  v-model="treatment.name"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Category </span>
                </label>
                <v-select
                  autocomplete="off"
                  solo
                  outlined
                  v-model="treatment.category"
                  :rules="rules.category"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Period </span>
                </label>
                <v-select
                  autocomplete="off"
                  :items="period"
                  v-model="treatment.period"
                  solo
                  outlined
                  item-text="name"
                  item-value="id"
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Cross Sell </span>
                </label>
                <v-autocomplete
                  v-model="treatment.cross_sell_treatments"
                  :items="treatments"
                  item-text="name"
                  item-value="id"
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  multiple
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      class="este-chip tag-chip px-3"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <span class="pr-2">
                        {{ data.item.name }}
                      </span>
                      <v-icon color="primary" small @click.stop="removeCrossSell(data.item)">
                        close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Upsell </span>
                </label>
                <v-autocomplete
                  v-model="treatment.upsell_treatments"
                  :items="treatments"
                  item-text="name"
                  item-value="id"
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  multiple
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      class="este-chip tag-chip px-3"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                    >
                      <span class="pr-2">
                        {{ data.item.name }}
                      </span>
                      <v-icon color="primary" small @click.stop="removeUpsell(data.item)">
                        close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Staff Permissions </span>
                </label>
                <v-autocomplete
                  v-model="treatment.staffs"
                  :items="staff"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item @click="toggleAllStaff">
                      <v-list-item-action>
                        <v-simple-checkbox
                          :ripple="false"
                          :value="staffSelect"
                          @input="toggleAllStaff"
                        >
                        </v-simple-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ treatment.staffs.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    rules: {
      name: [(v) => !!v || "Please enter name"],
      category: [(v) => !!v || "Please select category"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    staffSelect: false,
  }),
  computed: {
    treatment() {
      if (this.$store.state.treatments.item) {
        return this.$store.state.treatments.item;
      }
    },
    categories() {
      if (this.$store.state.categories.allList) {
        return this.$store.state.categories.allList;
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  created() {
    this.$store.dispatch("categories/GET_ALL_CATEGORY", "");
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" });
  },
  mounted() {
    this.checkStaffPermission();
  },
  methods: {
    checkStaffPermission() {
      let _this = this;
      setTimeout(function () {
        if (_this.treatment.staffs.length === _this.staff.length) {
          _this.staffSelect = true;
        }
      }, 500);
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("treatmentsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    toggleAllStaff() {
      this.staffSelect = !this.staffSelect;
      if (this.staffSelect === true) {
        this.treatment.staffs = [];
        this.staff.forEach((value) => {
          this.treatment.staffs.push(parseInt(value.id));
        });
      } else {
        this.treatment.staffs = [];
      }
    },
    async removeCrossSell(item) {
      const index = this.treatment.cross_sell_treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.treatment.cross_sell_treatments.splice(index, 1);
      } else {
        const i = this.treatment.cross_sell_treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.treatment.cross_sell_treatments.splice(i, 1);
      }
    },
    async removeUpsell(item) {
      const index = this.treatment.upsell_treatments.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.treatment.upsell_treatments.splice(index, 1);
      } else {
        const i = this.treatment.upsell_treatments.findIndex((x) => x === item.id);
        if (i >= 0) this.treatment.upsell_treatments.splice(i, 1);
      }
    },
    async create() {
      let formData = {};
      formData.id = this.treatment.id;
      formData.name = this.treatment.name;
      formData.category = this.treatment.category;
      formData.period = this.treatment.period;

      formData.cross_sell = [];
      this.treatment.cross_sell_treatments.forEach((value) => {
        if (value.id) {
          formData.cross_sell.push(value.id);
        } else {
          formData.cross_sell.push(value);
        }
      });
      formData.upsell = [];
      this.treatment.upsell_treatments.forEach((value) => {
        if (value.id) {
          formData.upsell.push(value.id);
        } else {
          formData.upsell.push(value);
        }
      });
      formData.user_id = [];
      this.treatment.staffs.forEach((value) => {
        if (value.id) {
          formData.user_id.push(value.id);
        } else {
          formData.user_id.push(value);
        }
      });
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("treatments/UPDATE_TREATMENT", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
