import collection from "../../../api/collections/Products";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    item: {},
    pagination: {},
  },
  mutations: {
    SET_PRODUCT(state, data) {
      state.list = data;
    },
    SET_PRODUCT_LIST(state, data) {
      state.all = data;
    },
    SET_PRODUCT_ITEM(state, data) {
      state.item = data;
    },
    RESET_PRODUCT(state, data) {
      state.item = {};
    },
    SET_PRODUCT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_PRODUCT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_PRODUCT", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_PRODUCT",
        true
      );
    },
    async GET_PRODUCT_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.list(payload);
          commit("SET_PRODUCT_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_PRODUCT_LIST",
        true
      );
    },
    async CREATE_PRODUCT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_PRODUCT",
        true
      );
      return data;
    },
    async SHOW_PRODUCT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_PRODUCT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_PRODUCT",
        false
      );
      return data;
    },
    async UPDATE_PRODUCT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_PRODUCT",
        true
      );

      return data;
    },
    async DELETE_PRODUCT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.delete(payload);
          return data;
        },
        commit,
        dispatch,
        "DELETE_PRODUCT",
        true
      );
      return data;
    },
  },
};
