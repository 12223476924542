<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-row>
          <template v-for="(item, index) in widgets">
            <v-col cols="12" md="3">
              <div
                class="widget"
                :class="item.active ? 'selected' : ''"
                @click="widgetFilter(item)"
              >
                <span class="active" v-if="item.active">
                  <img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16" />
                </span>
                <div class="content">
                  <div class="mb-5">
                    <span class="m-0">
                      <inline-svg width="34" height="34" :src="item.svg" />
                    </span>
                  </div>
                  <p class="number">{{ item.count }}</p>
                  <p class="text">{{ item.name }}</p>
                </div>
                <span class="bottom-arrow" v-if="item.active"></span>
              </div>
            </v-col>
          </template>
        </v-row>
        <v-card flat class="pa-0 mt-5">
          <v-card-text class="pa-0 pt-3">
            <v-skeleton-loader
              class="widget-card"
              v-show="loading"
              :loading="loading"
              type="table-row-divider@6"
            ></v-skeleton-loader>
            <v-data-table
              :fixed-header="true"
              v-show="!loading"
              class="results-table"
              item-key="id"
              :headers="headers"
              :items="items"
              :options.sync="options"
              sort-by="updated_at"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              :server-items-length="totalCount"
            >
              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center flex-row" v-if="item.name">
                  <span class="font-14 line-h-17 font-weight--bold text-black-100">
                    {{ item.name }}
                  </span>
                </div>
                <span
                  class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border"
                  v-if="!item.name && !item.social_handle"
                  >Not Supplied</span
                >
                <div class="d-flex align-center flex-row" v-if="item.name === null">
                  <span
                    class="font-14 line-h-17 font-weight--bold text-black-100 d-flex align-center"
                  >
                    <img
                      :src="`/images/${item.source_name.toLowerCase()}.svg`"
                      class="mr-1"
                      v-if="item.source_name !== null"
                    />
                    {{ item.social_handle }}
                  </span>
                </div>
                <div class="d-flex align-center mt-2">
                  <span
                    class="badge--chip font-10 line-h-12 text-black-300 font-weight--semibold mr-2"
                    :class="item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                  >
                    {{ item.user_type_name }}
                  </span>
                  <span
                    class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                    v-if="item.branch_name"
                    >{{ item.branch_name }}</span
                  >
                </div>
              </template>
              <template v-slot:item.source="{ item }">
                <span
                  v-if="item.source_name"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                >
                  {{ item.source_name }}
                </span>
              </template>
              <template v-slot:item.responsible="{ item }">
                <v-tooltip
                  content-class="este-tooltip"
                  color="#000"
                  text-color="#fff"
                  top
                  v-if="item.responsible !== null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                    >
                      {{ getInitials(item.responsible.name) }}
                    </span>
                  </template>
                  <span v-if="item.responsible !== null">{{ item.responsible.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.contact_no="{ item }">
                <span
                  class="font-14 line-h-17 font-weight--bold text-black-100"
                  v-if="item.contact_no"
                >
                  {{ item.contact_no }}
                  <v-tooltip
                    content-class="copy-tooltip"
                    :open-on-hover="false"
                    :open-on-click="true"
                    color="#000"
                    text-color="#fff"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon @click="copyContent(item.contact_no)" v-on="on" size="15"
                        >mdi-content-copy</v-icon
                      >
                    </template>
                    <span>Copied to clipboard</span>
                  </v-tooltip>
                </span>
                <div
                  class="font-13 line-h-16 font-weight--semibold text-black-300"
                  v-if="item.email"
                >
                  {{ item.email }}
                </div>
                <div
                  class="font-13 line-h-16 font-weight--semibold text-black-300 d-flex align-center"
                  v-if="item.social_handle !== null"
                >
                  <img
                    :src="`/images/${item.source_name.toLowerCase()}.svg`"
                    class="mr-1"
                    height="15"
                    v-if="item.source_name !== null"
                  />
                  {{ item.social_handle }}
                </div>
              </template>
              <template v-slot:item.opportunities="{ item }">
                <span
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                  @click="$router.push({ path: `/view-contact/${item.id}` })"
                >
                  {{ item.live_opportunity_count }}
                </span>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <div class="d-flex flex-column">
                  <span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1">
                    {{ item.updated_at }}
                  </span>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-for="(val, index) in statusTags">
                  <span
                    class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                    :style="
                      val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                    "
                    :key="index"
                    v-if="item.status === val.id"
                    >{{ val.name }}</span
                  >
                </template>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="d-flex justify-end">
                  <v-btn
                    :elevation="0"
                    class="bg-gray-100 btn-action mr-2"
                    v-if="item.enable_app_user"
                    @click="enableAppUser(item, 0)"
                  >
                    <v-icon color="green" size="16">mdi-cellphone-check</v-icon>
                  </v-btn>
                  <v-btn
                    :elevation="0"
                    class="bg-gray-100 btn-action mr-2"
                    v-if="!item.enable_app_user"
                    @click="enableAppUser(item, 1)"
                  >
                    <v-icon color="grey" size="16">mdi-cellphone</v-icon>
                  </v-btn>
                  <v-btn
                    :elevation="0"
                    class="bg-gray-100 btn-action mr-2"
                    @click="resetPassword(item)"
                  >
                    <v-icon color="grey" size="16">mdi-reload</v-icon>
                  </v-btn>
                  <v-btn
                    :elevation="0"
                    class="bg-gray-100 btn-action"
                    @click="$router.push({ path: `/contact/${item.id}` })"
                  >
                    <inline-svg height="16" src="/images/icons/duotune/general/gen055.svg" />
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              :loading="actionLoading"
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="noEmailExists" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Alert
          <a class="float-right pa-6" @click="noEmailExists = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          <p class="font-16 line-h-21 font-weight--regular text-black-100 letter-s-0 mb-4">
            The contact you have chosen does not have a connected email address to enable login to
            the app.
          </p>
          <p class="font-16 line-h-21 font-weight--regular text-black-100 letter-s-0">
            Please add the email address and try again.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center pb-5">
          <div class="d-flex justify-center align-center">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="noEmailExists = false"
              color="dark"
              x-large
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn";
import sliderFn from "../../../mixins/sliderFn";
import status from "../../../mixins/status";
import CustomerWidget from "../../../mixins/CustomerWidget";
import eventBus from "../../../eventBus.js";

export default {
  name: "Leads",
  mixins: [globalFn, sliderFn, status, CustomerWidget],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      actionLoading: false,
      jsonData: [],
      loading: false,
      showSkeleton: false,
      modalDialog: false,
      noEmailExists: false,
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Contact", value: "contact_no", sortable: false },
        { text: "Source", value: "source", sortable: false },
        { text: "Responsible", value: "responsible", sortable: false },
        { text: "Opportunities", value: "opportunities", sortable: false },
        { text: "Last Updated", value: "updated_at", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "action", sortable: false, align: "end" },
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 20,
      options: {},
      sortOrder: "desc",
      sortBy: "updated_at",
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: "",
      payload: {
        search: "",
        filter: {
          enquiry_from: "",
          enquiry_to: "",
          followup_from: "",
          followup_from_time: "",
          followup_to: "",
          followup_to_time: "",
          status: [],
          branch: [],
          source: [],
          assigned: [],
          assigned_to: 0,
          source_blank: 0,
          comment_count: "",
          comment_count_operator: "",
        },
        widget_filter: "",
        per_page: 20,
        page: 1,
        sort_by: "updated_at",
        sort_order: "desc",
      },
      message: "",
      action: "",
      actionText: "",
      enrolleUserValue: 0,
    };
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true,
    },
  },
  computed: {
    csvFileName() {
      const date = new Date().toLocaleString().replace(",", "");
      return `lead_csv_${date}`;
    },
  },
  mounted() {
    this.$root.$on("customerTableUpdate", this.readTheDataFromApi);
    this.$root.$on("searchCustomers", this.searchCustomers);
    this.$root.$on("AppointmentEvent", this.changeEntryStatus);
    this.$root.$on("CallbackEvent", this.changeEntryStatus);
  },
  methods: {
    async searchCustomers(data) {
      this.options.page = 1;
      this.items = [];
      if (data.widget_filter) {
        this.widgets.forEach(function (el, index) {
          el.active = false;
        });
        this.payload.widget_filter = "";
      }
      this.payload.search = data.search;
      this.payload.filter.enquiry_from = data.enquiry_from;
      this.payload.filter.enquiry_to = data.enquiry_to;
      this.payload.filter.followup_from = data.followup_from;
      this.payload.filter.followup_from_time = data.followup_from_time;
      this.payload.filter.followup_to = data.followup_to;
      this.payload.filter.followup_to_time = data.followup_to_time;
      this.payload.filter.status = data.status;
      this.payload.filter.branch = data.branch;
      this.payload.filter.source = data.source;
      this.payload.filter.assigned = data.assigned;
      this.payload.filter.assigned_to = data.assigned_to;
      this.payload.filter.source_blank = data.source_blank;
      this.payload.filter.comment_count = data.comment_count;
      this.payload.filter.comment_count_operator = data.comment_count_operator;

      await this.loadCount();
      await this.readTheDataFromApi();
    },
    async widgetFilter(item) {
      item.active = !item.active;
      this.widgets.forEach(function (el, index) {
        if (el.slug !== item.slug) {
          el.active = false;
        }
      });
      this.items = [];
      this.payload.widget_filter = item.active ? item.slug : "";
      this.options.page = 1;
      await this.readTheDataFromApi();
    },
    async paginate(e) {
      this.payload.per_page = e.itemsPerPage;
      this.payload.page = e.page;
      await this.readTheDataFromApi();
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch("contacts/GET", this.payload).then((res) => {
        this.items = res.data.data;
        this.totalCount = res.data.total;
      });
      this.loading = false;

      if (this.payload.search && this.items.length === 0) {
        this.$root.$emit("showCreateNew", true);
      } else {
        this.$root.$emit("showCreateNew", false);
      }
    },

    handleFunction(funName) {
      this[funName]();
    },
    async enableAppUser(item, value) {
      if (item.email) {
        this.selectedItem = item;
        this.enrolleUserValue = value;
        this.modalDialog = true;
        if (value) {
          this.message = "Please confirm you want to enable app user?";
          this.actionText = "Enable";
          this.action = "enrollUser";
        } else {
          this.message = "Please confirm you want to disable app user?";
          this.actionText = "Disable";
          this.action = "enrollUser";
        }
      } else {
        this.noEmailExists = true;
      }
    },

    async enrollUser() {
      this.actionLoading = true;
      await this.$store
        .dispatch(`contacts/ENROLL_USER`, {
          user_id: this.selectedItem.id,
          enable_app_user: this.enrolleUserValue,
        })
        .then((res) => {
          if (res.result === "success") {
            this.actionLoading = false;
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    async resetPassword(item) {
      this.selectedItem = item;
      this.modalDialog = true;
      this.message = "Please confirm you want to reset the app password for this user?";
      this.actionText = "Confirm";
      this.action = "confirmResetPassword";
    },

    async confirmResetPassword() {
      this.actionLoading = true;
      await this.$store
        .dispatch(`contacts/RESET_APP_USER_PASSWORD`, { user_id: this.selectedItem.id })
        .then((res) => {
          if (res.result === "success") {
            this.actionLoading = false;
            this.modalDialog = false;
            this.readTheDataFromApi();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
