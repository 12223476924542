<template>
  <v-row dense class="e-row">
    <v-col cols="12" md="12" class="e-col">
      <div>
        <label>
          <span class="label-txt">Select Contact:</span>
        </label>
      </div>
      <template v-if="!isContactSelected">
        <v-text-field
          flat
          rounded
          prepend-inner-icon="mdi-magnify"
          autocomplete="off"
          label="Search for a contact…"
          class="solo-cust"
          clearable
          clear-icon="mdi-close-circle-outline"
          solo
          hide-details="auto"
          :loading="loading"
          @input="conatctTyping = true"
          v-model="contactSearch"
        >
        </v-text-field>
        <template v-if="results && results.length > 0">
          <ul class="contact--list" :class="results && results.length > 20 ? 'height-300' : ''">
            <li v-for="(item, index) in results" :key="index">
              <a @click="findContact(item)" class="d-flex align-center flex-row cursor">
                <span
                  class="font-12 line-h-15 font-weight--regular text-black-100 ml-3"
                  v-if="item"
                >
                  <span v-if="item.name">{{ item.name }} </span>
                  <span v-if="item.name === null">{{ item.social_handle }} </span>
                  <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                  <span v-if="item.email">/ {{ item.email }} </span>
                </span>
                <span
                  class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                  :class="item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'"
                >
                  {{ item.user_type_name === "Lead" ? "L" : "C" }}
                </span>
              </a>
            </li>
          </ul>
        </template>
        <template v-else-if="contactSearch !== null && results.length === 0">
          <ul class="contact--list">
            <li class="cursor">No results found.</li>
          </ul>
        </template>
      </template>
      <template v-if="isContactSelected">
        <v-text-field
          flat
          :readonly="true"
          rounded
          @click:clear="clearSelected"
          autocomplete="off"
          class="solo-cust"
          clearable
          clear-icon="mdi-close-circle-outline"
          solo
          hide-details
          :value="formatContact"
        >
        </v-text-field>
      </template>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "SearchContact",
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      isEdit: false,
      loading: false,
      isContactSelected: false,
      contactSearch: null,
      results: [],
      selectedContact: null,
      conatctTyping: false,
    };
  },
  watch: {
    contactSearch: _.debounce(function () {
      this.conatctTyping = false;
    }, 1000),
    conatctTyping: function (value) {
      if (!value) {
        this.globalSearchAPI();
      }
    },
  },
  mounted() {
    this.isContactSelected = this.data.id ? true : false;
    this.isEdit = this.data.id ? false : true;
  },
  computed: {
    formatContact() {
      if (this.data.id && !this.isEdit) {
        const socilaHandle = this.data.social_handle ? `@${this.data.social_handle}` : null;
        const fullname = [this.data.name, socilaHandle, this.data.email, this.data.contact_no];
        const trimFullName = fullname.filter(function (el) {
          return el != null;
        });
        return trimFullName.join("/");
      } else if (this.selectedContact && this.isEdit) {
        const socilaHandle = this.selectedContact.social_handle
          ? `@${this.selectedContact.social_handle}`
          : null;
        const fullname = [
          this.selectedContact.name,
          socilaHandle,
          this.selectedContact.email,
          this.selectedContact.contact_no,
        ];
        const trimFullName = fullname.filter(function (el) {
          return el != null;
        });
        return trimFullName.join("/");
      } else {
        return "";
      }
    },
  },
  methods: {
    async globalSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.contactSearch;
      this.loading = true;
      await this.$store.dispatch("user/GLOBAL_SEARCH", data).then((res) => {
        this.results = res.data.data;
        this.loading = false;
      });
    },

    async findContact(item) {
      this.isContactSelected = true;
      this.selectedContact = item;
      this.$emit("catchSearchContact", item);
    },

    async clearSelected() {
      this.conatctTyping = false;
      this.isContactSelected = false;
      this.isEdit = true;
      this.results = [];
      this.contactSearch = null;
    },
  },
};
</script>
<style lang="css" scoped></style>
