<template>
  <div>
    <a class="action mr-3" @click="ShowAddPaymentForm = !ShowAddPaymentForm">
      <v-icon size="19" class="mt-n1 mr-2">mdil-plus-circle</v-icon>
      Add Payment
    </a>
    <add-payment
      v-if="ShowAddPaymentForm"
      :dealId="dealId"
      :dealTreatments="treatments"
    ></add-payment>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :item-class="rowClass"
      :hide-default-footer="true"
      dense
      class="inner-table"
    >
      <template v-slot:item.date="{ item }">
        {{ item.date | moment("DD/MM/YY") }}
      </template>
      <template v-slot:item.total="{ item }">
        <span class="red--text" v-if="item.method === 7">- {{ item.total | currency("£") }}</span>
        <span v-else>{{ item.total | currency("£") }}</span>
      </template>
      <template v-slot:item.taken_by="props">
        <v-edit-dialog
          content-class="edit-payment"
          large
          persistent
          @save="save(props.item)"
          v-if="props.item.status !== 2 && props.item.method !== 7"
        >
          <div>
            {{ props.item.taken_by.name }}
            <PencilIcon size="12" />
          </div>
          <template v-slot:input>
            <div class="my-4 body-2">Update Taken By</div>
            <v-select
              autocomplete="off"
              v-model="props.item.take_by"
              :items="staff"
              item-text="name"
              item-value="id"
              solo
              outlined
              flat
              class="solo-cust"
              hide-details="auto"
            >
              <template slot="append">
                <v-icon>mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </template>
        </v-edit-dialog>
        <template v-else>
          <span v-if="props.item.taken_by && props.item.taken_by.name">{{
            props.item.taken_by.name
          }}</span>
        </template>
      </template>
      <template v-slot:item.points_earned="{ item }">
        <span class="green--text" v-if="item.method !== 5 && item.method !== 7"
          >+ {{ item.points_earned }}</span
        >
        <span class="red--text" v-if="item.method === 5 || item.method === 7"
          >- {{ item.points_earned }}</span
        >
      </template>
      <template v-slot:item.balance="{ item }">
        <span class="red--text" v-if="item.method === 7">-{{ item.balance | currency("£") }}</span>
        <span v-else>{{ item.balance | currency("£") }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="deletePayment(item)"
          :loading="item.loading"
          v-if="
            item.status !== 2 && item.method !== 7 && hasPermission({ module: 'payment_delete' })
          "
        >
          <v-icon class="delete-deal" color="rgba(0, 0, 0, 0.2)">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import sidePaneMenu from "../../mixins/sidePaneMenu.js";
import AddPayment from "./AddPayment.vue";
import { PencilIcon } from "@vue-hero-icons/outline";
export default {
  mixins: [sidePaneMenu],
  components: {
    AddPayment,
    PencilIcon,
  },
  props: {
    dealId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
  data: () => ({
    taken_by: null,
    ShowAddPaymentForm: false,
    deleteLoading: false,
    items: [],
    treatments: [],
    loading: true,
    headers: [
      {
        text: "Payment Date",
        value: "date",
        sortable: false,
      },
      { text: "Method", value: "payment_method", sortable: false },
      { text: "£", value: "total", sortable: false },
      { text: "Taken By", value: "taken_by", align: "right", sortable: false },
      { text: "Balance", value: "balance", align: "right", sortable: false },
      { text: "Points", value: "points_earned", align: "right", sortable: false },
      { text: "", value: "actions", align: "end", sortable: false },
    ],
  }),
  computed: {
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
  },
  created() {
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: "", params: "" });
  },
  mounted() {
    this.$root.$on("RefreshDeals", this.payments);
    this.payments();
  },
  methods: {
    showAdd() {
      this.ShowAddPaymentForm = true;
    },
    async payments() {
      this.loading = true;
      await this.$store
        .dispatch("deals/SHOW_DEAL", {
          id: this.dealId,
          params: `?attributes=payments,dealTreatments`,
        })
        .then((res) => {
          this.items = res.data.payments.map(function (el) {
            let item = Object.assign({}, el);
            item.loading = false;
            return item;
          });
          this.treatments = res.data.deal_treatments;
          this.loading = false;
        });
    },

    async deletePayment(item) {
      item.loading = true;
      await this.$store
        .dispatch("payments/DELETE_PAYMENT", item.id)
        .then((response) => {
          if (response.result === "success") {
            item.loading = false;
            this.payments();
          } else if (response.result === "fail") {
            response.type = "error";
            item.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          item.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },

    rowClass(item) {
      if (item.status === 2) {
        return "strikeout";
      }
    },

    async save(item) {
      let formData = {};
      formData.id = item.id;
      formData.deal_id = item.deal_id;
      formData.total = item.total;
      formData.method = item.method;
      formData.take_by = item.take_by;
      await this.$store
        .dispatch("payments/UPDATE_PAYMENT", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.payments();
          } else if (response.result === "fail") {
            response.type = "error";
            item.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          item.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
