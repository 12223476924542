<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Book Appointment</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#client">Client Details</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside py-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="client">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col" v-if="selectedUser">
                    <div class="d-flex my-4">
                      <span
                        class="font-18 line-h-22 text-black-100 font-weight--bold me-2"
                        v-if="selectedUser.user && selectedUser.user.name"
                      >
                        {{ selectedUser.user.name }}
                      </span>
                      <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                        <img
                          :src="`/images/${selectedUser.user.source_name.toLowerCase()}.svg`"
                          class="mr-2"
                          v-if="selectedUser.user.source_name !== null"
                        />
                        {{ selectedUser.user.social_handle }}
                      </span>
                    </div>
                    <div class="d-flex">
                      <span
                        class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5"
                        :class="
                          selectedUser.user.user_type_name === 'Lead'
                            ? 'bg-blue-100'
                            : 'bg-gold-200'
                        "
                        >{{ selectedUser.user.user_type_name }}</span
                      >
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/map.svg" />
                        </span>
                        {{ selectedUser.user.branch_name }}
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="selectedUser.user.email"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/at.svg" />
                        </span>
                        {{ selectedUser.user.email }}
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="selectedUser.user.contact_no"
                      >
                        <span class="mr-1">
                          <inline-svg src="/images/icons/mobile.svg" />
                        </span>
                        {{ selectedUser.user.contact_no }}
                        <v-tooltip
                          content-class="copy-tooltip"
                          :open-on-hover="false"
                          :open-on-click="true"
                          color="#000"
                          text-color="#fff"
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="copyContent(selectedUser.user.contact_no)"
                              v-on="on"
                              size="15"
                              class="ml-2"
                              >mdi-content-copy</v-icon
                            >
                          </template>
                          <span>Copied to clipboard</span>
                        </v-tooltip>
                      </span>
                      <span
                        class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4"
                        v-if="selectedUser.user.social_handle !== null"
                      >
                        <img
                          :src="`/images/${selectedUser.user.source_name.toLowerCase()}.svg`"
                          height="15"
                          class="mr-2"
                          v-if="selectedUser.user.source_name !== null"
                        />
                        {{ selectedUser.user.social_handle }}
                      </span>
                    </div>
                  </v-col>
                  <v-col v-else cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Find client: </span>
                    </label>
                    <div class="mt-2 mb-4" v-if="selectedAppointment">
                      <v-chip class="este-chip tag-chip px-3 mb-2">
                        <span v-if="selectedAppointment.name">{{ selectedAppointment.name }} </span>
                        <span v-if="selectedAppointment.name === null"
                          >{{ selectedAppointment.social_handle }}
                        </span>
                        <span v-if="selectedAppointment.contact_no"
                          >/ {{ selectedAppointment.contact_no }}
                        </span>
                        <span v-if="selectedAppointment.email"
                          >/ {{ selectedAppointment.email }}
                        </span>
                        <v-icon
                          color="primary"
                          class="ml-2"
                          small
                          @click.stop="removeAppointmentedUser(item)"
                        >
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </div>
                    <div v-else>
                      <v-text-field
                        flat
                        rounded
                        prepend-inner-icon="mdi-magnify"
                        :rules="rules.user_id"
                        autocomplete="off"
                        label="Search for a client"
                        class="solo-cust"
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        solo
                        hide-details="auto"
                        @input="isTyping = true"
                        v-model="search"
                        :loading="loading"
                      >
                      </v-text-field>
                      <ul
                        class="contact--list"
                        v-if="search.length > 3"
                        :class="appointments.length > 20 ? 'height-300' : ''"
                      >
                        <li v-for="(item, index) in appointments" :key="index">
                          <a
                            @click="selectContact(item)"
                            class="d-flex align-center flex-row cursor"
                          >
                            <span
                              class="font-12 line-h-15 font-weight--regular text-black-100 ml-3"
                              v-if="item"
                            >
                              <span v-if="item.name">{{ item.name }} </span>
                              <span v-if="item.name === null">{{ item.social_handle }} </span>
                              <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                              <span v-if="item.email">/ {{ item.email }} </span>
                            </span>
                            <span
                              class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13"
                              :class="
                                item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
                              "
                            >
                              {{ item.user_type_name === "Lead" ? "L" : "C" }}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Treatment / Service: </span>
                    </label>
                    <v-autocomplete
                      v-model="form.treatment"
                      :items="treatments"
                      item-text="name"
                      item-value="id"
                      chips
                      autocomplete="off"
                      solo
                      outlined
                      flat
                      hide-details="auto"
                      class="este-autocomplete-input"
                      :disabled="selectedUser !== null ? true : false"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt"> Appointment Date: </span>
                      <v-menu
                        v-model="appointmentDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            autocomplete="off"
                            v-model="appointmentDateUkFormat"
                            solo
                            outlined
                            v-on="on"
                            flat
                            hide-details="auto"
                            class="solo-cust"
                            append-icon="mdil-calendar"
                            :rules="rules.appointment_date"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.appointment_date"
                          no-title
                          @input="appointmentDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt">Appointment Time:</span>
                          <v-menu
                            ref="appointmentFromTimeMenu"
                            v-model="appointmentFromTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_from_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust"
                                v-model="form.appointment_from_time"
                                label="From"
                                append-icon="mdil-clock"
                                :rules="rules.appointment_from_time"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentFromTimeMenu"
                              v-model="form.appointment_from_time"
                              full-width
                              @click:minute="
                                $refs.appointmentFromTimeMenu.save(form.appointment_from_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                          <v-menu
                            ref="appointmentToTimeMenu"
                            v-model="appointmentToTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.appointment_to_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                flat
                                hide-details="auto"
                                solo
                                class="solo-cust mt-5"
                                v-model="form.appointment_to_time"
                                :rules="rules.appointment_to_time"
                                label="To"
                                append-icon="mdil-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              :allowed-minutes="allowedStep"
                              no-title
                              ampm-in-title
                              format="24hr"
                              v-if="appointmentToTimeMenu"
                              v-model="form.appointment_to_time"
                              full-width
                              @click:minute="
                                $refs.appointmentToTimeMenu.save(form.appointment_to_time)
                              "
                            ></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="6" class="e-col">
                    <label>
                      <span class="label-txt"> Preferred Branch: </span>
                      <v-select
                        autocomplete="off"
                        :items="branches"
                        item-value="id"
                        item-text="branch"
                        :rules="rules.branch_id"
                        v-model="form.branch_id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                        @change="loadBranchSpecifications"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                  <v-col cols="6" class="e-col">
                    <label>
                      <span class="label-txt"> Duration: </span>
                      <v-text-field
                        v-model="form.duration"
                        autocomplete="off"
                        solo
                        outlined
                        flat
                        hide-details="auto"
                        class="solo-cust"
                      ></v-text-field>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Select Consultant </span>
                      <v-select
                        autocomplete="off"
                        :items="staff"
                        v-model="form.consultant_id"
                        item-text="name"
                        item-value="id"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                        clearable
                        clear-icon="mdi-close-circle-outline"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Machines </span>
                    </label>

                    <v-select
                      autocomplete="off"
                      item-value="id"
                      v-model="form.machine_id"
                      :items="machinesList"
                      item-text="name"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <template v-if="!flag">
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt"> Appointment Type </span>
                      </label>
                      <v-select
                        autocomplete="off"
                        :items="appointmentType"
                        v-model="form.appointment_type"
                        solo
                        outlined
                        flat
                        class="solo-cust"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt"> Status </span>
                    </label>
                    <v-select
                      autocomplete="off"
                      :items="
                        SortAtoZ(
                          statusTags.filter(function (el) {
                            return el.slug.includes('appointment');
                          })
                        )
                      "
                      v-model="form.status"
                      :rules="rules.status"
                      item-text="name"
                      item-value="id"
                      solo
                      outlined
                      flat
                      class="solo-cust"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="disabled"
          rounded
          x-large
          @click="addAppointment"
        >
          Add Appointment
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import globalFn from "../../../mixins/globalFn.js";
import status from "../../../mixins/status.js";
import eventBus from "../../../eventBus.js";
export default {
  mixins: [globalFn, status],
  data: () => ({
    loading: false,
    dropdown: true,
    selectedUser: null,
    disabled: false,
    clientSearch: null,
    appointmentDateMenu: false,
    appointmentFromTimeMenu: false,
    appointmentToTimeMenu: false,
    tab: null,
    search: "",
    isTyping: false,
    appointments: [],
    selectedAppointment: "",
    form: {
      user_id: 0,
      treatment_id: [],
      treatment: "",
      appointment_type: "Treatment",
      appointment_date: "",
      appointment_from_time: "",
      appointment_to_time: "",
      branch_id: "",
      appointment_notes: "",
      consultant_id: "",
      machine_id: "",
      duration: "",
      opportunity_id: "",
      status: 11,
    },
    items: [],
    rules: {
      user_id: [
        (v) => !!v || "Please select client",
        (v) => (v && v.length > 3) || "Please enter min 3 characters",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
      machine: [(v) => !!v || "Please select machine"],
      status: [(v) => !!v || "Please select status"],
      appointment_date: [(v) => !!v || "Please select appointment date"],
      appointment_from_time: [(v) => !!v || "Please select from time"],
      appointment_to_time: [(v) => !!v || "Please select to time"],
    },
    clientParams: "?sort_by=name&sort_order=ASC",
    leadParams: {
      attributes: "staff,branch,leadDetails,reasons,symptoms,treatments",
      search: "",
      filter: {
        date_from: "",
        date_to: "",
        status: [],
        branch: [],
      },
    },
    staff: [],
    machinesList: [],
    staffParams: {
      branch: "",
      type: "appointment",
    },
    updateLeadStatus: "",
    updateClientStatus: "",
    SelectedUserType: "",
  }),
  computed: {
    appointmentDateUkFormat() {
      if (!this.form.appointment_date) return null;
      const [year, month, day] = this.form.appointment_date.split("-");
      return `${day}/${month}/${year}`;
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    consultants() {
      if (this.$store.state.staff.consultants) {
        this.staff = this.$store.state.staff.consultants;
        return this.$store.state.staff.consultants;
      }
    },
    machines() {
      if (this.$store.state.machines.all) {
        this.machinesList = this.$store.state.machines.all;
        return this.$store.state.machines.all;
      }
    },
  },
  mounted() {
    eventBus.$on("calendarSlotData", this.loadSelectedSlotData);
    eventBus.$on("toBeScheduleAppointment", this.loadSelectedScheduleAppointment);
  },
  created() {
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("staff/GET_CONSULTANTS", "");
    this.$store.dispatch("machines/GET_MACHINE_LIST", "");
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.search.length > 3) {
        this.filterUserSearchAPI();
      }
    },
  },
  methods: {
    async loadSelectedScheduleAppointment(data) {
      if (data) {
        this.form.user_id = data.user_id;
        this.selectedUser = data;
        this.form.treatment = data.treatments[0].id;
        this.form.treatment_id.push(data.treatments[0].id);
      }
    },
    async loadSelectedSlotData(data) {
      console.log(data);
      if (data) {
        this.form.appointment_date = data.date;
        this.form.appointment_from_time = data.fromTime;
        if (data.category) {
          this.form.branch_id = data.category.branch_id;

          if (data.category.isMachine) {
            this.form.machine_id = data.category.id;
          } else {
            this.form.consultant_id = data.category.id;
          }

          this.loadBranchSpecifications();
        }
        this.updateStatus();
      }
    },
    async filterUserSearchAPI() {
      let data = {};
      data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22];
      data.page_size = 1000;
      data.search = this.search;
      this.loading = true;
      await this.$store.dispatch("appointments/FILTERED_APPOINTMENTS", data).then((res) => {
        this.appointments = res.data;
        this.loading = false;
      });
    },
    async selectContact(item) {
      this.selectedAppointment = item;
      this.form.user_id = item.id;
    },
    async removeAppointmentedUser(item) {
      this.selectedAppointment = "";
      this.form.user_id = null;
    },
    async loadBranchSpecifications() {
      this.staff = this.consultants.filter((record) => record.branch_id === this.form.branch_id);
      this.machinesList = this.machines.filter(
        (record) => record.branch_id === this.form.branch_id
      );
    },
    async getStaffList() {
      this.staff = [];
      await this.$store
        .dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" })
        .then((response) => {
          if (response.result === "success") {
            this.staff = response.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    allowedStep: (m) => m % 15 === 0,
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      eventBus.$emit("refreshCalendarData", "");
    },
    updateStatus() {
      if (
        this.form.appointment_date &&
        this.form.appointment_from_time &&
        this.form.appointment_to_time
      ) {
        this.form.status = 5;
      } else if (
        this.form.appointment_date &&
        !this.form.appointment_from_time &&
        !this.form.appointment_to_time
      ) {
        this.form.status = 11;
      }
    },
    async addAppointment() {
      this.updateLeadStatus = 19;
      this.updateClientStatus = 19;
      if (this.$refs.form.validate()) {
        this.disabled = true;
        this.form.treatment_id.push(this.form.treatment);
        this.form.treatment_id = [...new Set(this.form.treatment_id)];
        await this.$store
          .dispatch("appointments/CREATE_APPOINTMENT", this.form)
          .then((response) => {
            if (response.result === "success") {
              this.close();
            } else if (response.result === "fail") {
              console.log(response.result);
              this.disabled = false;
              let res = [];
              res.type = "error";
              res.message = response.message;
              this.$store.dispatch("snackbar/SHOW", res);
            }
          })
          .catch((err) => {
            console.log(err);
            this.disabled = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
