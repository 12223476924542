import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import "@mdi/light-font/css/materialdesignicons-light.css";

Vue.use(Vuetify);

const opts = {
  global: {
    ripples: false,
  },
  theme: {
    themes: {
      light: {
        primary: process.env.MIX_SECONDARY_COLOR,
        secondary: process.env.MIX_PRIMARY_COLOR,
        lightBlue: "#0C60A0",
        chase: "#ffccc9",
      },
    },
  },
};

export default new Vuetify(opts);
