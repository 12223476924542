<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Update User</div>
        <div class="tab-content-wrapper">
          <search-contact
            @catchSearchContact="getSelectedValue"
            :data="pabauApp.user"
          ></search-contact>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="update"
        >
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../store/action-types.js";
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import SearchContact from "../../../components/contacts/SearchContact";
export default {
  mixins: [status, globalFn],
  components: {
    SearchContact,
  },
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    existing: 1,
    user_id: 0,
    form: {
      title: "",
      name: "",
      address_one: "",
      address_two: "",
      city: "",
      postcode: "",
      contact_no: "",
      branch_id: "",
      email: "",
      status: 1,
      social_handle: "",
    },
    rules: {
      name: [(v) => !!v || "Please enter name"],
      email: [
        (v) => !!v || "Please enter email address",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com",
      ],
      branch_id: [(v) => !!v || "Please select branch"],
    },
    contacts: [],
  }),
  computed: {
    pabauApp() {
      if (this.$store.state.appointments.pabauAppointment) {
        if (!this.$store.state.appointments.pabauAppointment.user) {
          this.$store.state.appointments.pabauAppointment.user = {};
        }
        return this.$store.state.appointments.pabauAppointment;
      }
    },
  },
  methods: {
    getSelectedValue(data) {
      if (data) {
        this.user_id = data.id;
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("pabauAppointmentsTable", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async update() {
      let formData = {};
      formData.id = this.pabauApp.id;
      formData.user_id = this.user_id;
      this.loading = true;
      await this.$store
        .dispatch("appointments/PABAU_UPDATE_USER", formData)
        .then((response) => {
          if (response.result === "success") {
            this.loading = false;
            this.loadData();
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
