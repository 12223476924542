<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Offer</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Code </span>
                </label>
                <v-select
                  autocomplete="off"
                  :items="campaigns"
                  :rules="rules.campaign_id"
                  item-value="id"
                  item-text="name"
                  v-model="form.campaign_id"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
                <!-- <v-text-field autocomplete="off" solo outlined :rules="rules.code" v-model="form.code" flat hide-details="auto" class="solo-cust"></v-text-field> -->
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Name </span>
                </label>
                <v-text-field
                  autocomplete="off"
                  solo
                  outlined
                  :rules="rules.name"
                  v-model="form.name"
                  flat
                  hide-details="auto"
                  class="solo-cust"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Details </span>
                </label>
                <vue-editor v-model="form.details"></vue-editor>
                <!-- <v-textarea autocomplete="off" solo outlined v-model="form.details" flat hide-details="auto" class="solo-cust"></v-textarea> -->
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Applies to: </span>
                </label>
                <v-autocomplete
                  v-model="form.for_branch"
                  :items="branches"
                  item-text="branch"
                  item-value="id"
                  multiple
                  chips
                  autocomplete="off"
                  solo
                  outlined
                  flat
                  hide-details="auto"
                  class="este-autocomplete-input"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="branchChange">
                      <v-list-item-action>
                        <v-simple-checkbox
                          :ripple="false"
                          :value="brancAllSelect"
                          @input="branchChange"
                        >
                        </v-simple-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.branch }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ form.for_branch.length - 1 }} others)
                    </span>
                  </template>
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <label>
                  <span class="label-txt"> Starts from: </span>
                  <v-menu
                    v-model="startMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(form.starts_at)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.starts_at"
                      no-title
                      @input="startMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </label>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <label>
                  <span class="label-txt"> Expires: </span>
                  <v-menu
                    v-model="expireMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(form.expired_at)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="solo-cust"
                        append-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.expired_at"
                      no-title
                      @input="expireMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </label>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt"> Status </span>
                </label>
                <v-select
                  autocomplete="off"
                  :items="
                    SortAtoZ(
                      statusTags.filter(function (el) {
                        return el.slug.includes('offer');
                      })
                    )
                  "
                  v-model="form.status"
                  :rules="rules.status"
                  item-text="name"
                  item-value="id"
                  solo
                  outlined
                  flat
                  class="solo-cust"
                  hide-details="auto"
                >
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          class="b-blue"
          depressed
          :disabled="loading"
          rounded
          x-large
          @click="create"
        >
          Add Offer
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import status from "../../../mixins/status.js";
import globalFn from "../../../mixins/globalFn.js";
import { VueEditor } from "vue2-editor";
import _ from "lodash";
export default {
  mixins: [status, globalFn],
  components: {
    VueEditor,
  },
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    tab: null,
    brancAllSelect: false,
    startMenu: false,
    expireMenu: false,
    treatmentSelect: false,
    staffSelect: false,
    form: {
      code: "",
      campaign_id: "",
      name: "",
      details: "",
      for_branch: [],
      for_treatment: [],
      for_user: [],
      expired_at: "",
      starts_at: "",
      status: "",
    },
    rules: {
      name: [(v) => !!v || "Please enter name"],
      code: [(v) => !!v || "Please enter code"],
    },
    contacts: [],
    search: "",
    isTyping: false,
  }),
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList;
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all;
      }
    },
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.search) {
        this.loadContacts();
      }
    },
  },
  created() {
    this.$store.dispatch("campaign/LIST", "");
    this.$store.dispatch("treatments/GET_ALL_TREATMENT", "");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("staff/GET_STAFF_LIST", {
      data: {
        branch: "",
        type: "",
      },
      params: "",
    });
  },
  methods: {
    async loadContacts() {
      this.loading = true;
      await this.$store
        .dispatch("customers/GET_CONTACTS_LIST", { search: this.search })
        .then((response) => {
          console.log(response);
          if (response.result === "success") {
            this.loading = false;
            this.contacts = response.data.data;
          } else if (response.result === "fail") {
            response.type = "error";
            this.loading = false;
            this.$store.dispatch("snackbar/SHOW", response);
          }
        })
        .catch((err) => {
          this.loading = false;
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("refreshOffersTable", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    treatmentsSelectToggle() {
      this.treatmentSelect = !this.treatmentSelect;
      if (this.treatmentSelect === true) {
        this.form.for_treatment = [];
        this.treatments.forEach((value) => {
          this.form.for_treatment.push(parseInt(value.id));
        });
      } else {
        this.form.for_treatment = [];
      }
    },
    branchChange() {
      this.brancAllSelect = !this.brancAllSelect;
      if (this.brancAllSelect === true) {
        this.form.for_branch = [];
        this.branches.forEach((value) => {
          this.form.for_branch.push(parseInt(value.id));
        });
      } else {
        this.form.for_branch = [];
      }
    },

    toggleAllStaff() {
      this.staffSelect = !this.staffSelect;
      if (this.staffSelect === true) {
        this.form.for_user = [];
        this.contacts.forEach((value) => {
          this.form.for_user.push(parseInt(value.id));
        });
      } else {
        this.form.for_user = [];
      }
    },
    async create() {
      let formData = {};
      formData.name = this.form.name;
      formData.details = this.form.details;
      formData.expired_at = this.form.expired_at;
      formData.starts_at = this.form.starts_at;
      formData.code = this.form.code;
      formData.status = this.form.status;
      formData.campaign_id = this.form.campaign_id;

      formData.for_branch = [];
      this.form.for_branch.forEach((value) => {
        if (value.id) {
          formData.for_branch.push({ id: value.id });
        } else {
          formData.for_branch.push(value);
        }
      });
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("offers/CREATE", formData)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
