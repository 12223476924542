<template>
  <div>
    <v-text-field
      flat
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      label="Search for a deal…"
      solo
      hide-details
      class="search-input"
      @input="isTyping = true"
      v-model="search"
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left content-class="mega-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip
                small
                class="este-chip yellow-chip float-right"
                text-color="black"
                @click="searchKey"
              >
                Filter
              </v-chip>
              <v-chip
                small
                color="#221F20"
                class="este-chip mb-2 float-right"
                text-color="#FFFFFF"
                @click="reset"
              >
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" sm="6" md="4" class="filter-column">
                <span class="heading">Date From:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu
                    v-model="dateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.deal_from)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date from"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.deal_from"
                      no-title
                      @input="dateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="dateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        :value="dateUkFormat(filter.deal_to)"
                        solo
                        outlined
                        v-on="on"
                        flat
                        hide-details="auto"
                        class="advance-input"
                        placeholder="Date to"
                        prepend-inner-icon="mdil-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.deal_to"
                      no-title
                      @input="dateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="filter-column">
                <span class="heading"
                  >Branch:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="BranchToggle"
                    @click="handleFunction('BranchCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <template v-for="branch in BrachDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="branch.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="branchToggle(branch)"
                    >
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="filter-column">
                <span class="heading"
                  >Source:
                  <v-checkbox
                    hide-details
                    class="shrink mt-0"
                    v-model="SourceToggle"
                    @click="handleFunction('SourceCheckUncheck')"
                    :ripple="false"
                  ></v-checkbox>
                </span>
                <div class="mt-2">
                  <v-chip
                    small
                    class="este-chip tag-chip mb-2 text-decoration-line-through"
                    :class="SourceBlank === true ? 'selected' : ''"
                    text-color="black"
                    @click.stop="selectSourceBlank"
                  >
                    NONE
                  </v-chip>
                  <template v-for="item in SourceDefault">
                    <v-chip
                      small
                      class="este-chip tag-chip mb-2"
                      :class="item.active === true ? 'selected' : ''"
                      text-color="black"
                      @click="sourceToggle(item)"
                    >
                      {{ item.name | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import advancedSearch from "../../mixins/advancedSearch";
import globalFn from "../../mixins/globalFn";
import status from "../../mixins/status";
import _ from "lodash";
export default {
  name: "DealSearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dateFromMenu: false,
    dateToMenu: false,
    user: JSON.parse(localStorage.getItem("user")),
    filter: {
      search: "",
      deal_from: "",
      deal_to: "",
      branch: [],
      source: [],
      widget_filter: false,
      source_blank: 0,
    },
    search: "",
    isTyping: false,
  }),
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.filter.search = this.search;
        this.checkAll();
        this.searchKey();
      }
    },
  },
  mounted() {
    this.loadDefaults();
  },
  methods: {
    async searchKey() {
      this.$root.$emit("searchDeals", this.filter);
    },

    async reset() {
      await this.resetFilter();
      await this.loadDefaults();
    },
    async resetFilter() {
      this.filter.widget_filter = true;
      this.filter.search = "";
      this.filter.deal_from = "";
      this.filter.deal_to = "";
    },
  },
};
</script>
