<template>
  <v-card class="mt-6 pb-5 contact--card overflow-x-hidden overflow-y-auto" :max-height="700">
    <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
      Contact Activity
    </v-card-title>
    <v-divider class="my-5"></v-divider>
    <v-card-text class="px-7 py-0">
      <v-timeline align-top dense class="pt-0 ml-n10">
        <template v-for="(item, index) in audit">
          <template
            v-if="item.url !== null && getURLLastParam(item.url) === 'convert-lead-to-client'"
          >
            <v-timeline-item class="doc-timeline" color="secondary" x-small fill-dot>
              <v-row dense class="e-row">
                <v-col cols="12" md="12" class="e-col doc-item">
                  <div class="date">
                    {{ item.created_at | moment("MMMM Do YYYY") }} at
                    {{ item.created_at | moment("h:mm:ss a") }}
                  </div>
                  <div class="file-section">Lead is converted as Client</div>
                  <div class="added-user">
                    <span class="float-left user">By {{ item.owner_name }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </template>
          <template
            v-if="getURLLastParam(item.url) !== 'convert-lead-to-client' || item.url === null"
          >
            <template
              v-if="
                item.model !== 'Comment' &&
                item.event === 'created' &&
                Object.keys(item.old_values).length === 0
              "
            >
              <v-timeline-item class="doc-timeline mb-6" color="secondary" x-small fill-dot>
                <div class="date">
                  {{ item.created_at }}
                </div>
                <v-expansion-panels class="timeline-expansion" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <template
                        v-if="
                          item.url !== null &&
                          getURLLastParam(item.url) === 'create-deal' &&
                          item.model === 'Appointment'
                        "
                      >
                        Treatment {{ item.model }}
                      </template>
                      <template v-else>
                        {{ item.model }}
                      </template>
                      is {{ item.event }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="group-panel-content">
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Field</th>
                              <th class="text-left">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(value, key) in item.new_values">
                              <td>{{ capitalize(key.replace(/_/g, " ")) }}</td>
                              <td>{{ value }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div class="added-user">
                  <span class="float-left user"> By {{ item.owner_name }}</span>
                </div>
              </v-timeline-item>
            </template>
            <template v-else>
              <template v-for="(value, key) in item.new_values">
                <v-timeline-item
                  class="doc-timeline"
                  v-if="value !== null && key !== 'category'"
                  :key="index.key"
                  color="secondary"
                  x-small
                  fill-dot
                >
                  <v-row dense class="e-row">
                    <v-col cols="12" md="12" class="e-col doc-item">
                      <div class="date">{{ item.created_at }}</div>
                      <template v-if="key === 'is_urgent'">
                        <div class="file-section">
                          <div class="caption">
                            <div class="read-less">
                              <div class="d-flex flex-column">
                                <span>
                                  <span>{{ item.model }}</span>
                                  <span v-if="value">is flagged</span>
                                  <span v-if="!value">is unflagged</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="file-section" :class="key === 'status' ? 'status' : ''">
                          <ReadMoreText
                            @refreshAudit="loadAudit"
                            :data="item"
                            :event="item.event"
                            :moduleName="item.model"
                            :category="key"
                            :comment="value"
                          ></ReadMoreText>
                        </div>
                      </template>
                      <div class="added-user">
                        <span class="float-left user"> By {{ item.owner_name }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
            </template>
          </template>
        </template>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import ReadMoreText from "./ReadMoreText.vue";
import globalFn from "../../mixins/globalFn";
export default {
  name: "WidgetActivity",
  mixins: [globalFn],
  components: {
    ReadMoreText,
  },
  data() {
    return {
      audit: [],
      auditLoading: false,
    };
  },
  mounted() {
    this.loadAudit();
  },
  methods: {
    async loadAudit() {
      this.auditLoading = true;
      await this.$store
        .dispatch(
          "audit/GET_AUDIT",
          `?filters=[{"user_id":[{"equ":${this.$router.currentRoute.params.id}}]}]&attributes=comments,commentCalls`
        )
        .then((res) => {
          res.data = res.data.map(function (el) {
            let item = Object.assign({}, el);
            item.isMarked = false;

            if (el.comment_calls.length > 0) {
              const first = el.comment_calls[0];
              item.isMarked = first.is_marked;
            }
            return item;
          });
          this.audit = res.data;
          this.auditLoading = false;
        });
    },
    getURLLastParam(url) {
      if (url) {
        let parts = url.split("/");
        let lastSegment = parts.pop() || parts.pop();
        return lastSegment;
      }
    },
    HasAuditHistory() {
      let res = false;
      res = this.audit.filter((e) => e.model === "Lead" && e.event === "created").length > 0;
      return res;
    },
    HasAuditConvertToLeadUrl() {
      let res = false;
      res =
        this.audit.filter((e) => e.url && e.url.split("/").pop() === "convert-lead-to-client")
          .length > 0;
      return res;
    },
  },
};
</script>
<style lang="css" scoped></style>
