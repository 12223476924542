<template>
  <div>
    <v-card class="mt-3 pb-5">
      <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
        Opportunities
      </v-card-title>
      <v-divider class="my-5"></v-divider>
      <v-card-text class="px-7 py-0">
        <v-skeleton-loader
          class="widget-card"
          v-if="loading"
          type="table-row-divider@6"
        ></v-skeleton-loader>
        <v-data-table
          :item-class="itemRowBackground"
          :fixed-header="true"
          v-if="!loading"
          class="results-table"
          item-key="id"
          :headers="headers"
          :items="opportunities"
        >
          <template v-slot:item.id="{ item }">
            <span
              class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border cursor"
              @click="editItem(item)"
            >
              {{ item.id }}
            </span>
          </template>
          <template v-slot:item.enquiry="{ item }">
            <div class="d-flex flex-column">
              <span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
                {{ item.enquiry_date | moment("DD/MM/YY") }}
              </span>
              <span
                class="d-flex font-13 line-h-16 font-weight--semibold text-black-300"
                v-if="item.follow_up_date"
              >
                {{ item.follow_up_date | moment("DD/MM/YY") }} {{ item.follow_up_time }}
                <img
                  src="/images/icons/duotune/arrows/arr050.svg"
                  class="text-black-100 ma-0 ml-1"
                  width="18"
                  height="18"
                />
              </span>
            </div>
          </template>
          <template v-slot:item.responsible="{ item }">
            <v-tooltip
              content-class="este-tooltip"
              color="#000"
              text-color="#fff"
              top
              v-if="item.responsible !== null"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor"
                >
                  {{ getInitials(item.responsible.name) }}
                </span>
              </template>
              <span v-if="item.responsible !== null">{{ item.responsible.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.source="{ item }">
            <span
              v-if="item.source_name"
              class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
            >
              {{ item.source_name }}
            </span>
            <div class="mt-2" v-if="item.campaigns.length > 0">
              <template v-for="(campaign, index) in item.campaigns.slice(0, 1)">
                <span
                  class="badge--chip font-weight--semibold white--text font-11 line-h-13"
                  :style="`background: ${campaign.color}`"
                  :key="index"
                >
                  {{ campaign.chip | capitalize }}</span
                >
              </template>
              <span
                class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                v-if="item.campaigns.length > 1"
                >+{{ item.campaigns.length - 1 }}</span
              >
            </div>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <div class="d-flex flex-column width-100">
              <span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1">
                {{ item.updated_at }}
              </span>
            </div>
          </template>
          <template v-slot:item.treatments="{ item }">
            <template v-if="item.treatments.length > 0">
              <template v-for="(val, index) in item.treatments.slice(0, 1)">
                <span
                  :key="index"
                  class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"
                >
                  {{ val.name }}
                </span>
              </template>
              <span
                class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"
                v-if="item.treatments.length > 1"
                >+{{ item.treatments.length - 1 }}</span
              >
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="d-flex flex-column align-start justify-start">
              <template v-for="(val, index) in statusTags">
                <span
                  class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13"
                  :style="
                    val.outline ? `border: 1px solid ${val.color}` : `background: ${val.color}`
                  "
                  :key="index"
                  v-if="item.status === val.id"
                  >{{ val.name }}</span
                >
              </template>
              <div class="mt-2" v-if="item.branch_name">
                <span
                  class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"
                  >{{ item.branch_name }}</span
                >
              </div>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex align-center justify-end">
              <v-btn
                v-if="item.is_urgent"
                :elevation="0"
                class="bg-red-100 btn-action mr-2"
                @click="flagStatus(item, 0)"
              >
                <inline-svg
                  class="white--text"
                  width="16"
                  height="16"
                  src="/images/icons/duotune/maps/map001.svg"
                />
              </v-btn>
              <v-btn
                v-if="!item.is_urgent"
                :elevation="0"
                class="bg-gray-100 btn-action mr-2"
                @click="flagStatus(item, 1)"
              >
                <inline-svg
                  class="text-black-100"
                  width="16"
                  height="16"
                  src="/images/icons/duotune/maps/map001.svg"
                />
              </v-btn>
              <v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
                <img height="16" src="/images/icons/duotune/general/gen055.svg" />
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"
            ><img src="/images/close.svg" alt="Close"
          /></a>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn
              class="cancel-btn btn-gap"
              depressed
              rounded
              outlined
              @click="modalDialog = false"
              color="dark"
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
              class="delete-btn"
              :color="actionText === 'Archive' ? 'red' : 'secondary'"
              depressed
              @click="handleFunction(action)"
              rounded
              x-large
            >
              {{ actionText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import globalFn from "../../mixins/globalFn";
import sliderFn from "../../mixins/sliderFn";
import status from "../../mixins/status";
export default {
  name: "WidgetOpportunities",
  mixins: [globalFn, status, sliderFn],
  data() {
    return {
      loading: false,
      opportunities: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "Source", value: "source", sortable: false },
        { text: "Treatments", value: "treatments", sortable: false },
        { text: "Staff", value: "responsible", sortable: false },
        { text: "Enquiry", value: "enquiry", sortable: false },
        { text: "Updated", value: "updated_at", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "action", sortable: false, align: "end" },
      ],
      message: "",
      action: "",
      actionText: "",
      enrolleUserValue: 0,
      flagStatusValue: 0,
      modalDialog: false,
      selectedItem: null,
    };
  },
  mounted() {
    this.getWidgetData();
    this.$root.$on("refreshOppsWidget", this.getWidgetData);
  },
  methods: {
    async getWidgetData() {
      this.loading = true;
      await this.$store
        .dispatch("contacts/OPPORTUNITIES", { user_id: this.$router.currentRoute.params.id })
        .then((res) => {
          this.opportunities = res.data;
        });
      this.loading = false;
    },
    async editItem(item) {
      await this.$store.dispatch("opportunities/SHOW", { id: item.id }).then((res) => {
        if (this.$vuetify.breakpoint.name === "lg" || this.$vuetify.breakpoint.name === "xl") {
          this.openDrawer("add", "1100", "opportunities/editPane");
        } else {
          this.openDrawer("add", "750", "opportunities/editTab");
        }
      });
    },
    itemRowBackground(item) {
      return item.is_urgent ? "bg-red-200" : "";
    },
    handleFunction(funName) {
      this[funName]();
    },
    async flagStatus(item, val) {
      this.selectedItem = item;
      this.flagStatusValue = val;
      this.modalDialog = true;
      this.message = `Please confirm you want to ${val ? "flag" : "unflag"} this opportunity?`;
      this.actionText = "Confirm";
      this.action = "flagOpportunity";
    },
    async flagOpportunity() {
      await this.$store
        .dispatch(`opportunities/UPDATE`, {
          id: this.selectedItem.id,
          is_urgent: this.flagStatusValue,
        })
        .then((res) => {
          if (res.result === "success") {
            this.modalDialog = false;
            this.getWidgetData();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
<style lang="css" scoped></style>
