<template>
  <div>
    <v-tabs right v-model="dealTab" class="cust-tabs mb-5">
      <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
      <v-tab href="#payment" :class="dealTab === 'payment' ? 'v-tab--active' : ''">Payments</v-tab>
      <v-tab href="#appointment" :class="dealTab === 'appointment' ? 'v-tab--active' : ''"
        >Appointments</v-tab
      >
      <v-tab href="#products" :class="dealTab === 'appointment' ? 'v-tab--active' : ''"
        >Products</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="dealTab">
      <v-tab-item value="payment" :eager="true">
        <payments-history :dealId="dealId" :userId="userId"></payments-history>
      </v-tab-item>
      <v-tab-item value="appointment" :eager="true">
        <appointments-history :dealId="dealId" :userId="userId"></appointments-history>
      </v-tab-item>
      <v-tab-item value="products" :eager="true">
        <products-history :dealId="dealId" :userId="userId"></products-history>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import AppointmentsHistory from "./Appointments.vue";
import PaymentsHistory from "./Payments.vue";
import ProductsHistory from "./Products.vue";
export default {
  data: () => ({
    dealTab: null,
  }),
  components: {
    AppointmentsHistory,
    PaymentsHistory,
    ProductsHistory,
  },
  props: {
    data: {
      required: true,
    },
    dealId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
  created() {
    this.dealTab = "payment";
  },
};
</script>
