<template>
  <v-card flat class="pa-6">
    <div>
      <div class="d-flex justify-center align-center">
        <profile-picture
          :imageUrl="contact.picture"
          :name="contact.name"
          :editable="true"
          :height="60"
          :width="60"
          @edit="uploadImage"
        />
      </div>

      <!-- <div
        class="width-60 height-60 bg-gray-300 text-white-100 font-20 font-weight--bold d-flex rounded-100 mx-auto align-center justify-center"
      >
        {{ getInitials(contact.name) }}
      </div> -->
      <p class="my-3 font-16 lh-18 font-weight--bold text-black-100 text-center">
        {{ contact.name | capitalize }}
      </p>
    </div>
    <v-divider class="my-4"></v-divider>
    <p class="font-14 lh-16 text-black-100 font-weight--semibold pb-4">Details</p>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Referred By</span>
      <p></p>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Social handle</span>
      <p v-if="contact.social_handle">
        <img
          :src="`/images/${lowerCase(contact.source_name)}.svg`"
          class="mr-2"
          v-if="contact.source_name !== null"
        />
        {{ contact.social_handle }}
      </p>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Branch</span>
      <p>
        <span class="mr-1">
          <inline-svg src="/images/icons/map.svg" />
        </span>
        {{ contact.branch_name }}
      </p>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Gender</span>
      <p></p>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Email</span>
      <p>
        <span class="mr-1">
          <inline-svg src="/images/icons/at.svg" />
        </span>
        {{ contact.email }}
      </p>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column my-2">
      <span class="font-12 lh-14 text-gray-300 font-weight--regular mb-2">Contact No</span>
      <p>
        {{ contact.contact_no }}
        <v-tooltip
          content-class="copy-tooltip"
          :open-on-hover="false"
          :open-on-click="true"
          color="#000"
          text-color="#fff"
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon @click="copyContent(contact.contact_no)" v-on="on" size="15" class="ml-2"
              >mdi-content-copy</v-icon
            >
          </template>
          <span>Copied to clipboard</span>
        </v-tooltip>
      </p>
    </div>
  </v-card>
</template>
<script>
import status from "../../../mixins/status";
import globalFn from "../../../mixins/globalFn.js";
import modalFn from "../../../mixins/modalFn.js";
import profilePicture from "./profilePicture";

export default {
  name: "Profile",
  components: {
    profilePicture,
  },
  mixins: [status, globalFn, modalFn],
  props: ["contact"],
  methods: {
    uploadImage() {
      this.openModal("add", 600, "customers/upload.vue");
    },
  },
};
</script>
