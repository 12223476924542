import collection from "../../api/collections/User";
import asyncMiddleware from "../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    item: {},
  },
  mutations: {
    SET_USER(state, data) {
      state.item = data;
    },
  },
  actions: {
    async GET_USER({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload);
          commit("SET_USER", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_USER",
        true
      );
    },

    async GLOBAL_SEARCH({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.globalSearch(payload);
          return data;
        },
        commit,
        dispatch,
        "GLOBAL_SEARCH",
        true
      );
    },
  },
};
