import collection from "../../../api/collections/Clients";
import asyncMiddleware from "../../../utils/action-handler";

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    assignedTo: [],
    item: {},
    widget: {},
    pagination: {},
  },
  mutations: {
    SET_CLIENT(state, data) {
      state.list = data;
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data;
    },
    SET_CLIENT_LIST(state, data) {
      state.all = data;
    },
    SET_CLIENT_ITEM(state, data) {
      state.item = data;
    },
    RESET_CLIENT(state, data) {
      state.item = {};
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {};
    },
    SET_CLIENT_TABLE_PAGINATION(state, data) {
      state.pagination = data;
    },
  },
  actions: {
    async GET_CLIENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.get(payload.data, payload.params);
          commit("SET_CLIENT", data.data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CLIENT",
        true
      );
    },
    async GET_CLIENT_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.getList(payload);
          commit("SET_CLIENT_LIST", data.data);
          return data;
        },
        commit,
        dispatch,
        "GET_CLIENT_LIST",
        true
      );
    },
    async CREATE_CLIENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.create(payload);
          return data;
        },
        commit,
        dispatch,
        "CREATE_CLIENT",
        true
      );
      return data;
    },
    async SHOW_CLIENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          if (!payload.param) {
            payload.param = "";
          }
          const { data } = await collection.show(payload.id, payload.param);
          commit("SET_CLIENT_ITEM", data.data);
          return data;
        },
        commit,
        dispatch,
        "SHOW_CLIENT",
        false
      );
      return data;
    },
    async UPDATE_CLIENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.update(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "UPDATE_CLIENT",
        true
      );

      return data;
    },
    async ARCHIVE_CLIENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.archive(payload.id, payload);
          return data;
        },
        commit,
        dispatch,
        "ARCHIVE_CLIENT",
        true
      );

      return data;
    },
    async CONVERT_CLIENT_TO_LEAD({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.convertToLead(payload);
          return data;
        },
        commit,
        dispatch,
        "CONVERT_CLIENT_TO_LEAD",
        true
      );
    },

    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.comment(payload);
          return data;
        },
        commit,
        dispatch,
        "ADD_COMMENT",
        true
      );
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(
        async function () {
          const { data } = await collection.upload(payload);
          return data;
        },
        commit,
        dispatch,
        "UPLOAD_DOCS",
        true
      );
      return data;
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.clientCount(payload);
          commit("SET_WIDGET_COUNT", data.data);
          return data;
        },
        commit,
        dispatch,
        "WIDGET_COUNT",
        true
      );
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(
        async function () {
          const { data } = await collection.assignedTo(payload);
          commit("SET_ASSIGNED_TO", data.data);
          return data;
        },
        commit,
        dispatch,
        "ASSIGNED_TO",
        true
      );
    },
  },
};
