<template>
  {{ description }}
  <v-text-field
    :readonly="false"
    v-if="type === 'short_answer'"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
    class="solo-cust"
    placeholder="Short answer"
  ></v-text-field>

  <v-textarea
    :readonly="false"
    v-else-if="type === 'long_answer'"
    autocomplete="off"
    solo
    outlined
    flat
    hide-details="auto"
    class="solo-cust"
    placeholder="Long answer"
  ></v-textarea>

  <div v-else-if="['date', 'date_time'].includes(type)">
    <v-menu
      v-if="['date', 'date_time'].includes(type)"
      v-model="datepickerMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          autocomplete="off"
          prepend-inner-icon="mdil-calendar"
          clearable
          clear-icon="mdi-close-circle-outline"
          :value="dateUkFormat(date)"
          solo
          outlined
          v-on="on"
          flat
          hide-details="auto"
          class="solo-cust"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title @input="datepickerMenu = false"></v-date-picker>
    </v-menu>

    <v-text-field
      type="time"
      v-if="['time', 'date_time'].includes(type)"
      autocomplete="off"
      solo
      outlined
      flat
      hide-details="auto"
      class="solo-cust"
      placeholder="Time"
    ></v-text-field>
  </div>

  <div v-else-if="['checkbox', 'radio', 'dropdown', 'dropdown_multi_select'].includes(type)">
    <span v-for="(answer, index) in answers" :key="answer.id" class="d-flex align-center">
      <v-checkbox
        v-if="type === 'checkbox'"
        :readonly="true"
        autocomplete="off"
        :ripple="false"
        hide-details="auto"
      ></v-checkbox>

      <v-radio
        v-else-if="type === 'radio'"
        type="radio"
        :readonly="true"
        autocomplete="off"
        :ripple="false"
        hide-details="auto"
      ></v-radio>

      <p
        v-else-if="['dropdown', 'dropdown_multi_select'].includes(type)"
        class="text-subtitle-1 mr-2"
      >
        {{ answer.order }}
      </p>

      <v-text-field
        v-model="answer.title"
        placeholder="Please enter your option"
        autocomplete="off"
        solo
        outlined
        flat
        hide-details="auto"
        class="solo-cust mt-2"
      ></v-text-field>

      <v-btn icon small dense :elevation="0" @click="$emit('deleteOption', answer.id)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </span>
    <div class="d-flex align-center justify-end pa-4">
      <v-btn
        text
        class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold text-capitalize"
        :elevation="0"
        x-small
        @click="$emit('addOption')"
      >
        Add options
      </v-btn>
    </div>
  </div>
</template>

<script>
import globalFn from "../../mixins/globalFn";

export default {
  mixins: [globalFn],
  props: ["type", "answers"],
  date: () => ({
    datepickerMenu: false,
    date: new Date().toISOString(),
  }),
};
</script>

<style lang="scss" scoped></style>
