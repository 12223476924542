<template>
  <div>
    <div class="tab-content-wrapper">
      <template v-for="(item, k) in items">
        <div class="cell-item">
          <v-row dense class="e-row">
            <v-col cols="6" md="9">
              <img src="/images/bolt.svg" />
              <span class="cell-title" v-if="item.type_name === 'Repeat-sell'"
                >Repeat Treatment Opportunity</span
              >
              <span class="cell-title" v-if="item.type_name === 'Cross-sell'"
                >Cross sell Opportunity</span
              >
              <span class="cell-title" v-else-if="item.type_name === 'Upsell-Product'"
                >Product Upsell Opportunity</span
              >
              <span class="cell-title" v-else-if="item.type_name === 'Upsell-Service'"
                >Service Upsell Opportunity</span
              >
            </v-col>
            <v-col cols="6" md="3">
              <span class="cell-date">Generated {{ item.created_at | moment("DD/MM/YY") }}</span>
            </v-col>
          </v-row>
          <v-row dense class="e-row">
            <v-col cols="6" md="9">
              <v-chip small class="este-chip tag-chip" text-color="black">
                {{ item.treatment.name | capitalize }}
              </v-chip>
            </v-col>
            <v-col cols="6" md="3">
              <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <div class="logout-block">
                  <ul>
                    <!-- <li><a @click="createAppointment">Create Appointment</a></li> -->
                    <!-- <li><a @click="archive" class="text-mute">Archive</a></li> -->
                  </ul>
                </div>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    userId: {
      required: true,
    },
    keyId: {
      required: true,
    },
  },
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 20,
    items: [],
    user: JSON.parse(localStorage.getItem("user")),
    insightParams: {
      attributes: "user,deal,appointment,treatment,product",
      search: "",
      user_id: "",
      filter: {
        followup_date_from: "",
        followup_date_to: "",
        status: [],
        branch: [],
      },
    },
  }),
  computed: {},
  mounted() {
    this.insightParams.user_id = this.userId;
    this.loadInsights();
  },
  created() {},
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("insightsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async loadInsights() {
      await this.$store
        .dispatch("insights/GET_INSIGHT", {
          data: this.insightParams,
          params: `?page=${this.page}&per_page=${this.itemsPerPage}`,
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
    },
    async archive() {
      await this.$store
        .dispatch("insights/ARCHIVE_INSIGHT", { id: this.insight.id, status: 7 })
        .then((res) => {
          if (res.result === "success") {
            this.loadData();
          } else if (res.result === "fail") {
            res.type = "error";
            this.$store.dispatch("snackbar/SHOW", res);
          }
        })
        .catch((err) => {
          err.type = "error";
          this.$store.dispatch("snackbar/SHOW", err);
        });
    },
  },
};
</script>
