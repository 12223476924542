export default {
  data: () => ({
    loading: false,
    dateRaisedMenu: false,
    nextActionDateMenu: false,
    dropdown: true,
    selectedUser: null,
    deal_id: null,
    user: JSON.parse(localStorage.getItem("user")),
    rules: {
      user_id: [(v) => !!v || "Please select client"],
      responsible_id: [(v) => !!v || "Please select responsible person"],
    },
    staffParams: {
      branch: "",
      type: "",
    },
    clientParams: "?sort_by=name&sort_order=ASC",
    navigationTab: null,
    navigation: [
      {
        name: "Client Details",
        icon: "mdil-star",
        slug: "client",
        form: true,
      },
      {
        name: "Complaints",
        icon: "mdil-thumb-down",
        slug: "complaint",
        form: true,
      },
      {
        name: "Insights",
        icon: "mdil-chart-areaspline",
        slug: "insight",
        form: false,
      },
      {
        name: "Activity",
        icon: "mdi-history",
        slug: "activity",
        form: false,
      },
    ],
  }),
  mounted() {
    this.loadPersonal();
  },
  computed: {
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList;
      }
    },
    complaint() {
      if (this.$store.state.complaints.item) {
        return this.$store.state.complaints.item;
      }
    },
    nextActions() {
      if (this.$store.state.complaints.action) {
        return this.$store.state.complaints.action;
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority;
      }
    },
    escalation() {
      if (this.$store.state.complaints.escalation) {
        return this.$store.state.complaints.escalation;
      }
    },
    category() {
      if (this.$store.state.complaints.category) {
        return this.$store.state.complaints.category;
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all;
      }
    },
    clients() {
      if (this.$store.state.clients.all) {
        return this.$store.state.clients.all;
      }
    },
    dateRaisedUkFormat() {
      if (!this.complaint.date_raised) return null;
      const [year, month, day] = this.complaint.date_raised.split("-");
      return `${day}/${month}/${year}`;
    },
    nextActionUkFormat() {
      if (!this.complaint.next_action_date) return null;
      const [year, month, day] = this.complaint.next_action_date.split("-");
      return `${day}/${month}/${year}`;
    },
    personal() {
      if (this.$store.state.clients.item) {
        return this.$store.state.clients.item;
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list;
      }
    },
    dealsbyclient() {
      if (this.$store.state.deals.all) {
        return this.$store.state.deals.all;
      }
    },
  },
  created() {
    this.$store.dispatch("complaints/GET_NEXT_ACTION");
    this.$store.dispatch("branches/GET_ALL_BRANCH", "");
    this.$store.dispatch("complaints/GET_PRIORITY");
    this.$store.dispatch("complaints/GET_CATEGORY");
    this.$store.dispatch("complaints/GET_ESCALATION");
    this.$store.dispatch("staff/GET_STAFF_LIST", { data: this.staffParams, params: "" });
    this.$store.dispatch("clients/GET_CLIENT_LIST", this.clientParams);
    this.$store.dispatch("source/GET_SOURCE", "");
  },
  methods: {
    async loadPersonal() {
      await this.$store.dispatch("clients/SHOW_CLIENT", { id: this.complaint.user_id, param: "" });
      await this.loadDealsByClient();
    },
    async loadDealsByClient() {
      await this.$store.dispatch("deals/GET_DEAL_LIST", {
        data: { client_id: this.complaint.user_id },
        params: "",
      });
    },

    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("complaintsTableUpdate", "");
      this.$root.$emit("updateGlobalSearch", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async updateComplaint() {
      this.complaint.enquiry_date = this.changeDateFormat(this.enquiryUKformat);
      this.complaint.follow_up_date = this.changeDateFormat(this.followupUKformat);
      this.complaint.user_details = {};
      this.complaint.user_details.address_one = this.personal.address_one;
      this.complaint.user_details.address_two = this.personal.address_two;
      this.complaint.user_details.city = this.personal.city;
      this.complaint.user_details.postcode = this.personal.postcode;
      this.complaint.user_details.contact_no = this.personal.contact_no;
      if (this.personal.email) {
        this.complaint.user_details.email = this.personal.email;
      }
      this.complaint.user_details.branch_id = this.personal.branch_id;
      if (this.$refs.complaintForm.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("complaints/UPDATE_COMPLAINT", this.complaint)
          .then((response) => {
            if (response.result === "success") {
              this.loading = false;
              this.loadData();
            } else if (response.result === "fail") {
              response.type = "error";
              this.loading = false;
              this.$store.dispatch("snackbar/SHOW", response);
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
